import { ChakraProvider, extendTheme, useTheme, type SystemStyleObject } from "@chakra-ui/react"
import createCache from "@emotion/cache"
import { CacheProvider } from "@emotion/react"
import { useCallback, useEffect, useRef, useState } from "react"
import IframeComponent, { FrameContextConsumer, type FrameComponentProps } from "react-frame-component"

const Iframe = (
  props: Omit<FrameComponentProps, "children"> & { children?: React.ReactNode; documentStyle?: SystemStyleObject }
) => {
  const { children, style, documentStyle, ...rest } = props
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [height, setHeight] = useState(50)

  const handleResize = useCallback((iframe: React.RefObject<HTMLIFrameElement>) => {
    const height = iframe.current?.contentDocument?.body.scrollHeight ?? 0
    if (height !== 0) {
      setHeight(height)
    }
  }, [])

  useEffect(() => handleResize(iframeRef), [handleResize, iframeRef])

  const defaultTheme = useTheme()
  const theme = extendTheme(defaultTheme, {
    styles: {
      global: {
        body: {
          ...documentStyle,
        },
      },
    },
  })

  return (
    <IframeComponent
      {...rest}
      ref={iframeRef}
      style={{ height: `${height}px`, width: "100%", ...style }}
      contentDidUpdate={() => handleResize(iframeRef)}
      contentDidMount={() => handleResize(iframeRef)}
    >
      <FrameContextConsumer>
        {(frame) => {
          const head = frame.document?.head
          if (!head) return children
          return (
            <CacheProvider value={createCache({ container: head, key: "frame" })}>
              <ChakraProvider theme={theme}>{children}</ChakraProvider>
            </CacheProvider>
          )
        }}
      </FrameContextConsumer>
    </IframeComponent>
  )
}

export default Iframe
