import { hasPermission } from "@brm/schema-helpers/role.js"
import { Flex, Heading, HStack, Stack, useToast } from "@chakra-ui/react"
import { createColumnHelper } from "@tanstack/react-table"
import { FormattedMessage, useIntl, type IntlShape } from "react-intl"
import {
  useGetToolV1ListHiddenQuery,
  useGetUserV1WhoamiQuery,
  useGetVendorV1ListHiddenQuery,
  usePostToolV1ByIdHideMutation,
  usePostVendorV1ByIdHideMutation,
  type ToolMinimal,
  type VendorMinimal,
} from "../../app/services/generated-api.js"
import { ACTIONS_COLUMN_SIZE, PRIMARY_COLUMN_SIZE } from "../../components/DataTable/const.js"
import DataTable from "../../components/DataTable/DataTable.js"
import FullDataTableHeader from "../../components/DataTable/FullDataTableHeader.js"
import { ROOT_COLUMN_ID } from "../../components/DataTable/use-schema-columns.js"
import EnableIconButton from "../../components/icons/system/EnableIconButton.js"
import { Link } from "../../components/Link.js"
import { LONG_TOAST_DURATION } from "../../util/constant.js"
import { getAPIErrorMessage } from "../../util/error.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import NotFoundErrorView from "../error/NotFoundErrorView.js"
import ToolDisplay from "../tool/ToolDisplay.js"
import VendorDisplay from "../vendor/VendorDisplay.js"

const primaryColumn = ROOT_COLUMN_ID

const toolColumnHelper = createColumnHelper<ToolMinimal>()
const vendorColumnHelper = createColumnHelper<VendorMinimal>()

const getToolColumns = (intl: IntlShape, actionsComponent: (tool: ToolMinimal) => JSX.Element | null) => [
  toolColumnHelper.display({
    header: intl.formatMessage({
      id: "tool.settings.hidden.title",
      description: "Title for the hidden tools page",
      defaultMessage: "Tool Name",
    }),
    id: primaryColumn,
    size: PRIMARY_COLUMN_SIZE,
    cell: (item) => (
      <ToolDisplay
        toolLogoUrl={getPublicImageGcsUrl(item.row.original.image_asset?.gcs_file_name)}
        toolName={item.row.original.display_name}
      />
    ),
  }),
  toolColumnHelper.display({
    id: "tool-actions",
    size: ACTIONS_COLUMN_SIZE,
    cell: (item) => actionsComponent(item.row.original),
  }),
]

const getVendorColumns = (intl: IntlShape, actionsComponent: (vendor: VendorMinimal) => JSX.Element | null) => [
  vendorColumnHelper.display({
    header: intl.formatMessage({
      id: "vendor.settings.hidden.title",
      description: "Title for the hidden vendors page",
      defaultMessage: "Vendor Name",
    }),
    id: primaryColumn,
    size: PRIMARY_COLUMN_SIZE,
    cell: (item) => (
      <VendorDisplay
        vendorLogoUrl={getPublicImageGcsUrl(item.row.original.image_asset?.gcs_file_name)}
        vendorName={item.row.original.display_name}
      />
    ),
  }),
  vendorColumnHelper.display({
    id: "vendor-actions",
    size: ACTIONS_COLUMN_SIZE,
    cell: (item) => actionsComponent(item.row.original),
  }),
]

export default function ArchivedSettings() {
  const intl = useIntl()
  const toast = useToast()

  const { data: whoami } = useGetUserV1WhoamiQuery()
  const { data: hiddenTools } = useGetToolV1ListHiddenQuery()
  const { data: hiddenVendors } = useGetVendorV1ListHiddenQuery()

  const [hideTool] = usePostToolV1ByIdHideMutation()
  const [hideVendor] = usePostVendorV1ByIdHideMutation()

  if (!whoami || !hiddenTools || !hiddenVendors) {
    return null
  }

  if (!hasPermission(whoami.roles, "tool:read:hidden") && !hasPermission(whoami.roles, "vendor:read:hidden")) {
    return <NotFoundErrorView error={new Error("You are not authorized to access this page")} />
  }

  return (
    <Flex justifyContent="center" flexDir="column" gap={0} flexGrow={1} minHeight={0}>
      <FullDataTableHeader
        displayTitle={intl.formatMessage({
          id: "settings.archived.title",
          description: "Title for the archived page",
          defaultMessage: "Archived Entities",
        })}
      />

      <HStack flexGrow={1} alignItems="start" gap={8}>
        <Stack maxWidth="30%" flexGrow={1} padding={4}>
          <Heading size="md">
            <FormattedMessage
              id="tool.settings.hidden.title"
              description="Title for the hidden tools page"
              defaultMessage="Tools"
            />
          </Heading>
          {hiddenTools?.length > 0 ? (
            <DataTable<ToolMinimal>
              data={hiddenTools}
              columns={getToolColumns(intl, (tool: ToolMinimal) => (
                <HStack justifyContent="end">
                  <EnableIconButton
                    onClick={async () => {
                      try {
                        await hideTool({ id: tool.id, body: { hidden: false } }).unwrap()
                        toast({
                          description: (
                            <FormattedMessage
                              id="tool.enable.toast.title"
                              description="The title of the toast message when a tool is enabled"
                              defaultMessage="Tool enabled: {toolName}"
                              values={{
                                toolName: (
                                  <Link to={`/tools/${tool.id}`} fontWeight="bold">
                                    {tool.display_name}
                                  </Link>
                                ),
                              }}
                            />
                          ),
                          status: "success",
                          duration: LONG_TOAST_DURATION,
                        })
                      } catch (err) {
                        const title =
                          getAPIErrorMessage(err) ||
                          intl.formatMessage({
                            id: "tool.enable.toast.error",
                            description: "The title of the toast message when a tool enabling fails",
                            defaultMessage: "Failed to enable tool",
                          })
                        toast({ status: "error", title })
                      }
                    }}
                  />
                </HStack>
              ))}
              columnOrder={[primaryColumn]}
              isSticky
              stretchLastColumn
            />
          ) : (
            <FormattedMessage
              id="tool.settings.hidden.no.tools"
              description="Description for users to see when there are no hidden tools"
              defaultMessage="No archived tools found. Any tool that you hide for your organization will appear here."
            />
          )}
        </Stack>
        <Stack maxWidth="30%" flexGrow={1} padding={4}>
          <Heading size="md">
            <FormattedMessage
              id="vendor.settings.hidden.title"
              description="Title for the hidden vendors page"
              defaultMessage="Vendors"
            />
          </Heading>
          {hiddenVendors?.length > 0 ? (
            <DataTable<VendorMinimal>
              data={hiddenVendors}
              columns={getVendorColumns(intl, (vendor: VendorMinimal) => (
                <HStack justifyContent="end">
                  <EnableIconButton
                    onClick={async () => {
                      try {
                        await hideVendor({ id: vendor.id, body: { hidden: false } }).unwrap()
                        toast({
                          description: (
                            <FormattedMessage
                              id="vendor.enable.toast.title"
                              description="The title of the toast message when a vendor is enabled"
                              defaultMessage="Vendor enabled: {vendorName}"
                              values={{
                                vendorName: (
                                  <Link to={`/vendors/${vendor.id}`} fontWeight="bold">
                                    {vendor.display_name}
                                  </Link>
                                ),
                              }}
                            />
                          ),
                          status: "success",
                          duration: LONG_TOAST_DURATION,
                        })
                      } catch (err) {
                        const title =
                          getAPIErrorMessage(err) ||
                          intl.formatMessage({
                            id: "vendor.enable.toast.error",
                            description: "The title of the toast message when a vendor enabling fails",
                            defaultMessage: "Failed to enable vendor",
                          })
                        toast({ status: "error", title })
                      }
                    }}
                  />
                </HStack>
              ))}
              columnOrder={[primaryColumn]}
              isSticky
              stretchLastColumn
            />
          ) : (
            <FormattedMessage
              id="vendor.settings.hidden.no.vendors"
              description="Description for users to see when there are no archived vendors"
              defaultMessage="No archived vendors found. Any vendor that you hide for your organization will appear here."
            />
          )}
        </Stack>
      </HStack>
    </Flex>
  )
}
