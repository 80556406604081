import type { AgentResponseElement, Citation } from "@brm/schema-types/types.js"
import { WorkflowSoftwarePurchaseKindSchema, WorkflowSoftwareRenewalKindSchema } from "@brm/schemas"
import { uniqBy } from "@brm/util/collections.js"
import { getMarkdownConfig, normalizeGptMarkdownMath } from "@brm/util/markdown.js"
import {
  Button,
  Divider,
  HStack,
  Icon,
  Image,
  ListItem,
  OrderedList,
  // eslint-disable-next-line @typescript-eslint/no-restricted-imports
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Stack,
  Text,
  UnorderedList,
  chakra,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { AnimatedAreaSeries, AnimatedAxis, Tooltip, XYChart } from "@visx/xychart"
import { Fragment, memo, useMemo, type ReactNode } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import Markdown from "react-markdown"
import rehypeKatex from "rehype-katex"
import remarkMath from "remark-math"
import type { RenderElementProps } from "slate-react"
import { usePatchToolV1ByIdMutation } from "../../app/services/generated-api.js"
import { AgreementIcon } from "../../components/icons/icons.js"
import { Link, LinkOrSpan } from "../../components/Link.js"
import { pathForLegalAgreement } from "../../util/json-schema.js"
import { log } from "../../util/logger.js"
import InviteOrUpdateUserModal from "../organization/invites/InviteOrUpdateUserModal.js"
import StartWorkflowModal from "../workflows/run/start/StartWorkflowModal.js"

import "katex/dist/katex.min.css"

export interface AgentAction {
  name: string
  displayName: string
  onClick?: () => void
}

const RenderedMarkdown = memo(function RenderMarkdown({
  content,
  citationMap,
}: {
  content: string
  citationMap?: Record<string, Citation>
}) {
  const markdownConfig = getMarkdownConfig({
    remarkPlugins: [[remarkMath, { singleDollarTextMath: false }]],
    rehypePlugins: [rehypeKatex],
  })
  return (
    <Markdown
      {...markdownConfig}
      allowedElements={[...(markdownConfig.allowedElements ? markdownConfig.allowedElements : []), "code"]}
      components={{
        p: ({ node: _, ...props }) => <chakra.p {...props} />,
        hr: ({ node: _, ...props }) => <Divider {...props} />,
        ul: ({ node: _, ...props }) => <UnorderedList {...props} pl={3} whiteSpace="normal" />,
        ol: ({ node: _, ...props }) => <OrderedList {...props} pl={3} whiteSpace="normal" />,
        li: ({ node: _, ...props }) => <ListItem {...props} />,
        a: ({ node: _, ...props }) => {
          const href = props.href
          const citationMatch = href?.match(/^citation-(.*)$/u)
          const citationId = citationMatch?.[1]
          const citation = citationId && citationMap ? citationMap[citationId] : undefined
          return citation ? (
            <Citation key={`citation-${citationId}`} citation={citation}>
              {props.children}
            </Citation>
          ) : (
            <LinkOrSpan target="_blank" {...props} to={props.href} variant="highlighted" />
          )
        },
        img: ({ node: _, ...props }) => <Image {...props} />,
        blockquote: ({ node: _, ...props }) => (
          <chakra.blockquote borderLeftWidth={4} borderColor="gray.100" pl={2} mb={2} color="gray.700" {...props} />
        ),
        code: ({ node: _, ...props }) => <chakra.code {...props} px={1} py={0.5} rounded="sm" whiteSpace="pre-wrap" />,
      }}
    >
      {normalizeGptMarkdownMath(content)}
    </Markdown>
  )
})

interface SpendChartDatum {
  // eslint-disable-next-line no-restricted-globals
  x: Date
  y: string
}

const parseChartData = (chartDefinition: string) => {
  const chartNameRegex = /Chart Name: (.+)/u
  const xAxisRegex = /X Axis: \[(.+)\]/u
  const yAxisRegex = /Y Axis: \[(.+)\]/u

  const chartNameMatch = chartDefinition.match(chartNameRegex)
  const xAxisMatch = chartDefinition.match(xAxisRegex)
  const yAxisMatch = chartDefinition.match(yAxisRegex)

  const chartName = chartNameMatch && chartNameMatch[1] ? chartNameMatch[1] : ""
  const xAxisData = xAxisMatch && xAxisMatch[1] ? xAxisMatch[1].split(", ") : []
  const yAxisData = yAxisMatch && yAxisMatch[1] ? yAxisMatch[1].split(", ") : []

  return {
    chartName,
    xAxisData,
    yAxisData,
  }
}

const parseActionData = (actionData: string) => {
  const nameRegex = /Name: (.+)/u
  const typeRegex = /Type: (.+)/u
  const firstNameRegex = /First Name: (.+)/u
  const lastNameRegex = /Last Name: (.+)/u
  const emailRegex = /Email: (.+)/u
  const toolIdRegex = /Tool ID: (.+)/u
  const ownerIdRegex = /Owner ID: (.+)/u
  const requestTypeRegex = /Request Type: (.+)/u

  const nameMatch = actionData.match(nameRegex)
  const typeMatch = actionData.match(typeRegex)
  const firstNameMatch = actionData.match(firstNameRegex)
  const lastNameMatch = actionData.match(lastNameRegex)
  const emailMatch = actionData.match(emailRegex)
  const toolIdMatch = actionData.match(toolIdRegex)
  const ownerIdMatch = actionData.match(ownerIdRegex)
  const requestTypeMatch = actionData.match(requestTypeRegex)

  const name = nameMatch && nameMatch[1] ? nameMatch[1] : ""
  const type = typeMatch && typeMatch[1] ? typeMatch[1] : ""
  const firstName = firstNameMatch && firstNameMatch[1] ? firstNameMatch[1] : ""
  const lastName = lastNameMatch && lastNameMatch[1] ? lastNameMatch[1] : ""
  const email = emailMatch && emailMatch[1] ? emailMatch[1] : ""
  const toolId = toolIdMatch && toolIdMatch[1] ? toolIdMatch[1] : ""
  const ownerId = ownerIdMatch && ownerIdMatch[1] ? ownerIdMatch[1] : ""
  const requestType = requestTypeMatch && requestTypeMatch[1] ? requestTypeMatch[1] : ""

  return {
    name,
    type,
    firstName,
    lastName,
    email,
    toolId,
    ownerId,
    requestType,
  }
}

/**
 * This wrapped component is used to render the text from an agent using our custom rendering logic.
 * isLoading and status are hardcoded because the component is not intended to be used with streaming,
 * it only renders the complete, final text from an agent.
 */
export const AgentResponseDisplay = ({
  element,
}: RenderElementProps & {
  element: AgentResponseElement
}) => {
  const { children } = element
  const content = children[0]?.text ?? ""
  return <AgentResponse isLoading={false} content={content} status="waiting" />
}

const CitationSource = ({ source, key }: { source: Citation["source"]; key?: string }) => {
  switch (source.object_type) {
    case "LegalAgreement":
      return (
        <Link
          key={key}
          variant="highlighted"
          colorScheme="gray"
          to={source.link ?? pathForLegalAgreement(source.id, false)}
          target="_blank"
          width="full"
          borderWidth="1px"
          borderStyle="solid"
          borderColor="gray.300"
          color="gray.600"
          borderRadius="md"
          backgroundColor="gray.50"
          padding={2}
          gap={1}
          display="flex"
          alignItems="center"
          textOverflow="ellipsis"
          isTruncated
          overflow="hidden"
        >
          <Icon as={AgreementIcon} />
          {source.display_name ?? (
            <FormattedMessage
              id="citation.source.document"
              description="Label for source document in citation popover"
              defaultMessage="View Legal Agreement"
            />
          )}
        </Link>
      )
    default:
      return null
  }
}

const Citation = ({ citation, children }: { children: ReactNode; citation: Citation }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Popover placement="top" trigger="hover" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <chakra.span
          cursor="pointer"
          backgroundColor="warning.100"
          borderRadius="sm"
          _hover={{
            textDecoration: "underline",
          }}
        >
          {children}
        </chakra.span>
      </PopoverTrigger>
      <PopoverContent p={4} maxW="400px" borderRadius="lg">
        <Stack spacing={2}>
          <Text fontWeight="normal" color="gray.600" marginLeft={2} borderLeftWidth={2} borderColor="gray.200" pl={2}>
            <RenderedMarkdown content={citation.source_snippet} />
          </Text>
          <CitationSource source={citation.source} />
        </Stack>
      </PopoverContent>
    </Popover>
  )
}

export const AgentResponse = (props: {
  isLoading: boolean
  status?: string
  content: string
  actions?: AgentAction[]
  citations?: Citation[] | undefined
}) => {
  const { isLoading } = props

  const [updateOwner, updateOwnerResult] = usePatchToolV1ByIdMutation()
  const inviteModal = useDisclosure()
  const workflowRunModal = useDisclosure()
  const intl = useIntl()
  const toast = useToast()

  const citationMap = useMemo(
    () => Object.fromEntries(props.citations?.map((citation) => [citation.citation_id, citation]) ?? []),
    [props.citations]
  )
  const cursor = "●"

  let message = isLoading
    ? `${props.content} ${cursor} ${props.status && props.status !== "waiting" && props.status !== "citations" ? `\n${props.status}...` : ""}`
    : props.content

  let preExtraMessage = ""
  const extras: Array<React.ReactElement> = []
  const sources: Citation["source"][] = []

  // Replace citations with markdown links, citation rendering will be handled by the markdown renderer
  if (message.includes("</citation>")) {
    message = message.replace(/<citation id="(.*?)">(.*?)<\/citation>/gu, (_, citationId, citationText) => {
      return `[${citationText}](citation-${citationId})`
    })
    sources.push(
      ...uniqBy(
        props.citations?.map((citation) => citation.source) ?? [],
        (source) => `${source.object_type}-${source.id}`
      )
    )
  }

  if (message.includes("<action>")) {
    preExtraMessage = message.substring(0, message.indexOf("<action>"))
    if (isLoading && !message.includes("</action>")) {
      return (
        <>
          <RenderedMarkdown content={preExtraMessage} />
          <RenderedMarkdown content={`Preparing Action...  ${cursor}`} />
        </>
      )
    }
    const actionData = message.substring(message.indexOf("<action>") + "<action>".length, message.indexOf("</action>"))
    message = message.substring(message.indexOf("</action>") + "</action>".length)
    const { type, name, firstName, lastName, email, toolId, ownerId, requestType } = parseActionData(actionData)

    if (type === "invite" && name && firstName && lastName && email) {
      extras.push(
        <Fragment key={`${type}-${firstName}-${lastName}`}>
          <InviteOrUpdateUserModal
            isOpen={inviteModal.isOpen}
            onClose={inviteModal.onClose}
            initialState={{
              first_name: firstName,
              last_name: lastName,
              email,
              roles: [],
            }}
          />
          <Button minWidth="50%" alignSelf="center" colorScheme="brand" mb={1} onClick={inviteModal.onOpen}>
            {name}
          </Button>
        </Fragment>
      )
    } else if (type === "change_owner" && name && ownerId && toolId) {
      extras.push(
        <Button
          key={`${type}-${firstName}-${lastName}`}
          isLoading={updateOwnerResult.isLoading}
          minWidth="50%"
          alignSelf="center"
          colorScheme="brand"
          mb={1}
          onClick={async () => {
            try {
              await updateOwner({
                id: toolId,
                toolPatch: { id: toolId, object_type: "Tool", owner: { id: ownerId } },
              }).unwrap()
              toast({
                description: intl.formatMessage({
                  id: "betsy.owner.change",
                  description: "Toast success message when updating owner of a tool",
                  defaultMessage: "Owner updated successfully",
                }),
                status: "success",
              })
            } catch (err) {
              toast({
                description: intl.formatMessage({
                  id: "betsy.owner.change",
                  description: "Toast failure message when updating owner of a tool",
                  defaultMessage: "Failed to update owner",
                }),
                status: "error",
              })
              log.error("failed to update owner", err)
            }
          }}
        >
          {name}
        </Button>
      )
    } else if (type === "create_request") {
      const requestKind =
        requestType === WorkflowSoftwareRenewalKindSchema.const ||
        requestType === WorkflowSoftwarePurchaseKindSchema.const
          ? requestType
          : undefined
      extras.push(
        <Fragment key={`${type}-${firstName}-${lastName}`}>
          <Button minWidth="50%" alignSelf="center" colorScheme="brand" mb={1} onClick={workflowRunModal.onOpen}>
            {name}
          </Button>
          <StartWorkflowModal kind={requestKind} toolId={toolId} {...workflowRunModal} />
        </Fragment>
      )
    }
  }

  if (message.includes("<chart>")) {
    preExtraMessage = message.substring(0, message.indexOf("<chart>"))
    if (isLoading && !message.includes("</chart>")) {
      return (
        <>
          <RenderedMarkdown content={preExtraMessage} />
          <RenderedMarkdown content={`Preparing Chart...  ${cursor}`} />
        </>
      )
    }
    /* eslint-disable no-restricted-globals */
    const chartData = message.substring(message.indexOf("<chart>") + "<chart>".length, message.indexOf("</chart>"))
    message = message.substring(message.indexOf("</chart>") + "</chart>".length)

    const { chartName, xAxisData, yAxisData } = parseChartData(chartData)
    const data: SpendChartDatum[] = xAxisData.map((x, i) => ({
      x: new Date(
        Temporal.PlainDate.from(x)
          .toPlainDateTime({ hour: 0, minute: 0, second: 0 })
          .toZonedDateTime(Temporal.Now.timeZoneId()).epochMilliseconds
      ),
      y: yAxisData[i] ?? "",
    }))

    extras.push(
      <Fragment key={`chart-${chartName}`}>
        <Text align="center" mb={1}>
          {chartName}
        </Text>
        <XYChart
          margin={{ left: 30, top: 5, right: 0, bottom: 40 }}
          xScale={{ type: "time" }}
          yScale={{ type: "linear" }}
          width={500}
          height={300}
        >
          <AnimatedAxis
            numTicks={Math.min(data.length, 5)}
            orientation="bottom"
            tickFormat={(value: Date) =>
              // eslint-disable-next-line no-restricted-properties
              intl.formatDate(value, { year: "2-digit", month: "short", day: "numeric" })
            }
          />
          <AnimatedAxis
            orientation="left"
            tickFormat={(amount) =>
              intl.formatNumber(amount, {
                compactDisplay: "short",
                notation: "compact",
                style: "currency",
                currency: "USD",
              })
            }
          />
          <AnimatedAreaSeries
            fillOpacity={0.2}
            data={data}
            dataKey="Spend"
            xAccessor={(d) => d.x}
            yAccessor={(d) => parseFloat(d.y ?? "50")}
          />
          <Tooltip<SpendChartDatum>
            snapTooltipToDatumX={true}
            snapTooltipToDatumY={true}
            showVerticalCrosshair={true}
            renderTooltip={(data) => (
              <>
                <Text fontWeight="bold" mb={1}>
                  {`$${data.tooltipData?.nearestDatum?.datum?.y}`}
                </Text>
                <Text fontWeight="normal" mb={1}>
                  {
                    // eslint-disable-next-line no-restricted-properties
                    intl.formatDate(data.tooltipData?.nearestDatum?.datum?.x, {
                      year: "2-digit",
                      month: "short",
                      day: "numeric",
                    })
                  }
                </Text>
              </>
            )}
          />
        </XYChart>
      </Fragment>
    )
  }

  return (
    <Stack gap={2}>
      {preExtraMessage !== "" && <RenderedMarkdown content={preExtraMessage} />}
      {extras.length > 0 && <HStack alignItems="start">{extras}</HStack>}
      {message && <RenderedMarkdown content={message} citationMap={citationMap} />}
      {!isLoading && props.actions && props.actions.length > 0 && (
        <Text color="gray.400" fontWeight="light">
          {props.actions?.map((action, index) => {
            if (action.onClick) {
              return (
                <HStack key={action.name + index}>
                  <i>{action.displayName}</i>
                  <Button onClick={action.onClick} variant="ghost">
                    <FormattedMessage
                      id={`agent.action.${action.name}`}
                      description="Button text for agent actions"
                      defaultMessage="View Email"
                    />
                  </Button>
                </HStack>
              )
            }

            return <i key={action.name}>{action.displayName}</i>
          })}
        </Text>
      )}
      {props.status === "citations" && (
        <HStack alignItems="center" paddingTop={2}>
          <Spinner size="sm" />
          <FormattedMessage
            id="agent.status.citations"
            description="Status message for citations"
            defaultMessage="Connecting sources..."
          />
        </HStack>
      )}
      {sources.length > 0 && (
        <Stack paddingTop={2} gap={1}>
          <Text fontWeight="medium" color="gray.400" paddingBottom={1}>
            <FormattedMessage id="agent.status.sources" description="Subtitle for sources" defaultMessage="Sources" />
          </Text>
          {sources.map((source) => (
            <CitationSource key={source.id} source={source} />
          ))}
        </Stack>
      )}
    </Stack>
  )
}
