import type { Message } from "@brm/schema-types/types.js"

export const defaultMessages: Message[] = [
  {
    content: "Hi, I will gladly answer your questions about tools, vendors, and more!",
    role: "assistant",
    tool_calls: [],
    citations: null,
  },
]
export const defaultSuggestedPrompts = ["What is BRM?", "Who owns BRM?", "Purchase BRM"]

export const defaultLiteMessages: Message[] = [
  {
    content: "Hi, I will gladly answer your questions about agreements, renewals, and more!",
    role: "assistant",
    tool_calls: [],
    citations: null,
  },
]
export const defaultLiteSuggestedPrompts = ["What is the one thing I need to be aware of in my Legal Agreements?"]
