import { brmApi as api } from "./brm-api"
export const addTagTypes = [
  "field-config",
  "workflow-definitions",
  "workflow-runs",
  "user",
  "user-settings",
  "user-email-delegation",
  "user-notifications",
  "organization-users",
  "organization",
  "spend",
  "persons",
  "tools",
  "timeline",
  "legal",
  "vendors",
  "documents",
  "integrations",
  "initial-extraction-banner",
  "logins",
  "workflow-run-form-state",
  "workflow-run-step",
  "saved-view",
  "betsy",
  "workflow-field-timeline",
  "notifications",
  "tasks",
  "dynamo",
  "credit-card",
  "onboarding",
] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getOpenapi: build.query<GetOpenapiApiResponse, GetOpenapiApiArg>({
        query: () => ({ url: `/openapi` }),
      }),
      getSchemaV1ByObjectType: build.query<GetSchemaV1ByObjectTypeApiResponse, GetSchemaV1ByObjectTypeApiArg>({
        query: (queryArg) => ({ url: `/schema/v1/${queryArg.objectType}` }),
        providesTags: ["field-config"],
      }),
      getSchemaV1ByObjectTypePatch: build.query<
        GetSchemaV1ByObjectTypePatchApiResponse,
        GetSchemaV1ByObjectTypePatchApiArg
      >({
        query: (queryArg) => ({
          url: `/schema/v1/${queryArg.objectType}/patch`,
          params: {
            link_code: queryArg.linkCode,
          },
        }),
        providesTags: ["field-config"],
      }),
      getSchemaV1ByObjectTypeInput: build.query<
        GetSchemaV1ByObjectTypeInputApiResponse,
        GetSchemaV1ByObjectTypeInputApiArg
      >({
        query: (queryArg) => ({
          url: `/schema/v1/${queryArg.objectType}/input`,
          params: {
            link_code: queryArg.linkCode,
          },
        }),
        providesTags: ["field-config"],
      }),
      getSchemaV1Fields: build.query<GetSchemaV1FieldsApiResponse, GetSchemaV1FieldsApiArg>({
        query: () => ({ url: `/schema/v1/fields` }),
        providesTags: ["field-config", "workflow-definitions"],
      }),
      putSchemaV1FieldsDocumentExtractionJob: build.mutation<
        PutSchemaV1FieldsDocumentExtractionJobApiResponse,
        PutSchemaV1FieldsDocumentExtractionJobApiArg
      >({
        query: (queryArg) => ({ url: `/schema/v1/fields/document-extraction-job`, method: "PUT", body: queryArg.body }),
        invalidatesTags: ["field-config"],
      }),
      postSchemaV1FieldsDuplicates: build.query<
        PostSchemaV1FieldsDuplicatesApiResponse,
        PostSchemaV1FieldsDuplicatesApiArg
      >({
        query: (queryArg) => ({
          url: `/schema/v1/fields/duplicates`,
          method: "POST",
          body: queryArg.similarFieldQuery,
        }),
      }),
      putSchemaV1ByObjectTypeFieldsOrder: build.mutation<
        PutSchemaV1ByObjectTypeFieldsOrderApiResponse,
        PutSchemaV1ByObjectTypeFieldsOrderApiArg
      >({
        query: (queryArg) => ({
          url: `/schema/v1/${queryArg.objectType}/fields/order`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["field-config"],
      }),
      putSchemaV1ByObjectTypeFieldsAndFieldName: build.mutation<
        PutSchemaV1ByObjectTypeFieldsAndFieldNameApiResponse,
        PutSchemaV1ByObjectTypeFieldsAndFieldNameApiArg
      >({
        query: (queryArg) => ({
          url: `/schema/v1/${queryArg.objectType}/fields/${queryArg.fieldName}`,
          method: "PUT",
          body: queryArg.fieldConfigInput,
        }),
        invalidatesTags: ["field-config", "workflow-definitions", "workflow-runs"],
      }),
      deleteSchemaV1ByObjectTypeFieldsAndFieldName: build.mutation<
        DeleteSchemaV1ByObjectTypeFieldsAndFieldNameApiResponse,
        DeleteSchemaV1ByObjectTypeFieldsAndFieldNameApiArg
      >({
        query: (queryArg) => ({
          url: `/schema/v1/${queryArg.objectType}/fields/${queryArg.fieldName}`,
          method: "DELETE",
        }),
        invalidatesTags: ["field-config", "workflow-definitions", "workflow-runs"],
      }),
      postSchemaV1FieldsTextToField: build.mutation<
        PostSchemaV1FieldsTextToFieldApiResponse,
        PostSchemaV1FieldsTextToFieldApiArg
      >({
        query: (queryArg) => ({ url: `/schema/v1/fields/text-to-field`, method: "POST", body: queryArg.body }),
      }),
      postSchemaV1Map: build.query<PostSchemaV1MapApiResponse, PostSchemaV1MapApiArg>({
        query: (queryArg) => ({ url: `/schema/v1/map`, method: "POST", body: queryArg.body }),
        providesTags: ["field-config"],
      }),
      postEmailV1Inbound: build.mutation<PostEmailV1InboundApiResponse, PostEmailV1InboundApiArg>({
        query: (queryArg) => ({ url: `/email/v1/inbound`, method: "POST", body: queryArg.postmarkInboundMessage }),
      }),
      getUserV1Whoami: build.query<GetUserV1WhoamiApiResponse, GetUserV1WhoamiApiArg>({
        query: () => ({ url: `/user/v1/whoami` }),
        providesTags: ["user"],
      }),
      getUserV1CalendarKey: build.query<GetUserV1CalendarKeyApiResponse, GetUserV1CalendarKeyApiArg>({
        query: () => ({ url: `/user/v1/calendar-key` }),
        providesTags: ["user"],
      }),
      getUserV1Settings: build.query<GetUserV1SettingsApiResponse, GetUserV1SettingsApiArg>({
        query: () => ({ url: `/user/v1/settings` }),
        providesTags: ["user-settings"],
      }),
      getUserV1SettingsTotpQr: build.query<GetUserV1SettingsTotpQrApiResponse, GetUserV1SettingsTotpQrApiArg>({
        query: () => ({ url: `/user/v1/settings/totp/qr` }),
        providesTags: ["user-settings"],
      }),
      getUserV1SettingsContractCollectorPrivacy: build.query<
        GetUserV1SettingsContractCollectorPrivacyApiResponse,
        GetUserV1SettingsContractCollectorPrivacyApiArg
      >({
        query: () => ({ url: `/user/v1/settings/contract-collector-privacy` }),
        providesTags: ["user-settings"],
      }),
      postUserV1SettingsContractCollectorPrivacy: build.mutation<
        PostUserV1SettingsContractCollectorPrivacyApiResponse,
        PostUserV1SettingsContractCollectorPrivacyApiArg
      >({
        query: (queryArg) => ({
          url: `/user/v1/settings/contract-collector-privacy`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["user-settings"],
      }),
      getUserV1EmailDelegates: build.query<GetUserV1EmailDelegatesApiResponse, GetUserV1EmailDelegatesApiArg>({
        query: () => ({ url: `/user/v1/email-delegates` }),
        providesTags: ["user-email-delegation"],
      }),
      postUserV1EmailDelegates: build.mutation<PostUserV1EmailDelegatesApiResponse, PostUserV1EmailDelegatesApiArg>({
        query: (queryArg) => ({ url: `/user/v1/email-delegates`, method: "POST", body: queryArg.body }),
        invalidatesTags: ["user-email-delegation"],
      }),
      getUserV1WritingPersona: build.query<GetUserV1WritingPersonaApiResponse, GetUserV1WritingPersonaApiArg>({
        query: () => ({ url: `/user/v1/writing-persona` }),
      }),
      postUserV1WritingPersona: build.mutation<PostUserV1WritingPersonaApiResponse, PostUserV1WritingPersonaApiArg>({
        query: (queryArg) => ({ url: `/user/v1/writing-persona`, method: "POST", body: queryArg.body }),
      }),
      getUserV1DocumentsByIdContent: build.query<
        GetUserV1DocumentsByIdContentApiResponse,
        GetUserV1DocumentsByIdContentApiArg
      >({
        query: (queryArg) => ({ url: `/user/v1/documents/${queryArg.id}/content` }),
      }),
      postUserV1SettingsTotpEnable: build.mutation<
        PostUserV1SettingsTotpEnableApiResponse,
        PostUserV1SettingsTotpEnableApiArg
      >({
        query: (queryArg) => ({ url: `/user/v1/settings/totp/enable`, method: "POST", body: queryArg.body }),
        invalidatesTags: ["user-settings"],
      }),
      postUserV1SettingsTotpDisable: build.mutation<
        PostUserV1SettingsTotpDisableApiResponse,
        PostUserV1SettingsTotpDisableApiArg
      >({
        query: (queryArg) => ({ url: `/user/v1/settings/totp/disable`, method: "POST", body: queryArg.body }),
        invalidatesTags: ["user-settings"],
      }),
      getUserV1NotificationSettings: build.query<
        GetUserV1NotificationSettingsApiResponse,
        GetUserV1NotificationSettingsApiArg
      >({
        query: () => ({ url: `/user/v1/notification-settings` }),
        providesTags: ["user-notifications"],
      }),
      putUserV1NotificationSettings: build.mutation<
        PutUserV1NotificationSettingsApiResponse,
        PutUserV1NotificationSettingsApiArg
      >({
        query: (queryArg) => ({ url: `/user/v1/notification-settings`, method: "PUT", body: queryArg.body }),
        invalidatesTags: ["user-notifications"],
      }),
      postUserV1DisableNotificationChannel: build.mutation<
        PostUserV1DisableNotificationChannelApiResponse,
        PostUserV1DisableNotificationChannelApiArg
      >({
        query: (queryArg) => ({ url: `/user/v1/disable-notification-channel`, method: "POST", body: queryArg.body }),
        invalidatesTags: ["user-notifications"],
      }),
      getUserV1ById: build.query<GetUserV1ByIdApiResponse, GetUserV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/user/v1/${queryArg.id}` }),
      }),
      deleteUserV1ById: build.mutation<DeleteUserV1ByIdApiResponse, DeleteUserV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/user/v1/${queryArg.id}`, method: "DELETE", body: queryArg.body }),
        invalidatesTags: ["organization-users"],
      }),
      putUserV1ById: build.mutation<PutUserV1ByIdApiResponse, PutUserV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/user/v1/${queryArg.id}`, method: "PUT", body: queryArg.updateUser }),
        invalidatesTags: ["organization-users", "user"],
      }),
      getUserV1ByIdDisplay: build.query<GetUserV1ByIdDisplayApiResponse, GetUserV1ByIdDisplayApiArg>({
        query: (queryArg) => ({
          url: `/user/v1/${queryArg.id}/display`,
          params: {
            link_code: queryArg.linkCode,
          },
        }),
        providesTags: ["user"],
      }),
      getUserV1DeletionRequirementsById: build.query<
        GetUserV1DeletionRequirementsByIdApiResponse,
        GetUserV1DeletionRequirementsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/user/v1/deletion-requirements/${queryArg.id}` }),
      }),
      postUserV1Organization: build.query<PostUserV1OrganizationApiResponse, PostUserV1OrganizationApiArg>({
        query: (queryArg) => ({ url: `/user/v1/organization`, method: "POST", body: queryArg.body }),
        providesTags: ["organization-users"],
      }),
      postUserV1Create: build.mutation<PostUserV1CreateApiResponse, PostUserV1CreateApiArg>({
        query: (queryArg) => ({ url: `/user/v1/create`, method: "POST", body: queryArg.body }),
        invalidatesTags: ["organization-users"],
      }),
      postUserV1UpdateRoles: build.mutation<PostUserV1UpdateRolesApiResponse, PostUserV1UpdateRolesApiArg>({
        query: (queryArg) => ({ url: `/user/v1/update_roles`, method: "POST", body: queryArg.body }),
        invalidatesTags: ["organization-users"],
      }),
      postUserV1Invite: build.mutation<PostUserV1InviteApiResponse, PostUserV1InviteApiArg>({
        query: (queryArg) => ({ url: `/user/v1/invite`, method: "POST", body: queryArg.inviteUser }),
        invalidatesTags: ["organization-users"],
      }),
      postUserV1ResendInvite: build.mutation<PostUserV1ResendInviteApiResponse, PostUserV1ResendInviteApiArg>({
        query: (queryArg) => ({ url: `/user/v1/resend-invite`, method: "POST", body: queryArg.body }),
        invalidatesTags: ["organization-users"],
      }),
      postUserV1Login: build.mutation<PostUserV1LoginApiResponse, PostUserV1LoginApiArg>({
        query: (queryArg) => ({ url: `/user/v1/login`, method: "POST", body: queryArg.loginInput }),
      }),
      postUserV1SigninOktaInitiate: build.mutation<
        PostUserV1SigninOktaInitiateApiResponse,
        PostUserV1SigninOktaInitiateApiArg
      >({
        query: (queryArg) => ({ url: `/user/v1/signin/okta/initiate`, method: "POST", body: queryArg.body }),
      }),
      postUserV1ForgotPassword: build.mutation<PostUserV1ForgotPasswordApiResponse, PostUserV1ForgotPasswordApiArg>({
        query: (queryArg) => ({ url: `/user/v1/forgot-password`, method: "POST", body: queryArg.body }),
      }),
      getUserV1CodeByCodeEmail: build.query<GetUserV1CodeByCodeEmailApiResponse, GetUserV1CodeByCodeEmailApiArg>({
        query: (queryArg) => ({ url: `/user/v1/code/${queryArg.code}/email` }),
      }),
      postUserV1ChangePassword: build.mutation<PostUserV1ChangePasswordApiResponse, PostUserV1ChangePasswordApiArg>({
        query: (queryArg) => ({ url: `/user/v1/change-password`, method: "POST", body: queryArg.changePasswordInput }),
      }),
      getUserV1Logout: build.mutation<GetUserV1LogoutApiResponse, GetUserV1LogoutApiArg>({
        query: () => ({ url: `/user/v1/logout`, method: "GET" }),
      }),
      postUserV1CreateUser: build.mutation<PostUserV1CreateUserApiResponse, PostUserV1CreateUserApiArg>({
        query: (queryArg) => ({ url: `/user/v1/create_user`, method: "POST", body: queryArg.createUserInput }),
      }),
      postUserV1ByUserIdResetOnboarding: build.mutation<
        PostUserV1ByUserIdResetOnboardingApiResponse,
        PostUserV1ByUserIdResetOnboardingApiArg
      >({
        query: (queryArg) => ({ url: `/user/v1/${queryArg.userId}/reset-onboarding`, method: "POST" }),
      }),
      getOrganizationV1Current: build.query<GetOrganizationV1CurrentApiResponse, GetOrganizationV1CurrentApiArg>({
        query: () => ({ url: `/organization/v1/current` }),
        providesTags: ["organization"],
      }),
      getOrganizationV1CurrentOktaConfig: build.query<
        GetOrganizationV1CurrentOktaConfigApiResponse,
        GetOrganizationV1CurrentOktaConfigApiArg
      >({
        query: () => ({ url: `/organization/v1/current/okta/config` }),
        providesTags: ["organization"],
      }),
      putOrganizationV1Update: build.mutation<PutOrganizationV1UpdateApiResponse, PutOrganizationV1UpdateApiArg>({
        query: (queryArg) => ({ url: `/organization/v1/update`, method: "PUT", body: queryArg.updateOrganization }),
        invalidatesTags: ["organization"],
      }),
      postOrganizationV1AdminUpdateByOrganizationId: build.mutation<
        PostOrganizationV1AdminUpdateByOrganizationIdApiResponse,
        PostOrganizationV1AdminUpdateByOrganizationIdApiArg
      >({
        query: (queryArg) => ({
          url: `/organization/v1/admin-update/${queryArg.organizationId}`,
          method: "POST",
          body: queryArg.body,
        }),
      }),
      postOrganizationV1Create: build.mutation<PostOrganizationV1CreateApiResponse, PostOrganizationV1CreateApiArg>({
        query: (queryArg) => ({ url: `/organization/v1/create`, method: "POST", body: queryArg.createOrganization }),
        invalidatesTags: ["organization"],
      }),
      postOrganizationV1ClearOrganization: build.mutation<
        PostOrganizationV1ClearOrganizationApiResponse,
        PostOrganizationV1ClearOrganizationApiArg
      >({
        query: (queryArg) => ({ url: `/organization/v1/clear-organization`, method: "POST", body: queryArg.body }),
      }),
      postOrganizationV1PickableEntities: build.query<
        PostOrganizationV1PickableEntitiesApiResponse,
        PostOrganizationV1PickableEntitiesApiArg
      >({
        query: (queryArg) => ({ url: `/organization/v1/pickable-entities`, method: "POST", body: queryArg.body }),
        providesTags: ["organization-users"],
      }),
      postOrganizationV1PermissionedPickableEntities: build.query<
        PostOrganizationV1PermissionedPickableEntitiesApiResponse,
        PostOrganizationV1PermissionedPickableEntitiesApiArg
      >({
        query: (queryArg) => ({
          url: `/organization/v1/permissioned-pickable-entities`,
          method: "POST",
          body: queryArg.body,
        }),
        providesTags: ["organization-users"],
      }),
      postOrganizationV1OpenSignup: build.mutation<
        PostOrganizationV1OpenSignupApiResponse,
        PostOrganizationV1OpenSignupApiArg
      >({
        query: (queryArg) => ({ url: `/organization/v1/open-signup`, method: "POST", body: queryArg.body }),
      }),
      getSpendV1ByTypeAndId: build.query<GetSpendV1ByTypeAndIdApiResponse, GetSpendV1ByTypeAndIdApiArg>({
        query: (queryArg) => ({
          url: `/spend/v1/${queryArg["type"]}/${queryArg.id}`,
          params: {
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            interval: queryArg.interval,
          },
        }),
        providesTags: ["spend"],
      }),
      postSpendV1ExchangeRateSave: build.mutation<
        PostSpendV1ExchangeRateSaveApiResponse,
        PostSpendV1ExchangeRateSaveApiArg
      >({
        query: (queryArg) => ({ url: `/spend/v1/exchange-rate/save`, method: "POST", body: queryArg.body }),
      }),
      getStatisticsV1EmailIntegrations: build.query<
        GetStatisticsV1EmailIntegrationsApiResponse,
        GetStatisticsV1EmailIntegrationsApiArg
      >({
        query: () => ({ url: `/statistics/v1/email-integrations` }),
      }),
      getStatisticsV1Agreements: build.query<GetStatisticsV1AgreementsApiResponse, GetStatisticsV1AgreementsApiArg>({
        query: () => ({ url: `/statistics/v1/agreements` }),
      }),
      getStatisticsV1Persons: build.query<GetStatisticsV1PersonsApiResponse, GetStatisticsV1PersonsApiArg>({
        query: () => ({ url: `/statistics/v1/persons` }),
        providesTags: ["persons"],
      }),
      getStatisticsV1Tools: build.query<GetStatisticsV1ToolsApiResponse, GetStatisticsV1ToolsApiArg>({
        query: () => ({ url: `/statistics/v1/tools` }),
        providesTags: ["tools"],
      }),
      postToolV1List: build.query<PostToolV1ListApiResponse, PostToolV1ListApiArg>({
        query: (queryArg) => ({ url: `/tool/v1/list`, method: "POST", body: queryArg.listQueryStringParams }),
        providesTags: ["tools"],
      }),
      getToolV1ListHidden: build.query<GetToolV1ListHiddenApiResponse, GetToolV1ListHiddenApiArg>({
        query: () => ({ url: `/tool/v1/list/hidden` }),
        providesTags: ["tools"],
      }),
      postToolV1Csv: build.mutation<PostToolV1CsvApiResponse, PostToolV1CsvApiArg>({
        query: (queryArg) => ({ url: `/tool/v1/csv`, method: "POST", body: queryArg.body }),
        invalidatesTags: ["tools"],
      }),
      postToolV1AiFilter: build.mutation<PostToolV1AiFilterApiResponse, PostToolV1AiFilterApiArg>({
        query: (queryArg) => ({ url: `/tool/v1/ai-filter`, method: "POST", body: queryArg.body }),
      }),
      getToolV1PickerOptions: build.query<GetToolV1PickerOptionsApiResponse, GetToolV1PickerOptionsApiArg>({
        query: (queryArg) => ({
          url: `/tool/v1/picker-options`,
          params: {
            search: queryArg.search,
            link_code: queryArg.linkCode,
          },
        }),
      }),
      putToolV1ByIdFollowing: build.mutation<PutToolV1ByIdFollowingApiResponse, PutToolV1ByIdFollowingApiArg>({
        query: (queryArg) => ({ url: `/tool/v1/${queryArg.id}/following`, method: "PUT", body: queryArg.body }),
        invalidatesTags: ["tools"],
      }),
      patchToolV1ById: build.mutation<PatchToolV1ByIdApiResponse, PatchToolV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/tool/v1/${queryArg.id}`, method: "PATCH", body: queryArg.toolPatch }),
        invalidatesTags: ["tools", "timeline"],
      }),
      getToolV1ById: build.query<GetToolV1ByIdApiResponse, GetToolV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/tool/v1/${queryArg.id}` }),
        providesTags: ["tools"],
      }),
      postToolV1ByIdHide: build.mutation<PostToolV1ByIdHideApiResponse, PostToolV1ByIdHideApiArg>({
        query: (queryArg) => ({ url: `/tool/v1/${queryArg.id}/hide`, method: "POST", body: queryArg.body }),
        invalidatesTags: ["tools"],
      }),
      postToolV1ByIdLoginsList: build.query<PostToolV1ByIdLoginsListApiResponse, PostToolV1ByIdLoginsListApiArg>({
        query: (queryArg) => ({
          url: `/tool/v1/${queryArg.id}/logins/list`,
          method: "POST",
          body: queryArg.listQueryStringParams,
        }),
      }),
      postToolV1ByIdPersonsList: build.query<PostToolV1ByIdPersonsListApiResponse, PostToolV1ByIdPersonsListApiArg>({
        query: (queryArg) => ({
          url: `/tool/v1/${queryArg.id}/persons/list`,
          method: "POST",
          body: queryArg.listQueryStringParams,
        }),
        providesTags: ["tools"],
      }),
      postToolV1ByIdPersonsCsv: build.mutation<PostToolV1ByIdPersonsCsvApiResponse, PostToolV1ByIdPersonsCsvApiArg>({
        query: (queryArg) => ({ url: `/tool/v1/${queryArg.id}/persons/csv`, method: "POST", body: queryArg.body }),
        invalidatesTags: ["tools"],
      }),
      postToolV1: build.mutation<PostToolV1ApiResponse, PostToolV1ApiArg>({
        query: (queryArg) => ({ url: `/tool/v1/`, method: "POST", body: queryArg.body }),
      }),
      getToolV1Categories: build.query<GetToolV1CategoriesApiResponse, GetToolV1CategoriesApiArg>({
        query: () => ({ url: `/tool/v1/categories` }),
      }),
      getToolV1ByIdAnd: build.query<GetToolV1ByIdAndApiResponse, GetToolV1ByIdAndApiArg>({
        query: (queryArg) => ({ url: `/tool/v1/${queryArg.id}/${queryArg["*"]}` }),
        providesTags: ["tools"],
      }),
      postLegalV1AgreementsList: build.query<PostLegalV1AgreementsListApiResponse, PostLegalV1AgreementsListApiArg>({
        query: (queryArg) => ({
          url: `/legal/v1/agreements/list`,
          method: "POST",
          body: queryArg.listQueryStringParams,
        }),
        providesTags: ["tools", "legal", "vendors", "documents"],
      }),
      getLegalV1AgreementsPickerOptionsByQuery: build.query<
        GetLegalV1AgreementsPickerOptionsByQueryApiResponse,
        GetLegalV1AgreementsPickerOptionsByQueryApiArg
      >({
        query: (queryArg) => ({ url: `/legal/v1/agreements/picker-options/${queryArg.query}` }),
        providesTags: ["tools", "legal", "vendors", "documents"],
      }),
      getLegalV1AgreementsById: build.query<GetLegalV1AgreementsByIdApiResponse, GetLegalV1AgreementsByIdApiArg>({
        query: (queryArg) => ({ url: `/legal/v1/agreements/${queryArg.id}` }),
        providesTags: ["tools", "legal", "vendors", "documents"],
      }),
      putLegalV1AgreementsById: build.mutation<PutLegalV1AgreementsByIdApiResponse, PutLegalV1AgreementsByIdApiArg>({
        query: (queryArg) => ({ url: `/legal/v1/agreements/${queryArg.id}`, method: "PUT", body: queryArg.body }),
        invalidatesTags: ["tools", "legal", "vendors", "documents"],
      }),
      deleteLegalV1AgreementsById: build.mutation<
        DeleteLegalV1AgreementsByIdApiResponse,
        DeleteLegalV1AgreementsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/legal/v1/agreements/${queryArg.id}`, method: "DELETE" }),
        invalidatesTags: ["tools", "legal", "vendors", "documents"],
      }),
      postLegalV1AgreementsByIdVerification: build.mutation<
        PostLegalV1AgreementsByIdVerificationApiResponse,
        PostLegalV1AgreementsByIdVerificationApiArg
      >({
        query: (queryArg) => ({
          url: `/legal/v1/agreements/${queryArg.id}/verification`,
          method: "POST",
          body: queryArg.agreementVerificationRequest,
        }),
        invalidatesTags: ["tools", "legal", "vendors", "documents"],
      }),
      postLegalV1DetectLegalAgreementsByOrganizationId: build.mutation<
        PostLegalV1DetectLegalAgreementsByOrganizationIdApiResponse,
        PostLegalV1DetectLegalAgreementsByOrganizationIdApiArg
      >({
        query: (queryArg) => ({ url: `/legal/v1/detect-legal-agreements/${queryArg.organizationId}`, method: "POST" }),
      }),
      getLegalV1AgreementsByIdDocumentsAndDocumentIdUrl: build.query<
        GetLegalV1AgreementsByIdDocumentsAndDocumentIdUrlApiResponse,
        GetLegalV1AgreementsByIdDocumentsAndDocumentIdUrlApiArg
      >({
        query: (queryArg) => ({ url: `/legal/v1/agreements/${queryArg.id}/documents/${queryArg.documentId}/url` }),
      }),
      getLegalV1AgreementsByIdDocumentsAndDocumentIdContent: build.query<
        GetLegalV1AgreementsByIdDocumentsAndDocumentIdContentApiResponse,
        GetLegalV1AgreementsByIdDocumentsAndDocumentIdContentApiArg
      >({
        query: (queryArg) => ({ url: `/legal/v1/agreements/${queryArg.id}/documents/${queryArg.documentId}/content` }),
      }),
      postLegalV1Agreements: build.mutation<PostLegalV1AgreementsApiResponse, PostLegalV1AgreementsApiArg>({
        query: (queryArg) => ({ url: `/legal/v1/agreements`, method: "POST", body: queryArg.legalAgreementInput }),
        invalidatesTags: ["tools", "legal", "vendors", "documents"],
      }),
      getLegalV1AgreementsByIdAnd: build.query<
        GetLegalV1AgreementsByIdAndApiResponse,
        GetLegalV1AgreementsByIdAndApiArg
      >({
        query: (queryArg) => ({ url: `/legal/v1/agreements/${queryArg.id}/${queryArg["*"]}` }),
        providesTags: ["tools"],
      }),
      postVendorV1List: build.query<PostVendorV1ListApiResponse, PostVendorV1ListApiArg>({
        query: (queryArg) => ({ url: `/vendor/v1/list`, method: "POST", body: queryArg.listQueryStringParams }),
        providesTags: ["vendors"],
      }),
      getVendorV1ListHidden: build.query<GetVendorV1ListHiddenApiResponse, GetVendorV1ListHiddenApiArg>({
        query: () => ({ url: `/vendor/v1/list/hidden` }),
        providesTags: ["vendors"],
      }),
      postVendorV1Csv: build.mutation<PostVendorV1CsvApiResponse, PostVendorV1CsvApiArg>({
        query: (queryArg) => ({ url: `/vendor/v1/csv`, method: "POST", body: queryArg.body }),
        invalidatesTags: ["vendors"],
      }),
      postVendorV1AiFilter: build.mutation<PostVendorV1AiFilterApiResponse, PostVendorV1AiFilterApiArg>({
        query: (queryArg) => ({ url: `/vendor/v1/ai-filter`, method: "POST", body: queryArg.body }),
      }),
      postVendorV1: build.mutation<PostVendorV1ApiResponse, PostVendorV1ApiArg>({
        query: (queryArg) => ({ url: `/vendor/v1/`, method: "POST", body: queryArg.body }),
      }),
      getVendorV1PickerOptions: build.query<GetVendorV1PickerOptionsApiResponse, GetVendorV1PickerOptionsApiArg>({
        query: (queryArg) => ({
          url: `/vendor/v1/picker-options`,
          params: {
            search: queryArg.search,
          },
        }),
      }),
      putVendorV1ByIdFollowing: build.mutation<PutVendorV1ByIdFollowingApiResponse, PutVendorV1ByIdFollowingApiArg>({
        query: (queryArg) => ({ url: `/vendor/v1/${queryArg.id}/following`, method: "PUT", body: queryArg.body }),
        invalidatesTags: ["vendors"],
      }),
      patchVendorV1ById: build.mutation<PatchVendorV1ByIdApiResponse, PatchVendorV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/vendor/v1/${queryArg.id}`, method: "PATCH", body: queryArg.vendorPatch }),
        invalidatesTags: ["vendors", "timeline"],
      }),
      getVendorV1ById: build.query<GetVendorV1ByIdApiResponse, GetVendorV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/vendor/v1/${queryArg.id}` }),
        providesTags: ["vendors"],
      }),
      postVendorV1ByIdHide: build.mutation<PostVendorV1ByIdHideApiResponse, PostVendorV1ByIdHideApiArg>({
        query: (queryArg) => ({ url: `/vendor/v1/${queryArg.id}/hide`, method: "POST", body: queryArg.body }),
        invalidatesTags: ["vendors"],
      }),
      getVendorV1ByIdSpendBreakdown: build.query<
        GetVendorV1ByIdSpendBreakdownApiResponse,
        GetVendorV1ByIdSpendBreakdownApiArg
      >({
        query: (queryArg) => ({
          url: `/vendor/v1/${queryArg.id}/spend-breakdown`,
          params: {
            currency_code: queryArg.currencyCode,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
          },
        }),
        providesTags: ["vendors"],
      }),
      getVendorV1ByIdAnd: build.query<GetVendorV1ByIdAndApiResponse, GetVendorV1ByIdAndApiArg>({
        query: (queryArg) => ({ url: `/vendor/v1/${queryArg.id}/${queryArg["*"]}` }),
        providesTags: ["vendors"],
      }),
      getOauthV1SigninByProvider: build.query<GetOauthV1SigninByProviderApiResponse, GetOauthV1SigninByProviderApiArg>({
        query: (queryArg) => ({
          url: `/oauth/v1/signin/${queryArg.provider}`,
          params: {
            linkAuthCode: queryArg.linkAuthCode,
            iss: queryArg.iss,
            open_signup: queryArg.openSignup,
            code: queryArg.code,
          },
        }),
      }),
      getOauthV1SigninByProviderCallback: build.query<
        GetOauthV1SigninByProviderCallbackApiResponse,
        GetOauthV1SigninByProviderCallbackApiArg
      >({
        query: (queryArg) => ({
          url: `/oauth/v1/signin/${queryArg.provider}/callback`,
          params: {
            state: queryArg.state,
            code: queryArg.code,
            error: queryArg.error,
          },
        }),
      }),
      getOauthV1AuthByProvider: build.query<GetOauthV1AuthByProviderApiResponse, GetOauthV1AuthByProviderApiArg>({
        query: (queryArg) => ({ url: `/oauth/v1/auth/${queryArg.provider}` }),
      }),
      getOauthV1AuthByProviderCallback: build.query<
        GetOauthV1AuthByProviderCallbackApiResponse,
        GetOauthV1AuthByProviderCallbackApiArg
      >({
        query: (queryArg) => ({
          url: `/oauth/v1/auth/${queryArg.provider}/callback`,
          params: {
            state: queryArg.state,
            code: queryArg.code,
          },
        }),
      }),
      getOauthV1SignupValidate: build.query<GetOauthV1SignupValidateApiResponse, GetOauthV1SignupValidateApiArg>({
        query: (queryArg) => ({
          url: `/oauth/v1/signup/validate`,
          params: {
            code: queryArg.code,
          },
        }),
      }),
      getOauthV1ConnectByProvider: build.query<
        GetOauthV1ConnectByProviderApiResponse,
        GetOauthV1ConnectByProviderApiArg
      >({
        query: (queryArg) => ({
          url: `/oauth/v1/connect/${queryArg.provider}`,
          params: {
            integrationId: queryArg.integrationId,
          },
        }),
      }),
      getOauthV1ConnectByProviderCallback: build.query<
        GetOauthV1ConnectByProviderCallbackApiResponse,
        GetOauthV1ConnectByProviderCallbackApiArg
      >({
        query: (queryArg) => ({
          url: `/oauth/v1/connect/${queryArg.provider}/callback`,
          params: {
            state: queryArg.state,
            code: queryArg.code,
            integrationId: queryArg.integrationId,
            realmId: queryArg.realmId,
          },
        }),
      }),
      getIntegrationV1: build.query<GetIntegrationV1ApiResponse, GetIntegrationV1ApiArg>({
        query: () => ({ url: `/integration/v1/` }),
        providesTags: ["integrations"],
      }),
      getIntegrationV1InitialExtractionBanner: build.query<
        GetIntegrationV1InitialExtractionBannerApiResponse,
        GetIntegrationV1InitialExtractionBannerApiArg
      >({
        query: () => ({ url: `/integration/v1/initial-extraction-banner` }),
        providesTags: ["initial-extraction-banner"],
      }),
      getIntegrationV1Private: build.query<GetIntegrationV1PrivateApiResponse, GetIntegrationV1PrivateApiArg>({
        query: () => ({ url: `/integration/v1/private` }),
        providesTags: ["integrations", "initial-extraction-banner"],
      }),
      deleteIntegrationV1ById: build.mutation<DeleteIntegrationV1ByIdApiResponse, DeleteIntegrationV1ByIdApiArg>({
        query: (queryArg) => ({
          url: `/integration/v1/${queryArg.id}`,
          method: "DELETE",
          params: {
            data: queryArg.data,
          },
        }),
        invalidatesTags: ["integrations"],
      }),
      postIntegrationV1ClearData: build.mutation<
        PostIntegrationV1ClearDataApiResponse,
        PostIntegrationV1ClearDataApiArg
      >({
        query: (queryArg) => ({ url: `/integration/v1/clear-data`, method: "POST", body: queryArg.body }),
        invalidatesTags: ["integrations"],
      }),
      postIntegrationV1Create: build.mutation<PostIntegrationV1CreateApiResponse, PostIntegrationV1CreateApiArg>({
        query: (queryArg) => ({ url: `/integration/v1/create`, method: "POST", body: queryArg.createIntegration }),
        invalidatesTags: ["integrations"],
      }),
      postIntegrationV1ByIdDisable: build.mutation<
        PostIntegrationV1ByIdDisableApiResponse,
        PostIntegrationV1ByIdDisableApiArg
      >({
        query: (queryArg) => ({ url: `/integration/v1/${queryArg.id}/disable`, method: "POST" }),
        invalidatesTags: ["integrations", "initial-extraction-banner"],
      }),
      postIntegrationV1ByIdEnable: build.mutation<
        PostIntegrationV1ByIdEnableApiResponse,
        PostIntegrationV1ByIdEnableApiArg
      >({
        query: (queryArg) => ({ url: `/integration/v1/${queryArg.id}/enable`, method: "POST" }),
        invalidatesTags: ["integrations"],
      }),
      postIntegrationV1Update: build.mutation<PostIntegrationV1UpdateApiResponse, PostIntegrationV1UpdateApiArg>({
        query: (queryArg) => ({ url: `/integration/v1/update`, method: "POST", body: queryArg.updateIntegration }),
        invalidatesTags: ["integrations"],
      }),
      postIntegrationV1CrawlById: build.mutation<
        PostIntegrationV1CrawlByIdApiResponse,
        PostIntegrationV1CrawlByIdApiArg
      >({
        query: (queryArg) => ({ url: `/integration/v1/crawl/${queryArg.id}`, method: "POST" }),
        invalidatesTags: ["integrations"],
      }),
      getIntegrationV1HrisBySourceLinkToken: build.query<
        GetIntegrationV1HrisBySourceLinkTokenApiResponse,
        GetIntegrationV1HrisBySourceLinkTokenApiArg
      >({
        query: (queryArg) => ({ url: `/integration/v1/hris/${queryArg.source}/link-token` }),
        providesTags: ["integrations"],
      }),
      getIntegrationV1AccountingBySourceLinkToken: build.query<
        GetIntegrationV1AccountingBySourceLinkTokenApiResponse,
        GetIntegrationV1AccountingBySourceLinkTokenApiArg
      >({
        query: (queryArg) => ({ url: `/integration/v1/accounting/${queryArg.source}/link-token` }),
        providesTags: ["integrations"],
      }),
      postIntegrationV1MergeSync: build.mutation<
        PostIntegrationV1MergeSyncApiResponse,
        PostIntegrationV1MergeSyncApiArg
      >({
        query: (queryArg) => ({
          url: `/integration/v1/merge/sync`,
          method: "POST",
          body: queryArg.mergeWebhookPayload,
        }),
        invalidatesTags: ["integrations"],
      }),
      postPersonV1: build.query<PostPersonV1ApiResponse, PostPersonV1ApiArg>({
        query: (queryArg) => ({ url: `/person/v1/`, method: "POST", body: queryArg.listQueryStringParams }),
        providesTags: ["persons", "organization-users"],
      }),
      postPersonV1Merge: build.mutation<PostPersonV1MergeApiResponse, PostPersonV1MergeApiArg>({
        query: (queryArg) => ({ url: `/person/v1/merge`, method: "POST", body: queryArg.body }),
      }),
      getPersonV1ById: build.query<GetPersonV1ByIdApiResponse, GetPersonV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/person/v1/${queryArg.id}` }),
        providesTags: ["persons", "organization-users"],
      }),
      getPersonV1ByIdTools: build.query<GetPersonV1ByIdToolsApiResponse, GetPersonV1ByIdToolsApiArg>({
        query: (queryArg) => ({ url: `/person/v1/${queryArg.id}/tools` }),
        providesTags: ["persons"],
      }),
      getLoginV1Summary: build.query<GetLoginV1SummaryApiResponse, GetLoginV1SummaryApiArg>({
        query: (queryArg) => ({
          url: `/login/v1/summary`,
          params: {
            person_id: queryArg.personId,
          },
        }),
        providesTags: ["logins"],
      }),
      getLoginV1UserActivity: build.query<GetLoginV1UserActivityApiResponse, GetLoginV1UserActivityApiArg>({
        query: (queryArg) => ({
          url: `/login/v1/user-activity`,
          params: {
            tool_listing_id: queryArg.toolListingId,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            interval: queryArg.interval,
          },
        }),
        providesTags: ["logins"],
      }),
      getSearchV1Text: build.query<GetSearchV1TextApiResponse, GetSearchV1TextApiArg>({
        query: (queryArg) => ({
          url: `/search/v1/text`,
          params: {
            q: queryArg.q,
          },
        }),
      }),
      getEmployeeGroupV1: build.query<GetEmployeeGroupV1ApiResponse, GetEmployeeGroupV1ApiArg>({
        query: (queryArg) => ({
          url: `/employee-group/v1/`,
          params: {
            group_type: queryArg.groupType,
          },
        }),
      }),
      postDocumentV1: build.mutation<PostDocumentV1ApiResponse, PostDocumentV1ApiArg>({
        query: (queryArg) => ({ url: `/document/v1/`, method: "POST", body: queryArg.documentInput }),
        invalidatesTags: ["documents"],
      }),
      postDocumentV1List: build.query<PostDocumentV1ListApiResponse, PostDocumentV1ListApiArg>({
        query: (queryArg) => ({
          url: `/document/v1/list`,
          method: "POST",
          body: queryArg.listQueryStringParams,
          params: {
            tool_id: queryArg.toolId,
            vendor_id: queryArg.vendorId,
          },
        }),
        providesTags: ["documents"],
      }),
      putDocumentV1ById: build.mutation<PutDocumentV1ByIdApiResponse, PutDocumentV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/document/v1/${queryArg.id}`, method: "PUT", body: queryArg.body }),
        invalidatesTags: ["documents"],
      }),
      getDocumentV1Extracting: build.query<GetDocumentV1ExtractingApiResponse, GetDocumentV1ExtractingApiArg>({
        query: () => ({ url: `/document/v1/extracting` }),
        providesTags: ["legal"],
      }),
      postDocumentV1BackgroundExtractBatch: build.mutation<
        PostDocumentV1BackgroundExtractBatchApiResponse,
        PostDocumentV1BackgroundExtractBatchApiArg
      >({
        query: (queryArg) => ({
          url: `/document/v1/background-extract-batch`,
          method: "POST",
          body: queryArg.documentBatchExtractionRequest,
        }),
        invalidatesTags: ["documents"],
      }),
      postDocumentV1Move: build.mutation<PostDocumentV1MoveApiResponse, PostDocumentV1MoveApiArg>({
        query: (queryArg) => ({ url: `/document/v1/move`, method: "POST", body: queryArg.moveDocumentRequest }),
        invalidatesTags: ["documents"],
      }),
      postDocumentV1ByIdBackgroundExtract: build.mutation<
        PostDocumentV1ByIdBackgroundExtractApiResponse,
        PostDocumentV1ByIdBackgroundExtractApiArg
      >({
        query: (queryArg) => ({
          url: `/document/v1/${queryArg.id}/background-extract`,
          method: "POST",
          body: queryArg.documentBackgroundExtractionRequest,
        }),
        invalidatesTags: ["documents"],
      }),
      postDocumentV1RunExtractionByOrganizationId: build.mutation<
        PostDocumentV1RunExtractionByOrganizationIdApiResponse,
        PostDocumentV1RunExtractionByOrganizationIdApiArg
      >({
        query: (queryArg) => ({ url: `/document/v1/run-extraction/${queryArg.organizationId}`, method: "POST" }),
      }),
      postDocumentV1RetryPartialExtraction: build.mutation<
        PostDocumentV1RetryPartialExtractionApiResponse,
        PostDocumentV1RetryPartialExtractionApiArg
      >({
        query: (queryArg) => ({ url: `/document/v1/retry-partial-extraction`, method: "POST", body: queryArg.body }),
      }),
      postDocumentV1ByIdRetryExtraction: build.mutation<
        PostDocumentV1ByIdRetryExtractionApiResponse,
        PostDocumentV1ByIdRetryExtractionApiArg
      >({
        query: (queryArg) => ({ url: `/document/v1/${queryArg.id}/retry-extraction`, method: "POST" }),
        invalidatesTags: ["documents"],
      }),
      postDocumentV1ByIdExtractForm: build.mutation<
        PostDocumentV1ByIdExtractFormApiResponse,
        PostDocumentV1ByIdExtractFormApiArg
      >({
        query: (queryArg) => ({ url: `/document/v1/${queryArg.id}/extract/form`, method: "POST" }),
      }),
      getDocumentV1ByObjectTypeAndObjectIdDocumentsDocumentIdUrl: build.query<
        GetDocumentV1ByObjectTypeAndObjectIdDocumentsDocumentIdUrlApiResponse,
        GetDocumentV1ByObjectTypeAndObjectIdDocumentsDocumentIdUrlApiArg
      >({
        query: (queryArg) => ({
          url: `/document/v1/${queryArg.objectType}/${queryArg.objectId}/documents/${queryArg.documentId}/url`,
        }),
      }),
      getDocumentV1OwnByIdContent: build.query<
        GetDocumentV1OwnByIdContentApiResponse,
        GetDocumentV1OwnByIdContentApiArg
      >({
        query: (queryArg) => ({ url: `/document/v1/own/${queryArg.id}/content` }),
      }),
      getDocumentV1OwnByIdUrl: build.query<GetDocumentV1OwnByIdUrlApiResponse, GetDocumentV1OwnByIdUrlApiArg>({
        query: (queryArg) => ({ url: `/document/v1/own/${queryArg.id}/url` }),
      }),
      getWorkflowV1ByKindSchema: build.query<GetWorkflowV1ByKindSchemaApiResponse, GetWorkflowV1ByKindSchemaApiArg>({
        query: (queryArg) => ({ url: `/workflow/v1/${queryArg.kind}/schema` }),
        providesTags: ["field-config"],
      }),
      getWorkflowV1DefinitionsById: build.query<
        GetWorkflowV1DefinitionsByIdApiResponse,
        GetWorkflowV1DefinitionsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/workflow/v1/definitions/${queryArg.id}` }),
        providesTags: ["workflow-definitions"],
      }),
      putWorkflowV1DefinitionsById: build.mutation<
        PutWorkflowV1DefinitionsByIdApiResponse,
        PutWorkflowV1DefinitionsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/workflow/v1/definitions/${queryArg.id}`,
          method: "PUT",
          body: queryArg.workflowDefUpdate,
        }),
        invalidatesTags: ["workflow-definitions"],
      }),
      getWorkflowV1Definitions: build.query<GetWorkflowV1DefinitionsApiResponse, GetWorkflowV1DefinitionsApiArg>({
        query: () => ({ url: `/workflow/v1/definitions` }),
        providesTags: ["workflow-definitions"],
      }),
      postWorkflowV1Definitions: build.mutation<PostWorkflowV1DefinitionsApiResponse, PostWorkflowV1DefinitionsApiArg>({
        query: (queryArg) => ({ url: `/workflow/v1/definitions`, method: "POST", body: queryArg.workflowDefInput }),
        invalidatesTags: ["workflow-definitions"],
      }),
      getWorkflowV1DefinitionsKindList: build.query<
        GetWorkflowV1DefinitionsKindListApiResponse,
        GetWorkflowV1DefinitionsKindListApiArg
      >({
        query: () => ({ url: `/workflow/v1/definitions/kind/list` }),
        providesTags: ["workflow-definitions"],
      }),
      getWorkflowV1RunsPickables: build.query<GetWorkflowV1RunsPickablesApiResponse, GetWorkflowV1RunsPickablesApiArg>({
        query: () => ({ url: `/workflow/v1/runs/pickables` }),
      }),
      postWorkflowV1DefinitionsConditional: build.mutation<
        PostWorkflowV1DefinitionsConditionalApiResponse,
        PostWorkflowV1DefinitionsConditionalApiArg
      >({
        query: (queryArg) => ({ url: `/workflow/v1/definitions/conditional`, method: "POST", body: queryArg.body }),
      }),
      postWorkflowV1RunsList: build.query<PostWorkflowV1RunsListApiResponse, PostWorkflowV1RunsListApiArg>({
        query: (queryArg) => ({ url: `/workflow/v1/runs-list/`, method: "POST", body: queryArg.listQueryStringParams }),
        providesTags: ["workflow-runs"],
      }),
      postWorkflowV1Runs: build.mutation<PostWorkflowV1RunsApiResponse, PostWorkflowV1RunsApiArg>({
        query: (queryArg) => ({ url: `/workflow/v1/runs/`, method: "POST", body: queryArg.workflowRunInput }),
      }),
      getWorkflowV1RunsById: build.query<GetWorkflowV1RunsByIdApiResponse, GetWorkflowV1RunsByIdApiArg>({
        query: (queryArg) => ({ url: `/workflow/v1/runs/${queryArg.id}` }),
        providesTags: ["workflow-runs", "workflow-run-form-state"],
      }),
      patchWorkflowV1RunsById: build.mutation<PatchWorkflowV1RunsByIdApiResponse, PatchWorkflowV1RunsByIdApiArg>({
        query: (queryArg) => ({
          url: `/workflow/v1/runs/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.workflowRunUpdatePatch,
        }),
      }),
      deleteWorkflowV1RunsById: build.mutation<DeleteWorkflowV1RunsByIdApiResponse, DeleteWorkflowV1RunsByIdApiArg>({
        query: (queryArg) => ({ url: `/workflow/v1/runs/${queryArg.id}`, method: "DELETE" }),
      }),
      getWorkflowV1RunsByIdStepsAndStepId: build.query<
        GetWorkflowV1RunsByIdStepsAndStepIdApiResponse,
        GetWorkflowV1RunsByIdStepsAndStepIdApiArg
      >({
        query: (queryArg) => ({
          url: `/workflow/v1/runs/${queryArg.id}/steps/${queryArg.stepId}`,
          params: {
            code: queryArg.code,
          },
        }),
        providesTags: ["workflow-runs", "workflow-run-step"],
      }),
      putWorkflowV1RunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameGatherer: build.mutation<
        PutWorkflowV1RunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameGathererApiResponse,
        PutWorkflowV1RunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameGathererApiArg
      >({
        query: (queryArg) => ({
          url: `/workflow/v1/runs/${queryArg.id}/objects/${queryArg.objectType}/${queryArg.objectId}/fields/${queryArg.fieldName}/gatherer`,
          method: "PUT",
          body: queryArg.setFieldGatherRequest,
        }),
      }),
      postWorkflowV1RunsByIdCancel: build.mutation<
        PostWorkflowV1RunsByIdCancelApiResponse,
        PostWorkflowV1RunsByIdCancelApiArg
      >({
        query: (queryArg) => ({ url: `/workflow/v1/runs/${queryArg.id}/cancel`, method: "POST" }),
      }),
      patchWorkflowV1RunsByIdSteps: build.mutation<
        PatchWorkflowV1RunsByIdStepsApiResponse,
        PatchWorkflowV1RunsByIdStepsApiArg
      >({
        query: (queryArg) => ({
          url: `/workflow/v1/runs/${queryArg.id}/steps`,
          method: "PATCH",
          body: queryArg.workflowRunStepsUpdatePatch,
        }),
      }),
      putWorkflowV1StepRunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameApproval: build.mutation<
        PutWorkflowV1StepRunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameApprovalApiResponse,
        PutWorkflowV1StepRunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameApprovalApiArg
      >({
        query: (queryArg) => ({
          url: `/workflow/v1/step-runs/${queryArg.id}/objects/${queryArg.objectType}/${queryArg.objectId}/fields/${queryArg.fieldName}/approval`,
          method: "PUT",
          body: queryArg.workflowRunStepFieldApprovalStatus,
        }),
      }),
      getWorkflowV1SellerLinks: build.query<GetWorkflowV1SellerLinksApiResponse, GetWorkflowV1SellerLinksApiArg>({
        query: () => ({ url: `/workflow/v1/seller/links` }),
        providesTags: ["workflow-runs"],
      }),
      postWorkflowV1Links: build.mutation<PostWorkflowV1LinksApiResponse, PostWorkflowV1LinksApiArg>({
        query: (queryArg) => ({ url: `/workflow/v1/links`, method: "POST", body: queryArg.createBrmLinkInput }),
      }),
      getWorkflowV1LinksByCodeWorkflowRun: build.query<
        GetWorkflowV1LinksByCodeWorkflowRunApiResponse,
        GetWorkflowV1LinksByCodeWorkflowRunApiArg
      >({
        query: (queryArg) => ({ url: `/workflow/v1/links/${queryArg.code}/workflow-run` }),
        providesTags: ["workflow-runs", "workflow-run-form-state"],
      }),
      postWorkflowV1LinksByCodeWorkflowRunStepStatesAndWorkflowRunStepId: build.mutation<
        PostWorkflowV1LinksByCodeWorkflowRunStepStatesAndWorkflowRunStepIdApiResponse,
        PostWorkflowV1LinksByCodeWorkflowRunStepStatesAndWorkflowRunStepIdApiArg
      >({
        query: (queryArg) => ({
          url: `/workflow/v1/links/${queryArg.code}/workflow-run-step-states/${queryArg.workflowRunStepId}`,
          method: "POST",
          body: queryArg.workflowRunStepDraftStateUpdateInput,
        }),
      }),
      getWorkflowV1LinksByCode: build.query<GetWorkflowV1LinksByCodeApiResponse, GetWorkflowV1LinksByCodeApiArg>({
        query: (queryArg) => ({ url: `/workflow/v1/links/${queryArg.code}` }),
        providesTags: ["workflow-runs"],
      }),
      postWorkflowV1LinksByCode: build.mutation<PostWorkflowV1LinksByCodeApiResponse, PostWorkflowV1LinksByCodeApiArg>({
        query: (queryArg) => ({
          url: `/workflow/v1/links/${queryArg.code}`,
          method: "POST",
          body: queryArg.createBrmLinkInput,
        }),
      }),
      postWorkflowV1StepRunsByIdDraft: build.mutation<
        PostWorkflowV1StepRunsByIdDraftApiResponse,
        PostWorkflowV1StepRunsByIdDraftApiArg
      >({
        query: (queryArg) => ({
          url: `/workflow/v1/step-runs/${queryArg.id}/draft`,
          method: "POST",
          body: queryArg.workflowRunStepDraftStateUpdateInput,
        }),
      }),
      postWorkflowV1StepRunsByWorkflowRunStepIdUnapprove: build.mutation<
        PostWorkflowV1StepRunsByWorkflowRunStepIdUnapproveApiResponse,
        PostWorkflowV1StepRunsByWorkflowRunStepIdUnapproveApiArg
      >({
        query: (queryArg) => ({
          url: `/workflow/v1/step-runs/${queryArg.workflowRunStepId}/unapprove`,
          method: "POST",
          body: queryArg.body,
        }),
      }),
      postWorkflowV1StepRunsByWorkflowRunStepIdRequestChanges: build.mutation<
        PostWorkflowV1StepRunsByWorkflowRunStepIdRequestChangesApiResponse,
        PostWorkflowV1StepRunsByWorkflowRunStepIdRequestChangesApiArg
      >({
        query: (queryArg) => ({
          url: `/workflow/v1/step-runs/${queryArg.workflowRunStepId}/request-changes`,
          method: "POST",
        }),
      }),
      postWorkflowV1StepRunsByWorkflowRunStepIdReopen: build.mutation<
        PostWorkflowV1StepRunsByWorkflowRunStepIdReopenApiResponse,
        PostWorkflowV1StepRunsByWorkflowRunStepIdReopenApiArg
      >({
        query: (queryArg) => ({ url: `/workflow/v1/step-runs/${queryArg.workflowRunStepId}/reopen`, method: "POST" }),
      }),
      postWorkflowV1StepRunsByWorkflowRunStepIdSubmission: build.mutation<
        PostWorkflowV1StepRunsByWorkflowRunStepIdSubmissionApiResponse,
        PostWorkflowV1StepRunsByWorkflowRunStepIdSubmissionApiArg
      >({
        query: (queryArg) => ({
          url: `/workflow/v1/step-runs/${queryArg.workflowRunStepId}/submission`,
          method: "POST",
          body: queryArg.workflowStepRunSubmission,
        }),
      }),
      getWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdUrl: build.query<
        GetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdUrlApiResponse,
        GetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdUrlApiArg
      >({
        query: (queryArg) => ({
          url: `/workflow/v1/runs/${queryArg.workflowRunId}/documents/${queryArg.documentId}/url`,
        }),
      }),
      getWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdContent: build.query<
        GetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdContentApiResponse,
        GetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdContentApiArg
      >({
        query: (queryArg) => ({
          url: `/workflow/v1/runs/${queryArg.workflowRunId}/documents/${queryArg.documentId}/content`,
        }),
      }),
      postWorkflowV1Fields: build.query<PostWorkflowV1FieldsApiResponse, PostWorkflowV1FieldsApiArg>({
        query: (queryArg) => ({ url: `/workflow/v1/fields`, method: "POST", body: queryArg.body }),
      }),
      getTransactionV1ById: build.query<GetTransactionV1ByIdApiResponse, GetTransactionV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/transaction/v1/${queryArg.id}` }),
      }),
      postTransactionV1List: build.query<PostTransactionV1ListApiResponse, PostTransactionV1ListApiArg>({
        query: (queryArg) => ({
          url: `/transaction/v1/list`,
          method: "POST",
          body: queryArg.listQueryStringParams,
          params: {
            tool_id: queryArg.toolId,
            vendor_id: queryArg.vendorId,
            agreement_id: queryArg.agreementId,
          },
        }),
      }),
      getTransactionV1ByIdReceiptsAndReceiptIdDocumentUrl: build.query<
        GetTransactionV1ByIdReceiptsAndReceiptIdDocumentUrlApiResponse,
        GetTransactionV1ByIdReceiptsAndReceiptIdDocumentUrlApiArg
      >({
        query: (queryArg) => ({ url: `/transaction/v1/${queryArg.id}/receipts/${queryArg.receiptId}/document/url` }),
      }),
      getTransactionV1ByIdReceiptsAndReceiptIdDocumentContent: build.query<
        GetTransactionV1ByIdReceiptsAndReceiptIdDocumentContentApiResponse,
        GetTransactionV1ByIdReceiptsAndReceiptIdDocumentContentApiArg
      >({
        query: (queryArg) => ({
          url: `/transaction/v1/${queryArg.id}/receipts/${queryArg.receiptId}/document/content`,
        }),
      }),
      getSavedViewV1ByTableIdentifier: build.query<
        GetSavedViewV1ByTableIdentifierApiResponse,
        GetSavedViewV1ByTableIdentifierApiArg
      >({
        query: (queryArg) => ({ url: `/saved-view/v1/${queryArg.tableIdentifier}` }),
        providesTags: ["saved-view"],
      }),
      putSavedViewV1ById: build.mutation<PutSavedViewV1ByIdApiResponse, PutSavedViewV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/saved-view/v1/${queryArg.id}`, method: "PUT", body: queryArg.editSavedView }),
        invalidatesTags: ["saved-view"],
      }),
      deleteSavedViewV1ById: build.mutation<DeleteSavedViewV1ByIdApiResponse, DeleteSavedViewV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/saved-view/v1/${queryArg.id}`, method: "DELETE" }),
        invalidatesTags: ["saved-view"],
      }),
      postSavedViewV1Create: build.mutation<PostSavedViewV1CreateApiResponse, PostSavedViewV1CreateApiArg>({
        query: (queryArg) => ({ url: `/saved-view/v1/create`, method: "POST", body: queryArg.createSavedView }),
        invalidatesTags: ["saved-view"],
      }),
      postBetsyV1Vector: build.mutation<PostBetsyV1VectorApiResponse, PostBetsyV1VectorApiArg>({
        query: (queryArg) => ({ url: `/betsy/v1/vector`, method: "POST", body: queryArg.body }),
      }),
      postBetsyV1Ask: build.mutation<PostBetsyV1AskApiResponse, PostBetsyV1AskApiArg>({
        query: (queryArg) => ({ url: `/betsy/v1/ask`, method: "POST", body: queryArg.body }),
      }),
      postBetsyV1SlackEvent: build.mutation<PostBetsyV1SlackEventApiResponse, PostBetsyV1SlackEventApiArg>({
        query: (queryArg) => ({ url: `/betsy/v1/slack/event`, method: "POST", body: queryArg.body }),
      }),
      getBetsyV1Conversations: build.query<GetBetsyV1ConversationsApiResponse, GetBetsyV1ConversationsApiArg>({
        query: () => ({ url: `/betsy/v1/conversations` }),
      }),
      getBetsyV1ConversationsById: build.query<
        GetBetsyV1ConversationsByIdApiResponse,
        GetBetsyV1ConversationsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/betsy/v1/conversations/${queryArg.id}` }),
        providesTags: ["betsy"],
      }),
      putErpAccountV1ById: build.mutation<PutErpAccountV1ByIdApiResponse, PutErpAccountV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/erp-account/v1/${queryArg.id}`, method: "PUT", body: queryArg.body }),
      }),
      getTimelineV1Events: build.query<GetTimelineV1EventsApiResponse, GetTimelineV1EventsApiArg>({
        query: (queryArg) => ({
          url: `/timeline/v1/events`,
          params: {
            limit: queryArg.limit,
            workflow_run_id: queryArg.workflowRunId,
            tool_id: queryArg.toolId,
            vendor_id: queryArg.vendorId,
            until: queryArg.until,
            since: queryArg.since,
          },
        }),
        providesTags: ["timeline", "tools", "vendors"],
      }),
      deleteTimelineV1EventsById: build.mutation<
        DeleteTimelineV1EventsByIdApiResponse,
        DeleteTimelineV1EventsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/timeline/v1/events/${queryArg.id}`, method: "DELETE" }),
      }),
      putTimelineV1EventsById: build.mutation<PutTimelineV1EventsByIdApiResponse, PutTimelineV1EventsByIdApiArg>({
        query: (queryArg) => ({
          url: `/timeline/v1/events/${queryArg.id}`,
          method: "PUT",
          body: queryArg.timelineUpdate,
        }),
      }),
      getTimelineV1WorkflowRunByWorkflowRunId: build.query<
        GetTimelineV1WorkflowRunByWorkflowRunIdApiResponse,
        GetTimelineV1WorkflowRunByWorkflowRunIdApiArg
      >({
        query: (queryArg) => ({ url: `/timeline/v1/workflow_run/${queryArg.workflowRunId}` }),
        providesTags: ["timeline", "workflow-field-timeline"],
      }),
      getTimelineV1WorkflowRunByWorkflowRunIdWorkflowRunStepAndWorkflowRunStepId: build.query<
        GetTimelineV1WorkflowRunByWorkflowRunIdWorkflowRunStepAndWorkflowRunStepIdApiResponse,
        GetTimelineV1WorkflowRunByWorkflowRunIdWorkflowRunStepAndWorkflowRunStepIdApiArg
      >({
        query: (queryArg) => ({
          url: `/timeline/v1/workflow_run/${queryArg.workflowRunId}/workflow_run_step/${queryArg.workflowRunStepId}`,
        }),
        providesTags: ["timeline", "workflow-field-timeline"],
      }),
      getTimelineV1EventsObjectsByObjectTypeAndObjectIdFieldsFieldName: build.query<
        GetTimelineV1EventsObjectsByObjectTypeAndObjectIdFieldsFieldNameApiResponse,
        GetTimelineV1EventsObjectsByObjectTypeAndObjectIdFieldsFieldNameApiArg
      >({
        query: (queryArg) => ({
          url: `/timeline/v1/events/objects/${queryArg.objectType}/${queryArg.objectId}/fields/${queryArg.fieldName}`,
          params: {
            workflow_run_id: queryArg.workflowRunId,
          },
        }),
        providesTags: ["timeline", "workflow-field-timeline"],
      }),
      getTimelineV1EventsByIdDocumentAndDocumentIdContent: build.query<
        GetTimelineV1EventsByIdDocumentAndDocumentIdContentApiResponse,
        GetTimelineV1EventsByIdDocumentAndDocumentIdContentApiArg
      >({
        query: (queryArg) => ({ url: `/timeline/v1/events/${queryArg.id}/document/${queryArg.documentId}/content` }),
      }),
      postTimelineV1EventsObjectsByObjectTypeAndObjectIdStreaming: build.mutation<
        PostTimelineV1EventsObjectsByObjectTypeAndObjectIdStreamingApiResponse,
        PostTimelineV1EventsObjectsByObjectTypeAndObjectIdStreamingApiArg
      >({
        query: (queryArg) => ({
          url: `/timeline/v1/events/objects/${queryArg.objectType}/${queryArg.objectId}/streaming`,
          method: "POST",
          body: queryArg.timelineInput,
          params: {
            field_name: queryArg.fieldName,
            workflow_run_id: queryArg.workflowRunId,
            workflow_run_step_id: queryArg.workflowRunStepId,
          },
        }),
      }),
      postTimelineV1EventsObjectsByObjectTypeAndObjectId: build.mutation<
        PostTimelineV1EventsObjectsByObjectTypeAndObjectIdApiResponse,
        PostTimelineV1EventsObjectsByObjectTypeAndObjectIdApiArg
      >({
        query: (queryArg) => ({
          url: `/timeline/v1/events/objects/${queryArg.objectType}/${queryArg.objectId}`,
          method: "POST",
          body: queryArg.timelineInput,
          params: {
            field_name: queryArg.fieldName,
            workflow_run_id: queryArg.workflowRunId,
            workflow_run_step_id: queryArg.workflowRunStepId,
          },
        }),
      }),
      postTimelineV1EventsByIdReactions: build.mutation<
        PostTimelineV1EventsByIdReactionsApiResponse,
        PostTimelineV1EventsByIdReactionsApiArg
      >({
        query: (queryArg) => ({
          url: `/timeline/v1/events/${queryArg.id}/reactions`,
          method: "POST",
          body: queryArg.reactionInput,
        }),
      }),
      deleteTimelineV1EventsByIdReactionsAndReactionId: build.mutation<
        DeleteTimelineV1EventsByIdReactionsAndReactionIdApiResponse,
        DeleteTimelineV1EventsByIdReactionsAndReactionIdApiArg
      >({
        query: (queryArg) => ({
          url: `/timeline/v1/events/${queryArg.id}/reactions/${queryArg.reactionId}`,
          method: "DELETE",
        }),
      }),
      getErpTrackingCategoryV1: build.query<GetErpTrackingCategoryV1ApiResponse, GetErpTrackingCategoryV1ApiArg>({
        query: (queryArg) => ({
          url: `/erp-tracking-category/v1/`,
          params: {
            type: queryArg["type"],
          },
        }),
      }),
      postImageAssetV1ImageAssets: build.mutation<
        PostImageAssetV1ImageAssetsApiResponse,
        PostImageAssetV1ImageAssetsApiArg
      >({
        query: (queryArg) => ({ url: `/image-asset/v1/image-assets`, method: "POST", body: queryArg.imageAssetInput }),
      }),
      getImageAssetV1ByIdContent: build.query<GetImageAssetV1ByIdContentApiResponse, GetImageAssetV1ByIdContentApiArg>({
        query: (queryArg) => ({ url: `/image-asset/v1/${queryArg.id}/content` }),
      }),
      getPlaidV1LinkToken: build.query<GetPlaidV1LinkTokenApiResponse, GetPlaidV1LinkTokenApiArg>({
        query: () => ({ url: `/plaid/v1/link-token` }),
      }),
      postPlaidV1Webhooks: build.mutation<PostPlaidV1WebhooksApiResponse, PostPlaidV1WebhooksApiArg>({
        query: (queryArg) => ({ url: `/plaid/v1/webhooks`, method: "POST", body: queryArg.body }),
      }),
      getNotificationV1: build.query<GetNotificationV1ApiResponse, GetNotificationV1ApiArg>({
        query: (queryArg) => ({
          url: `/notification/v1/`,
          params: {
            limit: queryArg.limit,
            until: queryArg.until,
            since: queryArg.since,
            filter_by_unread: queryArg.filterByUnread,
            filter_by_mentioned: queryArg.filterByMentioned,
          },
        }),
        providesTags: ["notifications"],
      }),
      getNotificationV1Tasks: build.query<GetNotificationV1TasksApiResponse, GetNotificationV1TasksApiArg>({
        query: () => ({ url: `/notification/v1/tasks` }),
        providesTags: ["notifications", "workflow-runs", "tasks", "tools", "vendors", "integrations"],
      }),
      postNotificationV1TaskSnooze: build.mutation<
        PostNotificationV1TaskSnoozeApiResponse,
        PostNotificationV1TaskSnoozeApiArg
      >({
        query: (queryArg) => ({ url: `/notification/v1/task/snooze`, method: "POST", body: queryArg.taskSnoozeBody }),
        invalidatesTags: ["tasks"],
      }),
      postNotificationV1TaskDismiss: build.mutation<
        PostNotificationV1TaskDismissApiResponse,
        PostNotificationV1TaskDismissApiArg
      >({
        query: (queryArg) => ({ url: `/notification/v1/task/dismiss`, method: "POST", body: queryArg.taskDismissBody }),
        invalidatesTags: ["tasks"],
      }),
      getNotificationV1Count: build.query<GetNotificationV1CountApiResponse, GetNotificationV1CountApiArg>({
        query: () => ({ url: `/notification/v1/count` }),
        providesTags: ["notifications"],
      }),
      getNotificationV1CountUnread: build.query<
        GetNotificationV1CountUnreadApiResponse,
        GetNotificationV1CountUnreadApiArg
      >({
        query: () => ({ url: `/notification/v1/count/unread` }),
        providesTags: ["notifications"],
      }),
      getNotificationV1Unread: build.query<GetNotificationV1UnreadApiResponse, GetNotificationV1UnreadApiArg>({
        query: () => ({ url: `/notification/v1/unread` }),
        providesTags: ["notifications"],
      }),
      getNotificationV1ById: build.query<GetNotificationV1ByIdApiResponse, GetNotificationV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/notification/v1/${queryArg.id}` }),
        providesTags: ["notifications"],
      }),
      putNotificationV1ById: build.mutation<PutNotificationV1ByIdApiResponse, PutNotificationV1ByIdApiArg>({
        query: (queryArg) => ({
          url: `/notification/v1/${queryArg.id}`,
          method: "PUT",
          body: queryArg.notificationUpdate,
        }),
        invalidatesTags: ["notifications"],
      }),
      putNotificationV1Bulk: build.mutation<PutNotificationV1BulkApiResponse, PutNotificationV1BulkApiArg>({
        query: (queryArg) => ({
          url: `/notification/v1/bulk`,
          method: "PUT",
          body: queryArg.notificationBulkActionBody,
        }),
        invalidatesTags: ["notifications"],
      }),
      postDynamoV1ByToolListingId: build.mutation<
        PostDynamoV1ByToolListingIdApiResponse,
        PostDynamoV1ByToolListingIdApiArg
      >({
        query: (queryArg) => ({ url: `/dynamo/v1/${queryArg.toolListingId}`, method: "POST" }),
        invalidatesTags: ["dynamo"],
      }),
      getDynamoV1RunByRunId: build.query<GetDynamoV1RunByRunIdApiResponse, GetDynamoV1RunByRunIdApiArg>({
        query: (queryArg) => ({ url: `/dynamo/v1/run/${queryArg.runId}` }),
        providesTags: ["dynamo"],
      }),
      getDynamoV1ByIdIsDynamoRunning: build.query<
        GetDynamoV1ByIdIsDynamoRunningApiResponse,
        GetDynamoV1ByIdIsDynamoRunningApiArg
      >({
        query: (queryArg) => ({ url: `/dynamo/v1/${queryArg.id}/is-dynamo-running` }),
        providesTags: ["dynamo"],
      }),
      postDynamoV1ByIdReportDataTelemetry: build.mutation<
        PostDynamoV1ByIdReportDataTelemetryApiResponse,
        PostDynamoV1ByIdReportDataTelemetryApiArg
      >({
        query: (queryArg) => ({
          url: `/dynamo/v1/${queryArg.id}/report-data/telemetry`,
          method: "POST",
          body: queryArg.body,
        }),
      }),
      postDynamoV1ApplyByTimelineEventId: build.mutation<
        PostDynamoV1ApplyByTimelineEventIdApiResponse,
        PostDynamoV1ApplyByTimelineEventIdApiArg
      >({
        query: (queryArg) => ({ url: `/dynamo/v1/apply/${queryArg.timelineEventId}`, method: "POST" }),
      }),
      getCalendarV1RenewalsByCalendarKey: build.query<
        GetCalendarV1RenewalsByCalendarKeyApiResponse,
        GetCalendarV1RenewalsByCalendarKeyApiArg
      >({
        query: (queryArg) => ({ url: `/calendar/v1/renewals/${queryArg.calendarKey}` }),
        providesTags: ["legal"],
      }),
      postCreditCardV1List: build.query<PostCreditCardV1ListApiResponse, PostCreditCardV1ListApiArg>({
        query: (queryArg) => ({
          url: `/credit-card/v1/list`,
          method: "POST",
          body: queryArg.listQueryStringParams,
          params: {
            tool_id: queryArg.toolId,
            vendor_id: queryArg.vendorId,
          },
        }),
        providesTags: ["credit-card"],
      }),
      getCreditCardV1PickerOptions: build.query<
        GetCreditCardV1PickerOptionsApiResponse,
        GetCreditCardV1PickerOptionsApiArg
      >({
        query: (queryArg) => ({
          url: `/credit-card/v1/picker-options`,
          params: {
            search: queryArg.search,
            vendor_id: queryArg.vendorId,
            tool_id: queryArg.toolId,
          },
        }),
      }),
      postCreditCardV1Lock: build.mutation<PostCreditCardV1LockApiResponse, PostCreditCardV1LockApiArg>({
        query: (queryArg) => ({ url: `/credit-card/v1/lock`, method: "POST", body: queryArg.body }),
      }),
      postCreditCardV1UnlockById: build.mutation<
        PostCreditCardV1UnlockByIdApiResponse,
        PostCreditCardV1UnlockByIdApiArg
      >({
        query: (queryArg) => ({ url: `/credit-card/v1unlock/${queryArg.id}`, method: "POST" }),
      }),
      getOnboardingV1Latest: build.query<GetOnboardingV1LatestApiResponse, GetOnboardingV1LatestApiArg>({
        query: () => ({ url: `/onboarding/v1/latest` }),
        providesTags: ["onboarding"],
      }),
      postOnboardingV1StepByStepTypeRecord: build.mutation<
        PostOnboardingV1StepByStepTypeRecordApiResponse,
        PostOnboardingV1StepByStepTypeRecordApiArg
      >({
        query: (queryArg) => ({ url: `/onboarding/v1/step/${queryArg.stepType}/record`, method: "POST" }),
        invalidatesTags: ["onboarding"],
      }),
      getNegotiationV1ById: build.query<GetNegotiationV1ByIdApiResponse, GetNegotiationV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/negotiation/v1/${queryArg.id}` }),
        providesTags: ["betsy"],
      }),
      postNegotiationV1Compose: build.mutation<PostNegotiationV1ComposeApiResponse, PostNegotiationV1ComposeApiArg>({
        query: (queryArg) => ({ url: `/negotiation/v1/compose`, method: "POST", body: queryArg.body }),
      }),
      postNegotiationV1EmailSend: build.mutation<
        PostNegotiationV1EmailSendApiResponse,
        PostNegotiationV1EmailSendApiArg
      >({
        query: (queryArg) => ({ url: `/negotiation/v1/email/send`, method: "POST", body: queryArg.body }),
        invalidatesTags: ["betsy", "tasks"],
      }),
      postNegotiationV1UpdateContext: build.mutation<
        PostNegotiationV1UpdateContextApiResponse,
        PostNegotiationV1UpdateContextApiArg
      >({
        query: (queryArg) => ({ url: `/negotiation/v1/update-context`, method: "POST", body: queryArg.body }),
      }),
      postNegotiationV1Regenerate: build.mutation<
        PostNegotiationV1RegenerateApiResponse,
        PostNegotiationV1RegenerateApiArg
      >({
        query: (queryArg) => ({ url: `/negotiation/v1/regenerate`, method: "POST", body: queryArg.body }),
      }),
      postNegotiationV1Reset: build.mutation<PostNegotiationV1ResetApiResponse, PostNegotiationV1ResetApiArg>({
        query: (queryArg) => ({ url: `/negotiation/v1/reset`, method: "POST", body: queryArg.body }),
      }),
      postNegotiationV1GmailAddonHomepage: build.mutation<
        PostNegotiationV1GmailAddonHomepageApiResponse,
        PostNegotiationV1GmailAddonHomepageApiArg
      >({
        query: (queryArg) => ({ url: `/negotiation/v1/gmail-addon/homepage`, method: "POST", body: queryArg.body }),
      }),
      postNegotiationV1GmailAddonCreateNegotiation: build.mutation<
        PostNegotiationV1GmailAddonCreateNegotiationApiResponse,
        PostNegotiationV1GmailAddonCreateNegotiationApiArg
      >({
        query: (queryArg) => ({
          url: `/negotiation/v1/gmail-addon/create-negotiation`,
          method: "POST",
          body: queryArg.body,
        }),
      }),
      postNegotiationV1GmailAddonEmailOpened: build.mutation<
        PostNegotiationV1GmailAddonEmailOpenedApiResponse,
        PostNegotiationV1GmailAddonEmailOpenedApiArg
      >({
        query: (queryArg) => ({ url: `/negotiation/v1/gmail-addon/email-opened`, method: "POST", body: queryArg.body }),
      }),
      postNegotiationV1EmailSimRun: build.mutation<
        PostNegotiationV1EmailSimRunApiResponse,
        PostNegotiationV1EmailSimRunApiArg
      >({
        query: (queryArg) => ({ url: `/negotiation/v1/email-sim/run`, method: "POST", body: queryArg.body }),
      }),
      postNegotiationV1EmailSimInit: build.mutation<
        PostNegotiationV1EmailSimInitApiResponse,
        PostNegotiationV1EmailSimInitApiArg
      >({
        query: (queryArg) => ({ url: `/negotiation/v1/email-sim/init`, method: "POST", body: queryArg.body }),
      }),
      getSubscribeV1Organization: build.query<GetSubscribeV1OrganizationApiResponse, GetSubscribeV1OrganizationApiArg>({
        query: () => ({ url: `/subscribe/v1/organization` }),
      }),
      getSubscribeV1LinksByCodeWorkflowRun: build.query<
        GetSubscribeV1LinksByCodeWorkflowRunApiResponse,
        GetSubscribeV1LinksByCodeWorkflowRunApiArg
      >({
        query: (queryArg) => ({ url: `/subscribe/v1/links/${queryArg.code}/workflow-run` }),
      }),
      postRetoolV1DynamoRunLinearQaAgent: build.mutation<
        PostRetoolV1DynamoRunLinearQaAgentApiResponse,
        PostRetoolV1DynamoRunLinearQaAgentApiArg
      >({
        query: () => ({ url: `/retool/v1/dynamo/run-linear-qa-agent`, method: "POST" }),
      }),
      postRetoolV1DynamoRunLinearQaAgentByIssueId: build.mutation<
        PostRetoolV1DynamoRunLinearQaAgentByIssueIdApiResponse,
        PostRetoolV1DynamoRunLinearQaAgentByIssueIdApiArg
      >({
        query: (queryArg) => ({ url: `/retool/v1/dynamo/run-linear-qa-agent/${queryArg.issueId}`, method: "POST" }),
      }),
      postRetoolV1DynamoRunComplianceAgent: build.mutation<
        PostRetoolV1DynamoRunComplianceAgentApiResponse,
        PostRetoolV1DynamoRunComplianceAgentApiArg
      >({
        query: (queryArg) => ({ url: `/retool/v1/dynamo/run-compliance-agent`, method: "POST", body: queryArg.body }),
      }),
      postRetoolV1DynamoSyncToolData: build.mutation<
        PostRetoolV1DynamoSyncToolDataApiResponse,
        PostRetoolV1DynamoSyncToolDataApiArg
      >({
        query: (queryArg) => ({ url: `/retool/v1/dynamo/sync-tool-data`, method: "POST", body: queryArg.body }),
      }),
      getRetoolV1WorkflowsLint: build.query<GetRetoolV1WorkflowsLintApiResponse, GetRetoolV1WorkflowsLintApiArg>({
        query: () => ({ url: `/retool/v1/workflows/lint` }),
      }),
      postToolListingV1: build.mutation<PostToolListingV1ApiResponse, PostToolListingV1ApiArg>({
        query: (queryArg) => ({ url: `/tool-listing/v1/`, method: "POST", body: queryArg.createToolListing }),
      }),
      putToolListingV1ById: build.mutation<PutToolListingV1ByIdApiResponse, PutToolListingV1ByIdApiArg>({
        query: (queryArg) => ({
          url: `/tool-listing/v1/${queryArg.id}`,
          method: "PUT",
          body: queryArg.updateToolListing,
        }),
      }),
      deleteToolListingV1ById: build.mutation<DeleteToolListingV1ByIdApiResponse, DeleteToolListingV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/tool-listing/v1/${queryArg.id}`, method: "DELETE", body: queryArg.body }),
      }),
      postToolListingV1ByIdReportData: build.mutation<
        PostToolListingV1ByIdReportDataApiResponse,
        PostToolListingV1ByIdReportDataApiArg
      >({
        query: (queryArg) => ({
          url: `/tool-listing/v1/${queryArg.id}/report_data`,
          method: "POST",
          body: queryArg.toolUserReport,
        }),
      }),
      postToolListingV1Keys: build.mutation<PostToolListingV1KeysApiResponse, PostToolListingV1KeysApiArg>({
        query: (queryArg) => ({ url: `/tool-listing/v1/keys`, method: "POST", body: queryArg.body }),
      }),
      putToolListingV1ReplaceListing: build.mutation<
        PutToolListingV1ReplaceListingApiResponse,
        PutToolListingV1ReplaceListingApiArg
      >({
        query: (queryArg) => ({ url: `/tool-listing/v1/replace-listing`, method: "PUT", body: queryArg.body }),
      }),
      putToolListingV1ByIdLogo: build.mutation<PutToolListingV1ByIdLogoApiResponse, PutToolListingV1ByIdLogoApiArg>({
        query: (queryArg) => ({ url: `/tool-listing/v1/${queryArg.id}/logo`, method: "PUT", body: queryArg.body }),
      }),
      postToolListingV1TransferLogin: build.mutation<
        PostToolListingV1TransferLoginApiResponse,
        PostToolListingV1TransferLoginApiArg
      >({
        query: (queryArg) => ({ url: `/tool-listing/v1/transfer-login`, method: "POST", body: queryArg.body }),
      }),
      postToolListingV1TransferDescriptor: build.mutation<
        PostToolListingV1TransferDescriptorApiResponse,
        PostToolListingV1TransferDescriptorApiArg
      >({
        query: (queryArg) => ({ url: `/tool-listing/v1/transfer-descriptor`, method: "POST", body: queryArg.body }),
      }),
      postToolListingV1UpdateListingLogos: build.mutation<
        PostToolListingV1UpdateListingLogosApiResponse,
        PostToolListingV1UpdateListingLogosApiArg
      >({
        query: () => ({ url: `/tool-listing/v1/update-listing-logos`, method: "POST" }),
      }),
      postVendorListingV1: build.mutation<PostVendorListingV1ApiResponse, PostVendorListingV1ApiArg>({
        query: (queryArg) => ({ url: `/vendor-listing/v1/`, method: "POST", body: queryArg.createVendorListing }),
      }),
      postVendorListingV1MarkSimilarityChecked: build.mutation<
        PostVendorListingV1MarkSimilarityCheckedApiResponse,
        PostVendorListingV1MarkSimilarityCheckedApiArg
      >({
        query: (queryArg) => ({
          url: `/vendor-listing/v1/mark-similarity-checked`,
          method: "POST",
          body: queryArg.body,
        }),
      }),
      postVendorListingV1QueryVectors: build.mutation<
        PostVendorListingV1QueryVectorsApiResponse,
        PostVendorListingV1QueryVectorsApiArg
      >({
        query: (queryArg) => ({ url: `/vendor-listing/v1/query-vectors`, method: "POST", body: queryArg.body }),
      }),
      putVendorListingV1ById: build.mutation<PutVendorListingV1ByIdApiResponse, PutVendorListingV1ByIdApiArg>({
        query: (queryArg) => ({
          url: `/vendor-listing/v1/${queryArg.id}`,
          method: "PUT",
          body: queryArg.updateVendorListing,
        }),
      }),
      deleteVendorListingV1ById: build.mutation<DeleteVendorListingV1ByIdApiResponse, DeleteVendorListingV1ByIdApiArg>({
        query: (queryArg) => ({ url: `/vendor-listing/v1/${queryArg.id}`, method: "DELETE", body: queryArg.body }),
      }),
      postVendorListingV1ByIdReportData: build.mutation<
        PostVendorListingV1ByIdReportDataApiResponse,
        PostVendorListingV1ByIdReportDataApiArg
      >({
        query: (queryArg) => ({
          url: `/vendor-listing/v1/${queryArg.id}/report_data`,
          method: "POST",
          body: queryArg.vendorUserReport,
        }),
      }),
      postVendorListingV1Keys: build.mutation<PostVendorListingV1KeysApiResponse, PostVendorListingV1KeysApiArg>({
        query: (queryArg) => ({ url: `/vendor-listing/v1/keys`, method: "POST", body: queryArg.body }),
      }),
      putVendorListingV1ReplaceListing: build.mutation<
        PutVendorListingV1ReplaceListingApiResponse,
        PutVendorListingV1ReplaceListingApiArg
      >({
        query: (queryArg) => ({ url: `/vendor-listing/v1/replace-listing`, method: "PUT", body: queryArg.body }),
      }),
      putVendorListingV1ByIdLogo: build.mutation<
        PutVendorListingV1ByIdLogoApiResponse,
        PutVendorListingV1ByIdLogoApiArg
      >({
        query: (queryArg) => ({ url: `/vendor-listing/v1/${queryArg.id}/logo`, method: "PUT", body: queryArg.body }),
      }),
      getVendorListingV1FindWebsiteByListingType: build.query<
        GetVendorListingV1FindWebsiteByListingTypeApiResponse,
        GetVendorListingV1FindWebsiteByListingTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/vendor-listing/v1/find-website/${queryArg.listingType}`,
          params: {
            display_name: queryArg.displayName,
          },
        }),
      }),
      postVendorListingV1TransferTool: build.mutation<
        PostVendorListingV1TransferToolApiResponse,
        PostVendorListingV1TransferToolApiArg
      >({
        query: (queryArg) => ({ url: `/vendor-listing/v1/transfer-tool`, method: "POST", body: queryArg.body }),
      }),
      postVendorListingV1TransferDescriptor: build.mutation<
        PostVendorListingV1TransferDescriptorApiResponse,
        PostVendorListingV1TransferDescriptorApiArg
      >({
        query: (queryArg) => ({ url: `/vendor-listing/v1/transfer-descriptor`, method: "POST", body: queryArg.body }),
      }),
      postVendorListingV1UpdateListingLogos: build.mutation<
        PostVendorListingV1UpdateListingLogosApiResponse,
        PostVendorListingV1UpdateListingLogosApiArg
      >({
        query: () => ({ url: `/vendor-listing/v1/update-listing-logos`, method: "POST" }),
      }),
      getVendorListingV1DraftListingFromUrlByListingType: build.query<
        GetVendorListingV1DraftListingFromUrlByListingTypeApiResponse,
        GetVendorListingV1DraftListingFromUrlByListingTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/vendor-listing/v1/draft-listing-from-url/${queryArg.listingType}`,
          params: {
            url: queryArg.url,
          },
        }),
      }),
      putTransactionVendorToolListingV1ByMerchantIdentification: build.mutation<
        PutTransactionVendorToolListingV1ByMerchantIdentificationApiResponse,
        PutTransactionVendorToolListingV1ByMerchantIdentificationApiArg
      >({
        query: (queryArg) => ({
          url: `/transaction-vendor-tool-listing/v1/${queryArg.merchantIdentification}`,
          method: "PUT",
          body: queryArg.body,
        }),
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as generatedApi }
export type GetOpenapiApiResponse = unknown
export type GetOpenapiApiArg = void
export type GetSchemaV1ByObjectTypeApiResponse = unknown
export type GetSchemaV1ByObjectTypeApiArg = {
  objectType: ObjectType
}
export type GetSchemaV1ByObjectTypePatchApiResponse = unknown
export type GetSchemaV1ByObjectTypePatchApiArg = {
  linkCode?: NonEmptyTrimmedString
  objectType: "Tool" | "Vendor" | "WorkflowRun"
}
export type GetSchemaV1ByObjectTypeInputApiResponse = unknown
export type GetSchemaV1ByObjectTypeInputApiArg = {
  linkCode?: NonEmptyTrimmedString
  objectType: "LegalAgreement"
}
export type GetSchemaV1FieldsApiResponse = /** status 200 Default Response */ FieldConfigsCategoryResponse
export type GetSchemaV1FieldsApiArg = void
export type PutSchemaV1FieldsDocumentExtractionJobApiResponse = unknown
export type PutSchemaV1FieldsDocumentExtractionJobApiArg = {
  body: {
    fields: FieldConfigMinimal[]
  }
}
export type PostSchemaV1FieldsDuplicatesApiResponse = /** status 200 Default Response */ SimilarFieldResponse
export type PostSchemaV1FieldsDuplicatesApiArg = {
  similarFieldQuery: SimilarFieldQuery
}
export type PutSchemaV1ByObjectTypeFieldsOrderApiResponse = /** status 200 Default Response */ ApiResult
export type PutSchemaV1ByObjectTypeFieldsOrderApiArg = {
  objectType: CustomizableObjectType
  body: {
    category: null | FieldCategory
    fields: FieldReference[]
  }
}
export type PutSchemaV1ByObjectTypeFieldsAndFieldNameApiResponse = /** status 200 Default Response */ FieldConfig
export type PutSchemaV1ByObjectTypeFieldsAndFieldNameApiArg = {
  objectType: CustomizableObjectType
  fieldName: FieldName
  fieldConfigInput: FieldConfigInput
}
export type DeleteSchemaV1ByObjectTypeFieldsAndFieldNameApiResponse = unknown
export type DeleteSchemaV1ByObjectTypeFieldsAndFieldNameApiArg = {
  objectType: CustomizableObjectType
  fieldName: FieldName
}
export type PostSchemaV1FieldsTextToFieldApiResponse = /** status 200 Default Response */ {
  value: null | any
}
export type PostSchemaV1FieldsTextToFieldApiArg = {
  body: {
    source_text: NonEmptyString
    object_type: ObjectType
    field_name: string
    is_custom: boolean
  }
}
export type PostSchemaV1MapApiResponse = unknown
export type PostSchemaV1MapApiArg = {
  body: {
    object_types: ObjectType[]
  }
}
export type PostEmailV1InboundApiResponse = unknown
export type PostEmailV1InboundApiArg = {
  postmarkInboundMessage: PostmarkInboundMessage
}
export type GetUserV1WhoamiApiResponse =
  /** status 200 Related user information that is stored in the user's session and returned by the whoami endpoint. Future additions to this need to be nullable or all sessions need to be reset. */ UserWithOrganization
export type GetUserV1WhoamiApiArg = void
export type GetUserV1CalendarKeyApiResponse = /** status 200 Default Response */ {
  calendar_key: string
}
export type GetUserV1CalendarKeyApiArg = void
export type GetUserV1SettingsApiResponse = /** status 200 Default Response */ UserSettings
export type GetUserV1SettingsApiArg = void
export type GetUserV1SettingsTotpQrApiResponse = unknown
export type GetUserV1SettingsTotpQrApiArg = void
export type GetUserV1SettingsContractCollectorPrivacyApiResponse = /** status 200 Default Response */ {
  settings: null | ContractCollectorPrivacySetting[]
}
export type GetUserV1SettingsContractCollectorPrivacyApiArg = void
export type PostUserV1SettingsContractCollectorPrivacyApiResponse = unknown
export type PostUserV1SettingsContractCollectorPrivacyApiArg = {
  body: {
    settings: ContractCollectorPrivacySetting[]
  }
}
export type GetUserV1EmailDelegatesApiResponse = /** status 200 Default Response */ User[]
export type GetUserV1EmailDelegatesApiArg = void
export type PostUserV1EmailDelegatesApiResponse = unknown
export type PostUserV1EmailDelegatesApiArg = {
  body: {
    delegate_ids: string[]
  }
}
export type GetUserV1WritingPersonaApiResponse = /** status 200 Default Response */ {
  writing_persona: null | string
}
export type GetUserV1WritingPersonaApiArg = void
export type PostUserV1WritingPersonaApiResponse = unknown
export type PostUserV1WritingPersonaApiArg = {
  body: {
    writing_persona: string
  }
}
export type GetUserV1DocumentsByIdContentApiResponse = unknown
export type GetUserV1DocumentsByIdContentApiArg = {
  id: string
}
export type PostUserV1SettingsTotpEnableApiResponse = /** status 200 Default Response */ ApiResult
export type PostUserV1SettingsTotpEnableApiArg = {
  body: {
    token: NonEmptyTrimmedString
  }
}
export type PostUserV1SettingsTotpDisableApiResponse = /** status 200 Default Response */ ApiResult
export type PostUserV1SettingsTotpDisableApiArg = {
  body: {
    password: NonEmptyString
  }
}
export type GetUserV1NotificationSettingsApiResponse = /** status 200 Default Response */ {
  activeChannels: NotificationChannel[]
  settings: NotificationSetting[]
}
export type GetUserV1NotificationSettingsApiArg = void
export type PutUserV1NotificationSettingsApiResponse = unknown
export type PutUserV1NotificationSettingsApiArg = {
  body: UpdateNotificationSetting[]
}
export type PostUserV1DisableNotificationChannelApiResponse = unknown
export type PostUserV1DisableNotificationChannelApiArg = {
  body: {
    channel: NotificationChannel
  }
}
export type GetUserV1ByIdApiResponse = /** status 200 Default Response */ User
export type GetUserV1ByIdApiArg = {
  id: string
}
export type DeleteUserV1ByIdApiResponse = /** status 200 Default Response */ ApiResult
export type DeleteUserV1ByIdApiArg = {
  id: string
  body: {
    newOwnerId?: string
  }
}
export type PutUserV1ByIdApiResponse = /** status 200 Default Response */ User
export type PutUserV1ByIdApiArg = {
  id: string
  updateUser: UpdateUser
}
export type GetUserV1ByIdDisplayApiResponse = /** status 200 Default Response */ {
  id: string
  first_name: null | NonEmptyTrimmedString
  last_name: null | NonEmptyTrimmedString
  email: EmailString
  profile_image: null | ImageAsset
}
export type GetUserV1ByIdDisplayApiArg = {
  linkCode?: NonEmptyTrimmedString
  id: string
}
export type GetUserV1DeletionRequirementsByIdApiResponse = /** status 200 Default Response */ {
  requirement: null | ("replacement_owner" | "replacement_approver")
}
export type GetUserV1DeletionRequirementsByIdApiArg = {
  id: string
}
export type PostUserV1OrganizationApiResponse = /** status 200 Default Response */ UserOrganizationSummaryResponse
export type PostUserV1OrganizationApiArg = {
  body: {
    limit?: number
    offset?: number
    sort?: {
      by: UserOrganizationSummaryColumn
      direction: SortDirection
      nulls?: SortNulls
    }
    /**
        The inner array is an OR, the outer array is an AND so if we get passed in filter `A`, `B`, `C`:
        
        - `[[A, B], [C]]` is `(A OR B) AND C`
        - `[[A], [B], [C]]` is `(A) AND (B) AND (C)`
        - `[[A, B, C]]` is `(A OR B OR C)`
        
        TODO make this more flexible.
         */
    filter?: {
      /** The dot-separated path of the property to filter on.
            
            For arrays, a path segment can also be a wildcard `*` (e.g. `categories.*.id`), in which case the expression being filtered on is calculated by mapping over each element of the element (in the example, an array of IDs). */
      column: UserOrganizationSummaryColumn
      fields: FilterField
    }[][]
  }
}
export type PostUserV1CreateApiResponse = /** status 200 Default Response */ ApiResult
export type PostUserV1CreateApiArg = {
  body: {
    email: EmailString
    roles: Role[]
    first_name: NonEmptyTrimmedString
    last_name: NonEmptyTrimmedString
    person_id?: string
    organization_id: string
    admin_email: EmailString
  }
}
export type PostUserV1UpdateRolesApiResponse = /** status 200 Default Response */ ApiResult
export type PostUserV1UpdateRolesApiArg = {
  body: {
    id: string
    organization_id: string & string
    roles: Role[]
  }
}
export type PostUserV1InviteApiResponse = /** status 201 Default Response */ User
export type PostUserV1InviteApiArg = {
  inviteUser: InviteUser
}
export type PostUserV1ResendInviteApiResponse = /** status 200 Default Response */ ApiResult
export type PostUserV1ResendInviteApiArg = {
  body: {
    userId: string
  }
}
export type PostUserV1LoginApiResponse = /** status 200 Default Response */ LoginResult
export type PostUserV1LoginApiArg = {
  loginInput: LoginInput
}
export type PostUserV1SigninOktaInitiateApiResponse = /** status 200 Default Response */ {
  oktaDomain: HttpUrlString
}
export type PostUserV1SigninOktaInitiateApiArg = {
  body: {
    email: EmailString
  }
}
export type PostUserV1ForgotPasswordApiResponse = /** status 200 Default Response */ ApiResult
export type PostUserV1ForgotPasswordApiArg = {
  body: {
    email: string
  }
}
export type GetUserV1CodeByCodeEmailApiResponse = /** status 200 Default Response */ {
  email: EmailString
}
export type GetUserV1CodeByCodeEmailApiArg = {
  code: string
}
export type PostUserV1ChangePasswordApiResponse = /** status 200 Default Response */ ApiResult
export type PostUserV1ChangePasswordApiArg = {
  changePasswordInput: ChangePasswordInput
}
export type GetUserV1LogoutApiResponse = /** status 200 Default Response */ ApiResult
export type GetUserV1LogoutApiArg = void
export type PostUserV1CreateUserApiResponse = /** status 201 Default Response */ ApiResult
export type PostUserV1CreateUserApiArg = {
  createUserInput: CreateUserInput
}
export type PostUserV1ByUserIdResetOnboardingApiResponse = unknown
export type PostUserV1ByUserIdResetOnboardingApiArg = {
  userId: string
}
export type GetOrganizationV1CurrentApiResponse = /** status 200 Default Response */ Organization
export type GetOrganizationV1CurrentApiArg = void
export type GetOrganizationV1CurrentOktaConfigApiResponse =
  /** status 200 Sensitive parameters related to Okta SSO configuration for an organization. Only viewable by permissioned users */ OrganizationOktaSsoConfig
export type GetOrganizationV1CurrentOktaConfigApiArg = void
export type PutOrganizationV1UpdateApiResponse = /** status 200 Default Response */ Organization
export type PutOrganizationV1UpdateApiArg = {
  updateOrganization: UpdateOrganization
}
export type PostOrganizationV1AdminUpdateByOrganizationIdApiResponse = unknown
export type PostOrganizationV1AdminUpdateByOrganizationIdApiArg = {
  organizationId: string
  body: {
    brm_support_clearable: boolean
    admin_email: EmailString
  }
}
export type PostOrganizationV1CreateApiResponse = /** status 200 Default Response */ Organization
export type PostOrganizationV1CreateApiArg = {
  createOrganization: CreateOrganization
}
export type PostOrganizationV1ClearOrganizationApiResponse = /** status 200 Default Response */ ApiResult
export type PostOrganizationV1ClearOrganizationApiArg = {
  body: {
    organization_id: string
    additional_removed_entities?: ("workflow_def" | "organization")[]
    admin_email: EmailString
  }
}
export type PostOrganizationV1PickableEntitiesApiResponse = /** status 200 Default Response */ PickableEntities
export type PostOrganizationV1PickableEntitiesApiArg = {
  body: {
    filter: PickableEntityFilter
    limit?: LimitNumber
  }
}
export type PostOrganizationV1PermissionedPickableEntitiesApiResponse =
  /** status 200 Default Response */ PermissionedPickableEntity[]
export type PostOrganizationV1PermissionedPickableEntitiesApiArg = {
  body: {
    filter: PermissionedPickableEntityFilter
    limit?: LimitNumber
    link_code?: NonEmptyTrimmedString
  }
}
export type PostOrganizationV1OpenSignupApiResponse = unknown
export type PostOrganizationV1OpenSignupApiArg = {
  body: {
    organization_name: string
    code: string
  }
}
export type GetSpendV1ByTypeAndIdApiResponse = /** status 200 Default Response */ SpendResponse
export type GetSpendV1ByTypeAndIdApiArg = {
  startDate: DateString
  endDate?: DateString
  /** Interval to group spend by, e.g. P1M for monthly, P1W for weekly, P1D for daily. Only accepts months, weeks, and days. */
  interval?: DateDurationString
  id: string
  type: SpendQueryKind
}
export type PostSpendV1ExchangeRateSaveApiResponse = unknown
export type PostSpendV1ExchangeRateSaveApiArg = {
  body: {
    oldest_date?: string
  }
}
export type GetStatisticsV1EmailIntegrationsApiResponse =
  /** status 200 Default Response */ AllEmailIntegrationStatistic
export type GetStatisticsV1EmailIntegrationsApiArg = void
export type GetStatisticsV1AgreementsApiResponse = /** status 200 Default Response */ AllAgreementStatistic
export type GetStatisticsV1AgreementsApiArg = void
export type GetStatisticsV1PersonsApiResponse = /** status 200 Default Response */ AllPersonStatistic
export type GetStatisticsV1PersonsApiArg = void
export type GetStatisticsV1ToolsApiResponse = /** status 200 Default Response */ AllToolStatistic
export type GetStatisticsV1ToolsApiArg = void
export type PostToolV1ListApiResponse = /** status 200 Default Response */ ToolListItemResponse
export type PostToolV1ListApiArg = {
  listQueryStringParams: ListQueryStringParams
}
export type GetToolV1ListHiddenApiResponse = /** status 200 Default Response */ ToolMinimal[]
export type GetToolV1ListHiddenApiArg = void
export type PostToolV1CsvApiResponse = /** status 200 Default Response */ {
  url: string
}
export type PostToolV1CsvApiArg = {
  body: {
    limit?: number
    offset?: number
    sort?: {
      by: PropertyPathString
      direction: SortDirection
      nulls?: SortNulls
    }
    /**
        The inner array is an OR, the outer array is an AND so if we get passed in filter `A`, `B`, `C`:
        
        - `[[A, B], [C]]` is `(A OR B) AND C`
        - `[[A], [B], [C]]` is `(A) AND (B) AND (C)`
        - `[[A, B, C]]` is `(A OR B OR C)`
        
        TODO make this more flexible.
         */
    filter?: {
      /** The dot-separated path of the property to filter on.
            
            For arrays, a path segment can also be a wildcard `*` (e.g. `categories.*.id`), in which case the expression being filtered on is calculated by mapping over each element of the element (in the example, an array of IDs). */
      column: FilterPathString
      fields: FilterField
    }[][]
    columns?: FilterPathString[]
  }
}
export type PostToolV1AiFilterApiResponse = /** status 200 Default Response */ {
  /** The dot-separated path of the property to filter on.
    
    For arrays, a path segment can also be a wildcard `*` (e.g. `categories.*.id`), in which case the expression being filtered on is calculated by mapping over each element of the element (in the example, an array of IDs). */
  column: FilterPathString
  fields: FilterField
}[]
export type PostToolV1AiFilterApiArg = {
  body: {
    query: string
  }
}
export type GetToolV1PickerOptionsApiResponse = /** status 200 Default Response */ ToolOptionWithVendor[]
export type GetToolV1PickerOptionsApiArg = {
  search: string
  linkCode?: NonEmptyTrimmedString
}
export type PutToolV1ByIdFollowingApiResponse = unknown
export type PutToolV1ByIdFollowingApiArg = {
  id: string
  body: {
    following: boolean
  }
}
export type PatchToolV1ByIdApiResponse = /** status 200 Default Response */ Tool
export type PatchToolV1ByIdApiArg = {
  id: string
  toolPatch: ToolPatch
}
export type GetToolV1ByIdApiResponse =
  /** status 200 Tool detail information that a specific user sees when they land on the tool page */ Tool2
export type GetToolV1ByIdApiArg = {
  id: string
}
export type PostToolV1ByIdHideApiResponse = unknown
export type PostToolV1ByIdHideApiArg = {
  id: string
  body: {
    hidden: boolean
  }
}
export type PostToolV1ByIdLoginsListApiResponse = /** status 200 Default Response */ LoginSummaryResponse
export type PostToolV1ByIdLoginsListApiArg = {
  id: string
  listQueryStringParams: ListQueryStringParams
}
export type PostToolV1ByIdPersonsListApiResponse = /** status 200 Default Response */ PersonListItemResponse
export type PostToolV1ByIdPersonsListApiArg = {
  id: string
  listQueryStringParams: ListQueryStringParams
}
export type PostToolV1ByIdPersonsCsvApiResponse = /** status 200 Default Response */ {
  url: HttpUrlString
}
export type PostToolV1ByIdPersonsCsvApiArg = {
  id: string
  body: {
    limit?: number
    offset?: number
    sort?: {
      by: PropertyPathString
      direction: SortDirection
      nulls?: SortNulls
    }
    /**
        The inner array is an OR, the outer array is an AND so if we get passed in filter `A`, `B`, `C`:
        
        - `[[A, B], [C]]` is `(A OR B) AND C`
        - `[[A], [B], [C]]` is `(A) AND (B) AND (C)`
        - `[[A, B, C]]` is `(A OR B OR C)`
        
        TODO make this more flexible.
         */
    filter?: {
      /** The dot-separated path of the property to filter on.
            
            For arrays, a path segment can also be a wildcard `*` (e.g. `categories.*.id`), in which case the expression being filtered on is calculated by mapping over each element of the element (in the example, an array of IDs). */
      column: FilterPathString
      fields: FilterField
    }[][]
    columns?: PropertyPathString[]
  }
}
export type PostToolV1ApiResponse =
  /** status 200 A tool option for the user to pick with its vendor option. This allows us to switch both the vendor and the tool when the user updates the tool. */ ToolOptionWithVendor
export type PostToolV1ApiArg = {
  body: {
    user_provided_url: HttpUrlString
    user_provided_display_name: NonEmptyTrimmedString
  }
}
export type GetToolV1CategoriesApiResponse = /** status 200 Default Response */ ToolCategory[]
export type GetToolV1CategoriesApiArg = void
export type GetToolV1ByIdAndApiResponse = unknown
export type GetToolV1ByIdAndApiArg = {
  id: string
  "*": string
}
export type PostLegalV1AgreementsListApiResponse = /** status 200 Default Response */ LegalAgreementListResponse
export type PostLegalV1AgreementsListApiArg = {
  listQueryStringParams: ListQueryStringParams
}
export type GetLegalV1AgreementsPickerOptionsByQueryApiResponse =
  /** status 200 Default Response */ LegalAgreementWithToolsList
export type GetLegalV1AgreementsPickerOptionsByQueryApiArg = {
  query: string
}
export type GetLegalV1AgreementsByIdApiResponse = /** status 200 Default Response */ LegalAgreementListItem
export type GetLegalV1AgreementsByIdApiArg = {
  id: string
}
export type PutLegalV1AgreementsByIdApiResponse = /** status 200 Default Response */ LegalAgreement
export type PutLegalV1AgreementsByIdApiArg = {
  id: string
  body:
    | ({
        agreement_type: "subscription"
      } & SubscriptionLegalAgreementInput)
    | ({
        agreement_type: "enterprise"
      } & EnterpriseLegalAgreementInput)
}
export type DeleteLegalV1AgreementsByIdApiResponse = unknown
export type DeleteLegalV1AgreementsByIdApiArg = {
  id: string
}
export type PostLegalV1AgreementsByIdVerificationApiResponse = /** status 200 Default Response */ LegalAgreement
export type PostLegalV1AgreementsByIdVerificationApiArg = {
  id: string
  agreementVerificationRequest: AgreementVerificationRequest
}
export type PostLegalV1DetectLegalAgreementsByOrganizationIdApiResponse = unknown
export type PostLegalV1DetectLegalAgreementsByOrganizationIdApiArg = {
  organizationId: string
}
export type GetLegalV1AgreementsByIdDocumentsAndDocumentIdUrlApiResponse = /** status 200 Default Response */ {
  download_url: string
}
export type GetLegalV1AgreementsByIdDocumentsAndDocumentIdUrlApiArg = {
  id: string
  documentId: string
}
export type GetLegalV1AgreementsByIdDocumentsAndDocumentIdContentApiResponse = unknown
export type GetLegalV1AgreementsByIdDocumentsAndDocumentIdContentApiArg = {
  id: string
  documentId: string
}
export type PostLegalV1AgreementsApiResponse = /** status 201 Default Response */ LegalAgreementListItem
export type PostLegalV1AgreementsApiArg = {
  legalAgreementInput: LegalAgreementInput
}
export type GetLegalV1AgreementsByIdAndApiResponse = unknown
export type GetLegalV1AgreementsByIdAndApiArg = {
  id: string
  "*": string
}
export type PostVendorV1ListApiResponse = /** status 200 Default Response */ VendorListItemResponse
export type PostVendorV1ListApiArg = {
  listQueryStringParams: ListQueryStringParams
}
export type GetVendorV1ListHiddenApiResponse = /** status 200 Default Response */ VendorMinimal[]
export type GetVendorV1ListHiddenApiArg = void
export type PostVendorV1CsvApiResponse = /** status 200 Default Response */ {
  url: HttpUrlString
}
export type PostVendorV1CsvApiArg = {
  body: {
    limit?: number
    offset?: number
    sort?: {
      by: PropertyPathString
      direction: SortDirection
      nulls?: SortNulls
    }
    /**
        The inner array is an OR, the outer array is an AND so if we get passed in filter `A`, `B`, `C`:
        
        - `[[A, B], [C]]` is `(A OR B) AND C`
        - `[[A], [B], [C]]` is `(A) AND (B) AND (C)`
        - `[[A, B, C]]` is `(A OR B OR C)`
        
        TODO make this more flexible.
         */
    filter?: {
      /** The dot-separated path of the property to filter on.
            
            For arrays, a path segment can also be a wildcard `*` (e.g. `categories.*.id`), in which case the expression being filtered on is calculated by mapping over each element of the element (in the example, an array of IDs). */
      column: FilterPathString
      fields: FilterField
    }[][]
    columns?: PropertyPathString[]
  }
}
export type PostVendorV1AiFilterApiResponse = /** status 200 Default Response */ {
  /** The dot-separated path of the property to filter on.
    
    For arrays, a path segment can also be a wildcard `*` (e.g. `categories.*.id`), in which case the expression being filtered on is calculated by mapping over each element of the element (in the example, an array of IDs). */
  column: FilterPathString
  fields: FilterField
}[]
export type PostVendorV1AiFilterApiArg = {
  body: {
    query: string
  }
}
export type PostVendorV1ApiResponse =
  /** status 200 A vendor option for the user to pick, which may already exist as a vendor in the organization (in which case `id` will be set) or only globally as a vendor listing (i.e. not yet in the organization, `id` is `null`). */ VendorOption
export type PostVendorV1ApiArg = {
  body: {
    user_provided_url: HttpUrlString
    user_provided_display_name: NonEmptyTrimmedString
  }
}
export type GetVendorV1PickerOptionsApiResponse = /** status 200 Default Response */ VendorOptionWithTools[]
export type GetVendorV1PickerOptionsApiArg = {
  search: string
}
export type PutVendorV1ByIdFollowingApiResponse = unknown
export type PutVendorV1ByIdFollowingApiArg = {
  id: string
  body: {
    following: boolean
  }
}
export type PatchVendorV1ByIdApiResponse = /** status 200 Default Response */ Vendor
export type PatchVendorV1ByIdApiArg = {
  id: string
  vendorPatch: VendorPatch
}
export type GetVendorV1ByIdApiResponse =
  /** status 200 Vendor detail information that a specific user sees when they land on the vendor page */ VendorDetails
export type GetVendorV1ByIdApiArg = {
  id: string
}
export type PostVendorV1ByIdHideApiResponse = unknown
export type PostVendorV1ByIdHideApiArg = {
  id: string
  body: {
    hidden: boolean
  }
}
export type GetVendorV1ByIdSpendBreakdownApiResponse = /** status 200 Default Response */ {
  tool_spend: {
    tool: {
      /** The name of the tool */
      display_name: NonEmptyTrimmedString
      id: string
    }
    spend: CurrencyAmount
  }[]
  non_tool_spend: null | CurrencyAmount
}
export type GetVendorV1ByIdSpendBreakdownApiArg = {
  currencyCode: CurrencyCodeString
  startDate: DateString
  endDate: DateString
  id: string
}
export type GetVendorV1ByIdAndApiResponse = unknown
export type GetVendorV1ByIdAndApiArg = {
  id: string
  "*": string
}
export type GetOauthV1SigninByProviderApiResponse = unknown
export type GetOauthV1SigninByProviderApiArg = {
  linkAuthCode?: NonEmptyTrimmedString
  iss?: HttpUrlString
  openSignup?: boolean
  code?: string
  provider: "google" | "okta"
}
export type GetOauthV1SigninByProviderCallbackApiResponse = unknown
export type GetOauthV1SigninByProviderCallbackApiArg = {
  state: string
  code?: string
  error?: string
  provider: "google" | "okta"
}
export type GetOauthV1AuthByProviderApiResponse = unknown
export type GetOauthV1AuthByProviderApiArg = {
  provider: "ramp_oauth" | "google_oauth" | "gmail_oauth" | "brex_oauth" | "quickbooks_oauth" | "slack_oauth"
}
export type GetOauthV1AuthByProviderCallbackApiResponse = unknown
export type GetOauthV1AuthByProviderCallbackApiArg = {
  state: string
  code?: string
  provider: "ramp_oauth" | "google_oauth" | "gmail_oauth" | "brex_oauth" | "quickbooks_oauth" | "slack_oauth"
}
export type GetOauthV1SignupValidateApiResponse = /** status 200 Default Response */ {
  isValid: boolean
}
export type GetOauthV1SignupValidateApiArg = {
  code: string
}
export type GetOauthV1ConnectByProviderApiResponse = unknown
export type GetOauthV1ConnectByProviderApiArg = {
  integrationId?: string
  provider: "ramp_oauth" | "google_oauth" | "gmail_oauth" | "brex_oauth" | "quickbooks_oauth" | "slack_oauth"
}
export type GetOauthV1ConnectByProviderCallbackApiResponse = unknown
export type GetOauthV1ConnectByProviderCallbackApiArg = {
  state: string
  code?: string
  integrationId?: string
  realmId?: string
  provider: "ramp_oauth" | "google_oauth" | "gmail_oauth" | "brex_oauth" | "quickbooks_oauth" | "slack_oauth"
}
export type GetIntegrationV1ApiResponse = /** status 200 Default Response */ {
  id: string
  object_type: "Integration"
  organization_id: string
  created_at: DateTimeString
  display_name: NonEmptyTrimmedProseString
  provider: IntegrationProvider
  external_id: null | NonEmptyString
  merge_hris_type: null | MergeIntegrationHrisType
  merge_accounting_type: null | MergeIntegrationAccountingType
  logo: null | NonEmptyString
  email: null | NonEmptyString
  scope: null | NonEmptyString
  crawler_last_success: null | DateTimeString
  crawler_state?: null | {
    oldest_crawled_activity_date?: DateTimeString
    incremental_crawl_start_date?: DateTimeString
  }
  /** Text with statistics on the integration. For example, how many documents have been imported from an email integration. */
  detail_text?: string
  status: IntegrationStatus
  disabled_at: null | DateTimeString
}[]
export type GetIntegrationV1ApiArg = void
export type GetIntegrationV1InitialExtractionBannerApiResponse =
  /** status 200 Default Response */ InitialExtractionBanner
export type GetIntegrationV1InitialExtractionBannerApiArg = void
export type GetIntegrationV1PrivateApiResponse = /** status 200 Default Response */ {
  id: string
  object_type: "Integration"
  organization_id: string
  created_at: DateTimeString
  display_name: NonEmptyTrimmedProseString
  provider: IntegrationProvider
  external_id: null | NonEmptyString
  merge_hris_type: null | MergeIntegrationHrisType
  merge_accounting_type: null | MergeIntegrationAccountingType
  logo: null | NonEmptyString
  email: null | NonEmptyString
  scope: null | NonEmptyString
  crawler_last_success: null | DateTimeString
  crawler_state?: null | {
    oldest_crawled_activity_date?: DateTimeString
    incremental_crawl_start_date?: DateTimeString
  }
  /** Text with statistics on the integration. For example, how many documents have been imported from an email integration. */
  detail_text?: string
  status: IntegrationStatus
  disabled_at: null | DateTimeString
}[]
export type GetIntegrationV1PrivateApiArg = void
export type DeleteIntegrationV1ByIdApiResponse = /** status 200 Default Response */ ApiResult
export type DeleteIntegrationV1ByIdApiArg = {
  data?: "DELETE"
  id: string
}
export type PostIntegrationV1ClearDataApiResponse = /** status 200 Default Response */ ApiResult
export type PostIntegrationV1ClearDataApiArg = {
  body: {
    organization_id: string
    integration_id: string
    delete_after?: boolean
    admin_email: EmailString
  }
}
export type PostIntegrationV1CreateApiResponse = /** status 200 Default Response */ ApiResult
export type PostIntegrationV1CreateApiArg = {
  createIntegration: CreateIntegration
}
export type PostIntegrationV1ByIdDisableApiResponse = /** status 200 Default Response */ ApiResult
export type PostIntegrationV1ByIdDisableApiArg = {
  id: string
}
export type PostIntegrationV1ByIdEnableApiResponse = /** status 200 Default Response */ ApiResult
export type PostIntegrationV1ByIdEnableApiArg = {
  id: string
}
export type PostIntegrationV1UpdateApiResponse = /** status 200 Default Response */ ApiResult
export type PostIntegrationV1UpdateApiArg = {
  updateIntegration: UpdateIntegration
}
export type PostIntegrationV1CrawlByIdApiResponse = unknown
export type PostIntegrationV1CrawlByIdApiArg = {
  id: string
}
export type GetIntegrationV1HrisBySourceLinkTokenApiResponse = /** status 200 Default Response */ LinkTokenResponse
export type GetIntegrationV1HrisBySourceLinkTokenApiArg = {
  source:
    | "bamboohr"
    | "justworks"
    | "gusto"
    | "rippling"
    | "hibob"
    | "workday"
    | "paylocity"
    | "ukg-pro"
    | "ukg-ready"
    | "ukg-pro-workforce-management"
    | "adp-workforce-now"
    | "deel"
    | "namely"
    | "zenefits"
    | "charthop"
}
export type GetIntegrationV1AccountingBySourceLinkTokenApiResponse =
  /** status 200 Default Response */ LinkTokenResponse
export type GetIntegrationV1AccountingBySourceLinkTokenApiArg = {
  source: "netsuite" | "quickbooks-online" | "sage-intacct" | "xero"
}
export type PostIntegrationV1MergeSyncApiResponse = unknown
export type PostIntegrationV1MergeSyncApiArg = {
  mergeWebhookPayload: MergeWebhookPayload
}
export type PostPersonV1ApiResponse = /** status 200 Default Response */ PersonListItemResponse
export type PostPersonV1ApiArg = {
  listQueryStringParams: ListQueryStringParams
}
export type PostPersonV1MergeApiResponse = unknown
export type PostPersonV1MergeApiArg = {
  body: {
    food_person_ids: string[]
    gobbler_person_id: string
    organization_id: string
  }
}
export type GetPersonV1ByIdApiResponse = /** status 200 Default Response */ PersonDetails
export type GetPersonV1ByIdApiArg = {
  id: string
}
export type GetPersonV1ByIdToolsApiResponse = /** status 200 Default Response */ {
  id: string
  display_name: NonEmptyTrimmedString
  last_login: null | DateTimeString
  image_asset: null | ImageAsset
  description: null | NonEmptyTrimmedProseString
}[]
export type GetPersonV1ByIdToolsApiArg = {
  id: string
}
export type GetLoginV1SummaryApiResponse = /** status 200 Default Response */ LoginScopeSummary
export type GetLoginV1SummaryApiArg = {
  personId?: string
}
export type GetLoginV1UserActivityApiResponse = /** status 200 Default Response */ LoginUserActivityResponse
export type GetLoginV1UserActivityApiArg = {
  toolListingId: string
  startDate: DateString
  endDate?: DateString
  /** Interval to group by, e.g. P1M for monthly, P1W for weekly, P1D for daily. Only accepts months, weeks, and days. */
  interval: DateDurationString
}
export type GetSearchV1TextApiResponse = /** status 200 Default Response */ SearchResponse
export type GetSearchV1TextApiArg = {
  q: string
}
export type GetEmployeeGroupV1ApiResponse = /** status 200 Default Response */ {
  id: string
  object_type: "EmployeeGroup"
  display_name: null | NonEmptyTrimmedString
  group_type: null | EmployeeGroupType
}[]
export type GetEmployeeGroupV1ApiArg = {
  groupType?: "team" | "department" | "cost_center" | "business_unit" | "group"
}
export type PostDocumentV1ApiResponse =
  /** status 201 If the document with that contentHash has not been uploaded before, an UploadableDocumentSchema is returned, otherwise we return the previously uploaded document. */ CreateDocumentResponse
export type PostDocumentV1ApiArg = {
  documentInput: DocumentInput
}
export type PostDocumentV1ListApiResponse = /** status 200 Default Response */ DocumentListResponse
export type PostDocumentV1ListApiArg = {
  toolId?: string
  vendorId?: string
  listQueryStringParams: ListQueryStringParams
}
export type PutDocumentV1ByIdApiResponse = /** status 200 Default Response */ DocumentMinimal
export type PutDocumentV1ByIdApiArg = {
  id: string
  body: {
    status: "uploaded"
    link_code?: NonEmptyTrimmedString
  }
}
export type GetDocumentV1ExtractingApiResponse = /** status 200 Default Response */ ExtractingDocumentResponse
export type GetDocumentV1ExtractingApiArg = void
export type PostDocumentV1BackgroundExtractBatchApiResponse = unknown
export type PostDocumentV1BackgroundExtractBatchApiArg = {
  documentBatchExtractionRequest: DocumentBatchExtractionRequest
}
export type PostDocumentV1MoveApiResponse = /** status 200 Default Response */ {
  vendor?: VendorMinimal
  tool?: ToolMinimal
  legal_agreement?: LegalAgreementMinimal
}
export type PostDocumentV1MoveApiArg = {
  moveDocumentRequest: MoveDocumentRequest
}
export type PostDocumentV1ByIdBackgroundExtractApiResponse =
  /** status 200 Returns the document id of a document and agreement_id (if known) for an uploaded document that is enqueued for extraction */ DocumentBackgroundExtractionResponse
export type PostDocumentV1ByIdBackgroundExtractApiArg = {
  id: string
  documentBackgroundExtractionRequest: DocumentBackgroundExtractionRequest
}
export type PostDocumentV1RunExtractionByOrganizationIdApiResponse = unknown
export type PostDocumentV1RunExtractionByOrganizationIdApiArg = {
  organizationId: string
}
export type PostDocumentV1RetryPartialExtractionApiResponse = unknown
export type PostDocumentV1RetryPartialExtractionApiArg = {
  body: {
    target_extraction_status: DocumentExtractionStatus
    organization_ids?: string[]
    document_ids?: string[]
  }
}
export type PostDocumentV1ByIdRetryExtractionApiResponse =
  /** status 200 Returns the document id of a document and agreement_id (if known) for an uploaded document that is enqueued for extraction */ DocumentBackgroundExtractionResponse
export type PostDocumentV1ByIdRetryExtractionApiArg = {
  id: string
}
export type PostDocumentV1ByIdExtractFormApiResponse = /** status 200 Default Response */ ExtractedFieldConfigsResponse
export type PostDocumentV1ByIdExtractFormApiArg = {
  id: string
}
export type GetDocumentV1ByObjectTypeAndObjectIdDocumentsDocumentIdUrlApiResponse = /** status 200 Default Response */ {
  download_url: string
}
export type GetDocumentV1ByObjectTypeAndObjectIdDocumentsDocumentIdUrlApiArg = {
  objectType: ObjectsWithDocuments
  objectId: string
  documentId: string
}
export type GetDocumentV1OwnByIdContentApiResponse = unknown
export type GetDocumentV1OwnByIdContentApiArg = {
  id: string
}
export type GetDocumentV1OwnByIdUrlApiResponse = /** status 200 Default Response */ {
  download_url: string
}
export type GetDocumentV1OwnByIdUrlApiArg = {
  id: string
}
export type GetWorkflowV1ByKindSchemaApiResponse = unknown
export type GetWorkflowV1ByKindSchemaApiArg = {
  kind: WorkflowKind
}
export type GetWorkflowV1DefinitionsByIdApiResponse = /** status 200 Default Response */ WorkflowDef
export type GetWorkflowV1DefinitionsByIdApiArg = {
  id: string
}
export type PutWorkflowV1DefinitionsByIdApiResponse = /** status 200 Default Response */ WorkflowDef
export type PutWorkflowV1DefinitionsByIdApiArg = {
  id: string
  workflowDefUpdate: WorkflowDefUpdate
}
export type GetWorkflowV1DefinitionsApiResponse = /** status 200 Default Response */ WorkflowDef[]
export type GetWorkflowV1DefinitionsApiArg = void
export type PostWorkflowV1DefinitionsApiResponse = /** status 201 Default Response */ WorkflowDef
export type PostWorkflowV1DefinitionsApiArg = {
  workflowDefInput: WorkflowDefInput
}
export type GetWorkflowV1DefinitionsKindListApiResponse = /** status 200 Default Response */ WorkflowKind[]
export type GetWorkflowV1DefinitionsKindListApiArg = void
export type GetWorkflowV1RunsPickablesApiResponse = /** status 200 Default Response */ WorkflowRunMinimal[]
export type GetWorkflowV1RunsPickablesApiArg = void
export type PostWorkflowV1DefinitionsConditionalApiResponse =
  /** status 200 Default Response */ WorkflowConditionResponse
export type PostWorkflowV1DefinitionsConditionalApiArg = {
  body: {
    prompt: NonEmptyTrimmedProseString
    eligibleWorkflowObjects: WorkflowStepObjectInput[]
  }
}
export type PostWorkflowV1RunsListApiResponse = /** status 200 Default Response */ WorkflowRunListResponse
export type PostWorkflowV1RunsListApiArg = {
  listQueryStringParams: ListQueryStringParams
}
export type PostWorkflowV1RunsApiResponse = /** status 200 Default Response */ WorkflowRun
export type PostWorkflowV1RunsApiArg = {
  workflowRunInput: WorkflowRunInput
}
export type GetWorkflowV1RunsByIdApiResponse = /** status 200 Default Response */ WorkflowRun
export type GetWorkflowV1RunsByIdApiArg = {
  id: string
}
export type PatchWorkflowV1RunsByIdApiResponse = /** status 200 Default Response */ WorkflowRun
export type PatchWorkflowV1RunsByIdApiArg = {
  id: string
  workflowRunUpdatePatch: WorkflowRunUpdatePatch
}
export type DeleteWorkflowV1RunsByIdApiResponse = unknown
export type DeleteWorkflowV1RunsByIdApiArg = {
  id: string
}
export type GetWorkflowV1RunsByIdStepsAndStepIdApiResponse =
  /** status 200 Default Response */ WorkflowRunStepWithContext
export type GetWorkflowV1RunsByIdStepsAndStepIdApiArg = {
  code?: NonEmptyTrimmedString
  id: string
  stepId: string
}
export type PutWorkflowV1RunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameGathererApiResponse =
  /** status 200 Default Response */ WorkflowRun
export type PutWorkflowV1RunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameGathererApiArg = {
  id: string
  objectType:
    | "CreditCard"
    | "Document"
    | "Email"
    | "EmployeeGroup"
    | "ErpTrackingCategory"
    | "Integration"
    | "LegalAgreement"
    | "Link"
    | "Login"
    | "Person"
    | "Receipt"
    | "ReconciledTransaction"
    | "Tool"
    | "ToolCategory"
    | "ToolListing"
    | "User"
    | "Vendor"
    | "VendorListing"
    | "WorkflowRun"
    | "WorkflowRunApprover"
    | "WorkflowRunStep"
  objectId: string
  fieldName: string
  setFieldGatherRequest: SetFieldGatherRequest
}
export type PostWorkflowV1RunsByIdCancelApiResponse = unknown
export type PostWorkflowV1RunsByIdCancelApiArg = {
  id: string
}
export type PatchWorkflowV1RunsByIdStepsApiResponse = /** status 200 Default Response */ WorkflowRunSteps
export type PatchWorkflowV1RunsByIdStepsApiArg = {
  id: string
  workflowRunStepsUpdatePatch: WorkflowRunStepsUpdatePatch
}
export type PutWorkflowV1StepRunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameApprovalApiResponse =
  /** status 200 Default Response */ WorkflowRunStepWithContext
export type PutWorkflowV1StepRunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameApprovalApiArg = {
  id: string
  objectType: ObjectType
  objectId: string
  fieldName: NonEmptyTrimmedString
  workflowRunStepFieldApprovalStatus: WorkflowRunStepFieldApprovalStatus
}
export type GetWorkflowV1SellerLinksApiResponse = /** status 200 Default Response */ SellerLinksResponse
export type GetWorkflowV1SellerLinksApiArg = void
export type PostWorkflowV1LinksApiResponse = /** status 201 Default Response */ BrmLinkMinimal
export type PostWorkflowV1LinksApiArg = {
  createBrmLinkInput: CreateBrmLinkInput
}
export type GetWorkflowV1LinksByCodeWorkflowRunApiResponse = /** status 200 Default Response */ WorkflowRun
export type GetWorkflowV1LinksByCodeWorkflowRunApiArg = {
  code: NonEmptyTrimmedString
}
export type PostWorkflowV1LinksByCodeWorkflowRunStepStatesAndWorkflowRunStepIdApiResponse =
  /** status 200 Default Response */ WorkflowRun
export type PostWorkflowV1LinksByCodeWorkflowRunStepStatesAndWorkflowRunStepIdApiArg = {
  code: NonEmptyTrimmedString
  workflowRunStepId: string
  workflowRunStepDraftStateUpdateInput: WorkflowRunStepDraftStateUpdateInput
}
export type GetWorkflowV1LinksByCodeApiResponse = /** status 200 Default Response */ BrmLink
export type GetWorkflowV1LinksByCodeApiArg = {
  code: NonEmptyTrimmedString
}
export type PostWorkflowV1LinksByCodeApiResponse = /** status 201 Default Response */ BrmLinkMinimal
export type PostWorkflowV1LinksByCodeApiArg = {
  code: NonEmptyTrimmedString
  createBrmLinkInput: CreateBrmLinkInput
}
export type PostWorkflowV1StepRunsByIdDraftApiResponse = /** status 200 Default Response */ ApiResult
export type PostWorkflowV1StepRunsByIdDraftApiArg = {
  id: string
  workflowRunStepDraftStateUpdateInput: WorkflowRunStepDraftStateUpdateInput
}
export type PostWorkflowV1StepRunsByWorkflowRunStepIdUnapproveApiResponse = /** status 200 Default Response */ {
  id: string
  status: WorkflowRunStepStatus
  display_name: NonEmptyTrimmedProseString
}
export type PostWorkflowV1StepRunsByWorkflowRunStepIdUnapproveApiArg = {
  workflowRunStepId: string
  body: {
    reason: RichText
  }
}
export type PostWorkflowV1StepRunsByWorkflowRunStepIdRequestChangesApiResponse = /** status 200 Default Response */ {
  id: string
  status: WorkflowRunStepStatus
  display_name: NonEmptyTrimmedProseString
}
export type PostWorkflowV1StepRunsByWorkflowRunStepIdRequestChangesApiArg = {
  workflowRunStepId: string
}
export type PostWorkflowV1StepRunsByWorkflowRunStepIdReopenApiResponse = /** status 200 Default Response */ {
  id: string
  status: WorkflowRunStepStatus
  display_name: NonEmptyTrimmedProseString
}
export type PostWorkflowV1StepRunsByWorkflowRunStepIdReopenApiArg = {
  workflowRunStepId: string
}
export type PostWorkflowV1StepRunsByWorkflowRunStepIdSubmissionApiResponse = /** status 200 Default Response */ {
  workflow_run: {
    id: string
    /** The name of the request */
    display_name: NonEmptyTrimmedProseString
    status: WorkflowRunStatus
  }
  workflow_run_step: {
    id: string
    status: WorkflowRunStepStatus
    display_name: NonEmptyTrimmedProseString
    approval_steps: WorkflowRunApprovalStep[]
  }
}
export type PostWorkflowV1StepRunsByWorkflowRunStepIdSubmissionApiArg = {
  workflowRunStepId: string
  workflowStepRunSubmission: WorkflowStepRunSubmission
}
export type GetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdUrlApiResponse = /** status 200 Default Response */ {
  download_url: string
}
export type GetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdUrlApiArg = {
  workflowRunId: string
  documentId: string
}
export type GetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdContentApiResponse = unknown
export type GetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdContentApiArg = {
  workflowRunId: string
  documentId: NonEmptyTrimmedString
}
export type PostWorkflowV1FieldsApiResponse = /** status 200 Default Response */ FormFieldConfig[]
export type PostWorkflowV1FieldsApiArg = {
  body: WorkflowStepObjectInput[]
}
export type GetTransactionV1ByIdApiResponse = /** status 200 Default Response */ Transaction
export type GetTransactionV1ByIdApiArg = {
  id: string
}
export type PostTransactionV1ListApiResponse = /** status 200 Default Response */ TransactionListResponse
export type PostTransactionV1ListApiArg = {
  toolId?: string
  vendorId?: string
  agreementId?: string
  listQueryStringParams: ListQueryStringParams
}
export type GetTransactionV1ByIdReceiptsAndReceiptIdDocumentUrlApiResponse = /** status 200 Default Response */ {
  download_url: string
}
export type GetTransactionV1ByIdReceiptsAndReceiptIdDocumentUrlApiArg = {
  id: string
  receiptId: string
}
export type GetTransactionV1ByIdReceiptsAndReceiptIdDocumentContentApiResponse = unknown
export type GetTransactionV1ByIdReceiptsAndReceiptIdDocumentContentApiArg = {
  id: string
  receiptId: string
}
export type GetSavedViewV1ByTableIdentifierApiResponse = /** status 200 Default Response */ SavedView[]
export type GetSavedViewV1ByTableIdentifierApiArg = {
  tableIdentifier: TableIdentifier
}
export type PutSavedViewV1ByIdApiResponse = /** status 200 Default Response */ SavedView
export type PutSavedViewV1ByIdApiArg = {
  id: string
  editSavedView: EditSavedView
}
export type DeleteSavedViewV1ByIdApiResponse = /** status 200 Default Response */ SavedView
export type DeleteSavedViewV1ByIdApiArg = {
  id: string
}
export type PostSavedViewV1CreateApiResponse = /** status 200 Default Response */ SavedView
export type PostSavedViewV1CreateApiArg = {
  createSavedView: CreateSavedView
}
export type PostBetsyV1VectorApiResponse = /** status 200 Default Response */ {
  id: string
  text: string
  tool?: any
  vendor?: any
  transaction?: any
  person?: any
  user?: any
  employee_group?: any
  similarity: number
}[]
export type PostBetsyV1VectorApiArg = {
  body: {
    query: string
  }
}
export type PostBetsyV1AskApiResponse = unknown
export type PostBetsyV1AskApiArg = {
  body: {
    query: RichText
    messages: Message[]
    conversation_id: string
    log_message?: boolean
  }
}
export type PostBetsyV1SlackEventApiResponse = /** status 200 Default Response */ {
  challenge: string
}
export type PostBetsyV1SlackEventApiArg = {
  body: {
    type: "url_verification" | "event_callback"
    challenge?: string
    event?: {
      type: "app_mention" | "message"
      subtype?: "message_changed"
      channel: string
      bot_id?: string
      user?: string
      text?: string
      ts: string
      thread_ts?: string
    }
  }
}
export type GetBetsyV1ConversationsApiResponse = /** status 200 Default Response */ {
  id: string
  display_name: string
  created_at: DateTimeString
}[]
export type GetBetsyV1ConversationsApiArg = void
export type GetBetsyV1ConversationsByIdApiResponse = /** status 200 Default Response */ Conversation
export type GetBetsyV1ConversationsByIdApiArg = {
  id: string
}
export type PutErpAccountV1ByIdApiResponse = /** status 200 Default Response */ ApiResult
export type PutErpAccountV1ByIdApiArg = {
  id: string
  body: {
    hidden: boolean
  }
}
export type GetTimelineV1EventsApiResponse = /** status 200 Default Response */ PaginatedTimelineResponseSchema
export type GetTimelineV1EventsApiArg = {
  limit?: LimitNumber
  workflowRunId?: string
  toolId?: string
  vendorId?: string
  /** ISO datetime string to return events until (i.e. `created_at < until`). Cannot be used in combination with `since`. */
  until?: string
  /** ISO datetime string to return events since (i.e. `created_at > since`). Cannot be used in combination with `until`. */
  since?: string
}
export type DeleteTimelineV1EventsByIdApiResponse = unknown
export type DeleteTimelineV1EventsByIdApiArg = {
  id: string
}
export type PutTimelineV1EventsByIdApiResponse = unknown
export type PutTimelineV1EventsByIdApiArg = {
  id: string
  timelineUpdate: TimelineUpdate
}
export type GetTimelineV1WorkflowRunByWorkflowRunIdApiResponse =
  /** status 200 Default Response */ GetWorkflowRunFieldTimelineResponse
export type GetTimelineV1WorkflowRunByWorkflowRunIdApiArg = {
  workflowRunId: string
}
export type GetTimelineV1WorkflowRunByWorkflowRunIdWorkflowRunStepAndWorkflowRunStepIdApiResponse =
  /** status 200 Default Response */ GetWorkflowRunFieldTimelineResponse
export type GetTimelineV1WorkflowRunByWorkflowRunIdWorkflowRunStepAndWorkflowRunStepIdApiArg = {
  workflowRunStepId: string
  workflowRunId: string
}
export type GetTimelineV1EventsObjectsByObjectTypeAndObjectIdFieldsFieldNameApiResponse =
  /** status 200 Default Response */ GetWorkflowRunFieldTimelineResponse
export type GetTimelineV1EventsObjectsByObjectTypeAndObjectIdFieldsFieldNameApiArg = {
  workflowRunId?: string
  objectId: string
  objectType: ObjectType
  fieldName: NonEmptyTrimmedString
}
export type GetTimelineV1EventsByIdDocumentAndDocumentIdContentApiResponse = unknown
export type GetTimelineV1EventsByIdDocumentAndDocumentIdContentApiArg = {
  id: string
  documentId: string
}
export type PostTimelineV1EventsObjectsByObjectTypeAndObjectIdStreamingApiResponse = unknown
export type PostTimelineV1EventsObjectsByObjectTypeAndObjectIdStreamingApiArg = {
  fieldName?: NonEmptyTrimmedString
  workflowRunId?: string
  workflowRunStepId?: string
  objectId: string
  objectType: ObjectType
  timelineInput: TimelineInput
}
export type PostTimelineV1EventsObjectsByObjectTypeAndObjectIdApiResponse = unknown
export type PostTimelineV1EventsObjectsByObjectTypeAndObjectIdApiArg = {
  fieldName?: NonEmptyTrimmedString
  workflowRunId?: string
  workflowRunStepId?: string
  objectId: string
  objectType: ObjectType
  timelineInput: TimelineInput
}
export type PostTimelineV1EventsByIdReactionsApiResponse = unknown
export type PostTimelineV1EventsByIdReactionsApiArg = {
  id: string
  reactionInput: ReactionInput
}
export type DeleteTimelineV1EventsByIdReactionsAndReactionIdApiResponse = unknown
export type DeleteTimelineV1EventsByIdReactionsAndReactionIdApiArg = {
  id: string
  reactionId: string
}
export type GetErpTrackingCategoryV1ApiResponse = /** status 200 Default Response */ ErpTrackingCategory[]
export type GetErpTrackingCategoryV1ApiArg = {
  type?: "department" | "class"
}
export type PostImageAssetV1ImageAssetsApiResponse = /** status 201 Default Response */ UploadableImageAsset
export type PostImageAssetV1ImageAssetsApiArg = {
  imageAssetInput: ImageAssetInput
}
export type GetImageAssetV1ByIdContentApiResponse = unknown
export type GetImageAssetV1ByIdContentApiArg = {
  id: string
}
export type GetPlaidV1LinkTokenApiResponse = /** status 200 Default Response */ {
  link_token: string
}
export type GetPlaidV1LinkTokenApiArg = void
export type PostPlaidV1WebhooksApiResponse = /** status 200 Default Response */ ApiResult
export type PostPlaidV1WebhooksApiArg = {
  body: {
    webhook_type: "AUTH" | "HOLDINGS" | "INVESTMENTS_TRANSACTIONS" | "ITEM" | "LIABILITIES" | "TRANSACTIONS" | "ASSETS"
    item_id: string
    webhook_code: string
    environment: string
  }
}
export type GetNotificationV1ApiResponse = /** status 200 Default Response */ NotificationListResponse
export type GetNotificationV1ApiArg = {
  limit: LimitNumber
  /** ISO datetime string to return events until (i.e. `created_at < until`). Cannot be used in combination with `since`. */
  until?: string
  /** ISO datetime string to return events since (i.e. `created_at > since`). Cannot be used in combination with `until`. */
  since?: string
  filterByUnread?: boolean
  filterByMentioned?: boolean
}
export type GetNotificationV1TasksApiResponse = /** status 200 Default Response */ TaskListResponse
export type GetNotificationV1TasksApiArg = void
export type PostNotificationV1TaskSnoozeApiResponse = /** status 200 Default Response */ ApiResult
export type PostNotificationV1TaskSnoozeApiArg = {
  taskSnoozeBody: TaskSnoozeBody
}
export type PostNotificationV1TaskDismissApiResponse = /** status 200 Default Response */ ApiResult
export type PostNotificationV1TaskDismissApiArg = {
  taskDismissBody: TaskDismissBody
}
export type GetNotificationV1CountApiResponse = /** status 200 Default Response */ NotificationCountResponse
export type GetNotificationV1CountApiArg = void
export type GetNotificationV1CountUnreadApiResponse = /** status 200 Default Response */ NotificationCountResponse
export type GetNotificationV1CountUnreadApiArg = void
export type GetNotificationV1UnreadApiResponse = /** status 200 Default Response */ NotificationUnreadResponse
export type GetNotificationV1UnreadApiArg = void
export type GetNotificationV1ByIdApiResponse =
  /** status 200 A notification to be displayed in the inbox UI */ InboxNotification
export type GetNotificationV1ByIdApiArg = {
  id: string
}
export type PutNotificationV1ByIdApiResponse =
  /** status 200 A notification to be displayed in the inbox UI */ InboxNotification
export type PutNotificationV1ByIdApiArg = {
  id: string
  notificationUpdate: NotificationUpdate
}
export type PutNotificationV1BulkApiResponse = /** status 200 Default Response */ ApiResult
export type PutNotificationV1BulkApiArg = {
  notificationBulkActionBody: NotificationBulkActionBody
}
export type PostDynamoV1ByToolListingIdApiResponse = /** status 200 Default Response */ {
  run_id: string
}
export type PostDynamoV1ByToolListingIdApiArg = {
  toolListingId: string
}
export type GetDynamoV1RunByRunIdApiResponse = /** status 200 Default Response */ DynamoRun
export type GetDynamoV1RunByRunIdApiArg = {
  runId: string
}
export type GetDynamoV1ByIdIsDynamoRunningApiResponse = /** status 200 Default Response */ IsDynamoRunning
export type GetDynamoV1ByIdIsDynamoRunningApiArg = {
  id: string
}
export type PostDynamoV1ByIdReportDataTelemetryApiResponse = unknown
export type PostDynamoV1ByIdReportDataTelemetryApiArg = {
  id: string
  body: {
    report_body: string
    dynamo_run_id: string
  }
}
export type PostDynamoV1ApplyByTimelineEventIdApiResponse = unknown
export type PostDynamoV1ApplyByTimelineEventIdApiArg = {
  timelineEventId: string
}
export type GetCalendarV1RenewalsByCalendarKeyApiResponse = unknown
export type GetCalendarV1RenewalsByCalendarKeyApiArg = {
  calendarKey: string
}
export type PostCreditCardV1ListApiResponse = /** status 200 Default Response */ CreditCardSummaryResponse
export type PostCreditCardV1ListApiArg = {
  toolId?: string
  vendorId?: string
  listQueryStringParams: ListQueryStringParams
}
export type GetCreditCardV1PickerOptionsApiResponse = /** status 200 Default Response */ CreditCardListItem[]
export type GetCreditCardV1PickerOptionsApiArg = {
  search?: string
  vendorId?: string
  toolId?: string
}
export type PostCreditCardV1LockApiResponse = unknown
export type PostCreditCardV1LockApiArg = {
  body: {
    vendor_id?: string
    tool_id?: string
    card_id?: string
  }
}
export type PostCreditCardV1UnlockByIdApiResponse = unknown
export type PostCreditCardV1UnlockByIdApiArg = {
  id: string
}
export type GetOnboardingV1LatestApiResponse = /** status 200 Default Response */ null | OnboardingStep
export type GetOnboardingV1LatestApiArg = void
export type PostOnboardingV1StepByStepTypeRecordApiResponse = /** status 201 Default Response */ OnboardingStep
export type PostOnboardingV1StepByStepTypeRecordApiArg = {
  stepType: OnboardingStepType
}
export type GetNegotiationV1ByIdApiResponse = /** status 200 Default Response */ Negotiation
export type GetNegotiationV1ByIdApiArg = {
  id: string
}
export type PostNegotiationV1ComposeApiResponse = unknown
export type PostNegotiationV1ComposeApiArg = {
  body: {
    query: RichText
    negotiation_conversation_id: string
    email_draft?: EmailDraftInput
    vendor_id: string
  }
}
export type PostNegotiationV1EmailSendApiResponse = unknown
export type PostNegotiationV1EmailSendApiArg = {
  body: {
    email_draft: EmailDraftInput
    negotiation_conversation_id: string
  }
}
export type PostNegotiationV1UpdateContextApiResponse = unknown
export type PostNegotiationV1UpdateContextApiArg = {
  body: {
    negotiation_id: string
    key: string
    value: string
  }
}
export type PostNegotiationV1RegenerateApiResponse = unknown
export type PostNegotiationV1RegenerateApiArg = {
  body: {
    negotiation_conversation_id: string
  }
}
export type PostNegotiationV1ResetApiResponse = /** status 200 Default Response */ {
  negotiation_conversation: NegotiationConversation
}
export type PostNegotiationV1ResetApiArg = {
  body: {
    negotiation_id: string
    reply_to_email_id?: string
  }
}
export type PostNegotiationV1GmailAddonHomepageApiResponse = unknown
export type PostNegotiationV1GmailAddonHomepageApiArg = {
  body: {
    authorizationEventObject: {
      userOAuthToken: string
      systemIdToken: string
      userIdToken: string
    }
    commonEventObject: {
      parameters?: {
        is_called_from_function?: string
      }
    }
  }
}
export type PostNegotiationV1GmailAddonCreateNegotiationApiResponse = unknown
export type PostNegotiationV1GmailAddonCreateNegotiationApiArg = {
  body: {
    authorizationEventObject: {
      userOAuthToken: string
      systemIdToken: string
      userIdToken: string
    }
    gmail: {
      messageId: string
      accessToken: string
    }
    commonEventObject: {
      parameters: {
        vendor_id: string
        thread_id: string
      }
    }
  }
}
export type PostNegotiationV1GmailAddonEmailOpenedApiResponse = unknown
export type PostNegotiationV1GmailAddonEmailOpenedApiArg = {
  body: {
    gmail: {
      messageId: string
      accessToken: string
    }
    authorizationEventObject: {
      userOAuthToken: string
      systemIdToken: string
      userIdToken: string
    }
    commonEventObject: {
      parameters?: {
        is_called_from_function?: string
      }
    }
  }
}
export type PostNegotiationV1EmailSimRunApiResponse = unknown
export type PostNegotiationV1EmailSimRunApiArg = {
  body: {
    organization_id: string
    negotiation_id: string
    additional_context: null | string
  }
}
export type PostNegotiationV1EmailSimInitApiResponse = unknown
export type PostNegotiationV1EmailSimInitApiArg = {
  body: {
    organization_id: string
    negotiation_id: string
    user_id: null | string
    input_scenario: null | string
  }
}
export type GetSubscribeV1OrganizationApiResponse = unknown
export type GetSubscribeV1OrganizationApiArg = void
export type GetSubscribeV1LinksByCodeWorkflowRunApiResponse = unknown
export type GetSubscribeV1LinksByCodeWorkflowRunApiArg = {
  code: NonEmptyTrimmedString
}
export type PostRetoolV1DynamoRunLinearQaAgentApiResponse = unknown
export type PostRetoolV1DynamoRunLinearQaAgentApiArg = void
export type PostRetoolV1DynamoRunLinearQaAgentByIssueIdApiResponse = unknown
export type PostRetoolV1DynamoRunLinearQaAgentByIssueIdApiArg = {
  issueId: NonEmptyString
}
export type PostRetoolV1DynamoRunComplianceAgentApiResponse = unknown
export type PostRetoolV1DynamoRunComplianceAgentApiArg = {
  body: {
    tool_listing_id: string
  }[]
}
export type PostRetoolV1DynamoSyncToolDataApiResponse = unknown
export type PostRetoolV1DynamoSyncToolDataApiArg = {
  body: {
    tool_listing_id: string
  }[]
}
export type GetRetoolV1WorkflowsLintApiResponse = unknown
export type GetRetoolV1WorkflowsLintApiArg = void
export type PostToolListingV1ApiResponse = /** status 200 Default Response */ ApiResult
export type PostToolListingV1ApiArg = {
  createToolListing: CreateToolListing
}
export type PutToolListingV1ByIdApiResponse = /** status 200 Default Response */ ApiResult
export type PutToolListingV1ByIdApiArg = {
  id: string
  updateToolListing: UpdateToolListing
}
export type DeleteToolListingV1ByIdApiResponse = /** status 200 Default Response */ ApiResult
export type DeleteToolListingV1ByIdApiArg = {
  id: string
  body: {
    admin_email: EmailString
  }
}
export type PostToolListingV1ByIdReportDataApiResponse = unknown
export type PostToolListingV1ByIdReportDataApiArg = {
  id: string
  toolUserReport: ToolUserReport
}
export type PostToolListingV1KeysApiResponse = /** status 201 Default Response */ ApiResult
export type PostToolListingV1KeysApiArg = {
  body: {
    key: string
    tool_listing_id: string
    admin_email: EmailString
  }
}
export type PutToolListingV1ReplaceListingApiResponse = /** status 200 Default Response */ ApiResult
export type PutToolListingV1ReplaceListingApiArg = {
  body: {
    gobbler_tool_listing_id: string
    food_tool_listing_id: string
    admin_email: EmailString
  }
}
export type PutToolListingV1ByIdLogoApiResponse = /** status 200 Default Response */ ApiResult
export type PutToolListingV1ByIdLogoApiArg = {
  id: string
  body: {
    file_data: string
    mime_type: string
    admin_email: EmailString
  }
}
export type PostToolListingV1TransferLoginApiResponse = /** status 200 Default Response */ ApiResult
export type PostToolListingV1TransferLoginApiArg = {
  body: {
    gobblerId: string
    foodId: string
    login: LoginIdentifier
    admin_email: EmailString
  }
}
export type PostToolListingV1TransferDescriptorApiResponse = /** status 200 Default Response */ ApiResult
export type PostToolListingV1TransferDescriptorApiArg = {
  body: {
    gobblerId: string
    foodId: string
    descriptor: string
    admin_email: EmailString
  }
}
export type PostToolListingV1UpdateListingLogosApiResponse = /** status 200 Default Response */ ApiResult
export type PostToolListingV1UpdateListingLogosApiArg = void
export type PostVendorListingV1ApiResponse = /** status 200 Default Response */ ApiResult
export type PostVendorListingV1ApiArg = {
  createVendorListing: CreateVendorListing
}
export type PostVendorListingV1MarkSimilarityCheckedApiResponse = unknown
export type PostVendorListingV1MarkSimilarityCheckedApiArg = {
  body: {
    primary_vendor_listing_id: string
    similar_vendor_listing_id: string
    admin_email: EmailString
  }
}
export type PostVendorListingV1QueryVectorsApiResponse = unknown
export type PostVendorListingV1QueryVectorsApiArg = {
  body: {
    query: string
    admin_email: EmailString
  }
}
export type PutVendorListingV1ByIdApiResponse = /** status 200 Default Response */ ApiResult
export type PutVendorListingV1ByIdApiArg = {
  id: string
  updateVendorListing: UpdateVendorListing
}
export type DeleteVendorListingV1ByIdApiResponse = /** status 200 Default Response */ ApiResult
export type DeleteVendorListingV1ByIdApiArg = {
  id: string
  body: {
    admin_email: EmailString
  }
}
export type PostVendorListingV1ByIdReportDataApiResponse = unknown
export type PostVendorListingV1ByIdReportDataApiArg = {
  id: string
  vendorUserReport: VendorUserReport
}
export type PostVendorListingV1KeysApiResponse = /** status 201 Default Response */ ApiResult
export type PostVendorListingV1KeysApiArg = {
  body: {
    key: string
    vendor_listing_id: string
    admin_email: EmailString
  }
}
export type PutVendorListingV1ReplaceListingApiResponse = /** status 200 Default Response */ ApiResult
export type PutVendorListingV1ReplaceListingApiArg = {
  body: {
    gobbler_vendor_listing_id: string
    food_vendor_listing_id: string
    admin_email: EmailString
  }
}
export type PutVendorListingV1ByIdLogoApiResponse = /** status 200 Default Response */ ApiResult
export type PutVendorListingV1ByIdLogoApiArg = {
  id: string
  body: {
    file_data: string
    mime_type: string
    admin_email: EmailString
  }
}
export type GetVendorListingV1FindWebsiteByListingTypeApiResponse = unknown
export type GetVendorListingV1FindWebsiteByListingTypeApiArg = {
  displayName: NonEmptyString
  listingType: ToolOrVendorString
}
export type PostVendorListingV1TransferToolApiResponse = /** status 200 Default Response */ ApiResult
export type PostVendorListingV1TransferToolApiArg = {
  body: {
    gobblerId: string
    foodId: string
    toolListingId: string
    admin_email: EmailString
  }
}
export type PostVendorListingV1TransferDescriptorApiResponse = /** status 200 Default Response */ ApiResult
export type PostVendorListingV1TransferDescriptorApiArg = {
  body: {
    gobblerId: string
    foodId: string
    descriptor: string
    admin_email: EmailString
  }
}
export type PostVendorListingV1UpdateListingLogosApiResponse = /** status 200 Default Response */ ApiResult
export type PostVendorListingV1UpdateListingLogosApiArg = void
export type GetVendorListingV1DraftListingFromUrlByListingTypeApiResponse = unknown
export type GetVendorListingV1DraftListingFromUrlByListingTypeApiArg = {
  url: HttpUrlString
  listingType: ToolOrVendorString
}
export type PutTransactionVendorToolListingV1ByMerchantIdentificationApiResponse =
  /** status 200 Default Response */ ApiResult
export type PutTransactionVendorToolListingV1ByMerchantIdentificationApiArg = {
  merchantIdentification: string
  body: {
    status: ListingStatus
    admin_email: EmailString
    tool_listing_id?: null | string
  }
}
export type ObjectType =
  | "CreditCard"
  | "Document"
  | "Email"
  | "EmployeeGroup"
  | "ErpTrackingCategory"
  | "Integration"
  | "LegalAgreement"
  | "Link"
  | "Login"
  | "Person"
  | "Receipt"
  | "ReconciledTransaction"
  | "Tool"
  | "ToolCategory"
  | "ToolListing"
  | "User"
  | "Vendor"
  | "VendorListing"
  | "WorkflowRun"
  | "WorkflowRunApprover"
  | "WorkflowRunStep"
export type NonEmptyTrimmedString = string
export type CustomizableObjectType = "Tool" | "Vendor" | "LegalAgreement" | "WorkflowRun"
export type FieldName = string
export type FieldCategory = "it" | "compliance" | "finance" | "legal"
export type DateTimeString = string
export type EmailString = string
export type UserStatus = "pending" | "active" | "deleted"
export type ImageAsset = {
  id: string
  gcs_file_name: NonEmptyTrimmedString
  created_at: DateTimeString
  file_name: null | NonEmptyTrimmedString
  mime_type: null | NonEmptyTrimmedString
}
export type UserMinimal = {
  id: string
  object_type: "User"
  organization_id: string
  first_name: null | NonEmptyTrimmedString
  last_name: null | NonEmptyTrimmedString
  email: EmailString
  status: UserStatus
  profile_image: null | ImageAsset
  created_at: DateTimeString
}
export type FieldConfig = {
  object_type: CustomizableObjectType
  field_name: FieldName
  is_custom: boolean
  /** The data type of the field as a JSON schema */
  field_schema: null | any
  is_enabled: boolean
  is_internal_only: boolean
  category: null | FieldCategory
  needs_reextraction: boolean
  request_config_disabled: boolean
  required_in_request: boolean
  order_index: number
  updated_at: null | DateTimeString
  created_at: null | DateTimeString
  created_by: null | UserMinimal
  updated_by: null | UserMinimal
}
export type FieldConfigsCategoryResponse = {
  /** Schemas referenced by the field schemas. See the $defs key in the JSON Schema spec. */
  $defs: {
    [key: string]: any
  }
  fields: {
    Tool: FieldConfig[]
    Vendor: FieldConfig[]
    LegalAgreement: FieldConfig[]
    WorkflowRun: FieldConfig[]
  }
}
export type FieldConfigMinimal = {
  object_type: CustomizableObjectType
  field_name: FieldName
  is_custom: boolean
  /** The data type of the field as a JSON schema */
  field_schema: null | any
  is_enabled: boolean
  is_internal_only: boolean
  category: null | FieldCategory
  needs_reextraction: boolean
  request_config_disabled: boolean
  required_in_request: boolean
  order_index: number
}
export type SimilarField = {
  object_type: ObjectType
  field_name: FieldName
  /** True if the field is a custom field. */
  is_custom: boolean
  field_schema: any
}
export type SimilarFieldResponse = SimilarField[]
export type SimilarFieldQuery = {
  object_type?: CustomizableObjectType
  field_name?: FieldName
  is_custom?: boolean
  /** The data type of the field as a JSON schema */
  field_schema?: null | any
  is_enabled?: boolean
  is_internal_only?: boolean
  category?: null | FieldCategory
  needs_reextraction?: boolean
  request_config_disabled?: boolean
  required_in_request?: boolean
  order_index?: number
}
export type ApiResult = {
  status: "success" | "failure"
}
export type FieldReference = {
  object_type: ObjectType
  field_name: FieldName
  /** True if the field is a custom field. */
  is_custom: boolean
}
export type FieldConfigInput = {
  object_type: CustomizableObjectType
  field_name: FieldName
  is_custom: boolean
  /** The data type of the field as a JSON schema */
  field_schema: null | any
  is_enabled: boolean
  is_internal_only: boolean
  category: null | FieldCategory
  needs_reextraction: boolean
  request_config_disabled: boolean
  required_in_request: boolean
  order_index: number
}
export type NonEmptyString = string
export type PostmarkMailbox = {
  Email: string
  Name: string
  MailboxHash: string
}
export type PostmarkInboundMessage = {
  FromName: string
  MessageStream: string
  From: string
  FromFull: PostmarkMailbox
  To: string
  ToFull: PostmarkMailbox[]
  Cc: string
  CcFull: PostmarkMailbox[]
  Bcc: string
  BccFull: PostmarkMailbox[]
  OriginalRecipient: string
  Subject: string
  MessageID: string
  ReplyTo: string
  MailboxHash: string
  Date: string
  TextBody: string
  HtmlBody: string
  StrippedTextReply: string
  Tag: string
  Headers: {
    Name: string
    Value: string
  }[]
  Attachments: {
    Name: string
    Content: string
    ContentType: string
    ContentID: string
  }[]
}
export type PersonStatus =
  | ("active_employee" | "active_user")
  | ("inactive_employee" | "inactive_user")
  | ("pending_employee" | "pending_user")
export type Person = {
  id: string
  object_type: "Person"
  display_name: NonEmptyTrimmedString
  organization_id: string
  user_id: null | string
  first_name: null | NonEmptyTrimmedString
  last_name: null | NonEmptyTrimmedString
  email: null | EmailString
  profile_image: null | ImageAsset
  status?: null | PersonStatus
}
export type Role =
  | "super_admin"
  | "admin"
  | "compliance"
  | "compliance_approver"
  | "finance"
  | "finance_approver"
  | "it"
  | "it_approver"
  | "legal"
  | "legal_approver"
export type NonEmptyTrimmedProseString = string
export type Organization = {
  id: string
  display_name: NonEmptyTrimmedProseString
  email_domain: null | string
  allow_signups: boolean
  sso_only: boolean
  locale: NonEmptyTrimmedProseString
  time_zone: NonEmptyTrimmedProseString
  is_seller: boolean
  is_buyer: boolean
  trial_expires_at: null | DateTimeString
  is_lite: boolean
}
export type UserWithOrganization = {
  id: string
  object_type: "User"
  organization_id: string
  email: EmailString
  profile_image: null | ImageAsset
  created_at: DateTimeString
  person: Person
  roles: Role[]
  first_name: NonEmptyTrimmedString
  last_name: NonEmptyTrimmedString
  status: "active" | "deleted"
  totp_enabled: boolean
  organization: Organization
}
export type UserSettings = {
  totp_enabled: boolean
}
export type ContractCollectorPrivacySetting =
  | "new_legal_agreement"
  | "existing_legal_agreement"
  | "workflow_run"
  | "system_of_record"
export type User = {
  id: string
  object_type: "User"
  organization_id: string
  first_name: null | NonEmptyTrimmedString
  last_name: null | NonEmptyTrimmedString
  email: EmailString
  status: UserStatus
  profile_image: null | ImageAsset
  created_at: DateTimeString
  person: Person
  roles: Role[]
}
export type ApiError = {
  message: NonEmptyString
}
export type NotificationChannel = "email" | "slack" | "inbox"
export type NotificationType =
  | "mention"
  | "workflow_run.started"
  | "workflow_run.input_needed"
  | "workflow_run.completed"
  | "workflow_run.timeline_comment"
  | "workflow_run_step.approved"
  | "workflow_run_step.approval_requested"
  | "workflow_run_step.unapproved"
  | "workflow_run_step.changes_requested"
  | "workflow_run_step_field.reopened"
  | "workflow_run_field.gatherer_assigned"
  | "workflow_run_step.seller_submitted"
  | "tool.timeline_comment"
  | "tool_digest"
  | "vendor.timeline_comment"
  | "vendor_digest"
  | "tool_owned.timeline_comment"
  | "vendor_owned.timeline_comment"
  | "task.workflow_run"
  | "task.reply"
  | "renewal_reminder"
  | "tool_owned_renewal_reminder"
  | "vendor_owned_renewal_reminder"
  | "unverified_agreement_digest"
  | "timeline_comment.reaction"
  | "document.email_ingested"
  | "integration.disconnected"
  | "tools.inactive_owner_reassignment"
  | "vendors.inactive_owner_reassignment"
  | "organization.duplicative_subscriptions"
  | "tool_or_vendor.duplicative_subscriptions"
  | "workflow_run_step.approval_overridden"
  | "integration.historical_agreement_crawl_done"
export type NotificationSetting = {
  type: NotificationType
  channel: NotificationChannel
  enabled: boolean
  num_days: null | number
}
export type UpdateNotificationSetting = {
  type: NotificationType
  channel: NotificationChannel
  enabled?: boolean
  num_days?: number
}
export type UpdateUser = {
  roles?: Role[]
  first_name?: NonEmptyTrimmedString
  last_name?: NonEmptyTrimmedString
  transferred_user_id?: string
}
export type UserOrganizationSummaryResponse = {
  users: {
    id: string
    object_type: "User"
    organization_id: string
    first_name: null | NonEmptyTrimmedString
    last_name: null | NonEmptyTrimmedString
    email: EmailString
    status: UserStatus
    profile_image: null | ImageAsset
    created_at: DateTimeString
    person: Person
    roles: Role[]
    totp_enabled: boolean
  }[]
  total: number
}
export type UserOrganizationSummaryColumn = "email" | "status" | "created_at" | "full_name"
export type SortDirection = "ASC" | "DESC"
export type SortNulls = "FIRST" | "LAST"
export type StartsWithFilter = {
  comparator: "starts_with"
  value: NonEmptyString
}
export type ContainsFilter = {
  comparator: "contains"
  value: NonEmptyString
}
export type EqFilter = {
  comparator: "eq"
  value: NonEmptyString
}
export type NeFilter = {
  comparator: "ne"
  value: NonEmptyString
}
export type GtFilter = {
  comparator: "gt"
  value: NonEmptyString
}
export type LtFilter = {
  comparator: "lt"
  value: NonEmptyString
}
export type GteFilter = {
  comparator: "gte"
  value: NonEmptyString
}
export type LteFilter = {
  comparator: "lte"
  value: NonEmptyString
}
export type BetweenFilter = {
  comparator: "between"
  /** The minimum value, inclusive. Can be any comparable scalar, such as a decimal number or a date. */
  minValue: NonEmptyString
  /** The maximum value, inclusive. Can be any comparable scalar, such as a decimal number or a date. */
  maxValue: NonEmptyString
}
export type AnyFilter = {
  comparator: "any"
  values: NonEmptyString[]
  /** If true, this filter will return all values that match the values in the array or are null. If false, it will only return values that match the ones in the array */
  includeNull?: boolean
}
export type ArrContainsFilter = {
  comparator: "arr_contains"
  values: NonEmptyString[]
  /** If true, this filter will return all values that match the values in the array or are null. If false, it will only return values that match the ones in the array */
  includeNull?: boolean
}
export type ExistsFilter = {
  comparator: "exists"
  value: boolean
}
export type RangeWithinFilter = {
  comparator: "range_within"
  minValue: NonEmptyString
  maxValue: NonEmptyString
}
export type RangeGteFilter = {
  comparator: "range_gte"
  value: NonEmptyString
}
export type FilterField =
  | ({
      comparator: "starts_with"
    } & StartsWithFilter)
  | ({
      comparator: "contains"
    } & ContainsFilter)
  | ({
      comparator: "eq"
    } & EqFilter)
  | ({
      comparator: "ne"
    } & NeFilter)
  | ({
      comparator: "gt"
    } & GtFilter)
  | ({
      comparator: "lt"
    } & LtFilter)
  | ({
      comparator: "gte"
    } & GteFilter)
  | ({
      comparator: "lte"
    } & LteFilter)
  | ({
      comparator: "between"
    } & BetweenFilter)
  | ({
      comparator: "any"
    } & AnyFilter)
  | ({
      comparator: "arr_contains"
    } & ArrContainsFilter)
  | ({
      comparator: "exists"
    } & ExistsFilter)
  | ({
      comparator: "range_within"
    } & RangeWithinFilter)
  | ({
      comparator: "range_gte"
    } & RangeGteFilter)
export type InviteUser = {
  email: EmailString
  roles: Role[]
  first_name: NonEmptyTrimmedString
  last_name: NonEmptyTrimmedString
  person_id?: string
}
export type LoginResult = {
  state: "success" | "failure" | "2fa_required" | "okta_sso_required"
  message?: NonEmptyString
}
export type LoginInput = {
  email: string
  password: NonEmptyString
  token?: NonEmptyString
}
export type HttpUrlString = string
export type ChangePasswordInput = {
  passwordResetCode?: NonEmptyString
  newPassword: NonEmptyString
}
export type CreateUserInput = {
  email: EmailString
  firstName: NonEmptyTrimmedString
  lastName: NonEmptyTrimmedString
  linkCode: NonEmptyString
}
export type OrganizationOktaSsoConfig = {
  okta_client_id: null | NonEmptyTrimmedString
  okta_client_secret: null | NonEmptyTrimmedString
  okta_domain: null | HttpUrlString
}
export type UpdateOrganization = {
  id: string
  allow_signups?: boolean
  sso_only?: boolean
  okta_client_id?: NonEmptyTrimmedString
  okta_client_secret?: NonEmptyTrimmedString
  okta_domain?: HttpUrlString
}
export type CreateOrganization = {
  display_name: NonEmptyTrimmedProseString
  email_domain: null | string
  allow_signups: boolean
  sso_only: boolean
  locale: NonEmptyTrimmedProseString
  time_zone: NonEmptyTrimmedProseString
  is_lite: boolean
  admin_email: EmailString
}
export type UserPicker = {
  type: "user"
  object_type: "User"
  organization_id: string
  first_name: null | NonEmptyTrimmedString
  last_name: null | NonEmptyTrimmedString
  email: EmailString
  roles: Role[]
  id: string
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
}
export type PersonPicker = {
  type: "person"
  object_type: "Person"
  first_name: null | NonEmptyTrimmedString
  last_name: null | NonEmptyTrimmedString
  email: null | EmailString
  id: string
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
}
export type PersonVariablePicker = {
  type: "variable"
  variable: "manager"
}
export type FieldTagPicker = {
  type: "field"
  field_name: NonEmptyTrimmedString
  is_custom: boolean
  object_type: ObjectType
  display_name: NonEmptyTrimmedString
}
export type WorkflowRunStatus = "in_progress" | ("approved" | "rejected" | "cancelled")
export type WorkflowRunPicker = {
  type: "workflow_run"
  object_type: "WorkflowRun"
  status: WorkflowRunStatus
  id: string
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
}
export type LegalAgreementPicker = {
  type: "legal_agreement"
  object_type: "LegalAgreement"
  id: string
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
}
export type ToolPicker = {
  type: "tool"
  object_type: "Tool"
  id: string
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
}
export type VendorPicker = {
  type: "vendor"
  object_type: "Vendor"
  id: string
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
}
export type ToolListingPicker = {
  type: "tool_listing"
  object_type: "ToolListing"
  website?: null | HttpUrlString
  id: string
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
}
export type VendorListingPicker = {
  type: "vendor_listing"
  object_type: "VendorListing"
  website?: null | HttpUrlString
  id: string
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
}
export type WorkflowBuyerPicker = {
  type: "workflow_buyer"
  object_type: "User"
  organization_id: string
  first_name: null | NonEmptyTrimmedString
  last_name: null | NonEmptyTrimmedString
  email: EmailString
  roles: Role[]
  id: string
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
}
export type WorkflowSellerPicker = {
  type: "workflow_seller"
  object_type: "User"
  organization_id: string
  first_name: null | NonEmptyTrimmedString
  last_name: null | NonEmptyTrimmedString
  email: EmailString
  roles: Role[]
  id: string
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
}
export type AgentPicker = {
  type: "agent"
  id: string
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAsset
}
export type PickableEntity =
  | ({
      type: "user"
    } & UserPicker)
  | ({
      type: "person"
    } & PersonPicker)
  | ({
      type: "variable"
    } & PersonVariablePicker)
  | ({
      type: "field"
    } & FieldTagPicker)
  | ({
      type: "workflow_run"
    } & WorkflowRunPicker)
  | ({
      type: "legal_agreement"
    } & LegalAgreementPicker)
  | ({
      type: "tool"
    } & ToolPicker)
  | ({
      type: "vendor"
    } & VendorPicker)
  | ({
      type: "tool_listing"
    } & ToolListingPicker)
  | ({
      type: "vendor_listing"
    } & VendorListingPicker)
  | ({
      type: "workflow_buyer"
    } & WorkflowBuyerPicker)
  | ({
      type: "workflow_seller"
    } & WorkflowSellerPicker)
  | ({
      type: "agent"
    } & AgentPicker)
export type PickableEntities = PickableEntity[]
export type PickableEntityType =
  | "user"
  | "person"
  | "variable"
  | "workflow_seller"
  | "workflow_buyer"
  | "legal_agreement"
  | "workflow_run"
  | "tool"
  | "vendor"
  | "field"
  | "tool_listing"
  | "vendor_listing"
  | "agent"
export type PickableEntityFilter = {
  name?: string
  entities?: PickableEntityType[]
  /** The entity that is requesting the pickable entities. Used to filter pickable entities that the requesting entity is allowed to see. */
  requesting_entity?: {
    object_type: ObjectType
    object_id?: string
    workflow_step_id?: string
  }
}
export type LimitNumber = number
export type PermissionedPickableEntity =
  | ({
      type: "user"
    } & UserPicker)
  | ({
      type: "person"
    } & PersonPicker)
  | ({
      type: "variable"
    } & PersonVariablePicker)
export type PermissionedEntityType = "user" | "person" | "variable" | "workflow_seller" | "workflow_buyer"
export type Permission =
  | "transaction:read"
  | "spend:read"
  | "saved_view.shared:create"
  | "saved_view.shared:update"
  | "saved_view.shared:delete"
  | "login:read"
  | "user:update"
  | "user:delete"
  | "admin_role:create"
  | "admin_role:update"
  | "admin_role:delete"
  | "super_admin_role:create"
  | "super_admin_role:update"
  | "super_admin_role:delete"
  | "compliance_role:create"
  | "compliance_approver_role:create"
  | "finance_role:create"
  | "finance_approver_role:create"
  | "it_role:create"
  | "it_approver_role:create"
  | "legal_role:create"
  | "legal_approver_role:create"
  | "organization:update"
  | "organization_okta_sso_config:read"
  | "field_config:read"
  | "field_config:create"
  | "field_config:update"
  | "field_config:delete"
  | "integration:read"
  | "integration:create"
  | "integration:update"
  | "integration:delete"
  | "tool:update"
  | "vendor:update"
  | "tool:read:hidden"
  | "tool:hide"
  | "vendor:read:hidden"
  | "vendor:hide"
  | "legal:read"
  | "legal:create"
  | "legal:update"
  | "legal:delete"
  | "legal:read:private"
  | "legal:update:private"
  | "legal:delete:private"
  | "finance:update"
  | "compliance:read"
  | "compliance:create"
  | "compliance:update"
  | "compliance:delete"
  | "it:read"
  | "it:create"
  | "it:update"
  | "it:delete"
  | "workflow:approve"
  | "compliance:approve"
  | "finance:approve"
  | "it:approve"
  | "legal:approve"
  | "workflow:approve:any"
  | "workflow_def:read"
  | "workflow_def:create"
  | "workflow_def:update"
  | "workflow_def:delete"
  | "workflow_run:create"
  | "workflow_run:update"
  | "workflow_run:delete"
  | "workflow_run:view:any"
  | "workflow_run_step:submit:intake"
  | "workflow_run_step:update"
  | "credit_card:create"
  | "credit_card:delete"
export type PermissionedPickableEntityFilter = {
  name?: string
  /** The entity that is requesting the pickable entities. Used to filter pickable entities that the requesting entity is allowed to see. */
  requesting_entity?: {
    object_type: ObjectType
    object_id?: string
    workflow_step_id?: string
  }
  entities?: PermissionedEntityType[]
  permission?: {
    value: Permission
    includeAll: boolean
  }
}
export type NumericString = string
export type CurrencyCodeString = string
export type CurrencyAmount = {
  amount: NumericString
  currency_code: CurrencyCodeString
}
export type DateString = string
export type SpendInterval = {
  start_date: DateString
  total: {
    currency_amount: CurrencyAmount
    by_currency: {
      [key: string]: null | NumericString
    }
  }
  spend_splits: {
    credit_card?: {
      currency_amount: CurrencyAmount
      by_currency: {
        [key: string]: null | NumericString
      }
    }
    bill_payment?: {
      currency_amount: CurrencyAmount
      by_currency: {
        [key: string]: null | NumericString
      }
    }
    other?: {
      currency_amount: CurrencyAmount
      by_currency: {
        [key: string]: null | NumericString
      }
    }
  }
}
export type SpendResponse = {
  total: CurrencyAmount
  total_by_currency: {
    [key: string]: null | NumericString
  }
  intervals?: SpendInterval[]
}
export type DateDurationString = string
export type SpendQueryKind = "vendor" | "tool" | "organization"
export type TimePeriod =
  | "month_to_date"
  | "year_to_date"
  | "last_twelve_months"
  | "last_twenty_four_months"
  | "last_thirty_days"
export type NumberStatistic = {
  value: number
  increase_value: number
  time_period: TimePeriod
}
export type IntegrationProvider =
  | "ramp_oauth"
  | "google_oauth"
  | "gmail_oauth"
  | "brex_oauth"
  | "quickbooks_oauth"
  | "slack_oauth"
  | "ramp_client_creds"
  | "brex_apikey"
  | "mercury_apikey"
  | "okta_apikey"
  | "merge_hris_link_token"
  | "merge_accounting_link_token"
  | "okta_oauth"
  | "plaid_access_token"
export type UserWithProvider = {
  id: string
  object_type: "User"
  organization_id: string
  first_name: null | NonEmptyTrimmedString
  last_name: null | NonEmptyTrimmedString
  email: EmailString
  status: UserStatus
  profile_image: null | ImageAsset
  created_at: DateTimeString
  person: Person
  roles: Role[]
  integration_provider: IntegrationProvider
}
export type AllEmailIntegrationStatistic = {
  linked_agreement_count: NumberStatistic
  user_integrations: UserWithProvider[]
  current_user_connected: boolean
}
export type AllAgreementStatistic = {
  total_count: number
  verified_count: number
  unverified_agreement_id?: string
}
export type AllPersonStatistic = {
  employed_count: NumberStatistic
}
export type SpendStatistic = {
  currency_amount: CurrencyAmount
  increase_percent: null | NonEmptyString
}
export type AllToolStatistic = {
  count: NumberStatistic
  paid_count: NumberStatistic
  mtd_spend_statistic?: null | SpendStatistic
  ltm_spend_statistic?: null | SpendStatistic
  ytd_spend_statistic?: null | SpendStatistic
}
export type ImageAssetMinimal = {
  id: string
  gcs_file_name: NonEmptyTrimmedString
  created_at: DateTimeString
}
export type ToolStatus = "approved" | "in_review" | "unknown" | "canceling" | "warning" | "churned" | "rejected"
export type ToolFinanceStatus = "paid" | "unpaid" | "in_review" | "unknown" | "paid_unapproved" | "rejected"
export type ToolComplianceStatus = "approved" | "in_review" | "unknown" | "rejected"
export type ToolItStatus = "approved" | "in_review" | "unknown" | "rejected"
export type FieldSourceType = "sor" | "user" | "transaction" | "document" | "link" | "listing" | "derived"
export type DerivedMethod = "decision_date_calculation" | "tcv_summation"
export type DurationString = string
export type PageSource = {
  text: string[]
  page_number?: number
}
export type AssignedByMetadataOutput = {
  /** The source from the document assigned by the user as provenance. */
  source: PageSource
  object_field_source: {
    id: string
    type: "document" | "transaction"
    /** The display name for drop down menus */
    source_display_name?: NonEmptyTrimmedString
  }
}
export type FieldSourceOutputProperties = {
  /** The field source provenance type. */
  type?: FieldSourceType
  /** Unique identifier associated to the field source type. (eg. type = 'user' and id = user_id) */
  id?: null | string
  updated_at?: null | DateTimeString
  /** An enum to key on the calculation method and display name formatting. Only used when type = 'derived' */
  derived_method?: DerivedMethod
  derived_from?: NonEmptyTrimmedString[]
  /** Only used when type = 'listing' */
  rationale?: NonEmptyTrimmedString
  links?: HttpUrlString[]
  /** The display name for drop down menus */
  source_display_name?: NonEmptyTrimmedString
  /** Only used when type = 'listing' */
  read_time_estimate?: DurationString
  source_updated_at?: null | DateTimeString
  assigned_by_metadata?: null | AssignedByMetadataOutput
  is_extracting?: boolean
}
export type Suggestion = {
  value: any
  field_sources: FieldSourceOutputProperties[]
}
export type FieldMetadataWithSuggestions = {
  /** The field source provenance type. */
  type?: FieldSourceType
  /** Unique identifier associated to the field source type. (eg. type = 'user' and id = user_id) */
  id?: null | string
  updated_at?: null | DateTimeString
  /** An enum to key on the calculation method and display name formatting. Only used when type = 'derived' */
  derived_method?: DerivedMethod
  derived_from?: NonEmptyTrimmedString[]
  /** Only used when type = 'listing' */
  rationale?: NonEmptyTrimmedString
  links?: HttpUrlString[]
  /** The display name for drop down menus */
  source_display_name?: NonEmptyTrimmedString
  /** Only used when type = 'listing' */
  read_time_estimate?: DurationString
  source_updated_at?: null | DateTimeString
  assigned_by_metadata?: null | AssignedByMetadataOutput
  is_extracting?: boolean
  verified?: boolean
  verified_at?: null | DateTimeString
  verified_by?: null | string
  suggestions?: Suggestion[]
}
export type ToolFieldsMetadataOutput = {
  custom?: {
    [key: string]: FieldMetadataWithSuggestions
  }
  privacy_policy?: FieldMetadataWithSuggestions
  terms_of_service?: FieldMetadataWithSuggestions
  customer_security_impact_level?: FieldMetadataWithSuggestions
  is_critical?: FieldMetadataWithSuggestions
  is_subprocessor?: FieldMetadataWithSuggestions
  subprocessors?: FieldMetadataWithSuggestions
  soc2?: FieldMetadataWithSuggestions
  gdpr?: FieldMetadataWithSuggestions
  hipaa?: FieldMetadataWithSuggestions
  iso_27001?: FieldMetadataWithSuggestions
  pci_dss?: FieldMetadataWithSuggestions
  ccpa?: FieldMetadataWithSuggestions
  cdpa?: FieldMetadataWithSuggestions
  cmmc_2?: FieldMetadataWithSuggestions
  cpa?: FieldMetadataWithSuggestions
  cpra?: FieldMetadataWithSuggestions
  ctdpa?: FieldMetadataWithSuggestions
  eu_whistleblower?: FieldMetadataWithSuggestions
  nevada_privacy_law?: FieldMetadataWithSuggestions
  sox?: FieldMetadataWithSuggestions
  ucpa?: FieldMetadataWithSuggestions
  sea_rule_17a4f?: FieldMetadataWithSuggestions
  worm?: FieldMetadataWithSuggestions
  cybersecurity_policy?: FieldMetadataWithSuggestions
  information_security_policy?: FieldMetadataWithSuggestions
  aml_policy?: FieldMetadataWithSuggestions
  financial_stability?: FieldMetadataWithSuggestions
  dpa?: FieldMetadataWithSuggestions
  stores_pii?: FieldMetadataWithSuggestions
  pii_data_types?: FieldMetadataWithSuggestions
  pii_person_types?: FieldMetadataWithSuggestions
  pii_regions?: FieldMetadataWithSuggestions
  data_stored?: FieldMetadataWithSuggestions
  sso_types?: FieldMetadataWithSuggestions
  mfa_types?: FieldMetadataWithSuggestions
  access_control_types?: FieldMetadataWithSuggestions
  deployment_types?: FieldMetadataWithSuggestions
  scim?: FieldMetadataWithSuggestions
  integrates_with?: FieldMetadataWithSuggestions
  overview?: FieldMetadataWithSuggestions
  selection_rationale?: FieldMetadataWithSuggestions
  risks_limitations?: FieldMetadataWithSuggestions
  roi_analysis?: FieldMetadataWithSuggestions
  implementation_details?: FieldMetadataWithSuggestions
  support_from_vendor?: FieldMetadataWithSuggestions
  tools_considered?: FieldMetadataWithSuggestions
  owner?: FieldMetadataWithSuggestions
  status?: FieldMetadataWithSuggestions
  finance_status?: FieldMetadataWithSuggestions
  compliance_status?: FieldMetadataWithSuggestions
  it_status?: FieldMetadataWithSuggestions
}
export type ToolCategory = {
  id: string
  object_type: "ToolCategory"
  display_name: NonEmptyTrimmedString
  parent_id: null | string
}
export type CustomFields = {
  [key: string]: any
}
export type DocumentExtractionStatus =
  | "running_text_ocr"
  | "deduping_documents"
  | "categorizing"
  | "completed"
  | "duplicated"
  | "irrelevant"
  | "extracting_fields"
  | "populating_objects"
  | "failed"
export type DocumentMinimal = {
  id: string
  object_type: "Document"
  created_at: DateTimeString
  updated_at?: null | DateTimeString
  /** The original file basename as uploaded by the user. */
  file_name: null | NonEmptyTrimmedString
  status: "pending" | "uploaded" | "scanned" | "quarantined"
  extraction_status?: null | DocumentExtractionStatus
  /** The file size in bytes */
  file_size: null | number
  /** MIME type like `application/pdf` or `text/plain; charset=utf-8` */
  mime_type: null | NonEmptyTrimmedString
  /** Users who have email integrations which have found this document */
  user_email_integrations?: UserWithProvider[]
}
export type DocumentOrUrlString = DocumentMinimal | HttpUrlString
export type CustomerSecurityImpact = "none" | "low" | "medium" | "high"
export type CompliantWithDocument = {
  /** Whether the tool is compliant */
  compliant: null | boolean
  document: null | DocumentOrUrlString
}
export type ApplicableWithDocument = {
  /** Whether the standard is applicable */
  applicable: null | boolean
  document: null | DocumentOrUrlString
}
export type PiiDataType =
  | "name"
  | "home_phone_number"
  | "mobile_phone_number"
  | "personal_account_number"
  | "home_address"
  | "personal_reference_number"
  | "work_address"
  | "social_media_url"
  | "employee_number"
  | "ip_address"
  | "device_identifier"
  | "niss_identification_number"
  | "personal_email_address"
  | "bank_account_information"
  | "work_email_address"
  | "trade_union_membership"
export type PiiPersonType = "employees" | "clients" | "prospective_employees" | "contractors"
export type PiiRegion = "north_america" | "european_union" | "asia"
export type ComplianceData =
  | "customer_data"
  | "financial_data"
  | "employee_data"
  | "operational_data"
  | "technical_data"
  | "legal_data"
  | "intellectual_property"
  | "marketing_data"
  | "health_data"
  | "biometric_data"
export type SsoType =
  | "okta_oauth"
  | "google_oauth"
  | "okta_saml"
  | "google_saml"
  | "one_login_saml"
  | "entra_id_saml"
  | "rippling_saml"
  | "saml"
export type MfaType = "sms" | "email" | "otp_software" | "otp_hardware" | "passkey"
export type AccessControlType = "rbac" | "abac" | "acl"
export type SoftwareDeploymentType = "cloud" | "on_prem" | "hybrid" | "private_cloud"
export type ToolListing = {
  id: string
  object_type: "ToolListing"
  display_name: NonEmptyTrimmedString
  image_asset: null | {
    gcs_file_name: NonEmptyTrimmedString
  }
}
export type ElementTypes =
  | "block-quote"
  | "bulleted-list"
  | "numbered-list"
  | "heading-one"
  | "heading-two"
  | "image"
  | "document"
  | "link"
  | "list-item"
  | "mention"
  | "paragraph"
  | "field-tag"
  | "agent-tag"
  | "agent-response"
  | "standard-object"
  | "progress"
export type RichTextNode = {
  text?: string & string
  bold?: boolean
  italic?: boolean
  underline?: boolean
  type?: ElementTypes
  children?: RichTextNode[]
  file_name?: NonEmptyTrimmedString
  url?: string
  user_id?: string
  display_name?: NonEmptyTrimmedString
  organization_id?: string
  field_name?: NonEmptyTrimmedString
  object_type?: ObjectType
  is_custom?: boolean
  id?: string
  mime_type?: NonEmptyTrimmedString
  agent_id?: string
}
export type RichText = RichTextNode[]
export type VendorMinimal = {
  /** The company name of the vendor */
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAssetMinimal
  /** The description of the vendor */
  description: null | NonEmptyTrimmedProseString
  /** The URL of the vendor’s website */
  website: null | HttpUrlString
  id: string
  object_type: "Vendor"
  vendor_listing_id: string
  created_at: DateTimeString
}
export type EmployeeGroupType = "team" | "department" | "cost_center" | "business_unit" | "group"
export type EmployeeGroup = {
  id: string
  object_type: "EmployeeGroup"
  display_name: null | NonEmptyTrimmedString
  group_type: null | EmployeeGroupType
}
export type OneTimeFrequency = "one_time"
export type FrequencyOrOneTime = OneTimeFrequency | DateDurationString
export type LegalClauses = {
  /** Whether the contract has an arbitration clause. The arbitration clause requires the parties to the contract to resolve any dispute arising out of the contract through an arbitration process, as opposed to litigation or mediation. */
  arbitration: null | boolean
  /** Whether the contract has an assignment clause. The assignment clause indicates whether and when a party to the contract can assign their rights and obligations under the contract to someone else. */
  assignment: null | boolean
  /** Whether the contract has a confidentiality clause. The confidentiality clause prevents one party (or both parties) from disclosing information related to the agreement, or other sensitive information. */
  confidentiality: null | boolean
  /** Whether the contract has a data privacy clause. The data privacy clause may explain what private data will be collected, what the collecting party will do with the data, and/or how the data will be protected. */
  data_privacy: null | boolean
  /** Whether the contract has a data processing clause. The data processing clause outlines the roles and responsibilities of the parties involved with regards to processing of personal data. It details the methods, purposes, and scope of the data processing activities carried out under the agreement. It may specify the rights and obligations of the data controller and data processor, and the safeguards and measures that must be in place to ensure data security and compliance with relevant data protection laws and regulations. */
  data_processing: null | boolean
  /** Whether the contract has a data protection clause. The data protection clause ensures that the data given by one party to another is safeguarded from unlawful access by unauthorized users or entities. */
  data_protection: null | boolean
  /** Whether the contract has a dispute resolution clause. The dispute resolution clause sets forth the circumstances under which any disputes between the parties should be resolved (e.g., whether the dispute should be resolved via arbitration, mediation, or litigation; jurisdiction, venue, etc.) */
  dispute_resolution: null | boolean
  /** Whether the contract has a force majeure clause. The force majeure clause frees both parties from obligation if an extraordinary event directly prevents one or both parties from performing their obligations under the contract. */
  force_majeure: null | boolean
  /** Whether the contract has a governing law clause. The governing law clause specifies which jurisdiction’s law will apply in the event of a dispute. */
  governing_law: null | boolean
  /** Whether the contract has an indemnification clause. The indemnification clause obligates one party to compensate another party for certain costs and expenses, usually stemming from third party claims. */
  indemnification: null | boolean
  /** Whether the contract has an intellectual property clause. The intellectual property clause sets forth ownership, permitted uses, and use restrictions of intellectual property. */
  intellectual_property: null | boolean
  /** Whether the contract has a license clause. The license clause gives one party the right to use the product or services of the other party under certain conditions. */
  license: null | boolean
  /** Whether the contract has a limitation of liability clause. The limitation of liability clause limits the amount and types of compensation one party can recover from the other party when a claim is made or lawsuit is filed in response to a breach of the contract. */
  limitation_of_liability: null | boolean
  /** Whether the contract has a non disclosure clause. The non-disclosure clause prohibits the parties to the contract from releasing certain information to other parties. */
  non_disclosure: null | boolean
  /** Whether the contract has a non solicitation clause. The non-solicitation clause prohibits a party from offering employment to the counterparty's employees, for a specified period of time. */
  non_solicitation: null | boolean
  /** Whether the contract has a representation clause. The representations clause states that the representations contained in the contract are true and accurate as of the date of the agreement. */
  representation: null | boolean
  /** Whether the contract has a termination clause. The termination clause explains what will happen if either party terminates the contract. The clause may also set forth the circumstances in which one party may terminate the contract, such as required notice. */
  termination: null | boolean
  /** Whether the contract has a warranty clause. The warranty clause sets forth what promises, if any, are being made regarding the accuracy, completeness, or reliability of the subject matter of the contract, whether data, services, software, or otherwise. Warranty clauses can also specifically state that no such promises are being made or relied upon. */
  warranty: null | boolean
}
export type AgreementEffectiveStatus = "upcoming" | "active" | "unknown" | "expired"
export type AgreementType = "subscription" | "enterprise"
export type LegalClausesFieldsMetadata = any | any
export type LegalAgreementFieldsMetadata = {
  total_contract_value?: FieldMetadataWithSuggestions
  start_date?: FieldMetadataWithSuggestions
  end_date?: FieldMetadataWithSuggestions
  first_invoice_date?: FieldMetadataWithSuggestions
  invoice_interval?: FieldMetadataWithSuggestions
  net_payment_term?: FieldMetadataWithSuggestions
  auto_renews?: FieldMetadataWithSuggestions
  auto_renewal_opt_out_period?: FieldMetadataWithSuggestions
  decision_date?: FieldMetadataWithSuggestions
  is_signed?: FieldMetadataWithSuggestions
  buyer_signer_name?: FieldMetadataWithSuggestions
  buyer_signer_title?: FieldMetadataWithSuggestions
  vendor_signer_name?: FieldMetadataWithSuggestions
  vendor_signer_title?: FieldMetadataWithSuggestions
  documents?: FieldMetadataWithSuggestions
  vendor?: FieldMetadataWithSuggestions
  tools?: FieldMetadataWithSuggestions
  display_name?: FieldMetadataWithSuggestions
  agreement_type?: FieldMetadataWithSuggestions
  clauses?: LegalClausesFieldsMetadata
  custom?: {
    [key: string]: FieldMetadataWithSuggestions
  }
}
export type AgreementVerificationStatus = "draft" | "verified" | "in_review"
export type AgreementVisibilityStatus = "hidden" | "demo" | "private" | "draft" | "visible"
export type LegalAgreementMinimal = {
  /** The total cost of the contract to the buyer over the entire period of the contract, or `null` if unknown. */
  total_contract_value?: null | CurrencyAmount
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date: null | DateString
  /** The first date at which the buyer will be billed by the seller as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  first_invoice_date?: null | DateString
  /** Will the vendor invoice {buyer} monthly, quarterly, or annually? Look for a specific mention of when the buyer is invoiced. */
  invoice_interval?: null | FrequencyOrOneTime
  /** The amount of time that the buyer has to settle each bill, e.g. P30D for 30 days. `null` if unknown. Payment terms that are due upon receipt should return PT0S. */
  net_payment_term?: null | DateDurationString
  /** Whether the contract term automatically renews; true/false. */
  auto_renews?: null | boolean
  /** The period the buyer has to give notice to terminate the contract before the end of the current term. The period should be an ISO 8601 duration string (e.g., P30D for 30 days). */
  auto_renewal_opt_out_period?: null | DateDurationString
  /** The date when the buyer must decide whether to renew or cancel the contract. Renewal reminders are sent based on this date. */
  decision_date?: null | DateString
  /** Whether the contract was signed or is still unsigned */
  is_signed: null | boolean
  /** The full name of the person who signed for the buying party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  buyer_signer_name?: null | NonEmptyTrimmedProseString
  /** The buying signer’s title (e.g., CEO). */
  buyer_signer_title?: null | NonEmptyTrimmedProseString
  /** The full name of the person who signed for the selling party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  vendor_signer_name?: null | NonEmptyTrimmedProseString
  /** The selling signer’s title (e.g., CEO). */
  vendor_signer_title?: null | NonEmptyTrimmedProseString
  /** The legal clauses found in the contract. */
  clauses?: LegalClauses
  custom: CustomFields
  id: string
  object_type: "LegalAgreement"
  /** Whether the Legal Agreement is active, expired, or upcoming. Will be unknown if the agreement has not been verified or is missing start/end dates. */
  effective_status: AgreementEffectiveStatus
  /** The type of agreement (enterprise or subscription) */
  agreement_type: AgreementType
  /** The name of the agreement. */
  display_name: NonEmptyTrimmedProseString
  usd_total_contract_value?: null | NumericString
  /** The next occurrence of the billing interval (based on the first invoice date) */
  next_invoice_date?: null | DateString
  created_at: DateTimeString
  updated_at?: null | DateTimeString
  fields_metadata: LegalAgreementFieldsMetadata
  /** Legal agreements that enter through certain sources, like bulk upload or email, need to have their extracted fields verified. Manually created agreements always start verified. Verified agreements may become unverified when more documents are added. */
  verification_status: AgreementVerificationStatus
  visibility_status: AgreementVisibilityStatus
}
export type GrowthRate = number
export type SpendSummary = {
  currency_amount: CurrencyAmount
  growth: null | GrowthRate
}
export type AllSpendByCurrency = {
  total: {
    [key: string]: null | NumericString
  }
  ltm: {
    [key: string]: null | NumericString
  }
  ytd: {
    [key: string]: null | NumericString
  }
  mtd: {
    [key: string]: null | NumericString
  }
}
export type AggregatedSpend = {
  mtd: null | SpendSummary
  ytd: null | SpendSummary
  ltm: null | SpendSummary
  total: null | CurrencyAmount
  spend_by_currency_code: null | AllSpendByCurrency
}
export type TransactionPaymentMethod = "credit_card" | "bill_payment"
export type ErpDepartment = {
  id: string
  object_type: "ErpTrackingCategory"
  display_name: NonEmptyTrimmedString
  type: "department"
}
export type ErpClass = {
  id: string
  object_type: "ErpTrackingCategory"
  display_name: NonEmptyTrimmedString
  type: "class"
}
export type ErpTrackingCategoryGroups = {
  departments: ErpDepartment[]
  classes: ErpClass[]
}
export type ToolListItem = {
  /** The name of the tool */
  display_name: NonEmptyTrimmedString
  /** The description of the tool (what the tool does) */
  description: null | NonEmptyTrimmedProseString
  /** The URL of the tool's website */
  website: null | HttpUrlString
  /** The image asset for the tool's logo */
  image_asset: null | ImageAssetMinimal
  id: string
  object_type: "Tool"
  tool_listing_id: string
  created_at: DateTimeString
  updated_at?: null | DateTimeString
  /** The overall approval status of the tool */
  status: ToolStatus
  /** The financial approval status of the tool */
  finance_status?: ToolFinanceStatus
  /** The compliance approval status of the tool */
  compliance_status?: ToolComplianceStatus
  /** The IT approval status of the tool */
  it_status?: ToolItStatus
  /** The person in the organization responsible for the tool */
  owner: null | Person
  fields_metadata?: ToolFieldsMetadataOutput
  organization_id: string
  /** The tool categories that the tool falls into */
  categories: null | ToolCategory[]
  hidden?: boolean
  custom: CustomFields
  /** URL or Document containing the service’s privacy policy */
  privacy_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service’s terms of service */
  terms_of_service?: null | DocumentOrUrlString
  /** Risk level can be categorized as:
    
    - **High**: Vendor handles sensitive data or security services; critical business impact if they fail.
    - **Moderate**: Vendor doesn’t handle sensitive data or security; moderate business impact if they fail.
    - **Low**: Vendor has minimal involvement and little to no business impact if they fail.
    - **None**: Vendor has no impact on business operations. */
  customer_security_impact_level?: null | CustomerSecurityImpact
  /** A critical vendor has one or more of the following characteristics:
    
    1) Handles customer data,
    2) Is responsible for our infrastructure or code security,
    3) Has cloud services that affect our system’s security or availability,
    4) Whose failure would result in disruption to our service availability. */
  is_critical?: null | boolean
  /** Whether the product or service is a data subprocessor */
  is_subprocessor?: null | boolean
  /** Link to a vendor’s list of third-party subprocessors */
  subprocessors?: null | HttpUrlString
  /** Whether the product or service has SOC 2 compliance certification */
  soc2?: null | CompliantWithDocument
  /** Whether the product or service has a General Data Protection Regulation compliance certification */
  gdpr?: null | CompliantWithDocument
  /** Whether the product or service has Health Insurance Portability and Accountability Act compliance certification */
  hipaa?: null | CompliantWithDocument
  /** Whether the product or service has ISO 27001 compliance certification */
  iso_27001?: null | CompliantWithDocument
  /** Whether the product or service has Payment Card Industry Data Security Standard compliance certification */
  pci_dss?: null | CompliantWithDocument
  /** Whether the product or service has California Consumer Privacy Act compliance certification */
  ccpa?: null | CompliantWithDocument
  /** Whether the product or service has Colorado Privacy Act compliance certification */
  cdpa?: null | CompliantWithDocument
  /** Whether the product or service has Cybersecurity Maturity Model Certification 2.0 compliance certification */
  cmmc_2?: null | CompliantWithDocument
  /** Whether the product or service has Colorado Privacy Act compliance certification */
  cpa?: null | CompliantWithDocument
  /** Whether the product or service has California Privacy Rights Act compliance certification */
  cpra?: null | CompliantWithDocument
  /** Whether the product or service has Connecticut Data Protection Act compliance certification */
  ctdpa?: null | CompliantWithDocument
  /** Whether the product or service has EU Whistleblower compliance certification */
  eu_whistleblower?: null | CompliantWithDocument
  /** Whether the product or service has Nevada Privacy Law compliance certification */
  nevada_privacy_law?: null | CompliantWithDocument
  /** Whether the product or service has Sarbanes-Oxley Act compliance certification */
  sox?: null | CompliantWithDocument
  /** Whether the product or service has Uniform Consumer Credit Protection Act compliance certification */
  ucpa?: null | CompliantWithDocument
  /** Whether the product or service is Securities Exchange Act Rule 17a-4(f) compliant */
  sea_rule_17a4f?: null | boolean
  /** Whether the product or service stores data in write-once, read-many (WORM) format */
  worm?: null | ApplicableWithDocument
  /** URL or Document containing the service's cybersecurity policy */
  cybersecurity_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's information security policy */
  information_security_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's Anti-Money Laundering policy */
  aml_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's financial stability evidence */
  financial_stability?: null | DocumentOrUrlString
  /** The Data Processing Agreement for a tool */
  dpa?: null | DocumentMinimal
  /** Whether the product or service stores personal identifying information */
  stores_pii?: null | boolean
  /** The types of personal identifiable information the product or service collects or retains */
  pii_data_types?: null | PiiDataType[]
  /** The types of people whose personal identifiable information the product or service collects or retains */
  pii_person_types?: null | PiiPersonType[]
  /** The regions whose personal identifiable information the product or service collects or retains */
  pii_regions?: null | PiiRegion[]
  /** The types of data the product or service collects or retains */
  data_stored?: null | ComplianceData[]
  /** Lists the specific Single Sign-On (SSO) methods the software supports */
  sso_types?: null | SsoType[]
  /** Lists the specific Multi-Factor Authentication methods the software supports */
  mfa_types?: null | MfaType[]
  /** Describes the specific methods used by the software to regulate data access */
  access_control_types?: null | AccessControlType[]
  /** Indicates how the software is deployed and accessed */
  deployment_types?: null | SoftwareDeploymentType[]
  /** Determines if the software provides System for Cross-domain Identity Management functionality */
  scim?: null | boolean
  /** Which other tools does this product integrate with? */
  integrates_with?: null | ToolListing[]
  /** What problem(s) will this tool solve and how? */
  overview: null | RichText
  /** Why are you choosing this product over alternative tools and services? */
  selection_rationale: null | RichText
  /** What are the areas of concern or gaps this tool has? */
  risks_limitations: null | RichText
  /** What’s the estimated return on investment (ROI) for this tool? */
  roi_analysis: null | RichText
  /** How long will it take to implement and fully deploy? */
  implementation_details: null | RichText
  /** What level of support is provided by the vendor for the selected plan? */
  support_from_vendor: null | RichText
  /** What other tools and services were considered during the evaluation of this tool? */
  tools_considered: ToolListing[]
  /** The vendor offering the tool */
  vendor: VendorMinimal
  /** The number of users that have accessed the tool */
  total_people: null | number
  /** The department classification for the tool owner */
  owner_groups: EmployeeGroup[]
  /** The primary legal agreement with the tool’s vendor for the tool */
  primary_legal_agreement: null | LegalAgreementMinimal
  /** The number of active legal agreements on the tool */
  active_legal_agreement_count: number
  /** The aggregated spent on the tool/vendor over various time periods */
  spend?: AggregatedSpend
  /** The method used to pay (e.g. bill pay or credit card) */
  payment_methods?: null | TransactionPaymentMethod[]
  /** The department or class categorization for the tool from your accounting system */
  erp_tracking_categories: ErpTrackingCategoryGroups
}
export type ToolListItemResponse = {
  tools: ToolListItem[]
  total: number
}
export type PropertyPathString = string
export type FilterPathString = string
export type ListQueryStringParams = {
  limit?: number
  offset?: number
  sort?: {
    by: PropertyPathString
    direction: SortDirection
    nulls?: SortNulls
  }
  /**
    The inner array is an OR, the outer array is an AND so if we get passed in filter `A`, `B`, `C`:
    
    - `[[A, B], [C]]` is `(A OR B) AND C`
    - `[[A], [B], [C]]` is `(A) AND (B) AND (C)`
    - `[[A, B, C]]` is `(A OR B OR C)`
    
    TODO make this more flexible.
     */
  filter?: {
    /** The dot-separated path of the property to filter on.
        
        For arrays, a path segment can also be a wildcard `*` (e.g. `categories.*.id`), in which case the expression being filtered on is calculated by mapping over each element of the element (in the example, an array of IDs). */
    column: FilterPathString
    fields: FilterField
  }[][]
}
export type ToolMinimal = {
  /** The name of the tool */
  display_name: NonEmptyTrimmedString
  /** The description of the tool (what the tool does) */
  description: null | NonEmptyTrimmedProseString
  /** The URL of the tool's website */
  website: null | HttpUrlString
  /** The image asset for the tool's logo */
  image_asset: null | ImageAssetMinimal
  id: string
  object_type: "Tool"
  tool_listing_id: string
  created_at: DateTimeString
  updated_at?: null | DateTimeString
}
export type VendorOption = {
  /** The company name of the vendor */
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAssetMinimal
  /** The description of the vendor */
  description: null | NonEmptyTrimmedProseString
  /** The URL of the vendor’s website */
  website: null | HttpUrlString
  id: null | string
  object_type: "Vendor"
  vendor_listing_id: string
}
export type ToolOptionWithVendor = {
  /** The name of the tool */
  display_name: NonEmptyTrimmedString
  /** The description of the tool (what the tool does) */
  description: null | NonEmptyTrimmedProseString
  /** The URL of the tool's website */
  website: null | HttpUrlString
  /** The image asset for the tool's logo */
  image_asset: null | ImageAssetMinimal
  id: null | string
  object_type: "Tool"
  tool_listing_id: string
  vendor?: VendorOption
}
export type Tool = {
  /** The name of the tool */
  display_name: NonEmptyTrimmedString
  /** The description of the tool (what the tool does) */
  description: null | NonEmptyTrimmedProseString
  /** The URL of the tool's website */
  website: null | HttpUrlString
  /** The image asset for the tool's logo */
  image_asset: null | ImageAssetMinimal
  id: string
  object_type: "Tool"
  tool_listing_id: string
  created_at: DateTimeString
  updated_at?: null | DateTimeString
  /** The overall approval status of the tool */
  status: ToolStatus
  /** The financial approval status of the tool */
  finance_status?: ToolFinanceStatus
  /** The compliance approval status of the tool */
  compliance_status?: ToolComplianceStatus
  /** The IT approval status of the tool */
  it_status?: ToolItStatus
  /** The person in the organization responsible for the tool */
  owner: null | Person
  fields_metadata?: ToolFieldsMetadataOutput
  organization_id: string
  /** The tool categories that the tool falls into */
  categories: null | ToolCategory[]
  hidden?: boolean
  custom: CustomFields
}
export type ToolListingPatch = {
  id: string
  object_type: "ToolListing"
  display_name?: NonEmptyTrimmedString
  image_asset?: null | {
    gcs_file_name: NonEmptyTrimmedString
  }
}
export type CompliantWithDocumentPatch = {
  /** Whether the tool is compliant */
  compliant?: null | boolean
  document?: null | DocumentOrUrlString
}
export type ApplicableWithDocumentPatch = {
  /** Whether the standard is applicable */
  applicable?: null | boolean
  document?: null | DocumentOrUrlString
}
export type AssignedByMetadataInput = {
  /** The source from the document assigned by the user as provenance. */
  source: PageSource
  object_field_source: {
    id: string
    type: "document" | "transaction"
  }
}
export type FieldMetadata = {
  /** The field source provenance type. */
  type?: FieldSourceType
  /** Unique identifier associated to the field source type. (eg. type = 'user' and id = user_id) */
  id?: null | string
  updated_at?: null | DateTimeString
  /** An enum to key on the calculation method and display name formatting. Only used when type = 'derived' */
  derived_method?: DerivedMethod
  derived_from?: NonEmptyTrimmedString[]
  /** Only used when type = 'listing' */
  rationale?: NonEmptyTrimmedString
  links?: HttpUrlString[]
  assigned_by_metadata?: null | AssignedByMetadataInput
  verified?: boolean
  verified_at?: null | DateTimeString
  verified_by?: null | string
}
export type ToolFieldsMetadataInput = {
  custom?: {
    [key: string]: FieldMetadata
  }
  privacy_policy?: FieldMetadata
  terms_of_service?: FieldMetadata
  customer_security_impact_level?: FieldMetadata
  is_critical?: FieldMetadata
  is_subprocessor?: FieldMetadata
  subprocessors?: FieldMetadata
  soc2?: FieldMetadata
  gdpr?: FieldMetadata
  hipaa?: FieldMetadata
  iso_27001?: FieldMetadata
  pci_dss?: FieldMetadata
  ccpa?: FieldMetadata
  cdpa?: FieldMetadata
  cmmc_2?: FieldMetadata
  cpa?: FieldMetadata
  cpra?: FieldMetadata
  ctdpa?: FieldMetadata
  eu_whistleblower?: FieldMetadata
  nevada_privacy_law?: FieldMetadata
  sox?: FieldMetadata
  ucpa?: FieldMetadata
  sea_rule_17a4f?: FieldMetadata
  worm?: FieldMetadata
  cybersecurity_policy?: FieldMetadata
  information_security_policy?: FieldMetadata
  aml_policy?: FieldMetadata
  financial_stability?: FieldMetadata
  dpa?: FieldMetadata
  stores_pii?: FieldMetadata
  pii_data_types?: FieldMetadata
  pii_person_types?: FieldMetadata
  pii_regions?: FieldMetadata
  data_stored?: FieldMetadata
  sso_types?: FieldMetadata
  mfa_types?: FieldMetadata
  access_control_types?: FieldMetadata
  deployment_types?: FieldMetadata
  scim?: FieldMetadata
  integrates_with?: FieldMetadata
  overview?: FieldMetadata
  selection_rationale?: FieldMetadata
  risks_limitations?: FieldMetadata
  roi_analysis?: FieldMetadata
  implementation_details?: FieldMetadata
  support_from_vendor?: FieldMetadata
  tools_considered?: FieldMetadata
  owner?: FieldMetadata
  status?: FieldMetadata
  finance_status?: FieldMetadata
  compliance_status?: FieldMetadata
  it_status?: FieldMetadata
}
export type ToolPatch = {
  id: string
  object_type: "Tool"
  /** The overall approval status of the tool */
  status?: ToolStatus
  /** The financial approval status of the tool */
  finance_status?: ToolFinanceStatus
  /** The compliance approval status of the tool */
  compliance_status?: ToolComplianceStatus
  /** The IT approval status of the tool */
  it_status?: ToolItStatus
  hidden?: boolean
  custom?: CustomFields
  /** Lists the specific Single Sign-On (SSO) methods the software supports */
  sso_types?: null | SsoType[]
  /** Lists the specific Multi-Factor Authentication methods the software supports */
  mfa_types?: null | MfaType[]
  /** Describes the specific methods used by the software to regulate data access */
  access_control_types?: null | AccessControlType[]
  /** Indicates how the software is deployed and accessed */
  deployment_types?: null | SoftwareDeploymentType[]
  /** Determines if the software provides System for Cross-domain Identity Management functionality */
  scim?: null | boolean
  /** Which other tools does this product integrate with? */
  integrates_with?: null | ToolListingPatch[]
  /** URL or Document containing the service’s privacy policy */
  privacy_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service’s terms of service */
  terms_of_service?: null | DocumentOrUrlString
  /** Risk level can be categorized as:
    
    - **High**: Vendor handles sensitive data or security services; critical business impact if they fail.
    - **Moderate**: Vendor doesn’t handle sensitive data or security; moderate business impact if they fail.
    - **Low**: Vendor has minimal involvement and little to no business impact if they fail.
    - **None**: Vendor has no impact on business operations. */
  customer_security_impact_level?: null | CustomerSecurityImpact
  /** A critical vendor has one or more of the following characteristics:
    
    1) Handles customer data,
    2) Is responsible for our infrastructure or code security,
    3) Has cloud services that affect our system’s security or availability,
    4) Whose failure would result in disruption to our service availability. */
  is_critical?: null | boolean
  /** Whether the product or service is a data subprocessor */
  is_subprocessor?: null | boolean
  /** Link to a vendor’s list of third-party subprocessors */
  subprocessors?: null | HttpUrlString
  /** Whether the product or service is Securities Exchange Act Rule 17a-4(f) compliant */
  sea_rule_17a4f?: null | boolean
  /** URL or Document containing the service's cybersecurity policy */
  cybersecurity_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's information security policy */
  information_security_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's Anti-Money Laundering policy */
  aml_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's financial stability evidence */
  financial_stability?: null | DocumentOrUrlString
  /** Whether the product or service stores personal identifying information */
  stores_pii?: null | boolean
  /** The types of personal identifiable information the product or service collects or retains */
  pii_data_types?: null | PiiDataType[]
  /** The types of people whose personal identifiable information the product or service collects or retains */
  pii_person_types?: null | PiiPersonType[]
  /** The regions whose personal identifiable information the product or service collects or retains */
  pii_regions?: null | PiiRegion[]
  /** The types of data the product or service collects or retains */
  data_stored?: null | ComplianceData[]
  /** Whether the product or service has SOC 2 compliance certification */
  soc2?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Health Insurance Portability and Accountability Act compliance certification */
  hipaa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has a General Data Protection Regulation compliance certification */
  gdpr?: null | CompliantWithDocumentPatch
  /** Whether the product or service has ISO 27001 compliance certification */
  iso_27001?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Payment Card Industry Data Security Standard compliance certification */
  pci_dss?: null | CompliantWithDocumentPatch
  /** Whether the product or service has California Consumer Privacy Act compliance certification */
  ccpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Colorado Privacy Act compliance certification */
  cdpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Cybersecurity Maturity Model Certification 2.0 compliance certification */
  cmmc_2?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Colorado Privacy Act compliance certification */
  cpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has California Privacy Rights Act compliance certification */
  cpra?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Connecticut Data Protection Act compliance certification */
  ctdpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has EU Whistleblower compliance certification */
  eu_whistleblower?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Nevada Privacy Law compliance certification */
  nevada_privacy_law?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Sarbanes-Oxley Act compliance certification */
  sox?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Uniform Consumer Credit Protection Act compliance certification */
  ucpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service stores data in write-once, read-many (WORM) format */
  worm?: null | ApplicableWithDocumentPatch
  /** The Data Processing Agreement for a tool */
  dpa?: null | DocumentMinimal
  /** What problem(s) will this tool solve and how? */
  overview?: null | RichText
  /** Why are you choosing this product over alternative tools and services? */
  selection_rationale?: null | RichText
  /** What are the areas of concern or gaps this tool has? */
  risks_limitations?: null | RichText
  /** What’s the estimated return on investment (ROI) for this tool? */
  roi_analysis?: null | RichText
  /** How long will it take to implement and fully deploy? */
  implementation_details?: null | RichText
  /** What level of support is provided by the vendor for the selected plan? */
  support_from_vendor?: null | RichText
  /** What other tools and services were considered during the evaluation of this tool? */
  tools_considered?: ToolListingPatch[]
  fields_metadata?: ToolFieldsMetadataInput
  owner?: null | {
    id: string
  }
}
export type ListingComplianceDocument = {
  /** Whether the tool is compliant */
  compliant: null | boolean
}
export type LegalClausesExtractedSource = {
  arbitration?: PageSource
  assignment?: PageSource
  confidentiality?: PageSource
  data_privacy?: PageSource
  data_processing?: PageSource
  data_protection?: PageSource
  dispute_resolution?: PageSource
  force_majeure?: PageSource
  governing_law?: PageSource
  indemnification?: PageSource
  intellectual_property?: PageSource
  license?: PageSource
  limitation_of_liability?: PageSource
  non_disclosure?: PageSource
  non_solicitation?: PageSource
  representation?: PageSource
  termination?: PageSource
  warranty?: PageSource
}
export type ExtractedLegalAgreementFieldSources = {
  total_contract_value?: PageSource
  start_date?: PageSource
  end_date?: PageSource
  first_invoice_date?: PageSource
  invoice_interval?: PageSource
  net_payment_term?: PageSource
  auto_renews?: PageSource
  auto_renewal_opt_out_period?: PageSource
  decision_date?: PageSource
  is_signed?: PageSource
  buyer_signer_name?: PageSource
  buyer_signer_title?: PageSource
  vendor_signer_name?: PageSource
  vendor_signer_title?: PageSource
  clauses?: LegalClausesExtractedSource
  custom?: {
    [key: string]: PageSource
  }
}
export type ExtractedToolFieldSources = {
  privacy_policy?: PageSource
  terms_of_service?: PageSource
  customer_security_impact_level?: PageSource
  is_critical?: PageSource
  is_subprocessor?: PageSource
  subprocessors?: PageSource
  soc2?: PageSource
  gdpr?: PageSource
  hipaa?: PageSource
  iso_27001?: PageSource
  pci_dss?: PageSource
  ccpa?: PageSource
  cdpa?: PageSource
  cmmc_2?: PageSource
  cpa?: PageSource
  cpra?: PageSource
  ctdpa?: PageSource
  eu_whistleblower?: PageSource
  nevada_privacy_law?: PageSource
  sox?: PageSource
  ucpa?: PageSource
  sea_rule_17a4f?: PageSource
  worm?: PageSource
  cybersecurity_policy?: PageSource
  information_security_policy?: PageSource
  aml_policy?: PageSource
  financial_stability?: PageSource
  dpa?: PageSource
  stores_pii?: PageSource
  pii_data_types?: PageSource
  pii_person_types?: PageSource
  pii_regions?: PageSource
  data_stored?: PageSource
  sso_types?: PageSource
  mfa_types?: PageSource
  access_control_types?: PageSource
  deployment_types?: PageSource
  scim?: PageSource
  overview?: PageSource
  selection_rationale?: PageSource
  risks_limitations?: PageSource
  roi_analysis?: PageSource
  implementation_details?: PageSource
  support_from_vendor?: PageSource
  custom?: {
    [key: string]: PageSource
  }
}
export type ExtractedVendorFieldSources = {
  problems_addressed?: PageSource
  selection_rationale?: PageSource
  roi_analysis?: PageSource
  founded_year?: PageSource
  is_broker_dealer?: PageSource
  ein?: PageSource
  duns?: PageSource
  cybersecurity_breaches?: PageSource
  litigations?: PageSource
  custom?: {
    [key: string]: PageSource
  }
}
export type ExtractedReceiptFieldsSources = {
  is_recurring_subscription?: PageSource
  invoice_interval?: PageSource
  service_start_date?: PageSource
  service_end_date?: PageSource
}
export type ExtractedSorFieldSources = {
  domain?: PageSource
  tools?: PageSource
  vendor?: PageSource
}
export type DocumentExtractionType =
  | "sor_entities"
  | "legal_agreement_fields"
  | "receipt_fields"
  | "vendor_fields"
  | "tool_fields"
export type DocumentExtractionJob = {
  id: string
  extracted_at: null | DateTimeString
  extraction_type: DocumentExtractionType
  /** An array of field path strings that are part of this field extraction job. If it is null, then all fields are extracted */
  field_path_strings: null | string[]
}
export type DocumentClassification = "vendor_contract" | "w9" | "nda" | "dpa" | "soc2" | "other"
export type DocumentWithExtraction = {
  id: string
  object_type: "Document"
  created_at: DateTimeString
  updated_at?: null | DateTimeString
  /** The original file basename as uploaded by the user. */
  file_name: null | NonEmptyTrimmedString
  status: "pending" | "uploaded" | "scanned" | "quarantined"
  extraction_status?: null | DocumentExtractionStatus
  /** The file size in bytes */
  file_size: null | number
  /** MIME type like `application/pdf` or `text/plain; charset=utf-8` */
  mime_type: null | NonEmptyTrimmedString
  /** Users who have email integrations which have found this document */
  user_email_integrations?: UserWithProvider[]
  legal_agreement_extraction_result?: null | {
    sources?: null | ExtractedLegalAgreementFieldSources
  }
  tool_extraction_result?: null | {
    sources?: null | ExtractedToolFieldSources
  }
  vendor_extraction_result?: null | {
    sources?: null | ExtractedVendorFieldSources
  }
  receipt_extraction_result?: null | {
    sources?: null | ExtractedReceiptFieldsSources
  }
  sor_extraction_result?: null | {
    sources?: null | ExtractedSorFieldSources
  }
  summary?: null | NonEmptyTrimmedString
  associated_at?: null | DateTimeString
  extraction_jobs?: null | DocumentExtractionJob[]
  document_classification?: null | DocumentClassification
}
export type WorkflowKind =
  | "vendor_purchase"
  | "software_purchase"
  | "vendor_renewal"
  | "software_renewal"
  | "gather_data"
export type WorkflowRunMinimal = {
  id: string
  object_type: "WorkflowRun"
  /** The name of the request */
  display_name: NonEmptyTrimmedProseString
  created_at: DateTimeString
  /** The type of request e.g. purchase, renewal, or gather data */
  kind: WorkflowKind
  status: WorkflowRunStatus
  /** The date and time that the request was completed */
  completed_at: null | DateTimeString
  /** The target date by which this request should be completed */
  target_date: DateString
}
export type LegalAgreementSource = "workflow_run" | "email" | "manual" | "transaction"
export type LegalAgreementListItem = {
  /** The total cost of the contract to the buyer over the entire period of the contract, or `null` if unknown. */
  total_contract_value?: null | CurrencyAmount
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date: null | DateString
  /** The first date at which the buyer will be billed by the seller as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  first_invoice_date?: null | DateString
  /** Will the vendor invoice {buyer} monthly, quarterly, or annually? Look for a specific mention of when the buyer is invoiced. */
  invoice_interval?: null | FrequencyOrOneTime
  /** The amount of time that the buyer has to settle each bill, e.g. P30D for 30 days. `null` if unknown. Payment terms that are due upon receipt should return PT0S. */
  net_payment_term?: null | DateDurationString
  /** Whether the contract term automatically renews; true/false. */
  auto_renews?: null | boolean
  /** The period the buyer has to give notice to terminate the contract before the end of the current term. The period should be an ISO 8601 duration string (e.g., P30D for 30 days). */
  auto_renewal_opt_out_period?: null | DateDurationString
  /** The date when the buyer must decide whether to renew or cancel the contract. Renewal reminders are sent based on this date. */
  decision_date?: null | DateString
  /** Whether the contract was signed or is still unsigned */
  is_signed: null | boolean
  /** The full name of the person who signed for the buying party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  buyer_signer_name?: null | NonEmptyTrimmedProseString
  /** The buying signer’s title (e.g., CEO). */
  buyer_signer_title?: null | NonEmptyTrimmedProseString
  /** The full name of the person who signed for the selling party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  vendor_signer_name?: null | NonEmptyTrimmedProseString
  /** The selling signer’s title (e.g., CEO). */
  vendor_signer_title?: null | NonEmptyTrimmedProseString
  /** The legal clauses found in the contract. */
  clauses?: LegalClauses
  custom: CustomFields
  id: string
  object_type: "LegalAgreement"
  /** Whether the Legal Agreement is active, expired, or upcoming. Will be unknown if the agreement has not been verified or is missing start/end dates. */
  effective_status: AgreementEffectiveStatus
  /** The type of agreement (enterprise or subscription) */
  agreement_type: AgreementType
  /** The name of the agreement. */
  display_name: NonEmptyTrimmedProseString
  usd_total_contract_value?: null | NumericString
  /** The next occurrence of the billing interval (based on the first invoice date) */
  next_invoice_date?: null | DateString
  created_at: DateTimeString
  updated_at?: null | DateTimeString
  fields_metadata: LegalAgreementFieldsMetadata
  /** Legal agreements that enter through certain sources, like bulk upload or email, need to have their extracted fields verified. Manually created agreements always start verified. Verified agreements may become unverified when more documents are added. */
  verification_status: AgreementVerificationStatus
  visibility_status: AgreementVisibilityStatus
  /** Any documents related to this requests. Documents can include an Order Form, Statement of Work, a Quote, Master Services Agreements, etc. */
  documents: DocumentWithExtraction[]
  /** The vendor that the legal agreement is associated with */
  vendor: null | VendorMinimal
  /** The tools purchased with the legal agreement */
  tools: ToolMinimal[]
  /** The renewal started using this legal agreement as its 'active legal agreement' */
  renewal_successor?: null | WorkflowRunMinimal
  /** The user who added the legal agreement */
  created_by: null | User
  /** The cumulative list of sources which have provided information to this agreement */
  source: LegalAgreementSource[]
  /** Links to the purchase or renewal request if the agreement was created from one */
  workflow_run_source: null | WorkflowRunMinimal
  /** Email integrations that have found documents related to this agreement. */
  email_source: UserWithProvider[]
  /** The number of documents associated with this agreement */
  document_count: number
  /** The spend for this vendor */
  vendor_spend?: null | AggregatedSpend
}
export type CreditCardInterval = "daily" | "monthly" | "quarterly" | "tertiary" | "total" | "weekly" | "yearly"
export type CreditCardTaskStatus = "creating" | "locking" | "success" | "creation_failed" | "lock_failed"
export type CreditCardStatus = "active" | "shipped" | "terminated" | "locked" | "deleted"
export type ExtractedReceiptFields = {
  /** Indicates whether the receipt is a recurring subscription. Return false if not mentioned. */
  is_recurring_subscription?: null | boolean
  /** The frequency at which the customer is invoiced or charged, expressed as an ISO 8601 duration without a time component. Examples include 'P1M' for monthly or 'P1Y' for annual intervals. */
  invoice_interval?: null | DateDurationString
  /** The start of the range that the service was provided/the customer is being charged for, if mentioned in the invoice/receipt. */
  service_start_date?: null | DateString
  /** The end of the range that the service was provided/the customer is being charged for, if mentioned in the invoice/receipt. */
  service_end_date?: null | DateString
}
export type ReceiptExtractionResult = {
  fields?: null | ExtractedReceiptFields
  sources?: null | ExtractedReceiptFieldsSources
}
export type Receipt = {
  id: string
  object_type: "Receipt"
  document: {
    id: string
    object_type: "Document"
    created_at: DateTimeString
    updated_at?: null | DateTimeString
    /** The original file basename as uploaded by the user. */
    file_name: null | NonEmptyTrimmedString
    status: "pending" | "uploaded" | "scanned" | "quarantined"
    extraction_status?: null | DocumentExtractionStatus
    /** The file size in bytes */
    file_size: null | number
    /** MIME type like `application/pdf` or `text/plain; charset=utf-8` */
    mime_type: null | NonEmptyTrimmedString
    /** Users who have email integrations which have found this document */
    user_email_integrations?: UserWithProvider[]
    receipt_extraction_result?: null | ReceiptExtractionResult
    associated_at?: null | DateTimeString
  }
}
export type TransactionCategory =
  | "advertising"
  | "airlines"
  | "alcohol_and_bars"
  | "books_and_newspapers"
  | "car_rental"
  | "car_services"
  | "charitable_donations"
  | "clothing"
  | "clubs_and_memberships"
  | "education"
  | "electronics"
  | "entertainment"
  | "fees_and_financial_institutions"
  | "fines"
  | "freight_moving_and_delivery_services"
  | "fuel_and_gas"
  | "gambling"
  | "general_merchandise"
  | "government_services"
  | "insurance"
  | "internet_and_phone"
  | "legal"
  | "lodging"
  | "medical"
  | "office"
  | "office_supplies_and_cleaning"
  | "other"
  | "parking"
  | "pet"
  | "political_organizations"
  | "professional_services"
  | "religious_organizations"
  | "restaurants"
  | "saas_software"
  | "shipping"
  | "streaming_services"
  | "supermarkets_and_grocery_stores"
  | "taxes_and_tax_preparation"
  | "taxi_and_rideshare"
  | "travel"
  | "utilities"
export type MergeIntegrationHrisType =
  | "bamboohr"
  | "justworks"
  | "gusto"
  | "rippling"
  | "hibob"
  | "workday"
  | "paylocity"
  | "ukg-pro"
  | "ukg-ready"
  | "ukg-pro-workforce-management"
  | "adp-workforce-now"
  | "deel"
  | "namely"
  | "zenefits"
  | "charthop"
export type MergeIntegrationAccountingType = "netsuite" | "quickbooks-online" | "sage-intacct" | "xero"
export type IntegrationPublicFields = {
  id: string
  object_type: "Integration"
  organization_id: string
  created_at: DateTimeString
  display_name: NonEmptyTrimmedProseString
  provider: IntegrationProvider
  external_id: null | NonEmptyString
  merge_hris_type: null | MergeIntegrationHrisType
  merge_accounting_type: null | MergeIntegrationAccountingType
  logo: null | NonEmptyString
  email: null | NonEmptyString
  scope: null | NonEmptyString
}
export type TransactionMinimal = {
  object_type: "ReconciledTransaction"
  id: string
  organization_id: string
  created_at: DateTimeString
  currency_amount: CurrencyAmount
  transacted_at: DateTimeString
  merchant_identification: NonEmptyTrimmedString
  merchant_name: NonEmptyString
  memo: null | NonEmptyTrimmedString
  person: null | Person
  vendor: VendorMinimal
  tool: null | ToolMinimal
  receipts: Receipt[]
  transaction_category: null | TransactionCategory
  payment_method: null | TransactionPaymentMethod
  integrations: IntegrationPublicFields[]
}
export type CreditCardWithVendors = {
  id: string
  created_at: DateTimeString
  display_name?: null | NonEmptyTrimmedProseString
  spend_limit?: null | CurrencyAmount
  spend_limit_interval?: null | CreditCardInterval
  last_four?: null | NonEmptyString
  card_task_status?: null | CreditCardTaskStatus
  external_card_id?: null | string
  external_limit_id?: null | string
  status?: null | CreditCardStatus
  object_type: "CreditCard"
  owner?: null | Person
  /** The earlier date between the expiration or auto lock date */
  valid_until?: null | DateString
  auto_lock_date?: null | DateString
  vendors?: null | VendorMinimal[]
  last_transaction?: null | TransactionMinimal
}
export type WorkflowFieldsMetadata = {
  [key: string]:
    | {
        [key: string]: FieldMetadataWithSuggestions
      }
    | FieldMetadataWithSuggestions
}
export type VendorStatus = "approved" | "in_review" | "unknown" | "canceling" | "churned" | "rejected"
export type VendorFinanceStatus = "approved" | "in_review" | "unknown" | "rejected"
export type VendorComplianceStatus = "approved" | "in_review" | "unknown" | "rejected"
export type VendorItStatus = "approved" | "in_review" | "unknown" | "rejected"
export type EmploymentIdentificationNumber = string
export type DunsNumber = string
export type BooleanWithRichTextDetails = {
  /** Whether the field applies to this vendor */
  value: null | boolean
  /** Any related details supporting the value of the field. This is a rich text property. */
  details: null | RichText
}
export type VendorFieldsMetadataOutput = {
  custom?: {
    [key: string]: FieldMetadataWithSuggestions
  }
  problems_addressed?: FieldMetadataWithSuggestions
  selection_rationale?: FieldMetadataWithSuggestions
  roi_analysis?: FieldMetadataWithSuggestions
  founded_year?: FieldMetadataWithSuggestions
  is_broker_dealer?: FieldMetadataWithSuggestions
  ein?: FieldMetadataWithSuggestions
  duns?: FieldMetadataWithSuggestions
  cybersecurity_breaches?: FieldMetadataWithSuggestions
  litigations?: FieldMetadataWithSuggestions
  nda?: FieldMetadataWithSuggestions
  bank_info?: FieldMetadataWithSuggestions
  w9?: FieldMetadataWithSuggestions
  owner?: FieldMetadataWithSuggestions
  status?: FieldMetadataWithSuggestions
  finance_status?: FieldMetadataWithSuggestions
  compliance_status?: FieldMetadataWithSuggestions
  it_status?: FieldMetadataWithSuggestions
}
export type Vendor = {
  /** The company name of the vendor */
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAssetMinimal
  /** The description of the vendor */
  description: null | NonEmptyTrimmedProseString
  /** The URL of the vendor’s website */
  website: null | HttpUrlString
  id: string
  object_type: "Vendor"
  vendor_listing_id: string
  created_at: DateTimeString
  /** The overall approval status of the vendor */
  status: VendorStatus
  /** The financial approval status of the vendor */
  finance_status?: VendorFinanceStatus
  /** The compliance approval status of the vendor */
  compliance_status?: VendorComplianceStatus
  /** The IT approval status of the vendor */
  it_status?: VendorItStatus
  /** The person in the organization responsible for the vendor */
  owner: null | Person
  /** What problem(s) will this vendor solve and how? */
  problems_addressed: null | RichText
  /** Why are you choosing this vendor over alternative vendors? */
  selection_rationale: null | RichText
  /** What’s the estimated return on investment (ROI) for this vendor? */
  roi_analysis: null | RichText
  /** The year the company was founded */
  founded_year?: null | number
  /** Is the vendor a broker dealer? */
  is_broker_dealer?: null | boolean
  /** The unique number assigned to a business entity by the Internal Revenue Service (IRS). Used for tax filing and reporting purposes */
  ein?: null | EmploymentIdentificationNumber
  /** A nine-digit number assigned by Dun & Bradstreet for business identification and credit reporting */
  duns?: null | DunsNumber
  /** Whether the company has experienced any cybersecurity breaches and any related details */
  cybersecurity_breaches?: null | BooleanWithRichTextDetails
  /** Whether the company has any ongoing or pending litigation and any related details */
  litigations?: null | BooleanWithRichTextDetails
  /** The Non-Disclosure Agreement document for the vendor */
  nda?: null | DocumentMinimal
  /** The vendor's bank information */
  bank_info?: null | DocumentMinimal
  /** The vendor's W-9 */
  w9?: null | DocumentMinimal
  fields_metadata?: VendorFieldsMetadataOutput
  hidden?: boolean
  organization_id: string
  custom: CustomFields
}
export type Tool3 = {
  /** The name of the tool */
  display_name: NonEmptyTrimmedString
  /** The description of the tool (what the tool does) */
  description: null | NonEmptyTrimmedProseString
  /** The URL of the tool's website */
  website: null | HttpUrlString
  /** The image asset for the tool's logo */
  image_asset: null | ImageAssetMinimal
  id: string
  object_type: "Tool"
  tool_listing_id: string
  created_at: DateTimeString
  updated_at?: null | DateTimeString
  /** The overall approval status of the tool */
  status: ToolStatus
  /** The financial approval status of the tool */
  finance_status?: ToolFinanceStatus
  /** The compliance approval status of the tool */
  compliance_status?: ToolComplianceStatus
  /** The IT approval status of the tool */
  it_status?: ToolItStatus
  /** The person in the organization responsible for the tool */
  owner: null | Person
  fields_metadata?: ToolFieldsMetadataOutput
  organization_id: string
  /** The tool categories that the tool falls into */
  categories: null | ToolCategory[]
  hidden?: boolean
  custom: CustomFields
  /** URL or Document containing the service’s privacy policy */
  privacy_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service’s terms of service */
  terms_of_service?: null | DocumentOrUrlString
  /** Risk level can be categorized as:
    
    - **High**: Vendor handles sensitive data or security services; critical business impact if they fail.
    - **Moderate**: Vendor doesn’t handle sensitive data or security; moderate business impact if they fail.
    - **Low**: Vendor has minimal involvement and little to no business impact if they fail.
    - **None**: Vendor has no impact on business operations. */
  customer_security_impact_level?: null | CustomerSecurityImpact
  /** A critical vendor has one or more of the following characteristics:
    
    1) Handles customer data,
    2) Is responsible for our infrastructure or code security,
    3) Has cloud services that affect our system’s security or availability,
    4) Whose failure would result in disruption to our service availability. */
  is_critical?: null | boolean
  /** Whether the product or service is a data subprocessor */
  is_subprocessor?: null | boolean
  /** Link to a vendor’s list of third-party subprocessors */
  subprocessors?: null | HttpUrlString
  /** Whether the product or service has SOC 2 compliance certification */
  soc2?: null | CompliantWithDocument
  /** Whether the product or service has a General Data Protection Regulation compliance certification */
  gdpr?: null | CompliantWithDocument
  /** Whether the product or service has Health Insurance Portability and Accountability Act compliance certification */
  hipaa?: null | CompliantWithDocument
  /** Whether the product or service has ISO 27001 compliance certification */
  iso_27001?: null | CompliantWithDocument
  /** Whether the product or service has Payment Card Industry Data Security Standard compliance certification */
  pci_dss?: null | CompliantWithDocument
  /** Whether the product or service has California Consumer Privacy Act compliance certification */
  ccpa?: null | CompliantWithDocument
  /** Whether the product or service has Colorado Privacy Act compliance certification */
  cdpa?: null | CompliantWithDocument
  /** Whether the product or service has Cybersecurity Maturity Model Certification 2.0 compliance certification */
  cmmc_2?: null | CompliantWithDocument
  /** Whether the product or service has Colorado Privacy Act compliance certification */
  cpa?: null | CompliantWithDocument
  /** Whether the product or service has California Privacy Rights Act compliance certification */
  cpra?: null | CompliantWithDocument
  /** Whether the product or service has Connecticut Data Protection Act compliance certification */
  ctdpa?: null | CompliantWithDocument
  /** Whether the product or service has EU Whistleblower compliance certification */
  eu_whistleblower?: null | CompliantWithDocument
  /** Whether the product or service has Nevada Privacy Law compliance certification */
  nevada_privacy_law?: null | CompliantWithDocument
  /** Whether the product or service has Sarbanes-Oxley Act compliance certification */
  sox?: null | CompliantWithDocument
  /** Whether the product or service has Uniform Consumer Credit Protection Act compliance certification */
  ucpa?: null | CompliantWithDocument
  /** Whether the product or service is Securities Exchange Act Rule 17a-4(f) compliant */
  sea_rule_17a4f?: null | boolean
  /** Whether the product or service stores data in write-once, read-many (WORM) format */
  worm?: null | ApplicableWithDocument
  /** URL or Document containing the service's cybersecurity policy */
  cybersecurity_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's information security policy */
  information_security_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's Anti-Money Laundering policy */
  aml_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's financial stability evidence */
  financial_stability?: null | DocumentOrUrlString
  /** The Data Processing Agreement for a tool */
  dpa?: null | DocumentMinimal
  /** Whether the product or service stores personal identifying information */
  stores_pii?: null | boolean
  /** The types of personal identifiable information the product or service collects or retains */
  pii_data_types?: null | PiiDataType[]
  /** The types of people whose personal identifiable information the product or service collects or retains */
  pii_person_types?: null | PiiPersonType[]
  /** The regions whose personal identifiable information the product or service collects or retains */
  pii_regions?: null | PiiRegion[]
  /** The types of data the product or service collects or retains */
  data_stored?: null | ComplianceData[]
  /** Lists the specific Single Sign-On (SSO) methods the software supports */
  sso_types?: null | SsoType[]
  /** Lists the specific Multi-Factor Authentication methods the software supports */
  mfa_types?: null | MfaType[]
  /** Describes the specific methods used by the software to regulate data access */
  access_control_types?: null | AccessControlType[]
  /** Indicates how the software is deployed and accessed */
  deployment_types?: null | SoftwareDeploymentType[]
  /** Determines if the software provides System for Cross-domain Identity Management functionality */
  scim?: null | boolean
  /** Which other tools does this product integrate with? */
  integrates_with?: null | ToolListing[]
  /** What problem(s) will this tool solve and how? */
  overview: null | RichText
  /** Why are you choosing this product over alternative tools and services? */
  selection_rationale: null | RichText
  /** What are the areas of concern or gaps this tool has? */
  risks_limitations: null | RichText
  /** What’s the estimated return on investment (ROI) for this tool? */
  roi_analysis: null | RichText
  /** How long will it take to implement and fully deploy? */
  implementation_details: null | RichText
  /** What level of support is provided by the vendor for the selected plan? */
  support_from_vendor: null | RichText
  /** What other tools and services were considered during the evaluation of this tool? */
  tools_considered: ToolListing[]
}
export type WorkflowRunProgress = {
  status: WorkflowRunStatus
  approvals_required: number
  approvals_given: number
}
export type Request = {
  id: string
  object_type: "WorkflowRun"
  /** The name of the request */
  display_name: NonEmptyTrimmedProseString
  created_at: DateTimeString
  /** The type of request e.g. purchase, renewal, or gather data */
  kind: WorkflowKind
  status: WorkflowRunStatus
  /** The date and time that the request was completed */
  completed_at: null | DateTimeString
  /** The target date by which this request should be completed */
  target_date: DateString
  /** The amount of money being requested for this request */
  requested_spend: null | CurrencyAmount
  /** How often the requested spend amount will be charged (e.g. monthly, annually, or one-time) */
  requested_spend_frequency: null | FrequencyOrOneTime
  /** The number of payments requested for this request */
  requested_payments_count: null | number
  /** The credit card that has been assigned to this request for payment */
  assigned_credit_card: null | CreditCardWithVendors
  /** The method that will be used to pay for this request */
  payment_method: null | TransactionPaymentMethod
  custom: CustomFields
  fields_metadata?: WorkflowFieldsMetadata
  /** The user who created this request */
  created_by: User
  /** The user who owns and is responsible for this request */
  owner: User
  /** The users who are currently assigned to take action on this request */
  assigned_to: User[]
  /** The departments that this request is associated with */
  employee_groups: EmployeeGroup[]
  /** The vendor that the request is for. Note: Since requests do not directly connect to vendors, the vendor comes from the purchase or renewal's new_legal_agreement. */
  vendor: null | Vendor
  /** The tools that the request is for. Note: Since requests do not directly connect to tools, the tools come from the purchase or renewal's new_legal_agreement. */
  tools: Tool3[]
  progress: WorkflowRunProgress
}
export type OktaUsersCount = {
  l30d: number
  l60d: number
  l90d: number
  total: number
}
export type Scope = {
  scope: NonEmptyString
  description: null | NonEmptyTrimmedProseString
  provider: NonEmptyString
  resource_group: null | NonEmptyString
  risk_score: number
}
export type DocumentListItem = {
  id: string
  object_type: "Document"
  created_at: DateTimeString
  updated_at?: null | DateTimeString
  /** The original file basename as uploaded by the user. */
  file_name: null | NonEmptyTrimmedString
  status: "pending" | "uploaded" | "scanned" | "quarantined"
  extraction_status?: null | DocumentExtractionStatus
  /** The file size in bytes */
  file_size: null | number
  /** MIME type like `application/pdf` or `text/plain; charset=utf-8` */
  mime_type: null | NonEmptyTrimmedString
  /** Users who have email integrations which have found this document */
  user_email_integrations?: UserWithProvider[]
  intent_finalized_at: null | DateTimeString
  relation_created_at: null | DateTimeString
  uploaded_by: null | User
  field_category: null | string
  field_name: null | string
}
export type Tool2 = {
  /** The name of the tool */
  display_name: NonEmptyTrimmedString
  /** The description of the tool (what the tool does) */
  description: null | NonEmptyTrimmedProseString
  /** The URL of the tool's website */
  website: null | HttpUrlString
  /** The image asset for the tool's logo */
  image_asset: null | ImageAssetMinimal
  id: string
  object_type: "Tool"
  tool_listing_id: string
  created_at: DateTimeString
  updated_at?: null | DateTimeString
  /** The overall approval status of the tool */
  status: ToolStatus
  /** The financial approval status of the tool */
  finance_status?: ToolFinanceStatus
  /** The compliance approval status of the tool */
  compliance_status?: ToolComplianceStatus
  /** The IT approval status of the tool */
  it_status?: ToolItStatus
  /** The person in the organization responsible for the tool */
  owner: null | Person
  fields_metadata?: ToolFieldsMetadataOutput
  organization_id: string
  /** The tool categories that the tool falls into */
  categories: null | ToolCategory[]
  hidden?: boolean
  custom: CustomFields
  /** URL or Document containing the service’s privacy policy */
  privacy_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service’s terms of service */
  terms_of_service?: null | DocumentOrUrlString
  /** Risk level can be categorized as:
    
    - **High**: Vendor handles sensitive data or security services; critical business impact if they fail.
    - **Moderate**: Vendor doesn’t handle sensitive data or security; moderate business impact if they fail.
    - **Low**: Vendor has minimal involvement and little to no business impact if they fail.
    - **None**: Vendor has no impact on business operations. */
  customer_security_impact_level?: null | CustomerSecurityImpact
  /** A critical vendor has one or more of the following characteristics:
    
    1) Handles customer data,
    2) Is responsible for our infrastructure or code security,
    3) Has cloud services that affect our system’s security or availability,
    4) Whose failure would result in disruption to our service availability. */
  is_critical?: null | boolean
  /** Whether the product or service is a data subprocessor */
  is_subprocessor?: null | boolean
  /** Link to a vendor’s list of third-party subprocessors */
  subprocessors?: null | HttpUrlString
  /** Whether the product or service has SOC 2 compliance certification */
  soc2?: null | CompliantWithDocument
  /** Whether the product or service has a General Data Protection Regulation compliance certification */
  gdpr?: null | CompliantWithDocument
  /** Whether the product or service has Health Insurance Portability and Accountability Act compliance certification */
  hipaa?: null | CompliantWithDocument
  /** Whether the product or service has ISO 27001 compliance certification */
  iso_27001?: null | CompliantWithDocument
  /** Whether the product or service has Payment Card Industry Data Security Standard compliance certification */
  pci_dss?: null | CompliantWithDocument
  /** Whether the product or service has California Consumer Privacy Act compliance certification */
  ccpa?: null | CompliantWithDocument
  /** Whether the product or service has Colorado Privacy Act compliance certification */
  cdpa?: null | CompliantWithDocument
  /** Whether the product or service has Cybersecurity Maturity Model Certification 2.0 compliance certification */
  cmmc_2?: null | CompliantWithDocument
  /** Whether the product or service has Colorado Privacy Act compliance certification */
  cpa?: null | CompliantWithDocument
  /** Whether the product or service has California Privacy Rights Act compliance certification */
  cpra?: null | CompliantWithDocument
  /** Whether the product or service has Connecticut Data Protection Act compliance certification */
  ctdpa?: null | CompliantWithDocument
  /** Whether the product or service has EU Whistleblower compliance certification */
  eu_whistleblower?: null | CompliantWithDocument
  /** Whether the product or service has Nevada Privacy Law compliance certification */
  nevada_privacy_law?: null | CompliantWithDocument
  /** Whether the product or service has Sarbanes-Oxley Act compliance certification */
  sox?: null | CompliantWithDocument
  /** Whether the product or service has Uniform Consumer Credit Protection Act compliance certification */
  ucpa?: null | CompliantWithDocument
  /** Whether the product or service is Securities Exchange Act Rule 17a-4(f) compliant */
  sea_rule_17a4f?: null | boolean
  /** Whether the product or service stores data in write-once, read-many (WORM) format */
  worm?: null | ApplicableWithDocument
  /** URL or Document containing the service's cybersecurity policy */
  cybersecurity_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's information security policy */
  information_security_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's Anti-Money Laundering policy */
  aml_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's financial stability evidence */
  financial_stability?: null | DocumentOrUrlString
  /** The Data Processing Agreement for a tool */
  dpa?: null | DocumentMinimal
  /** Whether the product or service stores personal identifying information */
  stores_pii?: null | boolean
  /** The types of personal identifiable information the product or service collects or retains */
  pii_data_types?: null | PiiDataType[]
  /** The types of people whose personal identifiable information the product or service collects or retains */
  pii_person_types?: null | PiiPersonType[]
  /** The regions whose personal identifiable information the product or service collects or retains */
  pii_regions?: null | PiiRegion[]
  /** The types of data the product or service collects or retains */
  data_stored?: null | ComplianceData[]
  /** Lists the specific Single Sign-On (SSO) methods the software supports */
  sso_types?: null | SsoType[]
  /** Lists the specific Multi-Factor Authentication methods the software supports */
  mfa_types?: null | MfaType[]
  /** Describes the specific methods used by the software to regulate data access */
  access_control_types?: null | AccessControlType[]
  /** Indicates how the software is deployed and accessed */
  deployment_types?: null | SoftwareDeploymentType[]
  /** Determines if the software provides System for Cross-domain Identity Management functionality */
  scim?: null | boolean
  /** Which other tools does this product integrate with? */
  integrates_with?: null | ToolListing[]
  /** What problem(s) will this tool solve and how? */
  overview: null | RichText
  /** Why are you choosing this product over alternative tools and services? */
  selection_rationale: null | RichText
  /** What are the areas of concern or gaps this tool has? */
  risks_limitations: null | RichText
  /** What’s the estimated return on investment (ROI) for this tool? */
  roi_analysis: null | RichText
  /** How long will it take to implement and fully deploy? */
  implementation_details: null | RichText
  /** What level of support is provided by the vendor for the selected plan? */
  support_from_vendor: null | RichText
  /** What other tools and services were considered during the evaluation of this tool? */
  tools_considered: ToolListing[]
  /** The vendor offering the tool */
  vendor: VendorMinimal
  /** The number of users that have accessed the tool */
  total_people: null | number
  /** The department classification for the tool owner */
  owner_groups: EmployeeGroup[]
  /** The primary legal agreement with the tool’s vendor for the tool */
  primary_legal_agreement: null | LegalAgreementMinimal
  /** The number of active legal agreements on the tool */
  active_legal_agreement_count: number
  /** The aggregated spent on the tool/vendor over various time periods */
  spend?: AggregatedSpend
  /** The method used to pay (e.g. bill pay or credit card) */
  payment_methods?: null | TransactionPaymentMethod[]
  /** The department or class categorization for the tool from your accounting system */
  erp_tracking_categories: ErpTrackingCategoryGroups
  listing: {
    /** URL or Document containing the service’s privacy policy */
    privacy_policy?: null | DocumentOrUrlString
    /** URL or Document containing the service’s terms of service */
    terms_of_service?: null | DocumentOrUrlString
    /** Link to a vendor’s list of third-party subprocessors */
    subprocessors?: null | HttpUrlString
    /** Whether the product or service is Securities Exchange Act Rule 17a-4(f) compliant */
    sea_rule_17a4f?: null | boolean
    /** URL or Document containing the service's cybersecurity policy */
    cybersecurity_policy?: null | DocumentOrUrlString
    /** URL or Document containing the service's information security policy */
    information_security_policy?: null | DocumentOrUrlString
    /** URL or Document containing the service's Anti-Money Laundering policy */
    aml_policy?: null | DocumentOrUrlString
    /** URL or Document containing the service's financial stability evidence */
    financial_stability?: null | DocumentOrUrlString
    /** The Data Processing Agreement for a tool */
    dpa?: null | DocumentMinimal
    /** Whether the product or service stores personal identifying information */
    stores_pii?: null | boolean
    /** The types of personal identifiable information the product or service collects or retains */
    pii_data_types?: null | PiiDataType[]
    /** The types of people whose personal identifiable information the product or service collects or retains */
    pii_person_types?: null | PiiPersonType[]
    /** The regions whose personal identifiable information the product or service collects or retains */
    pii_regions?: null | PiiRegion[]
    /** The types of data the product or service collects or retains */
    data_stored?: null | ComplianceData[]
    soc2: null | ListingComplianceDocument
    gdpr: null | ListingComplianceDocument
    hipaa: null | ListingComplianceDocument
    iso_27001: null | ListingComplianceDocument
    pci_dss: null | ListingComplianceDocument
    ccpa: null | ListingComplianceDocument
    cdpa: null | ListingComplianceDocument
    cmmc_2: null | ListingComplianceDocument
    cpa: null | ListingComplianceDocument
    cpra: null | ListingComplianceDocument
    ctdpa: null | ListingComplianceDocument
    eu_whistleblower: null | ListingComplianceDocument
    nevada_privacy_law: null | ListingComplianceDocument
    sox: null | ListingComplianceDocument
    ucpa: null | ListingComplianceDocument
    worm: null | {
      /** Whether the standard is applicable */
      applicable: null | boolean
    }
    /** Lists the specific Single Sign-On (SSO) methods the software supports */
    sso_types?: null | SsoType[]
    /** Lists the specific Multi-Factor Authentication methods the software supports */
    mfa_types?: null | MfaType[]
    /** Describes the specific methods used by the software to regulate data access */
    access_control_types?: null | AccessControlType[]
    /** Indicates how the software is deployed and accessed */
    deployment_types?: null | SoftwareDeploymentType[]
    /** Determines if the software provides System for Cross-domain Identity Management functionality */
    scim?: null | boolean
    /** Which other tools does this product integrate with? */
    integrates_with?: null | ToolListing[]
  }
  /** The legal agreements with the tool’s vendor for this tool */
  legal_agreements: LegalAgreementListItem[]
  workflow_runs: Request[]
  following: boolean
  okta_users_count: OktaUsersCount
  scope_summary: {
    [key: string]: Scope[]
  }
  /** The integrations that this tool was detected through */
  integrations: IntegrationPublicFields[]
  documents: DocumentListItem[]
  department_users: {
    [key: string]: {
      id: string
      object_type: "EmployeeGroup"
      display_name: null | NonEmptyTrimmedString
      group_type: null | EmployeeGroupType
      count: number
    }
  }
}
export type LoginEventType = "authorize" | "revoke" | "authenticate"
export type LoginScope = {
  /** The same as `scope` */
  display_name: NonEmptyString
  scope: NonEmptyString
}
export type LoginMinimal = {
  object_type: "Login"
  organization_id: string
  created_at: DateTimeString
  authorized_at: DateTimeString
  event_type: LoginEventType
  app_name: NonEmptyString
  integration: IntegrationPublicFields
  person: Person
  external_event_id: NonEmptyString
  scopes: LoginScope[]
}
export type LoginSummaryResponse = {
  logins: LoginMinimal[]
  total: number
}
export type HrisGroups = {
  department: {
    id: string
    object_type: "EmployeeGroup"
    display_name: null | NonEmptyTrimmedString
    group_type: "department"
  }[]
  team: {
    id: string
    object_type: "EmployeeGroup"
    display_name: null | NonEmptyTrimmedString
    group_type: "team"
  }[]
}
export type DisplayableIntegrationType =
  | "google"
  | "okta"
  | "slack"
  | "brex"
  | "ramp"
  | "quickbooks"
  | "netsuite"
  | "quickbooks-online"
  | "sage-intacct"
  | "xero"
  | "bamboohr"
  | "justworks"
  | "gusto"
  | "rippling"
  | "hibob"
  | "workday"
  | "paylocity"
  | "ukg-pro"
  | "ukg-ready"
  | "ukg-pro-workforce-management"
  | "adp-workforce-now"
  | "deel"
  | "namely"
  | "zenefits"
  | "charthop"
export type IntegrationPersonStatus = "active" | "inactive" | "pending"
export type IntegrationPerson = {
  id: string
  displayable_integration_type: DisplayableIntegrationType
  integration: IntegrationPublicFields
  integration_provider_id: NonEmptyString
  status: null | IntegrationPersonStatus
  email: null | NonEmptyString
  profile_image: null | ImageAsset
  first_name: null | NonEmptyString
  last_name: null | NonEmptyString
}
export type PersonListItem = {
  id: string
  object_type: "Person"
  display_name: NonEmptyTrimmedString
  organization_id: string
  user_id: null | string
  first_name: null | NonEmptyTrimmedString
  last_name: null | NonEmptyTrimmedString
  email: null | EmailString
  profile_image: null | ImageAsset
  status?: null | PersonStatus
  created_at: DateTimeString
  tool_count: number
  last_login: null | {
    authorized_at: DateTimeString
    event_type: LoginEventType
  }
  hris_groups: HrisGroups
  user: null | User
  manager: null | Person
  sources: DisplayableIntegrationType[]
  integration_persons: IntegrationPerson[]
}
export type PersonListItemResponse = {
  total: number
  persons: PersonListItem[]
}
export type LegalAgreementListResponse = {
  items: LegalAgreementListItem[]
  total: number
}
export type LegalAgreementWithToolsList = {
  /** The total cost of the contract to the buyer over the entire period of the contract, or `null` if unknown. */
  total_contract_value?: null | CurrencyAmount
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date: null | DateString
  /** The first date at which the buyer will be billed by the seller as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  first_invoice_date?: null | DateString
  /** Will the vendor invoice {buyer} monthly, quarterly, or annually? Look for a specific mention of when the buyer is invoiced. */
  invoice_interval?: null | FrequencyOrOneTime
  /** The amount of time that the buyer has to settle each bill, e.g. P30D for 30 days. `null` if unknown. Payment terms that are due upon receipt should return PT0S. */
  net_payment_term?: null | DateDurationString
  /** Whether the contract term automatically renews; true/false. */
  auto_renews?: null | boolean
  /** The period the buyer has to give notice to terminate the contract before the end of the current term. The period should be an ISO 8601 duration string (e.g., P30D for 30 days). */
  auto_renewal_opt_out_period?: null | DateDurationString
  /** The date when the buyer must decide whether to renew or cancel the contract. Renewal reminders are sent based on this date. */
  decision_date?: null | DateString
  /** Whether the contract was signed or is still unsigned */
  is_signed: null | boolean
  /** The full name of the person who signed for the buying party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  buyer_signer_name?: null | NonEmptyTrimmedProseString
  /** The buying signer’s title (e.g., CEO). */
  buyer_signer_title?: null | NonEmptyTrimmedProseString
  /** The full name of the person who signed for the selling party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  vendor_signer_name?: null | NonEmptyTrimmedProseString
  /** The selling signer’s title (e.g., CEO). */
  vendor_signer_title?: null | NonEmptyTrimmedProseString
  /** The legal clauses found in the contract. */
  clauses?: LegalClauses
  custom: CustomFields
  id: string
  object_type: "LegalAgreement"
  /** Whether the Legal Agreement is active, expired, or upcoming. Will be unknown if the agreement has not been verified or is missing start/end dates. */
  effective_status: AgreementEffectiveStatus
  /** The type of agreement (enterprise or subscription) */
  agreement_type: AgreementType
  /** The name of the agreement. */
  display_name: NonEmptyTrimmedProseString
  usd_total_contract_value?: null | NumericString
  /** The next occurrence of the billing interval (based on the first invoice date) */
  next_invoice_date?: null | DateString
  created_at: DateTimeString
  updated_at?: null | DateTimeString
  fields_metadata: LegalAgreementFieldsMetadata
  /** Legal agreements that enter through certain sources, like bulk upload or email, need to have their extracted fields verified. Manually created agreements always start verified. Verified agreements may become unverified when more documents are added. */
  verification_status: AgreementVerificationStatus
  visibility_status: AgreementVisibilityStatus
  tools: Tool3[]
}[]
export type LegalAgreement = {
  /** The total cost of the contract to the buyer over the entire period of the contract, or `null` if unknown. */
  total_contract_value?: null | CurrencyAmount
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date: null | DateString
  /** The first date at which the buyer will be billed by the seller as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  first_invoice_date?: null | DateString
  /** Will the vendor invoice {buyer} monthly, quarterly, or annually? Look for a specific mention of when the buyer is invoiced. */
  invoice_interval?: null | FrequencyOrOneTime
  /** The amount of time that the buyer has to settle each bill, e.g. P30D for 30 days. `null` if unknown. Payment terms that are due upon receipt should return PT0S. */
  net_payment_term?: null | DateDurationString
  /** Whether the contract term automatically renews; true/false. */
  auto_renews?: null | boolean
  /** The period the buyer has to give notice to terminate the contract before the end of the current term. The period should be an ISO 8601 duration string (e.g., P30D for 30 days). */
  auto_renewal_opt_out_period?: null | DateDurationString
  /** The date when the buyer must decide whether to renew or cancel the contract. Renewal reminders are sent based on this date. */
  decision_date?: null | DateString
  /** Whether the contract was signed or is still unsigned */
  is_signed: null | boolean
  /** The full name of the person who signed for the buying party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  buyer_signer_name?: null | NonEmptyTrimmedProseString
  /** The buying signer’s title (e.g., CEO). */
  buyer_signer_title?: null | NonEmptyTrimmedProseString
  /** The full name of the person who signed for the selling party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  vendor_signer_name?: null | NonEmptyTrimmedProseString
  /** The selling signer’s title (e.g., CEO). */
  vendor_signer_title?: null | NonEmptyTrimmedProseString
  /** The legal clauses found in the contract. */
  clauses?: LegalClauses
  custom: CustomFields
  id: string
  object_type: "LegalAgreement"
  /** Whether the Legal Agreement is active, expired, or upcoming. Will be unknown if the agreement has not been verified or is missing start/end dates. */
  effective_status: AgreementEffectiveStatus
  /** The type of agreement (enterprise or subscription) */
  agreement_type: AgreementType
  /** The name of the agreement. */
  display_name: NonEmptyTrimmedProseString
  usd_total_contract_value?: null | NumericString
  /** The next occurrence of the billing interval (based on the first invoice date) */
  next_invoice_date?: null | DateString
  created_at: DateTimeString
  updated_at?: null | DateTimeString
  fields_metadata: LegalAgreementFieldsMetadata
  /** Legal agreements that enter through certain sources, like bulk upload or email, need to have their extracted fields verified. Manually created agreements always start verified. Verified agreements may become unverified when more documents are added. */
  verification_status: AgreementVerificationStatus
  visibility_status: AgreementVisibilityStatus
  /** Any documents related to this requests. Documents can include an Order Form, Statement of Work, a Quote, Master Services Agreements, etc. */
  documents: DocumentWithExtraction[]
  /** The vendor the legal agreement is with */
  vendor: null | Vendor
  /** The tools purchased with the legal agreement */
  tools: Tool3[]
}
export type LegalClausesInputFieldsMetadata = any | any
export type LegalAgreementInputFieldsMetadata = {
  total_contract_value?: FieldMetadata
  start_date?: FieldMetadata
  end_date?: FieldMetadata
  first_invoice_date?: FieldMetadata
  invoice_interval?: FieldMetadata
  net_payment_term?: FieldMetadata
  auto_renews?: FieldMetadata
  auto_renewal_opt_out_period?: FieldMetadata
  decision_date?: FieldMetadata
  is_signed?: FieldMetadata
  buyer_signer_name?: FieldMetadata
  buyer_signer_title?: FieldMetadata
  vendor_signer_name?: FieldMetadata
  vendor_signer_title?: FieldMetadata
  documents?: FieldMetadata
  vendor?: FieldMetadata
  tools?: FieldMetadata
  display_name?: FieldMetadata
  agreement_type?: FieldMetadata
  clauses?: LegalClausesInputFieldsMetadata
  custom?: {
    [key: string]: FieldMetadata
  }
}
export type ToolOption = {
  /** The name of the tool */
  display_name: NonEmptyTrimmedString
  /** The description of the tool (what the tool does) */
  description: null | NonEmptyTrimmedProseString
  /** The URL of the tool's website */
  website: null | HttpUrlString
  /** The image asset for the tool's logo */
  image_asset: null | ImageAssetMinimal
  id: null | string
  object_type: "Tool"
  tool_listing_id: string
}
export type DocumentReference = {
  id: string
  object_type: "Document"
}
export type SubscriptionLegalAgreementInput = {
  agreement_type: "subscription"
  object_type: "LegalAgreement"
  display_name: NonEmptyTrimmedProseString
  fields_metadata: LegalAgreementInputFieldsMetadata
  tools?: ToolOption[]
  vendor?: null | VendorOption
  custom: CustomFields
  documents: DocumentReference[]
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date?: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date?: null | DateString
  /** The first date at which the buyer will be billed by the seller as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  first_invoice_date?: null | DateString
  /** Will the vendor invoice {buyer} monthly, quarterly, or annually? Look for a specific mention of when the buyer is invoiced. */
  invoice_interval?: null | FrequencyOrOneTime
  /** Whether the contract term automatically renews; true/false. */
  auto_renews?: null | boolean
  /** The period the buyer has to give notice to terminate the contract before the end of the current term. The period should be an ISO 8601 duration string (e.g., P30D for 30 days). */
  auto_renewal_opt_out_period?: null | DateDurationString
  /** The date when the buyer must decide whether to renew or cancel the contract. Renewal reminders are sent based on this date. */
  decision_date?: null | DateString
}
export type EnterpriseLegalAgreementInput = {
  agreement_type: "enterprise"
  object_type: "LegalAgreement"
  display_name: NonEmptyTrimmedProseString
  fields_metadata: LegalAgreementInputFieldsMetadata
  tools?: ToolOption[]
  vendor?: null | VendorOption
  custom: CustomFields & CustomFields
  documents: DocumentReference[]
  /** The total cost of the contract to the buyer over the entire period of the contract, or `null` if unknown. */
  total_contract_value?: null | CurrencyAmount
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date?: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date?: null | DateString
  /** The first date at which the buyer will be billed by the seller as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  first_invoice_date?: null | DateString
  /** Will the vendor invoice {buyer} monthly, quarterly, or annually? Look for a specific mention of when the buyer is invoiced. */
  invoice_interval?: null | FrequencyOrOneTime
  /** The amount of time that the buyer has to settle each bill, e.g. P30D for 30 days. `null` if unknown. Payment terms that are due upon receipt should return PT0S. */
  net_payment_term?: null | DateDurationString
  /** Whether the contract term automatically renews; true/false. */
  auto_renews?: null | boolean
  /** The period the buyer has to give notice to terminate the contract before the end of the current term. The period should be an ISO 8601 duration string (e.g., P30D for 30 days). */
  auto_renewal_opt_out_period?: null | DateDurationString
  /** The date when the buyer must decide whether to renew or cancel the contract. Renewal reminders are sent based on this date. */
  decision_date?: null | DateString
  /** Whether the contract was signed or is still unsigned */
  is_signed?: null | boolean
  /** The full name of the person who signed for the buying party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  buyer_signer_name?: null | NonEmptyTrimmedProseString
  /** The buying signer’s title (e.g., CEO). */
  buyer_signer_title?: null | NonEmptyTrimmedProseString
  /** The full name of the person who signed for the selling party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  vendor_signer_name?: null | NonEmptyTrimmedProseString
  /** The selling signer’s title (e.g., CEO). */
  vendor_signer_title?: null | NonEmptyTrimmedProseString
  /** The legal clauses found in the contract. */
  clauses?: LegalClauses
}
export type LegalAgreementInput =
  | ({
      agreement_type: "subscription"
    } & SubscriptionLegalAgreementInput)
  | ({
      agreement_type: "enterprise"
    } & EnterpriseLegalAgreementInput)
export type AgreementVerificationRequest = {
  input: LegalAgreementInput
  field_path?: (string | number)[]
}
export type VendorListItem = {
  /** The company name of the vendor */
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAssetMinimal
  /** The description of the vendor */
  description: null | NonEmptyTrimmedProseString
  /** The URL of the vendor’s website */
  website: null | HttpUrlString
  id: string
  object_type: "Vendor"
  vendor_listing_id: string
  created_at: DateTimeString
  /** The overall approval status of the vendor */
  status: VendorStatus
  /** The financial approval status of the vendor */
  finance_status?: VendorFinanceStatus
  /** The compliance approval status of the vendor */
  compliance_status?: VendorComplianceStatus
  /** The IT approval status of the vendor */
  it_status?: VendorItStatus
  /** The person in the organization responsible for the vendor */
  owner: null | Person
  /** What problem(s) will this vendor solve and how? */
  problems_addressed: null | RichText
  /** Why are you choosing this vendor over alternative vendors? */
  selection_rationale: null | RichText
  /** What’s the estimated return on investment (ROI) for this vendor? */
  roi_analysis: null | RichText
  /** The year the company was founded */
  founded_year?: null | number
  /** Is the vendor a broker dealer? */
  is_broker_dealer?: null | boolean
  /** The unique number assigned to a business entity by the Internal Revenue Service (IRS). Used for tax filing and reporting purposes */
  ein?: null | EmploymentIdentificationNumber
  /** A nine-digit number assigned by Dun & Bradstreet for business identification and credit reporting */
  duns?: null | DunsNumber
  /** Whether the company has experienced any cybersecurity breaches and any related details */
  cybersecurity_breaches?: null | BooleanWithRichTextDetails
  /** Whether the company has any ongoing or pending litigation and any related details */
  litigations?: null | BooleanWithRichTextDetails
  /** The Non-Disclosure Agreement document for the vendor */
  nda?: null | DocumentMinimal
  /** The vendor's bank information */
  bank_info?: null | DocumentMinimal
  /** The vendor's W-9 */
  w9?: null | DocumentMinimal
  fields_metadata?: VendorFieldsMetadataOutput
  hidden?: boolean
  organization_id: string
  custom: CustomFields
  /** The number of tools used by the organization from the vendor */
  tool_count: number
  /** The number of active legal agreements on the vendor */
  active_legal_agreement_count: number
  /** The department classification for the vendor owner */
  owner_groups: EmployeeGroup[]
  /** The primary legal agreement between the organization and the vendor */
  primary_legal_agreement: null | LegalAgreementMinimal
  /** The vendor categories that the vendor falls into */
  vendor_categories: null | TransactionCategory[]
  /** The aggregated spent on the tool/vendor over various time periods */
  spend?: AggregatedSpend
  /** The method used to pay (e.g. bill pay or credit card) */
  payment_methods?: null | TransactionPaymentMethod[]
  /** The department or class categorization for the tool from your accounting system */
  erp_tracking_categories: ErpTrackingCategoryGroups
}
export type VendorListItemResponse = {
  vendors: VendorListItem[]
  total: number
}
export type VendorOptionWithTools = {
  /** The company name of the vendor */
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAssetMinimal
  /** The description of the vendor */
  description: null | NonEmptyTrimmedProseString
  /** The URL of the vendor’s website */
  website: null | HttpUrlString
  id: null | string
  object_type: "Vendor"
  vendor_listing_id: string
  /** The tools array is optional because on the initial load of the form, the saved VendorOption does not get returned with the tools array. */
  tools?: ToolOption[]
}
export type VendorFieldsMetadataInput = {
  custom?: {
    [key: string]: FieldMetadata
  }
  problems_addressed?: FieldMetadata
  selection_rationale?: FieldMetadata
  roi_analysis?: FieldMetadata
  founded_year?: FieldMetadata
  is_broker_dealer?: FieldMetadata
  ein?: FieldMetadata
  duns?: FieldMetadata
  cybersecurity_breaches?: FieldMetadata
  litigations?: FieldMetadata
  nda?: FieldMetadata
  bank_info?: FieldMetadata
  w9?: FieldMetadata
  owner?: FieldMetadata
  status?: FieldMetadata
  finance_status?: FieldMetadata
  compliance_status?: FieldMetadata
  it_status?: FieldMetadata
}
export type VendorPatch = {
  id: string
  object_type: "Vendor"
  /** The overall approval status of the vendor */
  status?: VendorStatus
  /** The financial approval status of the vendor */
  finance_status?: VendorFinanceStatus
  /** The compliance approval status of the vendor */
  compliance_status?: VendorComplianceStatus
  /** The IT approval status of the vendor */
  it_status?: VendorItStatus
  /** What problem(s) will this vendor solve and how? */
  problems_addressed?: null | RichText
  /** Why are you choosing this vendor over alternative vendors? */
  selection_rationale?: null | RichText
  /** What’s the estimated return on investment (ROI) for this vendor? */
  roi_analysis?: null | RichText
  /** The year the company was founded */
  founded_year?: null | number
  /** Is the vendor a broker dealer? */
  is_broker_dealer?: null | boolean
  /** The unique number assigned to a business entity by the Internal Revenue Service (IRS). Used for tax filing and reporting purposes */
  ein?: null | EmploymentIdentificationNumber
  /** A nine-digit number assigned by Dun & Bradstreet for business identification and credit reporting */
  duns?: null | DunsNumber
  /** Whether the company has experienced any cybersecurity breaches and any related details */
  cybersecurity_breaches?: null | BooleanWithRichTextDetails
  /** Whether the company has any ongoing or pending litigation and any related details */
  litigations?: null | BooleanWithRichTextDetails
  /** The Non-Disclosure Agreement document for the vendor */
  nda?: null | DocumentMinimal
  /** The vendor's bank information */
  bank_info?: null | DocumentMinimal
  /** The vendor's W-9 */
  w9?: null | DocumentMinimal
  hidden?: boolean
  custom?: CustomFields
  fields_metadata?: VendorFieldsMetadataInput
  owner?: null | {
    id: string
  }
}
export type VendorTool = {
  /** The name of the tool */
  display_name: NonEmptyTrimmedString
  /** The description of the tool (what the tool does) */
  description: null | NonEmptyTrimmedProseString
  /** The image asset for the tool's logo */
  image_asset: null | ImageAssetMinimal
  id: string
  /** The person in the organization responsible for the tool */
  owner: null | Person
  overview?: null | RichText
  selection_rationale?: null | RichText
  roi_analysis?: null | RichText
}
export type VendorDetails = {
  /** The company name of the vendor */
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAssetMinimal
  /** The description of the vendor */
  description: null | NonEmptyTrimmedProseString
  /** The URL of the vendor’s website */
  website: null | HttpUrlString
  id: string
  object_type: "Vendor"
  vendor_listing_id: string
  created_at: DateTimeString
  /** The overall approval status of the vendor */
  status: VendorStatus
  /** The financial approval status of the vendor */
  finance_status?: VendorFinanceStatus
  /** The compliance approval status of the vendor */
  compliance_status?: VendorComplianceStatus
  /** The IT approval status of the vendor */
  it_status?: VendorItStatus
  /** The person in the organization responsible for the vendor */
  owner: null | Person
  /** What problem(s) will this vendor solve and how? */
  problems_addressed: null | RichText
  /** Why are you choosing this vendor over alternative vendors? */
  selection_rationale: null | RichText
  /** What’s the estimated return on investment (ROI) for this vendor? */
  roi_analysis: null | RichText
  /** The year the company was founded */
  founded_year?: null | number
  /** Is the vendor a broker dealer? */
  is_broker_dealer?: null | boolean
  /** The unique number assigned to a business entity by the Internal Revenue Service (IRS). Used for tax filing and reporting purposes */
  ein?: null | EmploymentIdentificationNumber
  /** A nine-digit number assigned by Dun & Bradstreet for business identification and credit reporting */
  duns?: null | DunsNumber
  /** Whether the company has experienced any cybersecurity breaches and any related details */
  cybersecurity_breaches?: null | BooleanWithRichTextDetails
  /** Whether the company has any ongoing or pending litigation and any related details */
  litigations?: null | BooleanWithRichTextDetails
  /** The Non-Disclosure Agreement document for the vendor */
  nda?: null | DocumentMinimal
  /** The vendor's bank information */
  bank_info?: null | DocumentMinimal
  /** The vendor's W-9 */
  w9?: null | DocumentMinimal
  fields_metadata?: VendorFieldsMetadataOutput
  hidden?: boolean
  organization_id: string
  custom: CustomFields
  /** The number of tools used by the organization from the vendor */
  tool_count: number
  /** The number of active legal agreements on the vendor */
  active_legal_agreement_count: number
  /** The department classification for the vendor owner */
  owner_groups: EmployeeGroup[]
  /** The primary legal agreement between the organization and the vendor */
  primary_legal_agreement: null | LegalAgreementMinimal
  /** The vendor categories that the vendor falls into */
  vendor_categories: null | TransactionCategory[]
  /** The aggregated spent on the tool/vendor over various time periods */
  spend?: AggregatedSpend
  /** The method used to pay (e.g. bill pay or credit card) */
  payment_methods?: null | TransactionPaymentMethod[]
  /** The department or class categorization for the tool from your accounting system */
  erp_tracking_categories: ErpTrackingCategoryGroups
  workflow_runs: Request[]
  legal_agreements: LegalAgreementListItem[]
  following: boolean
  tools: VendorTool[]
  integrations: IntegrationPublicFields[]
  documents: DocumentListItem[]
}
export type IntegrationStatus =
  | "connected"
  | "disconnected"
  | "connecting"
  | "failing"
  | "deleting"
  | "resetting"
  | "disabled"
export type InitialExtractionBanner =
  | {
      /** The percent of days the initial fast extraction has crawled out of the default historical lookback period */
      extraction_progress: number
      /** The number of agreements that have been extracted */
      agreements_extracted: number
    }
  | "completed"
export type CreateApiKeyIntegration = {
  auth_type: "apikey"
  api_key: NonEmptyString
  display_name: NonEmptyTrimmedProseString
  provider: "brex_apikey" | "mercury_apikey" | "okta_apikey"
  resource_domain?: NonEmptyString
}
export type CreateOAuthAuthorizationCodeIntegration = {
  auth_type: "oauth_authorization_code"
  display_name: NonEmptyTrimmedProseString
  provider: "ramp_oauth" | "google_oauth" | "gmail_oauth" | "brex_oauth" | "quickbooks_oauth" | "slack_oauth"
  access_token: null | NonEmptyString
  external_id: null | NonEmptyString
  email?: null | NonEmptyString
}
export type CreateOAuthClientCredsIntegration = {
  auth_type: "oauth_client_credentials"
  display_name: NonEmptyTrimmedProseString
  provider: "ramp_client_creds"
  client_id: NonEmptyString
  client_secret: NonEmptyString
  scope?: NonEmptyString
}
export type CreateHrisLinkTokenApiIntegration = {
  auth_type: "hris_link_token"
  display_name: NonEmptyTrimmedProseString
  provider: "merge_hris_link_token"
  public_token: NonEmptyString
  merge_hris_type: MergeIntegrationHrisType
  access_token: null | NonEmptyString
  origin_id: NonEmptyString
}
export type CreateAccountingLinkTokenApiIntegration = {
  auth_type: "accounting_link_token"
  display_name: NonEmptyTrimmedProseString
  provider: "merge_accounting_link_token"
  public_token: NonEmptyString
  merge_accounting_type: MergeIntegrationAccountingType
  access_token: null | NonEmptyString
  origin_id: NonEmptyString
}
export type CreateDomainPrivateKeyIntegration = {
  auth_type: "domain_private_key"
  display_name: NonEmptyTrimmedProseString
  provider: "okta_oauth"
  resource_domain: NonEmptyString
  private_key: NonEmptyString
  client_id: NonEmptyString
}
export type PlaidAccount = {
  id: NonEmptyString
  name: NonEmptyString
  mask: null | NonEmptyString
  type: NonEmptyString
  subtype: NonEmptyString
}
export type CreatePlaidAccessTokenIntegration = {
  auth_type: "plaid_access_token"
  display_name: NonEmptyTrimmedProseString
  provider: "plaid_access_token"
  institution_id: NonEmptyString
  institution_name: NonEmptyString
  accounts: PlaidAccount[]
  public_token: NonEmptyString
  link_session_id: NonEmptyString
}
export type CreateIntegration =
  | ({
      auth_type: "apikey"
    } & CreateApiKeyIntegration)
  | ({
      auth_type: "oauth_authorization_code"
    } & CreateOAuthAuthorizationCodeIntegration)
  | ({
      auth_type: "oauth_client_credentials"
    } & CreateOAuthClientCredsIntegration)
  | ({
      auth_type: "hris_link_token"
    } & CreateHrisLinkTokenApiIntegration)
  | ({
      auth_type: "accounting_link_token"
    } & CreateAccountingLinkTokenApiIntegration)
  | ({
      auth_type: "domain_private_key"
    } & CreateDomainPrivateKeyIntegration)
  | ({
      auth_type: "plaid_access_token"
    } & CreatePlaidAccessTokenIntegration)
export type UpdateIntegration = {
  integration_id: string
  api_key?: NonEmptyString
  resource_domain?: NonEmptyString
  private_key?: NonEmptyString
  client_id?: NonEmptyString
  client_secret?: NonEmptyString
}
export type LinkTokenResponse = {
  token: string
  originId: string
}
export type MergeWebhookPayload = {
  hook: {
    event: string
  }
  linked_account: {
    id: string
    end_user_origin_id: string
    integration: string
    category: string
    status: string
  }
  data: {
    account_token: string
  }
}
export type PersonDetails = {
  id: string
  object_type: "Person"
  display_name: NonEmptyTrimmedString
  organization_id: string
  user_id: null | string
  first_name: null | NonEmptyTrimmedString
  last_name: null | NonEmptyTrimmedString
  email: null | EmailString
  profile_image: null | ImageAsset
  status?: null | PersonStatus
  created_at: DateTimeString
  tool_count: number
  last_login: null | {
    authorized_at: DateTimeString
    event_type: LoginEventType
  }
  hris_groups: HrisGroups
  user: null | User
  manager: null | Person
  sources: DisplayableIntegrationType[]
  integration_persons: IntegrationPerson[]
}
export type LoginScopeSummary = {
  [key: string]: {
    scope: NonEmptyString
    description: null | NonEmptyTrimmedProseString
    provider: NonEmptyString
    resource_group: null | NonEmptyString
    risk_score: number
    person_count: number
    tools: ToolMinimal[]
  }[]
}
export type LoginUserActivityResponse = {
  total_active_users: number
  intervals: {
    start_date: DateString
    active_users: number
  }[]
}
export type TransactionSearchResult = {
  type: "transaction"
  id: string
  bank_memo: null | NonEmptyTrimmedString
  bank_external_memo: null | NonEmptyTrimmedString
  cc_memo: null | NonEmptyTrimmedString
  erp_bill_payment_memo: null | NonEmptyTrimmedString
  erp_expense_memo: null | NonEmptyTrimmedString
  merchant_name: NonEmptyString
  receipt_text: null | NonEmptyString
  provider: null | IntegrationProvider
  merge_accounting_type: null | MergeIntegrationAccountingType
  vendor: {
    id: string
    display_name: NonEmptyTrimmedString
  }
  tool: null | {
    id: string
    display_name: NonEmptyTrimmedString
  }
  currency_amount: CurrencyAmount
  transacted_at: DateTimeString
  _snippetResult?: {
    receipt_text?: {
      value: string
    }
  }
  _highlightResult?: {
    bank_memo?: {
      value: string
    }
    bank_external_memo?: {
      value: string
    }
    cc_memo?: {
      value: string
    }
    erp_bill_payment_memo?: {
      value: string
    }
    erp_expense_memo?: {
      value: string
    }
    merchant_descriptor?: {
      value: string
    }
    merchant_name?: {
      value: string
    }
    receipt_text?: {
      value: string
    }
    vendor?: {
      display_name?: {
        value: string
      }
    }
    tool?: {
      display_name?: {
        value: string
      }
    }
  }
}
export type PersonSearchResult = {
  first_name: null | NonEmptyTrimmedString
  last_name: null | NonEmptyTrimmedString
  email: null | EmailString
  profile_image: null | ImageAsset
  type: "person"
  id: string
  _highlightResult?: {
    first_name?: {
      value: string
    }
    last_name?: {
      value: string
    }
  }
}
export type VendorSearchResult = {
  /** The company name of the vendor */
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAssetMinimal
  /** The description of the vendor */
  description: null | NonEmptyTrimmedProseString
  type: "vendor"
  id: string
  custom_description: null | NonEmptyTrimmedProseString
  custom_name: null | NonEmptyString
  _highlightResult: {
    display_name?: {
      value: string
    }
    description?: {
      value: string
    }
  }
  _snippetResult?: {
    description?: {
      value: string
    }
  }
}
export type ToolSearchResult = {
  /** The name of the tool */
  display_name: NonEmptyTrimmedString
  /** The description of the tool (what the tool does) */
  description: null | NonEmptyTrimmedProseString
  /** The image asset for the tool's logo */
  image_asset: null | ImageAssetMinimal
  type: "tool"
  id: string
  custom_description: null | NonEmptyTrimmedProseString
  custom_name: null | NonEmptyString
  _highlightResult: {
    display_name?: {
      value: string
    }
    description?: {
      value: string
    }
  }
  _snippetResult?: {
    description?: {
      value: string
    }
  }
}
export type WorkflowRunSearchResult = {
  type: "workflow_run"
  id: string
  workflow_run_name: NonEmptyTrimmedString
  workflow_run_status: WorkflowRunStatus
  workflow_run_kind: WorkflowKind
  workflow_run_created_at: DateTimeString
  organization_id: string
  vendor_id: null | string
  vendor_name: null | NonEmptyTrimmedString
  vendor_logo: null | NonEmptyTrimmedString
  vendor_description: null | NonEmptyTrimmedProseString
  tool_id: null | string
  tool_name: null | NonEmptyTrimmedString
  tool_logo: null | NonEmptyTrimmedString
  description: null | NonEmptyTrimmedProseString
  tool_image_asset: null | ImageAsset
  vendor_image_asset: null | ImageAsset
  _highlightResult: {
    display_name?: {
      value: string
    }
    description?: {
      value: string
    }
  }
  _snippetResult?: {
    description?: {
      value: string
    }
  }
}
export type LegalDocumentResult = {
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date?: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date?: null | DateString
  id: string
  /** The original file basename as uploaded by the user. */
  file_name: null | NonEmptyTrimmedString
  raw_text: null | string
  tools: ToolMinimal[]
  legal_agreement_id: string
  type: "legal_document"
  visibility_status: AgreementVisibilityStatus
  created_by: null | string
  tool_display_names: NonEmptyTrimmedString[]
  _snippetResult?: {
    raw_text?: {
      value: string
    }
  }
  _highlightResult?: {
    file_name?: {
      value: string
    }
    tool_display_names?: {
      value: string
    }[]
  }
}
export type SearchResponse = (
  | ({
      type: "transaction"
    } & TransactionSearchResult)
  | ({
      type: "person"
    } & PersonSearchResult)
  | ({
      type: "vendor"
    } & VendorSearchResult)
  | ({
      type: "tool"
    } & ToolSearchResult)
  | ({
      type: "workflow_run"
    } & WorkflowRunSearchResult)
  | ({
      type: "legal_document"
    } & LegalDocumentResult)
)[]
export type UploadableDocument = {
  id: string
  object_type: "Document"
  created_at: DateTimeString
  updated_at?: null | DateTimeString
  /** The original file basename as uploaded by the user. */
  file_name: null | NonEmptyTrimmedString
  extraction_status?: null | DocumentExtractionStatus
  /** The file size in bytes */
  file_size: null | number
  /** MIME type like `application/pdf` or `text/plain; charset=utf-8` */
  mime_type: null | NonEmptyTrimmedString
  /** Users who have email integrations which have found this document */
  user_email_integrations?: UserWithProvider[]
  upload_url: string
  status: "pending"
}
export type UploadedDocument = {
  id: string
  object_type: "Document"
  created_at: DateTimeString
  updated_at?: null | DateTimeString
  /** The original file basename as uploaded by the user. */
  file_name: null | NonEmptyTrimmedString
  extraction_status?: null | DocumentExtractionStatus
  /** The file size in bytes */
  file_size: null | number
  /** MIME type like `application/pdf` or `text/plain; charset=utf-8` */
  mime_type: null | NonEmptyTrimmedString
  /** Users who have email integrations which have found this document */
  user_email_integrations?: UserWithProvider[]
  status: "uploaded" | "scanned" | "quarantined"
  existing_legal_agreements: LegalAgreementMinimal[]
}
export type CreateDocumentResponse =
  | ({
      status: "pending"
    } & UploadableDocument)
  | ({
      status: "uploaded"
    } & UploadedDocument)
  | ({
      status: "scanned"
    } & UploadedDocument)
  | ({
      status: "quarantined"
    } & UploadedDocument)
export type HexString = string
export type DocumentInput = {
  /** The original file basename as uploaded by the user. */
  file_name: NonEmptyTrimmedString
  /** The file size in bytes */
  file_size: number
  mime_type: NonEmptyTrimmedString
  /** The SHA1 hash of the file content as a hex string. Optional property for when we want to prevent duplicate documents during bulk upload. */
  content_hash?: HexString
  link_code?: NonEmptyTrimmedString
}
export type DocumentListResponse = {
  documents: DocumentListItem[]
  total: number
}
export type ExtractingDocumentResponse = {
  /** The documents that are being extracted into legal agreements */
  documents: DocumentMinimal[]
  /** Whether there are email documents that have text being extracted. Not all email documents get fields extracted into legal agreements */
  has_uncategorized_emails: boolean
}
export type DocumentToolBatchExtractionRequest = {
  extraction_request_type: "tool"
  tool_id: string
  document_ids: string[]
}
export type DocumentVendorBatchExtractionRequest = {
  extraction_request_type: "vendor"
  vendor_id: string
  document_ids: string[]
}
export type DocumentLegalAgreementBatchExtractionRequest = {
  extraction_request_type: "legal_agreement"
  legal_agreement_id: string
  document_ids: string[]
}
export type DocumentUnknownLegalAgreementBatchExtractionRequest = {
  extraction_request_type: "unknown_legal_agreement"
  document_ids: string[]
}
export type DocumentBatchExtractionRequest =
  | ({
      extraction_request_type: "tool"
    } & DocumentToolBatchExtractionRequest)
  | ({
      extraction_request_type: "vendor"
    } & DocumentVendorBatchExtractionRequest)
  | ({
      extraction_request_type: "legal_agreement"
    } & DocumentLegalAgreementBatchExtractionRequest)
  | ({
      extraction_request_type: "unknown_legal_agreement"
    } & DocumentUnknownLegalAgreementBatchExtractionRequest)
export type MoveDocumentCreateAction = {
  action: "create_agreement"
  display_name: string
}
export type MoveDocumentToAgreementAction = {
  action: "add_to_agreement"
  legal_agreement_id: string
}
export type MoveDocumentToToolAction = {
  action: "add_to_tool"
  tool_id: string
}
export type MoveDocumentToVendorAction = {
  action: "add_to_vendor"
  vendor_id: string
}
export type MoveDocumentAction =
  | ({
      action: "create_agreement"
    } & MoveDocumentCreateAction)
  | ({
      action: "add_to_agreement"
    } & MoveDocumentToAgreementAction)
  | ({
      action: "add_to_tool"
    } & MoveDocumentToToolAction)
  | ({
      action: "add_to_vendor"
    } & MoveDocumentToVendorAction)
export type MoveDocumentRequest = {
  old_agreement_id: string
  move_action: MoveDocumentAction
  document_id: string
}
export type DocumentBackgroundExtractionResponse = {
  document_id: string
  agreement_id?: string
}
export type DocumentLegalAgreementBackgroundExtractionRequest = {
  extraction_request_type: "legal_agreement"
  legal_agreement_id: string
}
export type DocumentWorkflowAgreementBackgroundExtractionRequest = {
  extraction_request_type: "workflow_run_agreement"
  workflow_run_id: string
}
export type DocumentExtractionSubscriptionLegalAgreementInput = {
  agreement_type: "subscription"
  object_type: "LegalAgreement"
  fields_metadata: LegalAgreementInputFieldsMetadata
  tools?: ToolOption[]
  vendor?: null | VendorOption
  custom: CustomFields
  documents: DocumentReference[]
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date?: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date?: null | DateString
  /** The first date at which the buyer will be billed by the seller as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  first_invoice_date?: null | DateString
  /** Will the vendor invoice {buyer} monthly, quarterly, or annually? Look for a specific mention of when the buyer is invoiced. */
  invoice_interval?: null | FrequencyOrOneTime
  /** Whether the contract term automatically renews; true/false. */
  auto_renews?: null | boolean
  /** The period the buyer has to give notice to terminate the contract before the end of the current term. The period should be an ISO 8601 duration string (e.g., P30D for 30 days). */
  auto_renewal_opt_out_period?: null | DateDurationString
  /** The date when the buyer must decide whether to renew or cancel the contract. Renewal reminders are sent based on this date. */
  decision_date?: null | DateString
  display_name?: string
}
export type DocumentExtractionEnterpriseLegalAgreementInput = {
  agreement_type: "enterprise"
  object_type: "LegalAgreement"
  fields_metadata: LegalAgreementInputFieldsMetadata
  tools?: ToolOption[]
  vendor?: null | VendorOption
  custom: CustomFields & CustomFields
  documents: DocumentReference[]
  /** The total cost of the contract to the buyer over the entire period of the contract, or `null` if unknown. */
  total_contract_value?: null | CurrencyAmount
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date?: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date?: null | DateString
  /** The first date at which the buyer will be billed by the seller as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  first_invoice_date?: null | DateString
  /** Will the vendor invoice {buyer} monthly, quarterly, or annually? Look for a specific mention of when the buyer is invoiced. */
  invoice_interval?: null | FrequencyOrOneTime
  /** The amount of time that the buyer has to settle each bill, e.g. P30D for 30 days. `null` if unknown. Payment terms that are due upon receipt should return PT0S. */
  net_payment_term?: null | DateDurationString
  /** Whether the contract term automatically renews; true/false. */
  auto_renews?: null | boolean
  /** The period the buyer has to give notice to terminate the contract before the end of the current term. The period should be an ISO 8601 duration string (e.g., P30D for 30 days). */
  auto_renewal_opt_out_period?: null | DateDurationString
  /** The date when the buyer must decide whether to renew or cancel the contract. Renewal reminders are sent based on this date. */
  decision_date?: null | DateString
  /** Whether the contract was signed or is still unsigned */
  is_signed?: null | boolean
  /** The full name of the person who signed for the buying party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  buyer_signer_name?: null | NonEmptyTrimmedProseString
  /** The buying signer’s title (e.g., CEO). */
  buyer_signer_title?: null | NonEmptyTrimmedProseString
  /** The full name of the person who signed for the selling party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  vendor_signer_name?: null | NonEmptyTrimmedProseString
  /** The selling signer’s title (e.g., CEO). */
  vendor_signer_title?: null | NonEmptyTrimmedProseString
  /** The legal clauses found in the contract. */
  clauses?: LegalClauses
  display_name?: string
}
export type DocumentExtractionLegalAgreementInput =
  | ({
      agreement_type: "subscription"
    } & DocumentExtractionSubscriptionLegalAgreementInput)
  | ({
      agreement_type: "enterprise"
    } & DocumentExtractionEnterpriseLegalAgreementInput)
export type DocumentNewLegalAgreementBackgroundExtractionRequest = {
  extraction_request_type: "new_legal_agreement"
  agreement_input: DocumentExtractionLegalAgreementInput
}
export type ObjectsWithDocuments = "Tool" | "Vendor" | "LegalAgreement" | "WorkflowRun"
export type DocumentFieldBackgroundExtractionRequest = {
  extraction_request_type: "field_upload"
  field_name: string
  starting_relations?: {
    object_type: ObjectsWithDocuments
    object_ids: string[]
  }
}
export type DocumentLinkBackgroundExtractionRequest = {
  extraction_request_type: "link_field_upload"
  workflow_run_id: string
  link_code?: NonEmptyTrimmedString
  field_name?: string
}
export type DocumentLinkAgreementBackgroundExtractionRequest = {
  extraction_request_type: "link_agreement"
  workflow_run_id: string
  link_code?: NonEmptyTrimmedString
}
export type DocumentBackgroundExtractionRequest =
  | ({
      extraction_request_type: "legal_agreement"
    } & DocumentLegalAgreementBackgroundExtractionRequest)
  | ({
      extraction_request_type: "workflow_run_agreement"
    } & DocumentWorkflowAgreementBackgroundExtractionRequest)
  | ({
      extraction_request_type: "new_legal_agreement"
    } & DocumentNewLegalAgreementBackgroundExtractionRequest)
  | ({
      extraction_request_type: "field_upload"
    } & DocumentFieldBackgroundExtractionRequest)
  | ({
      extraction_request_type: "link_field_upload"
    } & DocumentLinkBackgroundExtractionRequest)
  | ({
      extraction_request_type: "link_agreement"
    } & DocumentLinkAgreementBackgroundExtractionRequest)
export type NewExtractedField = {
  object_type: CustomizableObjectType
  field_name: FieldName
  is_custom: boolean
  /** The data type of the field as a JSON schema */
  field_schema: null | any
  is_enabled: boolean
  is_internal_only: boolean
  category: null | FieldCategory
  needs_reextraction: boolean
  request_config_disabled: boolean
  required_in_request: boolean
  order_index: number
  type: "new_field"
  /** The original question from the questionnaire that this field was matched to */
  question: string
}
export type ExistingExtractedField = {
  object_type: CustomizableObjectType
  field_name: FieldName
  is_custom: boolean
  /** The data type of the field as a JSON schema */
  field_schema: null | any
  is_enabled: boolean
  is_internal_only: boolean
  category: null | FieldCategory
  needs_reextraction: boolean
  request_config_disabled: boolean
  required_in_request: boolean
  order_index: number
  updated_at: null | DateTimeString
  created_at: null | DateTimeString
  created_by: null | UserMinimal
  updated_by: null | UserMinimal
  type: "existing_field"
  /** The original question from the questionnaire that this field was matched to */
  question: string
}
export type ExtractedFieldConfig =
  | ({
      type: "new_field"
    } & NewExtractedField)
  | ({
      type: "existing_field"
    } & ExistingExtractedField)
export type ExtractedFieldConfigsResponse = ExtractedFieldConfig[]
export type WorkflowDefUserApprover = {
  id: string
  type: "user"
  user: User
}
export type WorkflowDefVariableApprover = {
  id: string
  type: "variable"
  variable: "manager"
}
export type WorkflowDefApprover =
  | ({
      type: "user"
    } & WorkflowDefUserApprover)
  | ({
      type: "variable"
    } & WorkflowDefVariableApprover)
export type ConditionType = "and" | "or"
export type WorkflowCondition = {
  id: string
  field_name: NonEmptyTrimmedString
  object_type: ObjectType
  is_custom: boolean
  condition_schema: any
}
export type WorkflowConditionGroup = {
  id: string
  group_type: ConditionType
  conditions: WorkflowCondition[]
  children_groups: WorkflowConditionGroup[]
}
export type WorkflowDefApprovalStep = {
  id: string
  approvers: WorkflowDefApprover[]
  condition: null | WorkflowConditionGroup
}
export type WorkflowStepStandardType = "details" | "compliance" | "it" | "finance" | "legal" | "close" | "custom"
export type WorkflowStepObject = {
  id: string
  object_type: CustomizableObjectType
  category: null | FieldCategory
  disabled_at: null | DateTimeString
}
export type WorkflowDefStep = {
  id: string
  display_name: NonEmptyTrimmedProseString
  approval_steps: WorkflowDefApprovalStep[]
  type: WorkflowStepStandardType
  condition: null | WorkflowConditionGroup
  objects: WorkflowStepObject[]
}
export type WorkflowDef = {
  id: string
  created_at: DateTimeString
  kind: WorkflowKind
  display_name: NonEmptyTrimmedProseString
  description: null | NonEmptyTrimmedProseString
  updated_at: DateTimeString
  steps: WorkflowDefStep[]
  last_updated_by: User
}
export type ApproverUserInput = {
  id: string
  organization_id: string
  first_name: null | NonEmptyTrimmedString
  last_name: null | NonEmptyTrimmedString
  email: EmailString
  profile_image: null | ImageAsset
  roles: Role[]
}
export type WorkflowDefUserApproverInput = {
  type: "user"
  id?: string
  user: ApproverUserInput
}
export type WorkflowDefVariableApproverInput = {
  type: "variable"
  variable: "manager"
  id?: string
}
export type WorkflowDefApproverInput =
  | ({
      type: "user"
    } & WorkflowDefUserApproverInput)
  | ({
      type: "variable"
    } & WorkflowDefVariableApproverInput)
export type WorkflowConditionInput = {
  field_name: NonEmptyTrimmedString
  object_type: ObjectType
  is_custom: boolean
  condition_schema: any
}
export type WorkflowConditionGroupInput = {
  group_type: ConditionType
  conditions: WorkflowConditionInput[]
  children_groups: WorkflowConditionGroupInput[]
}
export type WorkflowDefApprovalStepInput = {
  approvers: WorkflowDefApproverInput[]
  condition: null | WorkflowConditionGroupInput
}
export type WorkflowStepObjectInput = {
  object_type: CustomizableObjectType
  category: null | FieldCategory
  disabled_at: null | DateTimeString
}
export type WorkflowDefStepInput = {
  display_name: NonEmptyTrimmedProseString
  type: WorkflowStepStandardType
  step_configuration_input_id: number
  next_input_ids: number[]
  approval_steps: WorkflowDefApprovalStepInput[]
  condition: null | WorkflowConditionGroupInput
  objects: WorkflowStepObjectInput[]
}
export type WorkflowDefUpdate = {
  display_name: NonEmptyTrimmedProseString
  description?: null | NonEmptyTrimmedProseString
  steps: WorkflowDefStepInput[]
}
export type WorkflowDefInput = {
  kind: WorkflowKind
  display_name: NonEmptyTrimmedProseString
  description?: null | NonEmptyTrimmedProseString
  steps: WorkflowDefStepInput[]
}
export type WorkflowConditionResponse = {
  condition: WorkflowConditionGroupInput
}
export type WorkflowRunListResponse = {
  list: Request[]
  total: number
}
export type CreditCardPatch = {
  display_name?: null | NonEmptyTrimmedProseString
  spend_limit?: null | CurrencyAmount
  spend_limit_interval?: null | CreditCardInterval
  object_type?: "CreditCard"
  id?: null | string
  auto_lock_date?: null | DateString
}
export type LegalWorkflowFieldsMetadata = {
  total_contract_value?: FieldMetadataWithSuggestions
  start_date?: FieldMetadataWithSuggestions
  end_date?: FieldMetadataWithSuggestions
  first_invoice_date?: FieldMetadataWithSuggestions
  invoice_interval?: FieldMetadataWithSuggestions
  net_payment_term?: FieldMetadataWithSuggestions
  auto_renews?: FieldMetadataWithSuggestions
  auto_renewal_opt_out_period?: FieldMetadataWithSuggestions
  decision_date?: FieldMetadataWithSuggestions
  is_signed?: FieldMetadataWithSuggestions
  buyer_signer_name?: FieldMetadataWithSuggestions
  buyer_signer_title?: FieldMetadataWithSuggestions
  vendor_signer_name?: FieldMetadataWithSuggestions
  vendor_signer_title?: FieldMetadataWithSuggestions
  documents?: FieldMetadataWithSuggestions
  vendor?: FieldMetadataWithSuggestions
  tools?: FieldMetadataWithSuggestions
  clauses?: LegalClausesFieldsMetadata
  custom?: {
    [key: string]: FieldMetadataWithSuggestions
  }
}
export type LegalClausesPatch = {
  /** Whether the contract has an arbitration clause. The arbitration clause requires the parties to the contract to resolve any dispute arising out of the contract through an arbitration process, as opposed to litigation or mediation. */
  arbitration?: null | boolean
  /** Whether the contract has an assignment clause. The assignment clause indicates whether and when a party to the contract can assign their rights and obligations under the contract to someone else. */
  assignment?: null | boolean
  /** Whether the contract has a confidentiality clause. The confidentiality clause prevents one party (or both parties) from disclosing information related to the agreement, or other sensitive information. */
  confidentiality?: null | boolean
  /** Whether the contract has a data privacy clause. The data privacy clause may explain what private data will be collected, what the collecting party will do with the data, and/or how the data will be protected. */
  data_privacy?: null | boolean
  /** Whether the contract has a data processing clause. The data processing clause outlines the roles and responsibilities of the parties involved with regards to processing of personal data. It details the methods, purposes, and scope of the data processing activities carried out under the agreement. It may specify the rights and obligations of the data controller and data processor, and the safeguards and measures that must be in place to ensure data security and compliance with relevant data protection laws and regulations. */
  data_processing?: null | boolean
  /** Whether the contract has a data protection clause. The data protection clause ensures that the data given by one party to another is safeguarded from unlawful access by unauthorized users or entities. */
  data_protection?: null | boolean
  /** Whether the contract has a dispute resolution clause. The dispute resolution clause sets forth the circumstances under which any disputes between the parties should be resolved (e.g., whether the dispute should be resolved via arbitration, mediation, or litigation; jurisdiction, venue, etc.) */
  dispute_resolution?: null | boolean
  /** Whether the contract has a force majeure clause. The force majeure clause frees both parties from obligation if an extraordinary event directly prevents one or both parties from performing their obligations under the contract. */
  force_majeure?: null | boolean
  /** Whether the contract has a governing law clause. The governing law clause specifies which jurisdiction’s law will apply in the event of a dispute. */
  governing_law?: null | boolean
  /** Whether the contract has an indemnification clause. The indemnification clause obligates one party to compensate another party for certain costs and expenses, usually stemming from third party claims. */
  indemnification?: null | boolean
  /** Whether the contract has an intellectual property clause. The intellectual property clause sets forth ownership, permitted uses, and use restrictions of intellectual property. */
  intellectual_property?: null | boolean
  /** Whether the contract has a license clause. The license clause gives one party the right to use the product or services of the other party under certain conditions. */
  license?: null | boolean
  /** Whether the contract has a limitation of liability clause. The limitation of liability clause limits the amount and types of compensation one party can recover from the other party when a claim is made or lawsuit is filed in response to a breach of the contract. */
  limitation_of_liability?: null | boolean
  /** Whether the contract has a non disclosure clause. The non-disclosure clause prohibits the parties to the contract from releasing certain information to other parties. */
  non_disclosure?: null | boolean
  /** Whether the contract has a non solicitation clause. The non-solicitation clause prohibits a party from offering employment to the counterparty's employees, for a specified period of time. */
  non_solicitation?: null | boolean
  /** Whether the contract has a representation clause. The representations clause states that the representations contained in the contract are true and accurate as of the date of the agreement. */
  representation?: null | boolean
  /** Whether the contract has a termination clause. The termination clause explains what will happen if either party terminates the contract. The clause may also set forth the circumstances in which one party may terminate the contract, such as required notice. */
  termination?: null | boolean
  /** Whether the contract has a warranty clause. The warranty clause sets forth what promises, if any, are being made regarding the accuracy, completeness, or reliability of the subject matter of the contract, whether data, services, software, or otherwise. Warranty clauses can also specifically state that no such promises are being made or relied upon. */
  warranty?: null | boolean
}
export type LegalAgreementPatchOutput = {
  id: string
  object_type: "LegalAgreement"
  /** The name of the agreement. */
  display_name?: NonEmptyTrimmedProseString
  /** The total cost of the contract to the buyer over the entire period of the contract, or `null` if unknown. */
  total_contract_value?: null | CurrencyAmount
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date?: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date?: null | DateString
  /** The first date at which the buyer will be billed by the seller as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  first_invoice_date?: null | DateString
  /** Will the vendor invoice {buyer} monthly, quarterly, or annually? Look for a specific mention of when the buyer is invoiced. */
  invoice_interval?: null | FrequencyOrOneTime
  /** The amount of time that the buyer has to settle each bill, e.g. P30D for 30 days. `null` if unknown. Payment terms that are due upon receipt should return PT0S. */
  net_payment_term?: null | DateDurationString
  /** Whether the contract term automatically renews; true/false. */
  auto_renews?: null | boolean
  /** The period the buyer has to give notice to terminate the contract before the end of the current term. The period should be an ISO 8601 duration string (e.g., P30D for 30 days). */
  auto_renewal_opt_out_period?: null | DateDurationString
  /** The date when the buyer must decide whether to renew or cancel the contract. Renewal reminders are sent based on this date. */
  decision_date?: null | DateString
  /** Whether the contract was signed or is still unsigned */
  is_signed?: null | boolean
  /** The full name of the person who signed for the buying party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  buyer_signer_name?: null | NonEmptyTrimmedProseString
  /** The buying signer’s title (e.g., CEO). */
  buyer_signer_title?: null | NonEmptyTrimmedProseString
  /** The full name of the person who signed for the selling party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  vendor_signer_name?: null | NonEmptyTrimmedProseString
  /** The selling signer’s title (e.g., CEO). */
  vendor_signer_title?: null | NonEmptyTrimmedProseString
  custom?: CustomFields
  fields_metadata?: LegalWorkflowFieldsMetadata
  /** Any documents related to this requests. Documents can include an Order Form, Statement of Work, a Quote, Master Services Agreements, etc. */
  documents?: DocumentWithExtraction[]
  /** The legal clauses found in the contract. */
  clauses?: LegalClausesPatch
  tool_ids?: string[]
  vendor_id?: string
}
export type Tool4 = {
  id: string
  object_type: "Tool"
  /** Lists the specific Single Sign-On (SSO) methods the software supports */
  sso_types?: null | SsoType[]
  /** Lists the specific Multi-Factor Authentication methods the software supports */
  mfa_types?: null | MfaType[]
  /** Describes the specific methods used by the software to regulate data access */
  access_control_types?: null | AccessControlType[]
  /** Indicates how the software is deployed and accessed */
  deployment_types?: null | SoftwareDeploymentType[]
  /** Determines if the software provides System for Cross-domain Identity Management functionality */
  scim?: null | boolean
  /** Which other tools does this product integrate with? */
  integrates_with?: null | ToolListingPatch[]
  /** URL or Document containing the service’s privacy policy */
  privacy_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service’s terms of service */
  terms_of_service?: null | DocumentOrUrlString
  /** Risk level can be categorized as:
    
    - **High**: Vendor handles sensitive data or security services; critical business impact if they fail.
    - **Moderate**: Vendor doesn’t handle sensitive data or security; moderate business impact if they fail.
    - **Low**: Vendor has minimal involvement and little to no business impact if they fail.
    - **None**: Vendor has no impact on business operations. */
  customer_security_impact_level?: null | CustomerSecurityImpact
  /** A critical vendor has one or more of the following characteristics:
    
    1) Handles customer data,
    2) Is responsible for our infrastructure or code security,
    3) Has cloud services that affect our system’s security or availability,
    4) Whose failure would result in disruption to our service availability. */
  is_critical?: null | boolean
  /** Whether the product or service is a data subprocessor */
  is_subprocessor?: null | boolean
  /** Link to a vendor’s list of third-party subprocessors */
  subprocessors?: null | HttpUrlString
  /** Whether the product or service is Securities Exchange Act Rule 17a-4(f) compliant */
  sea_rule_17a4f?: null | boolean
  /** URL or Document containing the service's cybersecurity policy */
  cybersecurity_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's information security policy */
  information_security_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's Anti-Money Laundering policy */
  aml_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's financial stability evidence */
  financial_stability?: null | DocumentOrUrlString
  /** Whether the product or service stores personal identifying information */
  stores_pii?: null | boolean
  /** The types of personal identifiable information the product or service collects or retains */
  pii_data_types?: null | PiiDataType[]
  /** The types of people whose personal identifiable information the product or service collects or retains */
  pii_person_types?: null | PiiPersonType[]
  /** The regions whose personal identifiable information the product or service collects or retains */
  pii_regions?: null | PiiRegion[]
  /** The types of data the product or service collects or retains */
  data_stored?: null | ComplianceData[]
  /** Whether the product or service has SOC 2 compliance certification */
  soc2?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Health Insurance Portability and Accountability Act compliance certification */
  hipaa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has a General Data Protection Regulation compliance certification */
  gdpr?: null | CompliantWithDocumentPatch
  /** Whether the product or service has ISO 27001 compliance certification */
  iso_27001?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Payment Card Industry Data Security Standard compliance certification */
  pci_dss?: null | CompliantWithDocumentPatch
  /** Whether the product or service has California Consumer Privacy Act compliance certification */
  ccpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Colorado Privacy Act compliance certification */
  cdpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Cybersecurity Maturity Model Certification 2.0 compliance certification */
  cmmc_2?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Colorado Privacy Act compliance certification */
  cpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has California Privacy Rights Act compliance certification */
  cpra?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Connecticut Data Protection Act compliance certification */
  ctdpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has EU Whistleblower compliance certification */
  eu_whistleblower?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Nevada Privacy Law compliance certification */
  nevada_privacy_law?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Sarbanes-Oxley Act compliance certification */
  sox?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Uniform Consumer Credit Protection Act compliance certification */
  ucpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service stores data in write-once, read-many (WORM) format */
  worm?: null | ApplicableWithDocumentPatch
  /** The Data Processing Agreement for a tool */
  dpa?: null | DocumentMinimal
  /** What problem(s) will this tool solve and how? */
  overview?: null | RichText
  /** Why are you choosing this product over alternative tools and services? */
  selection_rationale?: null | RichText
  /** What are the areas of concern or gaps this tool has? */
  risks_limitations?: null | RichText
  /** What’s the estimated return on investment (ROI) for this tool? */
  roi_analysis?: null | RichText
  /** How long will it take to implement and fully deploy? */
  implementation_details?: null | RichText
  /** What level of support is provided by the vendor for the selected plan? */
  support_from_vendor?: null | RichText
  /** What other tools and services were considered during the evaluation of this tool? */
  tools_considered?: ToolListingPatch[]
  custom?: CustomFields
  fields_metadata?: WorkflowFieldsMetadata
}
export type VendorPatchOutput = {
  id: string
  object_type: "Vendor"
  /** What problem(s) will this vendor solve and how? */
  problems_addressed?: null | RichText
  /** Why are you choosing this vendor over alternative vendors? */
  selection_rationale?: null | RichText
  /** What’s the estimated return on investment (ROI) for this vendor? */
  roi_analysis?: null | RichText
  /** The year the company was founded */
  founded_year?: null | number
  /** Is the vendor a broker dealer? */
  is_broker_dealer?: null | boolean
  /** The unique number assigned to a business entity by the Internal Revenue Service (IRS). Used for tax filing and reporting purposes */
  ein?: null | EmploymentIdentificationNumber
  /** A nine-digit number assigned by Dun & Bradstreet for business identification and credit reporting */
  duns?: null | DunsNumber
  /** Whether the company has experienced any cybersecurity breaches and any related details */
  cybersecurity_breaches?: null | BooleanWithRichTextDetails
  /** Whether the company has any ongoing or pending litigation and any related details */
  litigations?: null | BooleanWithRichTextDetails
  /** The Non-Disclosure Agreement document for the vendor */
  nda?: null | DocumentMinimal
  /** The vendor's bank information */
  bank_info?: null | DocumentMinimal
  /** The vendor's W-9 */
  w9?: null | DocumentMinimal
  custom?: CustomFields
  fields_metadata?: WorkflowFieldsMetadata
}
export type WorkflowRunStatePatch = {
  id: string
  object_type: "WorkflowRun"
  /** The amount of money being requested for this request */
  requested_spend?: null | CurrencyAmount
  /** How often the requested spend amount will be charged (e.g. monthly, annually, or one-time) */
  requested_spend_frequency?: null | FrequencyOrOneTime
  /** The number of payments requested for this request */
  requested_payments_count?: null | number
  /** The method that will be used to pay for this request */
  payment_method?: null | TransactionPaymentMethod
  custom?: CustomFields
  assigned_credit_card?: null | CreditCardPatch
  fields_metadata?: WorkflowFieldsMetadata
  legal_agreement?: LegalAgreementPatchOutput
  tools?: Tool4[]
  vendor?: null | VendorPatchOutput
}
export type InvitedSellerUser = {
  first_name: null | NonEmptyTrimmedProseString
  last_name: null | NonEmptyTrimmedProseString
  email: EmailString
  profile_image?: null | ImageAsset
  is_pending: boolean
  invited_at: DateTimeString
  opened_at: null | DateTimeString
}
export type WorkflowRunFieldGatherer = {
  object_id: string
  object_type: ObjectType
  field_name: NonEmptyTrimmedString
  gatherer: null | User
  gatherer_is_seller: boolean
  updated_at: null | DateTimeString
}
export type WorkflowRunFieldGathererLookupMap = {
  [key: string]: {
    [key: string]: WorkflowRunFieldGatherer
  }
}
export type WorkflowRunStepStatus =
  | "pending"
  | "pending_submitted"
  | "in_progress"
  | "submitted"
  | "completed"
  | "approved"
  | "rejected"
  | "cancelled"
  | "skipped"
export type WorkflowRunApprover = {
  id: string
  type: "user"
  user: User
}
export type WorkflowRunApprovalStep = {
  id: string
  approvers: WorkflowRunApprover[]
  approved_by: null | User
  condition: null | WorkflowConditionGroup
  skipped_by_conditional: boolean
}
export type FieldCounts = {
  completed: number
  completed_required: number
  required: number
  total: number
  approved: number
}
export type WorkflowRunStepFieldApprovalStatus = "approved" | "change_requested" | "needs_review"
export type WorkflowRunStepFieldApproval = {
  id: string
  status: WorkflowRunStepFieldApprovalStatus
  object_id: string
  object_type: ObjectType
  field_name: NonEmptyTrimmedString
  user: User
  updated_at: null | DateTimeString
}
export type WorkflowRunStepFieldApprovalLookupMap = {
  [key: string]: {
    [key: string]: WorkflowRunStepFieldApproval
  }
}
export type WorkflowRunStepWithContext = {
  id: string
  workflow_run_id: string
  status: WorkflowRunStepStatus
  display_name: NonEmptyTrimmedProseString
  type: WorkflowStepStandardType
  objects: WorkflowStepObject[]
  submitted_at: null | DateTimeString
  seller_submitted_at: null | DateTimeString
  completed_at: null | DateTimeString
  owner: User
  approval_steps: WorkflowRunApprovalStep[]
  condition: null | WorkflowConditionGroup
  next: string[]
  field_counts: FieldCounts
  field_approvals: WorkflowRunStepFieldApprovalLookupMap
  submitted_by: null | User
  draft_state_updated_at: null | DateTimeString
  draft_state_updated_by: null | User
  comment_count: number
}
export type WorkflowRunSteps = WorkflowRunStepWithContext[]
export type CommentCounts = {
  [key: string]: {
    [key: string]: number
  }
}
export type ToolWithOwner = {
  /** The name of the tool */
  display_name: NonEmptyTrimmedString
  /** The description of the tool (what the tool does) */
  description: null | NonEmptyTrimmedProseString
  /** The URL of the tool's website */
  website: null | HttpUrlString
  /** The image asset for the tool's logo */
  image_asset: null | ImageAssetMinimal
  id: string
  object_type: "Tool"
  tool_listing_id: string
  created_at: DateTimeString
  updated_at?: null | DateTimeString
  /** The overall approval status of the tool */
  status: ToolStatus
  /** The financial approval status of the tool */
  finance_status?: ToolFinanceStatus
  /** The compliance approval status of the tool */
  compliance_status?: ToolComplianceStatus
  /** The IT approval status of the tool */
  it_status?: ToolItStatus
  /** The person in the organization responsible for the tool */
  owner: null | Person
}
export type VendorWithOwner = {
  /** The company name of the vendor */
  display_name: NonEmptyTrimmedString
  image_asset: null | ImageAssetMinimal
  /** The description of the vendor */
  description: null | NonEmptyTrimmedProseString
  /** The URL of the vendor’s website */
  website: null | HttpUrlString
  id: string
  object_type: "Vendor"
  vendor_listing_id: string
  created_at: DateTimeString
  /** The overall approval status of the vendor */
  status: VendorStatus
  /** The financial approval status of the vendor */
  finance_status?: VendorFinanceStatus
  /** The compliance approval status of the vendor */
  compliance_status?: VendorComplianceStatus
  /** The IT approval status of the vendor */
  it_status?: VendorItStatus
  /** The person in the organization responsible for the vendor */
  owner: null | Person
}
export type LinkWithAuthCode = {
  id: string
  created_at: DateTimeString
  first_name: null | NonEmptyTrimmedProseString
  last_name: null | NonEmptyTrimmedProseString
  email: EmailString
  body: null | NonEmptyTrimmedProseString
  auth_code?: NonEmptyTrimmedString
}
export type WorkflowRun = {
  id: string
  object_type: "WorkflowRun"
  /** The name of the request */
  display_name: NonEmptyTrimmedProseString
  created_at: DateTimeString
  /** The type of request e.g. purchase, renewal, or gather data */
  kind: WorkflowKind
  status: WorkflowRunStatus
  /** The date and time that the request was completed */
  completed_at: null | DateTimeString
  /** The target date by which this request should be completed */
  target_date: DateString
  /** The amount of money being requested for this request */
  requested_spend: null | CurrencyAmount
  /** How often the requested spend amount will be charged (e.g. monthly, annually, or one-time) */
  requested_spend_frequency: null | FrequencyOrOneTime
  /** The number of payments requested for this request */
  requested_payments_count: null | number
  /** The credit card that has been assigned to this request for payment */
  assigned_credit_card: null | CreditCardWithVendors
  /** The method that will be used to pay for this request */
  payment_method: null | TransactionPaymentMethod
  custom: CustomFields
  fields_metadata?: WorkflowFieldsMetadata
  /** The user who created this request */
  created_by: User
  /** The user who owns and is responsible for this request */
  owner: User
  context_schema: any
  merged_state: WorkflowRunStatePatch
  seller_users: InvitedSellerUser[]
  seller_due_date: null | DateString
  field_gatherers: WorkflowRunFieldGathererLookupMap
  buyer_organization_image_asset: null | ImageAsset
  steps: WorkflowRunSteps
  comment_counts: CommentCounts
  active_legal_agreement: null | LegalAgreement
  tools: ToolWithOwner[]
  vendor: null | VendorWithOwner
  links: LinkWithAuthCode[]
  assigned_credit_card_id: null | string
}
export type LinkWorkflowSellerInvite = {
  message: null | NonEmptyTrimmedProseString
  due_date: null | DateString
  recipients: {
    email: EmailString
    first_name: NonEmptyTrimmedProseString
    last_name: NonEmptyTrimmedProseString
  }[]
}
export type SoftwarePurchaseWorkflowRunInput = {
  owner_id: string
  employee_group_ids: string[]
  tool_owner_id?: string
  vendor_owner_id?: string
  link_invite: null | LinkWorkflowSellerInvite
  /** The name of the request */
  display_name: NonEmptyTrimmedProseString
  /** The target date by which this request should be completed */
  target_date: DateString
  kind: "software_purchase"
  tools: ToolOption[]
}
export type VendorPurchaseWorkflowRunInput = {
  owner_id: string
  employee_group_ids: string[]
  tool_owner_id?: string
  vendor_owner_id?: string
  link_invite: null | LinkWorkflowSellerInvite
  /** The name of the request */
  display_name: NonEmptyTrimmedProseString
  /** The target date by which this request should be completed */
  target_date: DateString
  kind: "vendor_purchase"
  vendor: VendorOption
}
export type SoftwareRenewalWorkflowRunInput = {
  owner_id: string
  employee_group_ids: string[]
  tool_owner_id?: string
  vendor_owner_id?: string
  link_invite: null | LinkWorkflowSellerInvite
  /** The name of the request */
  display_name: NonEmptyTrimmedProseString
  /** The target date by which this request should be completed */
  target_date: DateString
  kind: "software_renewal"
  active_legal_agreement_id?: string
  new_legal_agreement_name?: NonEmptyTrimmedString
  tools?: ToolOption[]
}
export type VendorRenewalWorkflowRunInput = {
  owner_id: string
  employee_group_ids: string[]
  tool_owner_id?: string
  vendor_owner_id?: string
  link_invite: null | LinkWorkflowSellerInvite
  /** The name of the request */
  display_name: NonEmptyTrimmedProseString
  /** The target date by which this request should be completed */
  target_date: DateString
  kind: "vendor_renewal"
  active_legal_agreement_id?: string
  new_legal_agreement_name?: NonEmptyTrimmedString
  vendor?: VendorOption
}
export type GatherDataWorkflowRunInput = {
  owner_id: string
  employee_group_ids: string[]
  tool_owner_id?: string
  vendor_owner_id?: string
  link_invite: null | LinkWorkflowSellerInvite
  /** The name of the request */
  display_name: NonEmptyTrimmedProseString
  /** The target date by which this request should be completed */
  target_date: DateString
  kind: "gather_data"
  category: FieldCategory
  tool: ToolOption
}
export type WorkflowRunInput =
  | ({
      kind: "software_purchase"
    } & SoftwarePurchaseWorkflowRunInput)
  | ({
      kind: "vendor_purchase"
    } & VendorPurchaseWorkflowRunInput)
  | ({
      kind: "software_renewal"
    } & SoftwareRenewalWorkflowRunInput)
  | ({
      kind: "vendor_renewal"
    } & VendorRenewalWorkflowRunInput)
  | ({
      kind: "gather_data"
    } & GatherDataWorkflowRunInput)
export type WorkflowRunUpdatePatch = {
  owner_id?: string
  /** The name of the request */
  display_name?: NonEmptyTrimmedProseString
  /** The target date by which this request should be completed */
  target_date?: DateString
}
export type SetFieldGatherRequest = {
  gatherer_id: null | string
  is_custom: boolean
  workflow_run_step_id: string
}
export type WorkflowRunApproverUpdate = {
  id: string
  type: "user"
  user: {
    id: string
  }
}
export type WorkflowRunApprovalStepUpdate = {
  id: string
  approvers: WorkflowRunApproverUpdate[]
}
export type WorkflowRunStepsUpdatePatch = {
  id: string
  owner?: {
    id: string
  }
  approval_steps: WorkflowRunApprovalStepUpdate[]
}[]
export type SellerLinkData = {
  link: LinkWithAuthCode
  workflowRun: WorkflowRunMinimal
  buyerOrganization: Organization
}
export type SellerLinksResponse = {
  links: SellerLinkData[]
}
export type BrmLinkMinimal = {
  id: string
  created_at: DateTimeString
  first_name: null | NonEmptyTrimmedProseString
  last_name: null | NonEmptyTrimmedProseString
  email: EmailString
  body: null | NonEmptyTrimmedProseString
}
export type CreateBrmLinkInput = {
  email: EmailString
  workflow_run_id: string
  is_seller: boolean
}
export type WorkflowFieldsMetadataInput = {
  [key: string]:
    | {
        [key: string]: FieldMetadata
      }
    | FieldMetadata
}
export type LegalWorkflowFieldsMetadataInput = {
  total_contract_value?: FieldMetadata
  start_date?: FieldMetadata
  end_date?: FieldMetadata
  first_invoice_date?: FieldMetadata
  invoice_interval?: FieldMetadata
  net_payment_term?: FieldMetadata
  auto_renews?: FieldMetadata
  auto_renewal_opt_out_period?: FieldMetadata
  decision_date?: FieldMetadata
  is_signed?: FieldMetadata
  buyer_signer_name?: FieldMetadata
  buyer_signer_title?: FieldMetadata
  vendor_signer_name?: FieldMetadata
  vendor_signer_title?: FieldMetadata
  documents?: FieldMetadata
  vendor?: FieldMetadata
  tools?: FieldMetadata
  clauses?: LegalClausesInputFieldsMetadata
  custom?: {
    [key: string]: FieldMetadata
  }
}
export type LegalAgreementPatchInput = {
  id: string
  object_type: "LegalAgreement"
  /** The name of the agreement. */
  display_name?: NonEmptyTrimmedProseString
  /** The total cost of the contract to the buyer over the entire period of the contract, or `null` if unknown. */
  total_contract_value?: null | CurrencyAmount
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date?: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date?: null | DateString
  /** The first date at which the buyer will be billed by the seller as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  first_invoice_date?: null | DateString
  /** Will the vendor invoice {buyer} monthly, quarterly, or annually? Look for a specific mention of when the buyer is invoiced. */
  invoice_interval?: null | FrequencyOrOneTime
  /** The amount of time that the buyer has to settle each bill, e.g. P30D for 30 days. `null` if unknown. Payment terms that are due upon receipt should return PT0S. */
  net_payment_term?: null | DateDurationString
  /** Whether the contract term automatically renews; true/false. */
  auto_renews?: null | boolean
  /** The period the buyer has to give notice to terminate the contract before the end of the current term. The period should be an ISO 8601 duration string (e.g., P30D for 30 days). */
  auto_renewal_opt_out_period?: null | DateDurationString
  /** The date when the buyer must decide whether to renew or cancel the contract. Renewal reminders are sent based on this date. */
  decision_date?: null | DateString
  /** Whether the contract was signed or is still unsigned */
  is_signed?: null | boolean
  /** The full name of the person who signed for the buying party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  buyer_signer_name?: null | NonEmptyTrimmedProseString
  /** The buying signer’s title (e.g., CEO). */
  buyer_signer_title?: null | NonEmptyTrimmedProseString
  /** The full name of the person who signed for the selling party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  vendor_signer_name?: null | NonEmptyTrimmedProseString
  /** The selling signer’s title (e.g., CEO). */
  vendor_signer_title?: null | NonEmptyTrimmedProseString
  custom?: CustomFields
  /** The legal documents of the agreement. */
  documents?: DocumentMinimal[]
  fields_metadata?: LegalWorkflowFieldsMetadataInput
  clauses?: LegalClausesPatch
  tool_ids?: string[]
  vendor_id?: string
}
export type Tool5 = {
  id: string
  object_type: "Tool"
  /** Lists the specific Single Sign-On (SSO) methods the software supports */
  sso_types?: null | SsoType[]
  /** Lists the specific Multi-Factor Authentication methods the software supports */
  mfa_types?: null | MfaType[]
  /** Describes the specific methods used by the software to regulate data access */
  access_control_types?: null | AccessControlType[]
  /** Indicates how the software is deployed and accessed */
  deployment_types?: null | SoftwareDeploymentType[]
  /** Determines if the software provides System for Cross-domain Identity Management functionality */
  scim?: null | boolean
  /** Which other tools does this product integrate with? */
  integrates_with?: null | ToolListingPatch[]
  /** URL or Document containing the service’s privacy policy */
  privacy_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service’s terms of service */
  terms_of_service?: null | DocumentOrUrlString
  /** Risk level can be categorized as:
    
    - **High**: Vendor handles sensitive data or security services; critical business impact if they fail.
    - **Moderate**: Vendor doesn’t handle sensitive data or security; moderate business impact if they fail.
    - **Low**: Vendor has minimal involvement and little to no business impact if they fail.
    - **None**: Vendor has no impact on business operations. */
  customer_security_impact_level?: null | CustomerSecurityImpact
  /** A critical vendor has one or more of the following characteristics:
    
    1) Handles customer data,
    2) Is responsible for our infrastructure or code security,
    3) Has cloud services that affect our system’s security or availability,
    4) Whose failure would result in disruption to our service availability. */
  is_critical?: null | boolean
  /** Whether the product or service is a data subprocessor */
  is_subprocessor?: null | boolean
  /** Link to a vendor’s list of third-party subprocessors */
  subprocessors?: null | HttpUrlString
  /** Whether the product or service is Securities Exchange Act Rule 17a-4(f) compliant */
  sea_rule_17a4f?: null | boolean
  /** URL or Document containing the service's cybersecurity policy */
  cybersecurity_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's information security policy */
  information_security_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's Anti-Money Laundering policy */
  aml_policy?: null | DocumentOrUrlString
  /** URL or Document containing the service's financial stability evidence */
  financial_stability?: null | DocumentOrUrlString
  /** Whether the product or service stores personal identifying information */
  stores_pii?: null | boolean
  /** The types of personal identifiable information the product or service collects or retains */
  pii_data_types?: null | PiiDataType[]
  /** The types of people whose personal identifiable information the product or service collects or retains */
  pii_person_types?: null | PiiPersonType[]
  /** The regions whose personal identifiable information the product or service collects or retains */
  pii_regions?: null | PiiRegion[]
  /** The types of data the product or service collects or retains */
  data_stored?: null | ComplianceData[]
  /** Whether the product or service has SOC 2 compliance certification */
  soc2?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Health Insurance Portability and Accountability Act compliance certification */
  hipaa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has a General Data Protection Regulation compliance certification */
  gdpr?: null | CompliantWithDocumentPatch
  /** Whether the product or service has ISO 27001 compliance certification */
  iso_27001?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Payment Card Industry Data Security Standard compliance certification */
  pci_dss?: null | CompliantWithDocumentPatch
  /** Whether the product or service has California Consumer Privacy Act compliance certification */
  ccpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Colorado Privacy Act compliance certification */
  cdpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Cybersecurity Maturity Model Certification 2.0 compliance certification */
  cmmc_2?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Colorado Privacy Act compliance certification */
  cpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has California Privacy Rights Act compliance certification */
  cpra?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Connecticut Data Protection Act compliance certification */
  ctdpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service has EU Whistleblower compliance certification */
  eu_whistleblower?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Nevada Privacy Law compliance certification */
  nevada_privacy_law?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Sarbanes-Oxley Act compliance certification */
  sox?: null | CompliantWithDocumentPatch
  /** Whether the product or service has Uniform Consumer Credit Protection Act compliance certification */
  ucpa?: null | CompliantWithDocumentPatch
  /** Whether the product or service stores data in write-once, read-many (WORM) format */
  worm?: null | ApplicableWithDocumentPatch
  /** The Data Processing Agreement for a tool */
  dpa?: null | DocumentMinimal
  /** What problem(s) will this tool solve and how? */
  overview?: null | RichText
  /** Why are you choosing this product over alternative tools and services? */
  selection_rationale?: null | RichText
  /** What are the areas of concern or gaps this tool has? */
  risks_limitations?: null | RichText
  /** What’s the estimated return on investment (ROI) for this tool? */
  roi_analysis?: null | RichText
  /** How long will it take to implement and fully deploy? */
  implementation_details?: null | RichText
  /** What level of support is provided by the vendor for the selected plan? */
  support_from_vendor?: null | RichText
  /** What other tools and services were considered during the evaluation of this tool? */
  tools_considered?: ToolListingPatch[]
  custom?: CustomFields
  fields_metadata?: WorkflowFieldsMetadata
}
export type VendorPatchInput = {
  id: string
  object_type: "Vendor"
  /** What problem(s) will this vendor solve and how? */
  problems_addressed?: null | RichText
  /** Why are you choosing this vendor over alternative vendors? */
  selection_rationale?: null | RichText
  /** What’s the estimated return on investment (ROI) for this vendor? */
  roi_analysis?: null | RichText
  /** The year the company was founded */
  founded_year?: null | number
  /** Is the vendor a broker dealer? */
  is_broker_dealer?: null | boolean
  /** The unique number assigned to a business entity by the Internal Revenue Service (IRS). Used for tax filing and reporting purposes */
  ein?: null | EmploymentIdentificationNumber
  /** A nine-digit number assigned by Dun & Bradstreet for business identification and credit reporting */
  duns?: null | DunsNumber
  /** Whether the company has experienced any cybersecurity breaches and any related details */
  cybersecurity_breaches?: null | BooleanWithRichTextDetails
  /** Whether the company has any ongoing or pending litigation and any related details */
  litigations?: null | BooleanWithRichTextDetails
  /** The Non-Disclosure Agreement document for the vendor */
  nda?: null | DocumentMinimal
  /** The vendor's bank information */
  bank_info?: null | DocumentMinimal
  /** The vendor's W-9 */
  w9?: null | DocumentMinimal
  custom?: CustomFields
  fields_metadata?: WorkflowFieldsMetadataInput
}
export type WorkflowRunStatePatchInput = {
  id: string
  object_type: "WorkflowRun"
  /** The amount of money being requested for this request */
  requested_spend?: null | CurrencyAmount
  /** How often the requested spend amount will be charged (e.g. monthly, annually, or one-time) */
  requested_spend_frequency?: null | FrequencyOrOneTime
  /** The number of payments requested for this request */
  requested_payments_count?: null | number
  /** The method that will be used to pay for this request */
  payment_method?: null | TransactionPaymentMethod
  custom?: CustomFields
  assigned_credit_card?: null | CreditCardPatch
  fields_metadata?: WorkflowFieldsMetadataInput
  legal_agreement?: LegalAgreementPatchInput
  tools?: Tool5[]
  vendor?: null | VendorPatchInput
}
export type WorkflowRunStepDraftStateUpdateInput = {
  state: null | WorkflowRunStatePatchInput
  submit: boolean
}
export type BrmLink = {
  id: string
  created_at: DateTimeString
  first_name: null | NonEmptyTrimmedProseString
  last_name: null | NonEmptyTrimmedProseString
  email: EmailString
  body: null | NonEmptyTrimmedProseString
  sender: null | UserMinimal
}
export type WorkflowStepRunSubmission = {
  decision: "proceed" | "abort"
  state: WorkflowRunStatePatchInput
  link_auth_code?: NonEmptyTrimmedString
}
export type FormFieldConfig = {
  object_type: ObjectType
  field_name: NonEmptyTrimmedString
  is_custom: boolean
  is_required: boolean
  is_internal_only: boolean
  field_title: NonEmptyTrimmedString
}
export type CreditCardTransaction = {
  integration: IntegrationPublicFields
  external_transaction_id: NonEmptyString
  external_expense_id: null | NonEmptyString
  memo: null | NonEmptyTrimmedString
}
export type AccountLike = {
  display_name: null | NonEmptyTrimmedProseString
}
export type BillPayment = {
  external_id: NonEmptyString
  merge_remote_id: null | NonEmptyString
  account: null | AccountLike
  bill: null | {
    line_items: {
      account: null | AccountLike
    }[]
    memo: null | NonEmptyString
  }
  memo: null | NonEmptyString
  integration: IntegrationPublicFields
}
export type Expense = {
  external_id: NonEmptyString
  merge_remote_id: null | NonEmptyString
  account: null | AccountLike
  line_items: {
    account: null | AccountLike
  }[]
  memo: null | NonEmptyString
  integration: IntegrationPublicFields
}
export type CreditCardMinimal = {
  id: string
  created_at: DateTimeString
  display_name?: null | NonEmptyTrimmedProseString
  spend_limit?: null | CurrencyAmount
  spend_limit_interval?: null | CreditCardInterval
  last_four?: null | NonEmptyString
  card_task_status?: null | CreditCardTaskStatus
  external_card_id?: null | string
  external_limit_id?: null | string
  status?: null | CreditCardStatus
  object_type: "CreditCard"
  owner?: null | Person
  /** The earlier date between the expiration or auto lock date */
  valid_until?: null | DateString
  auto_lock_date?: null | DateString
}
export type Transaction = {
  object_type: "ReconciledTransaction"
  id: string
  organization_id: string
  created_at: DateTimeString
  currency_amount: CurrencyAmount
  transacted_at: DateTimeString
  merchant_identification: NonEmptyTrimmedString
  merchant_name: NonEmptyString
  memo: null | NonEmptyTrimmedString
  person: null | Person
  receipts: Receipt[]
  transaction_category: null | TransactionCategory
  payment_method: null | TransactionPaymentMethod
  integrations: IntegrationPublicFields[]
  tool: null | Tool
  vendor: Vendor
  cc_transaction: null | CreditCardTransaction
  bank_memo: null | NonEmptyTrimmedString
  bank_account: null | AccountLike
  bill_payment: null | BillPayment
  expense: null | Expense
  card: null | CreditCardMinimal
  /** The department or class categorization for the transaction from your accounting system */
  erp_tracking_categories: ErpTrackingCategoryGroups
}
export type TransactionListResponse = {
  transactions: Transaction[]
  total: number
}
export type TableIdentifier =
  | "tools"
  | "vendors"
  | "persons"
  | "transactions"
  | "tool-logins"
  | "tool-users"
  | "workflow-runs"
  | "legal-agreements"
  | "credit-cards"
  | "documents"
export type SavedView = {
  id: string
  organization_id: string
  owner_id: null | string
  table_identifier: TableIdentifier
  name: NonEmptyTrimmedString
  is_default: boolean
  is_shared: boolean
  /** The list of property paths that are displayed as columns in the table of the saved view. */
  column_params: null | PropertyPathString[]
  /** The filters as encoded in the URL, i.e. `new URLSearchParams(savedView.filter_params).toString()` will return the URL query string for the filters. */
  filter_params: null | {
    [key: string]: string
  }
  /** The sort order as encoded in the URL, i.e. `new URLSearchParams(savedView.sort_params).toString()` will return the URL query string for the sort order. */
  sort_params: null | {
    sort?: FilterPathString
    sort_desc?: FilterPathString
  }
}
export type EditSavedView = {
  name?: NonEmptyTrimmedString
  is_shared?: boolean
  /** The list of property paths that are displayed as columns in the table of the saved view. */
  column_params?: null | PropertyPathString[]
  /** The filters as encoded in the URL, i.e. `new URLSearchParams(savedView.filter_params).toString()` will return the URL query string for the filters. */
  filter_params?: null | {
    [key: string]: string
  }
  /** The sort order as encoded in the URL, i.e. `new URLSearchParams(savedView.sort_params).toString()` will return the URL query string for the sort order. */
  sort_params?: null | {
    sort?: FilterPathString
    sort_desc?: FilterPathString
  }
}
export type CreateSavedView = {
  table_identifier: TableIdentifier
  name: NonEmptyTrimmedString
  is_shared: boolean
  /** The list of property paths that are displayed as columns in the table of the saved view. */
  column_params: null | PropertyPathString[]
  /** The filters as encoded in the URL, i.e. `new URLSearchParams(savedView.filter_params).toString()` will return the URL query string for the filters. */
  filter_params: null | {
    [key: string]: string
  }
  /** The sort order as encoded in the URL, i.e. `new URLSearchParams(savedView.sort_params).toString()` will return the URL query string for the sort order. */
  sort_params: null | {
    sort?: FilterPathString
    sort_desc?: FilterPathString
  }
}
export type ToolCall = {
  function: {
    name: string
    arguments: string
  }
}
export type Citation = {
  /** The key corresponding to a citation citation tag in the embedded_response */
  citation_id: string
  source: {
    id: string
    object_type: string
    /** The name of the source document */
    display_name: string
    /** The link to the source document */
    link: string
  }
  /** The snippet of the raw text that was referenced from the source document. */
  source_snippet: string
}
export type Message = {
  role: "user" | "assistant"
  content: string
  rich_text?: null | RichText
  tool_calls: null | ToolCall[]
  citations: null | Citation[]
}
export type Conversation = {
  id: string
  user_id: string
  user: UserMinimal
  display_name: string
  created_at: DateTimeString
  messages: Message[]
}
export type Reaction = {
  id: string
  created_at: DateTimeString
  emoji: NonEmptyTrimmedString
  user: UserMinimal
}
export type AggregatedReaction = {
  emoji: NonEmptyTrimmedString
  reactions: Reaction[]
  count: number
}
export type NonPendingUser = {
  id: string
  object_type: "User"
  organization_id: string
  email: EmailString
  profile_image: null | ImageAsset
  created_at: DateTimeString
  person: Person
  roles: Role[]
  first_name: NonEmptyTrimmedString
  last_name: NonEmptyTrimmedString
  status: "active" | "deleted"
}
export type AlternateActorType = "unregistered_seller_user" | "system" | "unknown"
export type CommentReplyTask = {
  id: string
  recipient: User
  due_by: DateString
  resolved_at: null | DateTimeString
  resolved_by: null | string
  dismissed_at: null | DateTimeString
}
export type TimelineEventReplyTimelineEvent = {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: null | string
  object_id: string
  object_type: ObjectType
  type: "timeline_event_reply"
  actor: NonPendingUser | AlternateActorType
  rich_text_body: RichText
  parent_id: string
  reply_tasks: null | CommentReplyTask[]
}
export type Login = {
  object_type: "Login"
  organization_id: string
  created_at: DateTimeString
  authorized_at: DateTimeString
  event_type: LoginEventType
  app_name: NonEmptyString
  integration: IntegrationPublicFields
  person: Person
  external_event_id: NonEmptyString
  scopes: LoginScope[]
  tool: ToolMinimal
}
export type LegalAgreementWithMinimalRelations = {
  /** The total cost of the contract to the buyer over the entire period of the contract, or `null` if unknown. */
  total_contract_value?: null | CurrencyAmount
  /** When the contract becomes effective as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  start_date: null | DateString
  /** When the contract ends as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  end_date: null | DateString
  /** The first date at which the buyer will be billed by the seller as an ISO8601 YYYY-MM-DD date string, or `null` if unknown. */
  first_invoice_date?: null | DateString
  /** Will the vendor invoice {buyer} monthly, quarterly, or annually? Look for a specific mention of when the buyer is invoiced. */
  invoice_interval?: null | FrequencyOrOneTime
  /** The amount of time that the buyer has to settle each bill, e.g. P30D for 30 days. `null` if unknown. Payment terms that are due upon receipt should return PT0S. */
  net_payment_term?: null | DateDurationString
  /** Whether the contract term automatically renews; true/false. */
  auto_renews?: null | boolean
  /** The period the buyer has to give notice to terminate the contract before the end of the current term. The period should be an ISO 8601 duration string (e.g., P30D for 30 days). */
  auto_renewal_opt_out_period?: null | DateDurationString
  /** The date when the buyer must decide whether to renew or cancel the contract. Renewal reminders are sent based on this date. */
  decision_date?: null | DateString
  /** Whether the contract was signed or is still unsigned */
  is_signed: null | boolean
  /** The full name of the person who signed for the buying party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  buyer_signer_name?: null | NonEmptyTrimmedProseString
  /** The buying signer’s title (e.g., CEO). */
  buyer_signer_title?: null | NonEmptyTrimmedProseString
  /** The full name of the person who signed for the selling party of the agreement. This is NOT a company name but a human person. `null` if unknown. */
  vendor_signer_name?: null | NonEmptyTrimmedProseString
  /** The selling signer’s title (e.g., CEO). */
  vendor_signer_title?: null | NonEmptyTrimmedProseString
  /** The legal clauses found in the contract. */
  clauses?: LegalClauses
  custom: CustomFields
  id: string
  object_type: "LegalAgreement"
  /** Whether the Legal Agreement is active, expired, or upcoming. Will be unknown if the agreement has not been verified or is missing start/end dates. */
  effective_status: AgreementEffectiveStatus
  /** The type of agreement (enterprise or subscription) */
  agreement_type: AgreementType
  /** The name of the agreement. */
  display_name: NonEmptyTrimmedProseString
  usd_total_contract_value?: null | NumericString
  /** The next occurrence of the billing interval (based on the first invoice date) */
  next_invoice_date?: null | DateString
  created_at: DateTimeString
  updated_at?: null | DateTimeString
  fields_metadata: LegalAgreementFieldsMetadata
  /** Legal agreements that enter through certain sources, like bulk upload or email, need to have their extracted fields verified. Manually created agreements always start verified. Verified agreements may become unverified when more documents are added. */
  verification_status: AgreementVerificationStatus
  visibility_status: AgreementVisibilityStatus
  vendor: null | VendorMinimal
  tools: ToolMinimal[]
}
export type ObjectValue =
  | ({
      object_type: "Vendor"
    } & VendorMinimal)
  | ({
      object_type: "Tool"
    } & ToolMinimal)
  | ({
      object_type: "Login"
    } & Login)
  | ({
      object_type: "ReconciledTransaction"
    } & TransactionMinimal)
  | ({
      object_type: "LegalAgreement"
    } & LegalAgreementWithMinimalRelations)
  | ({
      object_type: "WorkflowRun"
    } & WorkflowRunMinimal)
export type WorkflowRunStepMinimal = {
  id: string
  workflow_run_id: string
  status: WorkflowRunStepStatus
  display_name: NonEmptyTrimmedProseString
  type: WorkflowStepStandardType
  objects: WorkflowStepObject[]
  submitted_at: null | DateTimeString
  seller_submitted_at: null | DateTimeString
  completed_at: null | DateTimeString
}
export type CommentTimelineEvent = {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: null | string
  object_id: string
  object_type: ObjectType
  type: "comment"
  actor: NonPendingUser | AlternateActorType
  field_name: null | NonEmptyTrimmedString
  rich_text_body: RichText
  replies: TimelineEventReplyTimelineEvent[]
  object_value: null | ObjectValue
  is_private: null | boolean
  workflow_run_step: null | WorkflowRunStepMinimal
  reply_tasks: null | CommentReplyTask[]
}
export type ActorOrAlternateType = User | NonPendingUser | AlternateActorType
export type FieldChangeTimelineEvent = {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: null | string
  object_id: string
  object_type: ObjectType
  type: "field_change"
  actor: ActorOrAlternateType
  field_name: NonEmptyTrimmedString
  old_value: any
  new_value: any
  is_custom_field: boolean
  object_value: null | ObjectValue
}
export type NewObjectTimelineEvent = {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: null | string
  object_id: string
  object_type: ObjectType
  type: "new_object"
  actor: ActorOrAlternateType
  object_value: null | ObjectValue
}
export type FieldApprovedTimelineEvent = {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: null | string
  object_id: string
  object_type: ObjectType
  type: "field_approved"
  actor: User
  field_name: NonEmptyTrimmedString
}
export type FieldUnapprovedTimelineEvent = {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: null | string
  object_id: string
  object_type: ObjectType
  type: "field_unapproved"
  actor: User
  field_name: NonEmptyTrimmedString
}
export type WorkflowStepUnapprovedTimelineEvent = {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: null | string
  type: "workflow_step_unapproved"
  actor: User
  rich_text_body: RichText
  workflow_run_step: {
    id: string
    display_name: NonEmptyTrimmedProseString
    workflow_run_id: string
  }
}
export type WorkflowSellerInviteSentEvent = {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: (null | string) & string
  workflow_run_step_id: null | string
  object_id: string
  object_type: ObjectType
  type: "workflow_seller_invite_sent"
  actor: ActorOrAlternateType
  invited_user: {
    first_name?: NonEmptyTrimmedString
    last_name?: NonEmptyTrimmedString
    email: EmailString
  }
}
export type WorkflowSellerLinkStepSubmittedEvent = {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: (null | string) & string
  object_id: string
  object_type: ObjectType
  type: "workflow_seller_link_step_submitted"
  actor: ActorOrAlternateType
  workflow_run_step: {
    id: string
    display_name: NonEmptyTrimmedProseString
    workflow_run_id: string
  }
}
export type WorkflowStepSubmittedForReviewEvent = {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: (null | string) & string
  object_id: string
  object_type: ObjectType
  type: "workflow_step_submitted_for_review"
  actor: User
  workflow_run_step: {
    id: string
    display_name: NonEmptyTrimmedProseString
    workflow_run_id: string
  }
}
export type WorkflowStepApprovedEvent = {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: (null | string) & string
  object_id: string
  object_type: ObjectType
  type: "workflow_step_approved"
  actor: User
  workflow_run_step: {
    id: string
    display_name: NonEmptyTrimmedProseString
    workflow_run_id: string
  }
}
export type WorkflowStepCompletedEvent = {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: (null | string) & string
  object_id: string
  object_type: ObjectType
  type: "workflow_step_completed"
  actor: User
  workflow_run_step: {
    id: string
    display_name: NonEmptyTrimmedProseString
    workflow_run_id: string
  }
}
export type WorkflowStepAutoApprovedEvent = {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: (null | string) & string
  object_id: string
  object_type: ObjectType
  type: "workflow_step_auto_approved"
  actor: User
  workflow_run_step: {
    id: string
    display_name: NonEmptyTrimmedProseString
    workflow_run_id: string
  }
}
export type DeleteObjectTimelineEvent = {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: null | string
  object_id: string
  object_type: ObjectType
  type: "delete_object"
  actor: ActorOrAlternateType
  object_value: null | ObjectValue
}
export type WorkflowStepChangesRequestedEvent = {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: (null | string) & string
  object_id: string
  object_type: ObjectType
  type: "workflow_step_changes_requested"
  actor: User
  workflow_run_step: {
    id: string
    display_name: NonEmptyTrimmedProseString
    workflow_run_id: string
  }
}
export type WorkflowStepReopenedEvent = {
  id: string
  created_at: DateTimeString
  updated_at: null | DateTimeString
  reaction_groups: AggregatedReaction[]
  workflow_run_id: null | string
  workflow_run_step_id: (null | string) & string
  object_id: string
  object_type: ObjectType
  type: "workflow_step_reopened"
  actor: User
  workflow_run_step: {
    id: string
    display_name: NonEmptyTrimmedProseString
    workflow_run_id: string
  }
}
export type TimelineEvent =
  | ({
      type: "comment"
    } & CommentTimelineEvent)
  | ({
      type: "timeline_event_reply"
    } & TimelineEventReplyTimelineEvent)
  | ({
      type: "field_change"
    } & FieldChangeTimelineEvent)
  | ({
      type: "new_object"
    } & NewObjectTimelineEvent)
  | ({
      type: "field_approved"
    } & FieldApprovedTimelineEvent)
  | ({
      type: "field_unapproved"
    } & FieldUnapprovedTimelineEvent)
  | ({
      type: "workflow_step_unapproved"
    } & WorkflowStepUnapprovedTimelineEvent)
  | ({
      type: "workflow_seller_invite_sent"
    } & WorkflowSellerInviteSentEvent)
  | ({
      type: "workflow_seller_link_step_submitted"
    } & WorkflowSellerLinkStepSubmittedEvent)
  | ({
      type: "workflow_step_submitted_for_review"
    } & WorkflowStepSubmittedForReviewEvent)
  | ({
      type: "workflow_step_approved"
    } & WorkflowStepApprovedEvent)
  | ({
      type: "workflow_step_completed"
    } & WorkflowStepCompletedEvent)
  | ({
      type: "workflow_step_auto_approved"
    } & WorkflowStepAutoApprovedEvent)
  | ({
      type: "delete_object"
    } & DeleteObjectTimelineEvent)
  | ({
      type: "workflow_step_changes_requested"
    } & WorkflowStepChangesRequestedEvent)
  | ({
      type: "workflow_step_reopened"
    } & WorkflowStepReopenedEvent)
export type TimelineParams = {
  limit?: LimitNumber
  workflow_run_id?: string
  tool_id?: string
  vendor_id?: string
  /** ISO datetime string to return events until (i.e. `created_at < until`). Cannot be used in combination with `since`. */
  until?: string
  /** ISO datetime string to return events since (i.e. `created_at > since`). Cannot be used in combination with `until`. */
  since?: string
}
export type PaginatedTimelineResponseSchema = {
  /** The items for the current page of the collection. */
  items: TimelineEvent[]
  previousPageParams?: TimelineParams
  nextPageParams?: TimelineParams
}
export type TimelineUpdate = {
  rich_text_body?: RichText
}
export type GetWorkflowRunFieldTimelineResponse = TimelineEvent[]
export type MentionedUserToCreateTaskFor = {
  user_id: string
  date: DateString
}
export type CommentTimelineEventInput = {
  type: "comment"
  rich_text_body: RichText
  is_private?: boolean
  is_custom_field?: boolean
  mentioned_users_to_create_tasks_for?: MentionedUserToCreateTaskFor[]
}
export type TimelineEventReplyTimelineEventInput = {
  type: "timeline_event_reply"
  rich_text_body: RichText
  parent_id: string
  mentioned_users_to_create_tasks_for?: MentionedUserToCreateTaskFor[]
}
export type TimelineInput =
  | ({
      type: "comment"
    } & CommentTimelineEventInput)
  | ({
      type: "timeline_event_reply"
    } & TimelineEventReplyTimelineEventInput)
export type ReactionInput = {
  emoji: NonEmptyTrimmedString
}
export type ErpTrackingCategory =
  | ({
      type: "department"
    } & ErpDepartment)
  | ({
      type: "class"
    } & ErpClass)
export type UploadableImageAsset = {
  id: string
  gcs_file_name: NonEmptyTrimmedString
  created_at: DateTimeString
  file_name: null | NonEmptyTrimmedString
  mime_type: null | NonEmptyTrimmedString
  upload_url: string
}
export type ImageAssetInput = {
  file_name: NonEmptyTrimmedString
  file_size: number
  mime_type: NonEmptyTrimmedString
}
export type MentionNotification = {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "mention"
  timeline_event:
    | ({
        type: "timeline_event_reply"
      } & TimelineEventReplyTimelineEvent)
    | ({
        type: "comment"
      } & CommentTimelineEvent)
  vendor_display_name: null | string
  vendor_logo_asset: null | ImageAsset
  tool_display_name: null | string
  workflow_run_display_name: null | string
  workflow_run_step_display_name: null | string
  workflow_run_organization_id: null | string
  link_auth_code: null | string
  field_title: null | string
}
export type WorkflowRunStartedNotification = {
  id: string
  sender: (null | UserMinimal) & UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "workflow_run.started"
  workflow_run: {
    id: string
    object_type: "WorkflowRun"
    /** The name of the request */
    display_name: NonEmptyTrimmedProseString
    created_at: DateTimeString
    /** The type of request e.g. purchase, renewal, or gather data */
    kind: WorkflowKind
    status: WorkflowRunStatus
    /** The date and time that the request was completed */
    completed_at: null | DateTimeString
    /** The target date by which this request should be completed */
    target_date: DateString
    /** The amount of money being requested for this request */
    requested_spend: null | CurrencyAmount
    /** How often the requested spend amount will be charged (e.g. monthly, annually, or one-time) */
    requested_spend_frequency: null | FrequencyOrOneTime
    /** The number of payments requested for this request */
    requested_payments_count: null | number
    /** The method that will be used to pay for this request */
    payment_method: null | TransactionPaymentMethod
    custom: CustomFields
    fields_metadata?: WorkflowFieldsMetadata
    /** The user who created this request */
    created_by: User
    /** The user who owns and is responsible for this request */
    owner: User
  }
  workflow_run_display_name: null | string
  tool_image: null | ImageAsset
  tool_display_name: string
}
export type WorkflowRunInputNeededNotification = {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "workflow_run.input_needed"
  workflow_run: WorkflowRunMinimal
  workflow_run_display_name: null | string
  tool_image: null | ImageAsset
  tool_display_name: string
}
export type WorkflowRunCompletedNotification = {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "workflow_run.completed"
  workflow_run: WorkflowRunMinimal
  workflow_run_display_name: string
  tool_image: null | ImageAsset
  tool_display_name: string
}
export type WorkflowRunStepApprovedNotification = {
  id: string
  sender: (null | UserMinimal) & UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "workflow_run_step.approved"
  workflow_run_step: WorkflowRunStepMinimal
  workflow_run: WorkflowRunMinimal
  workflow_run_display_name: string
  workflow_run_step_display_name: string
  tool_image: null | ImageAsset
  tool_display_name: string
}
export type WorkflowRunStepApprovalRequestedNotification = {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "workflow_run_step.approval_requested"
  workflow_run_step: WorkflowRunStepMinimal
  workflow_run: WorkflowRunMinimal
  workflow_run_display_name: string
  workflow_run_step_display_name: string
  tool_image: null | ImageAsset
  tool_display_name: string
}
export type WorkflowRunStepApprovalOverriddenNotification = {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "workflow_run_step.approval_overridden"
  approver: UserMinimal
  workflow_run_step: WorkflowRunStepMinimal
  workflow_run: WorkflowRunMinimal
  workflow_run_display_name: string
  workflow_run_step_display_name: string
  tool_image: null | ImageAsset
  tool_display_name: string
}
export type WorkflowRunStepUnapprovedNotification = {
  id: string
  sender: (null | UserMinimal) & UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "workflow_run_step.unapproved"
  workflow_run_step: WorkflowRunStepMinimal
  workflow_run: WorkflowRunMinimal
  workflow_run_display_name: string
  workflow_run_step_display_name: string
  tool_image: null | ImageAsset
  tool_display_name: string
}
export type WorkflowRunStepChangesRequestedNotification = {
  id: string
  sender: (null | UserMinimal) & UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "workflow_run_step.changes_requested"
  workflow_run_step: WorkflowRunStepMinimal
  workflow_run: WorkflowRunMinimal
  workflow_run_display_name: string
  workflow_run_step_display_name: string
  tool_image: null | ImageAsset
  tool_display_name: string
}
export type WorkflowRunStepFieldReopenedNotification = {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "workflow_run_step_field.reopened"
  workflow_run_step_field_approval: WorkflowRunStepFieldApproval
  workflow_run_step: WorkflowRunStepMinimal
  workflow_run: WorkflowRunMinimal
  timeline_event: FieldUnapprovedTimelineEvent
  workflow_run_display_name: string
  workflow_run_step_display_name: string
  field_title: string
  tool_image: null | ImageAsset
  tool_display_name: string
}
export type WorkflowRunStepSellerSubmittedNotification = {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "workflow_run_step.seller_submitted"
  workflow_run_step: WorkflowRunStepMinimal
  workflow_run: WorkflowRunMinimal
  workflow_run_display_name: string
  workflow_run_step_display_name: string
  tool_image: null | ImageAsset
  tool_display_name: string
  timeline_event: WorkflowSellerLinkStepSubmittedEvent
}
export type WorkflowRunStepTimelineEventNotification = {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "workflow_run.timeline_comment"
  timeline_event: CommentTimelineEvent
  workflow_run_step: null | WorkflowRunStepMinimal
  workflow_run: WorkflowRunMinimal
  workflow_run_display_name: string
  workflow_run_step_display_name: null | string
  workflow_run_organization_id: null | string
  link_auth_code: null | string
  field_title: null | string
  vendor_logo_asset: null | ImageAsset
}
export type ToolTimelineCommentNotification = {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "tool.timeline_comment"
  timeline_event: CommentTimelineEvent
  tool_display_name: null | string
}
export type VendorTimelineCommentNotification = {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "vendor.timeline_comment"
  timeline_event: CommentTimelineEvent
  vendor_display_name: string
}
export type RenewalReminderNotification = {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "renewal_reminder"
  legal_agreement: LegalAgreementMinimal
  vendor_image: null | ImageAsset
  vendor_display_name: string
}
export type ToolOwnedTimelineCommentNotification = {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "tool_owned.timeline_comment"
  timeline_event: CommentTimelineEvent
  tool_display_name: string
}
export type ToolOwnedRenewalReminderNotification = {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "tool_owned_renewal_reminder"
  legal_agreement: LegalAgreementMinimal
  vendor_image: null | ImageAsset
  vendor_display_name: string
}
export type VendorOwnedTimelineCommentNotification = {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "vendor_owned.timeline_comment"
  timeline_event: CommentTimelineEvent
  vendor_display_name: string
}
export type VendorOwnedRenewalReminderNotification = {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "vendor_owned_renewal_reminder"
  legal_agreement: LegalAgreementMinimal
  vendor_image: null | ImageAsset
  vendor_display_name: string
}
export type TimelineCommentReactionNotification = {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "timeline_comment.reaction"
  timeline_event:
    | ({
        type: "timeline_event_reply"
      } & TimelineEventReplyTimelineEvent)
    | ({
        type: "comment"
      } & CommentTimelineEvent)
  reactor: UserMinimal
  reaction_emoji: string
  other_reactors: UserMinimal[]
  other_reaction_emojis: string[]
  workflow_run_display_name: null | string
  workflow_run_step_display_name: null | string
  field_title: null | string
  tool_display_name: null | string
  vendor_display_name: null | string
  vendor_logo_asset: null | ImageAsset
}
export type IntegrationSummary = {
  id: string
  object_type: "Integration"
  organization_id: string
  created_at: DateTimeString
  display_name: NonEmptyTrimmedProseString
  provider: IntegrationProvider
  external_id: null | NonEmptyString
  merge_hris_type: null | MergeIntegrationHrisType
  merge_accounting_type: null | MergeIntegrationAccountingType
  logo: null | NonEmptyString
  email: null | NonEmptyString
  scope: null | NonEmptyString
  crawler_last_success: null | DateTimeString
  crawler_state?: null | {
    oldest_crawled_activity_date?: DateTimeString
    incremental_crawl_start_date?: DateTimeString
  }
  /** Text with statistics on the integration. For example, how many documents have been imported from an email integration. */
  detail_text?: string
  status: IntegrationStatus
  disabled_at: null | DateTimeString
}
export type IntegrationHistoricalAgreementCrawlDoneNotification = {
  id: string
  sender: null | UserMinimal
  created_at: DateTimeString
  read_at: null | DateTimeString
  type: "integration.historical_agreement_crawl_done"
  integration: IntegrationSummary
  agreement_count: number
}
export type InboxNotification =
  | ({
      type: "mention"
    } & MentionNotification)
  | ({
      type: "workflow_run.started"
    } & WorkflowRunStartedNotification)
  | ({
      type: "workflow_run.input_needed"
    } & WorkflowRunInputNeededNotification)
  | ({
      type: "workflow_run.completed"
    } & WorkflowRunCompletedNotification)
  | ({
      type: "workflow_run_step.approved"
    } & WorkflowRunStepApprovedNotification)
  | ({
      type: "workflow_run_step.approval_requested"
    } & WorkflowRunStepApprovalRequestedNotification)
  | ({
      type: "workflow_run_step.approval_overridden"
    } & WorkflowRunStepApprovalOverriddenNotification)
  | ({
      type: "workflow_run_step.unapproved"
    } & WorkflowRunStepUnapprovedNotification)
  | ({
      type: "workflow_run_step.changes_requested"
    } & WorkflowRunStepChangesRequestedNotification)
  | ({
      type: "workflow_run_step_field.reopened"
    } & WorkflowRunStepFieldReopenedNotification)
  | ({
      type: "workflow_run_step.seller_submitted"
    } & WorkflowRunStepSellerSubmittedNotification)
  | ({
      type: "workflow_run.timeline_comment"
    } & WorkflowRunStepTimelineEventNotification)
  | ({
      type: "tool.timeline_comment"
    } & ToolTimelineCommentNotification)
  | ({
      type: "vendor.timeline_comment"
    } & VendorTimelineCommentNotification)
  | ({
      type: "renewal_reminder"
    } & RenewalReminderNotification)
  | ({
      type: "tool_owned.timeline_comment"
    } & ToolOwnedTimelineCommentNotification)
  | ({
      type: "tool_owned_renewal_reminder"
    } & ToolOwnedRenewalReminderNotification)
  | ({
      type: "vendor_owned.timeline_comment"
    } & VendorOwnedTimelineCommentNotification)
  | ({
      type: "vendor_owned_renewal_reminder"
    } & VendorOwnedRenewalReminderNotification)
  | ({
      type: "timeline_comment.reaction"
    } & TimelineCommentReactionNotification)
  | ({
      type: "integration.historical_agreement_crawl_done"
    } & IntegrationHistoricalAgreementCrawlDoneNotification)
export type NotificationListParams = {
  limit: LimitNumber
  /** ISO datetime string to return events until (i.e. `created_at < until`). Cannot be used in combination with `since`. */
  until?: string
  /** ISO datetime string to return events since (i.e. `created_at > since`). Cannot be used in combination with `until`. */
  since?: string
  filter_by_unread?: boolean
  filter_by_mentioned?: boolean
}
export type NotificationListResponse = {
  /** The items for the current page of the collection. */
  items: InboxNotification[]
  previousPageParams?: NotificationListParams
  nextPageParams?: NotificationListParams
}
export type WorkflowStepGathererTask = {
  sort_by_date: null | DateString
  recipient_id: string
  organization_id: string
  workflow_run_id: string
  workflow_name: string
  tool_logo_gcs_file_name: null | string
  workflow_run_due_date: DateString
  requestor: null | UserMinimal
  pending_steps: WorkflowStepStandardType[]
  pending_step_ids: string[]
  type: "workflow_step_gatherer"
}
export type WorkflowStepReadyForReviewTask = {
  sort_by_date: null | DateString
  recipient_id: string
  organization_id: string
  workflow_run_id: string
  workflow_name: string
  tool_logo_gcs_file_name: null | string
  workflow_run_due_date: DateString
  requestor: null | UserMinimal
  workflow_run_step_id: string
  workflow_run_step_display_name: WorkflowStepStandardType
  submitter_first_name: string
  submitter_last_name: string
  submitter_id: string
  type: "workflow_run_step_ready_for_review"
}
export type WorkflowRunAggregatedGatherAndReviewStepsTask = {
  sort_by_date: null | DateString
  recipient_id: string
  organization_id: string
  workflow_run_id: string
  workflow_name: string
  tool_logo_gcs_file_name: null | string
  workflow_run_due_date: DateString
  requestor: null | UserMinimal
  gather_steps: WorkflowStepStandardType[]
  approve_steps: WorkflowStepStandardType[]
  type: "workflow_run_aggregated_steps_ready_for_review_or_approval"
}
export type WorkflowRunAggregatedGatherFieldsTask = {
  sort_by_date: null | DateString
  recipient_id: string
  organization_id: string
  workflow_run_id: string
  workflow_name: string
  tool_logo_gcs_file_name: null | string
  workflow_run_due_date: DateString
  requestor: null | UserMinimal
  gather_steps: WorkflowStepStandardType[]
  type: "workflow_run_aggregated_gather_fields"
}
export type WorkflowFieldGathererTask = {
  sort_by_date: null | DateString
  recipient_id: string
  organization_id: string
  workflow_run_id: string
  workflow_name: string
  tool_logo_gcs_file_name: null | string
  workflow_run_due_date: DateString
  requestor: null | UserMinimal
  object_type: ObjectType
  object_id: string
  field_name: string
  is_custom: boolean
  type: "workflow_field_gatherer"
  workflow_run_step_id: string
  workflow_run_step_display_name: WorkflowStepStandardType
}
export type AgreementRenewalTask = {
  sort_by_date: null | DateString
  recipient_id: string
  organization_id: string
  agreement_id: string
  type: "agreement_renewal"
  agreement_decision_date: DateString
  agreement_end_date: null | DateString
  auto_renews: null | boolean
  agreement_name: string
  tool_logo_gcs_file_name: null | string
  vendor_logo_gcs_file_name: null | string
  tool_id: null | string
  tool_display_name: null | string
  vendor_id: string
  vendor_display_name: null | string
  is_tool_owner: boolean
  is_vendor_owner: boolean
  is_tool_subscriber: boolean
  is_vendor_subscriber: boolean
  ltm_spend: null | number
  dismissed_at: null | DateTimeString
  snoozed_until: null | DateString
  negotiation_id: null | string
  negotiation_thread_id: null | string
}
export type AgreementVerificationTask = {
  sort_by_date: null | DateString
  recipient_id: string
  organization_id: string
  type: "agreement_verification"
  unverified_agreement_count: number
}
export type ReplyTask = {
  sort_by_date: null | DateString
  recipient_id: string
  organization_id: string
  type: "reply"
  reply_task_id: string
  due_by: null | DateString
  workflow_run_id?: null | string
  tool_id?: null | string
  vendor_id?: null | string
  workflow_run_display_name?: null | string
  workflow_run_step_id?: null | string
  object_type?: null | ObjectType
  object_id?: null | string
  field_name?: null | string
  is_custom?: null | boolean
  tool_display_name?: null | string
  vendor_display_name?: null | string
  timeline_event_id: string
  created_by: UserMinimal
  rich_text_body: RichText
}
export type DisconnectedIntegrationTask = {
  sort_by_date: null | DateString
  recipient_id: string
  organization_id: string
  type: "disconnected_integration"
  integration: {
    id: string
    display_name: NonEmptyTrimmedProseString
    provider: IntegrationProvider
    crawler_last_success: null | DateTimeString
  }
}
export type ToolsInactiveOwnerReassignmentTask = {
  sort_by_date: null | DateString
  recipient_id: string
  organization_id: string
  type: "tools_inactive_owner_reassignment"
  inactive_owners_count: number
  tools_needing_reassignment_count: number
}
export type VendorsInactiveOwnerReassignmentTask = {
  sort_by_date: null | DateString
  recipient_id: string
  organization_id: string
  type: "vendors_inactive_owner_reassignment"
  inactive_owners_count: number
  vendors_needing_reassignment_count: number
}
export type AgreementDuplicativeSubscriptionsTask = {
  sort_by_date: null | DateString
  recipient_id: string
  organization_id: string
  type: "agreement_duplicative_subscriptions"
  duplicative_legal_agreement_ids: string[]
  object_type: ObjectType
  object_id: string
  object_display_name: string
  object_logo_gcs_file_name: null | string
  tool_id: null | string
  vendor_id: null | string
}
export type NegotiationReplyTask = {
  sort_by_date: null | DateString
  recipient_id: string
  organization_id: string
  type: "negotiation_reply"
  negotiation_id: string
  vendor_logo_gcs_file_name: null | string
  tool_logo_gcs_file_name: null | string
  vendor_id: string
  vendor_display_name: string
  tool_id: null | string
  tool_display_name: null | string
  dismissed_at: null | DateTimeString
  snoozed_until: null | DateString
  date_of_reply: DateString
}
export type Task =
  | ({
      type: "workflow_step_gatherer"
    } & WorkflowStepGathererTask)
  | ({
      type: "workflow_run_step_ready_for_review"
    } & WorkflowStepReadyForReviewTask)
  | ({
      type: "workflow_run_aggregated_steps_ready_for_review_or_approval"
    } & WorkflowRunAggregatedGatherAndReviewStepsTask)
  | ({
      type: "workflow_run_aggregated_gather_fields"
    } & WorkflowRunAggregatedGatherFieldsTask)
  | ({
      type: "workflow_field_gatherer"
    } & WorkflowFieldGathererTask)
  | ({
      type: "agreement_renewal"
    } & AgreementRenewalTask)
  | ({
      type: "agreement_verification"
    } & AgreementVerificationTask)
  | ({
      type: "reply"
    } & ReplyTask)
  | ({
      type: "disconnected_integration"
    } & DisconnectedIntegrationTask)
  | ({
      type: "tools_inactive_owner_reassignment"
    } & ToolsInactiveOwnerReassignmentTask)
  | ({
      type: "vendors_inactive_owner_reassignment"
    } & VendorsInactiveOwnerReassignmentTask)
  | ({
      type: "agreement_duplicative_subscriptions"
    } & AgreementDuplicativeSubscriptionsTask)
  | ({
      type: "negotiation_reply"
    } & NegotiationReplyTask)
export type TaskListResponse = {
  items: Task[]
}
export type TaskSnoozeBody = {
  task: Task
  snoozedUntil: DateString
}
export type TaskDismissBody = {
  task: Task
}
export type NotificationCountResponse = {
  count: number
}
export type NotificationUnreadResponse = {
  unread: boolean
}
export type NotificationUpdate = {
  read_at?: null | DateTimeString
  archived_at?: null | DateTimeString
}
export type NotificationBulkActionBody = {
  action: "delete_all" | "delete_read" | "mark_all_read" | "mark_all_unread"
}
export type DynamoRunStatus =
  | "init"
  | "collected"
  | "answered"
  | "done"
  | "collection_failed"
  | "answering_failed"
  | "unreachable_url"
export type DynamoRun = {
  id: string
  created_at: string
  updated_at: string
  status: DynamoRunStatus
  reviewed_links: HttpUrlString[]
  relevant_links: HttpUrlString[]
  emails: EmailString[]
  field_names: string[]
  tool_listing_id: string
}
export type IsDynamoRunning = {
  id: string
  created_at: string
  updated_at: string
  status: DynamoRunStatus
  reviewed_links: HttpUrlString[]
  relevant_links: HttpUrlString[]
  emails: EmailString[]
  field_names: string[]
  tool_listing_id: string
} | null
export type IntegrationBase = {
  id: string
  object_type: "Integration"
  organization_id: string
  created_at: DateTimeString
  created_by_id: null | string
  display_name: NonEmptyTrimmedProseString
  crawler_state: null | {
    [key: string]: any
  }
  crawler_error_count: number
  crawler_last_success: null | NonEmptyString
  customization_setting: null | {
    [key: string]: any
  }
  status: IntegrationStatus
  email: null | NonEmptyString
  mocked: boolean
}
export type CreditCardListItem = {
  id: string
  created_at: DateTimeString
  display_name?: null | NonEmptyTrimmedProseString
  spend_limit?: null | CurrencyAmount
  spend_limit_interval?: null | CreditCardInterval
  last_four?: null | NonEmptyString
  card_task_status?: null | CreditCardTaskStatus
  external_card_id?: null | string
  external_limit_id?: null | string
  status?: null | CreditCardStatus
  object_type: "CreditCard"
  owner?: null | Person
  /** The earlier date between the expiration or auto lock date */
  valid_until?: null | DateString
  auto_lock_date?: null | DateString
  vendors?: null | VendorMinimal[]
  last_transaction?: null | TransactionMinimal
  workflow_run_source?: null | WorkflowRunMinimal
  integration: IntegrationBase
}
export type CreditCardSummaryResponse = {
  cards: CreditCardListItem[]
  total: number
}
export type OnboardingStepType =
  | "welcome.contract_collection"
  | "welcome.terms_extraction"
  | "welcome.renewal_calendar"
  | "welcome.never_miss_a_renewal"
  | "contract_collector.connect_gmail"
  | "overview.view_agreement"
  | "verify_agreement.multiple_documents"
  | "verify_agreement.jump_to_source"
  | "verify_agreement.verify_vendor"
  | "verify_agreement.verify_start_date"
  | "verify_agreement.verify_end_date"
  | "verify_agreement.verify_auto_renews"
  | "verify_agreement.verify_decision_date"
  | "verify_agreement.accept_agreement"
  | "overview.view_renewal_agenda"
  | "full_text_search.search_document_text"
  | "overview.ask_ai"
  | "configure_notifications.renewal_reminder"
  | "configure_notifications.connect_slack"
  | "overview.view_inbox"
  | "inbox.verify_agreements"
  | "overview.agreement_list"
  | "complete"
export type OnboardingStep = {
  id: string
  user_id: string
  step: OnboardingStepType
  seen_at: null | DateTimeString
}
export type Email = {
  id: string
  received_at: null | DateTimeString
  organization_id: string
  to_email_addresses: string[]
  from_email_address: string
  from_person: null | Person
  subject: null | string
  html_text: null | string
  cc_email_addresses: null | string[]
  bcc_email_addresses: null | string[]
  attachment_names: null | string[]
  snippet: null | string
  body: null | string
}
export type EmailDraftInput = {
  id?: string
  to_addresses: string[]
  cc_addresses: string[]
  bcc_addresses: string[]
  /** The body of the email in html. */
  html_body: string
  /** The subject headline of the email. */
  subject?: string
  html_reply_body?: null | string
  /** Whether to include the reply body in the email draft. */
  include_reply_body: boolean
  response_type?: null | ("reply" | "forward" | "reply_all")
}
export type NegotiationConversation = {
  id: string
  betsy_conversation: Conversation
  reply_to_email: null | Email
  show_agent_telemetry: boolean
  email_drafts: null | EmailDraftInput[]
  sent_email: null | Email
}
export type NegotiationContext = {
  id: string
  created_at: string
  updated_at: string
  agreement_notes: null | string
  usage_and_roi: null | string
  vendor_performance: null | string
  market_research: null | string
  negotiation_goals: null | string
  notes: null | string
}
export type Negotiation = {
  id: string
  conversations: NegotiationConversation[]
  vendor: VendorMinimal
  tool: null | ToolMinimal
  email_thread: Email[]
  input_legal_agreement: null | LegalAgreementMinimal
  context: null | NegotiationContext
}
export type ListingStatus = "active" | "hidden"
export type CreateToolListing = {
  key: NonEmptyString
  admin_email: EmailString
  display_name: NonEmptyTrimmedProseString
  description: NonEmptyTrimmedProseString
  vendor_listing_id: string
  website?: HttpUrlString
  status?: ListingStatus
}
export type UpdateToolListing = {
  admin_email?: EmailString
  display_name?: NonEmptyTrimmedProseString
  description?: NonEmptyTrimmedProseString
  website?: HttpUrlString
  status?: ListingStatus
}
export type ToolUserReportColumn = "display_name" | "description" | "logo_image_asset_id" | "website" | "other"
export type ToolUserReport = {
  report_columns: ToolUserReportColumn[]
  report_body?: NonEmptyString
}
export type LoginIdentifier = {
  client_id: NonEmptyString
  provider: IntegrationProvider
}
export type CreateVendorListing = {
  key: NonEmptyString
  admin_email: EmailString
  display_name: NonEmptyTrimmedProseString
  description: NonEmptyTrimmedProseString
  website?: HttpUrlString
  status?: ListingStatus
}
export type UpdateVendorListing = {
  admin_email?: EmailString
  display_name?: NonEmptyTrimmedProseString
  description?: NonEmptyTrimmedProseString
  website?: HttpUrlString
  status?: ListingStatus
}
export type VendorUserReport = {
  report_columns: ("display_name" | "description" | "logo_image_asset_id" | "website" | "other")[]
  report_body?: NonEmptyTrimmedProseString
}
export type ToolOrVendorString = "tool" | "vendor"
export const {
  useGetOpenapiQuery,
  useLazyGetOpenapiQuery,
  useGetSchemaV1ByObjectTypeQuery,
  useLazyGetSchemaV1ByObjectTypeQuery,
  useGetSchemaV1ByObjectTypePatchQuery,
  useLazyGetSchemaV1ByObjectTypePatchQuery,
  useGetSchemaV1ByObjectTypeInputQuery,
  useLazyGetSchemaV1ByObjectTypeInputQuery,
  useGetSchemaV1FieldsQuery,
  useLazyGetSchemaV1FieldsQuery,
  usePutSchemaV1FieldsDocumentExtractionJobMutation,
  usePostSchemaV1FieldsDuplicatesQuery,
  useLazyPostSchemaV1FieldsDuplicatesQuery,
  usePutSchemaV1ByObjectTypeFieldsOrderMutation,
  usePutSchemaV1ByObjectTypeFieldsAndFieldNameMutation,
  useDeleteSchemaV1ByObjectTypeFieldsAndFieldNameMutation,
  usePostSchemaV1FieldsTextToFieldMutation,
  usePostSchemaV1MapQuery,
  useLazyPostSchemaV1MapQuery,
  usePostEmailV1InboundMutation,
  useGetUserV1WhoamiQuery,
  useLazyGetUserV1WhoamiQuery,
  useGetUserV1CalendarKeyQuery,
  useLazyGetUserV1CalendarKeyQuery,
  useGetUserV1SettingsQuery,
  useLazyGetUserV1SettingsQuery,
  useGetUserV1SettingsTotpQrQuery,
  useLazyGetUserV1SettingsTotpQrQuery,
  useGetUserV1SettingsContractCollectorPrivacyQuery,
  useLazyGetUserV1SettingsContractCollectorPrivacyQuery,
  usePostUserV1SettingsContractCollectorPrivacyMutation,
  useGetUserV1EmailDelegatesQuery,
  useLazyGetUserV1EmailDelegatesQuery,
  usePostUserV1EmailDelegatesMutation,
  useGetUserV1WritingPersonaQuery,
  useLazyGetUserV1WritingPersonaQuery,
  usePostUserV1WritingPersonaMutation,
  useGetUserV1DocumentsByIdContentQuery,
  useLazyGetUserV1DocumentsByIdContentQuery,
  usePostUserV1SettingsTotpEnableMutation,
  usePostUserV1SettingsTotpDisableMutation,
  useGetUserV1NotificationSettingsQuery,
  useLazyGetUserV1NotificationSettingsQuery,
  usePutUserV1NotificationSettingsMutation,
  usePostUserV1DisableNotificationChannelMutation,
  useGetUserV1ByIdQuery,
  useLazyGetUserV1ByIdQuery,
  useDeleteUserV1ByIdMutation,
  usePutUserV1ByIdMutation,
  useGetUserV1ByIdDisplayQuery,
  useLazyGetUserV1ByIdDisplayQuery,
  useGetUserV1DeletionRequirementsByIdQuery,
  useLazyGetUserV1DeletionRequirementsByIdQuery,
  usePostUserV1OrganizationQuery,
  useLazyPostUserV1OrganizationQuery,
  usePostUserV1CreateMutation,
  usePostUserV1UpdateRolesMutation,
  usePostUserV1InviteMutation,
  usePostUserV1ResendInviteMutation,
  usePostUserV1LoginMutation,
  usePostUserV1SigninOktaInitiateMutation,
  usePostUserV1ForgotPasswordMutation,
  useGetUserV1CodeByCodeEmailQuery,
  useLazyGetUserV1CodeByCodeEmailQuery,
  usePostUserV1ChangePasswordMutation,
  useGetUserV1LogoutMutation,
  usePostUserV1CreateUserMutation,
  usePostUserV1ByUserIdResetOnboardingMutation,
  useGetOrganizationV1CurrentQuery,
  useLazyGetOrganizationV1CurrentQuery,
  useGetOrganizationV1CurrentOktaConfigQuery,
  useLazyGetOrganizationV1CurrentOktaConfigQuery,
  usePutOrganizationV1UpdateMutation,
  usePostOrganizationV1AdminUpdateByOrganizationIdMutation,
  usePostOrganizationV1CreateMutation,
  usePostOrganizationV1ClearOrganizationMutation,
  usePostOrganizationV1PickableEntitiesQuery,
  useLazyPostOrganizationV1PickableEntitiesQuery,
  usePostOrganizationV1PermissionedPickableEntitiesQuery,
  useLazyPostOrganizationV1PermissionedPickableEntitiesQuery,
  usePostOrganizationV1OpenSignupMutation,
  useGetSpendV1ByTypeAndIdQuery,
  useLazyGetSpendV1ByTypeAndIdQuery,
  usePostSpendV1ExchangeRateSaveMutation,
  useGetStatisticsV1EmailIntegrationsQuery,
  useLazyGetStatisticsV1EmailIntegrationsQuery,
  useGetStatisticsV1AgreementsQuery,
  useLazyGetStatisticsV1AgreementsQuery,
  useGetStatisticsV1PersonsQuery,
  useLazyGetStatisticsV1PersonsQuery,
  useGetStatisticsV1ToolsQuery,
  useLazyGetStatisticsV1ToolsQuery,
  usePostToolV1ListQuery,
  useLazyPostToolV1ListQuery,
  useGetToolV1ListHiddenQuery,
  useLazyGetToolV1ListHiddenQuery,
  usePostToolV1CsvMutation,
  usePostToolV1AiFilterMutation,
  useGetToolV1PickerOptionsQuery,
  useLazyGetToolV1PickerOptionsQuery,
  usePutToolV1ByIdFollowingMutation,
  usePatchToolV1ByIdMutation,
  useGetToolV1ByIdQuery,
  useLazyGetToolV1ByIdQuery,
  usePostToolV1ByIdHideMutation,
  usePostToolV1ByIdLoginsListQuery,
  useLazyPostToolV1ByIdLoginsListQuery,
  usePostToolV1ByIdPersonsListQuery,
  useLazyPostToolV1ByIdPersonsListQuery,
  usePostToolV1ByIdPersonsCsvMutation,
  usePostToolV1Mutation,
  useGetToolV1CategoriesQuery,
  useLazyGetToolV1CategoriesQuery,
  useGetToolV1ByIdAndQuery,
  useLazyGetToolV1ByIdAndQuery,
  usePostLegalV1AgreementsListQuery,
  useLazyPostLegalV1AgreementsListQuery,
  useGetLegalV1AgreementsPickerOptionsByQueryQuery,
  useLazyGetLegalV1AgreementsPickerOptionsByQueryQuery,
  useGetLegalV1AgreementsByIdQuery,
  useLazyGetLegalV1AgreementsByIdQuery,
  usePutLegalV1AgreementsByIdMutation,
  useDeleteLegalV1AgreementsByIdMutation,
  usePostLegalV1AgreementsByIdVerificationMutation,
  usePostLegalV1DetectLegalAgreementsByOrganizationIdMutation,
  useGetLegalV1AgreementsByIdDocumentsAndDocumentIdUrlQuery,
  useLazyGetLegalV1AgreementsByIdDocumentsAndDocumentIdUrlQuery,
  useGetLegalV1AgreementsByIdDocumentsAndDocumentIdContentQuery,
  useLazyGetLegalV1AgreementsByIdDocumentsAndDocumentIdContentQuery,
  usePostLegalV1AgreementsMutation,
  useGetLegalV1AgreementsByIdAndQuery,
  useLazyGetLegalV1AgreementsByIdAndQuery,
  usePostVendorV1ListQuery,
  useLazyPostVendorV1ListQuery,
  useGetVendorV1ListHiddenQuery,
  useLazyGetVendorV1ListHiddenQuery,
  usePostVendorV1CsvMutation,
  usePostVendorV1AiFilterMutation,
  usePostVendorV1Mutation,
  useGetVendorV1PickerOptionsQuery,
  useLazyGetVendorV1PickerOptionsQuery,
  usePutVendorV1ByIdFollowingMutation,
  usePatchVendorV1ByIdMutation,
  useGetVendorV1ByIdQuery,
  useLazyGetVendorV1ByIdQuery,
  usePostVendorV1ByIdHideMutation,
  useGetVendorV1ByIdSpendBreakdownQuery,
  useLazyGetVendorV1ByIdSpendBreakdownQuery,
  useGetVendorV1ByIdAndQuery,
  useLazyGetVendorV1ByIdAndQuery,
  useGetOauthV1SigninByProviderQuery,
  useLazyGetOauthV1SigninByProviderQuery,
  useGetOauthV1SigninByProviderCallbackQuery,
  useLazyGetOauthV1SigninByProviderCallbackQuery,
  useGetOauthV1AuthByProviderQuery,
  useLazyGetOauthV1AuthByProviderQuery,
  useGetOauthV1AuthByProviderCallbackQuery,
  useLazyGetOauthV1AuthByProviderCallbackQuery,
  useGetOauthV1SignupValidateQuery,
  useLazyGetOauthV1SignupValidateQuery,
  useGetOauthV1ConnectByProviderQuery,
  useLazyGetOauthV1ConnectByProviderQuery,
  useGetOauthV1ConnectByProviderCallbackQuery,
  useLazyGetOauthV1ConnectByProviderCallbackQuery,
  useGetIntegrationV1Query,
  useLazyGetIntegrationV1Query,
  useGetIntegrationV1InitialExtractionBannerQuery,
  useLazyGetIntegrationV1InitialExtractionBannerQuery,
  useGetIntegrationV1PrivateQuery,
  useLazyGetIntegrationV1PrivateQuery,
  useDeleteIntegrationV1ByIdMutation,
  usePostIntegrationV1ClearDataMutation,
  usePostIntegrationV1CreateMutation,
  usePostIntegrationV1ByIdDisableMutation,
  usePostIntegrationV1ByIdEnableMutation,
  usePostIntegrationV1UpdateMutation,
  usePostIntegrationV1CrawlByIdMutation,
  useGetIntegrationV1HrisBySourceLinkTokenQuery,
  useLazyGetIntegrationV1HrisBySourceLinkTokenQuery,
  useGetIntegrationV1AccountingBySourceLinkTokenQuery,
  useLazyGetIntegrationV1AccountingBySourceLinkTokenQuery,
  usePostIntegrationV1MergeSyncMutation,
  usePostPersonV1Query,
  useLazyPostPersonV1Query,
  usePostPersonV1MergeMutation,
  useGetPersonV1ByIdQuery,
  useLazyGetPersonV1ByIdQuery,
  useGetPersonV1ByIdToolsQuery,
  useLazyGetPersonV1ByIdToolsQuery,
  useGetLoginV1SummaryQuery,
  useLazyGetLoginV1SummaryQuery,
  useGetLoginV1UserActivityQuery,
  useLazyGetLoginV1UserActivityQuery,
  useGetSearchV1TextQuery,
  useLazyGetSearchV1TextQuery,
  useGetEmployeeGroupV1Query,
  useLazyGetEmployeeGroupV1Query,
  usePostDocumentV1Mutation,
  usePostDocumentV1ListQuery,
  useLazyPostDocumentV1ListQuery,
  usePutDocumentV1ByIdMutation,
  useGetDocumentV1ExtractingQuery,
  useLazyGetDocumentV1ExtractingQuery,
  usePostDocumentV1BackgroundExtractBatchMutation,
  usePostDocumentV1MoveMutation,
  usePostDocumentV1ByIdBackgroundExtractMutation,
  usePostDocumentV1RunExtractionByOrganizationIdMutation,
  usePostDocumentV1RetryPartialExtractionMutation,
  usePostDocumentV1ByIdRetryExtractionMutation,
  usePostDocumentV1ByIdExtractFormMutation,
  useGetDocumentV1ByObjectTypeAndObjectIdDocumentsDocumentIdUrlQuery,
  useLazyGetDocumentV1ByObjectTypeAndObjectIdDocumentsDocumentIdUrlQuery,
  useGetDocumentV1OwnByIdContentQuery,
  useLazyGetDocumentV1OwnByIdContentQuery,
  useGetDocumentV1OwnByIdUrlQuery,
  useLazyGetDocumentV1OwnByIdUrlQuery,
  useGetWorkflowV1ByKindSchemaQuery,
  useLazyGetWorkflowV1ByKindSchemaQuery,
  useGetWorkflowV1DefinitionsByIdQuery,
  useLazyGetWorkflowV1DefinitionsByIdQuery,
  usePutWorkflowV1DefinitionsByIdMutation,
  useGetWorkflowV1DefinitionsQuery,
  useLazyGetWorkflowV1DefinitionsQuery,
  usePostWorkflowV1DefinitionsMutation,
  useGetWorkflowV1DefinitionsKindListQuery,
  useLazyGetWorkflowV1DefinitionsKindListQuery,
  useGetWorkflowV1RunsPickablesQuery,
  useLazyGetWorkflowV1RunsPickablesQuery,
  usePostWorkflowV1DefinitionsConditionalMutation,
  usePostWorkflowV1RunsListQuery,
  useLazyPostWorkflowV1RunsListQuery,
  usePostWorkflowV1RunsMutation,
  useGetWorkflowV1RunsByIdQuery,
  useLazyGetWorkflowV1RunsByIdQuery,
  usePatchWorkflowV1RunsByIdMutation,
  useDeleteWorkflowV1RunsByIdMutation,
  useGetWorkflowV1RunsByIdStepsAndStepIdQuery,
  useLazyGetWorkflowV1RunsByIdStepsAndStepIdQuery,
  usePutWorkflowV1RunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameGathererMutation,
  usePostWorkflowV1RunsByIdCancelMutation,
  usePatchWorkflowV1RunsByIdStepsMutation,
  usePutWorkflowV1StepRunsByIdObjectsAndObjectTypeObjectIdFieldsFieldNameApprovalMutation,
  useGetWorkflowV1SellerLinksQuery,
  useLazyGetWorkflowV1SellerLinksQuery,
  usePostWorkflowV1LinksMutation,
  useGetWorkflowV1LinksByCodeWorkflowRunQuery,
  useLazyGetWorkflowV1LinksByCodeWorkflowRunQuery,
  usePostWorkflowV1LinksByCodeWorkflowRunStepStatesAndWorkflowRunStepIdMutation,
  useGetWorkflowV1LinksByCodeQuery,
  useLazyGetWorkflowV1LinksByCodeQuery,
  usePostWorkflowV1LinksByCodeMutation,
  usePostWorkflowV1StepRunsByIdDraftMutation,
  usePostWorkflowV1StepRunsByWorkflowRunStepIdUnapproveMutation,
  usePostWorkflowV1StepRunsByWorkflowRunStepIdRequestChangesMutation,
  usePostWorkflowV1StepRunsByWorkflowRunStepIdReopenMutation,
  usePostWorkflowV1StepRunsByWorkflowRunStepIdSubmissionMutation,
  useGetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdUrlQuery,
  useLazyGetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdUrlQuery,
  useGetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdContentQuery,
  useLazyGetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdContentQuery,
  usePostWorkflowV1FieldsQuery,
  useLazyPostWorkflowV1FieldsQuery,
  useGetTransactionV1ByIdQuery,
  useLazyGetTransactionV1ByIdQuery,
  usePostTransactionV1ListQuery,
  useLazyPostTransactionV1ListQuery,
  useGetTransactionV1ByIdReceiptsAndReceiptIdDocumentUrlQuery,
  useLazyGetTransactionV1ByIdReceiptsAndReceiptIdDocumentUrlQuery,
  useGetTransactionV1ByIdReceiptsAndReceiptIdDocumentContentQuery,
  useLazyGetTransactionV1ByIdReceiptsAndReceiptIdDocumentContentQuery,
  useGetSavedViewV1ByTableIdentifierQuery,
  useLazyGetSavedViewV1ByTableIdentifierQuery,
  usePutSavedViewV1ByIdMutation,
  useDeleteSavedViewV1ByIdMutation,
  usePostSavedViewV1CreateMutation,
  usePostBetsyV1VectorMutation,
  usePostBetsyV1AskMutation,
  usePostBetsyV1SlackEventMutation,
  useGetBetsyV1ConversationsQuery,
  useLazyGetBetsyV1ConversationsQuery,
  useGetBetsyV1ConversationsByIdQuery,
  useLazyGetBetsyV1ConversationsByIdQuery,
  usePutErpAccountV1ByIdMutation,
  useGetTimelineV1EventsQuery,
  useLazyGetTimelineV1EventsQuery,
  useDeleteTimelineV1EventsByIdMutation,
  usePutTimelineV1EventsByIdMutation,
  useGetTimelineV1WorkflowRunByWorkflowRunIdQuery,
  useLazyGetTimelineV1WorkflowRunByWorkflowRunIdQuery,
  useGetTimelineV1WorkflowRunByWorkflowRunIdWorkflowRunStepAndWorkflowRunStepIdQuery,
  useLazyGetTimelineV1WorkflowRunByWorkflowRunIdWorkflowRunStepAndWorkflowRunStepIdQuery,
  useGetTimelineV1EventsObjectsByObjectTypeAndObjectIdFieldsFieldNameQuery,
  useLazyGetTimelineV1EventsObjectsByObjectTypeAndObjectIdFieldsFieldNameQuery,
  useGetTimelineV1EventsByIdDocumentAndDocumentIdContentQuery,
  useLazyGetTimelineV1EventsByIdDocumentAndDocumentIdContentQuery,
  usePostTimelineV1EventsObjectsByObjectTypeAndObjectIdStreamingMutation,
  usePostTimelineV1EventsObjectsByObjectTypeAndObjectIdMutation,
  usePostTimelineV1EventsByIdReactionsMutation,
  useDeleteTimelineV1EventsByIdReactionsAndReactionIdMutation,
  useGetErpTrackingCategoryV1Query,
  useLazyGetErpTrackingCategoryV1Query,
  usePostImageAssetV1ImageAssetsMutation,
  useGetImageAssetV1ByIdContentQuery,
  useLazyGetImageAssetV1ByIdContentQuery,
  useGetPlaidV1LinkTokenQuery,
  useLazyGetPlaidV1LinkTokenQuery,
  usePostPlaidV1WebhooksMutation,
  useGetNotificationV1Query,
  useLazyGetNotificationV1Query,
  useGetNotificationV1TasksQuery,
  useLazyGetNotificationV1TasksQuery,
  usePostNotificationV1TaskSnoozeMutation,
  usePostNotificationV1TaskDismissMutation,
  useGetNotificationV1CountQuery,
  useLazyGetNotificationV1CountQuery,
  useGetNotificationV1CountUnreadQuery,
  useLazyGetNotificationV1CountUnreadQuery,
  useGetNotificationV1UnreadQuery,
  useLazyGetNotificationV1UnreadQuery,
  useGetNotificationV1ByIdQuery,
  useLazyGetNotificationV1ByIdQuery,
  usePutNotificationV1ByIdMutation,
  usePutNotificationV1BulkMutation,
  usePostDynamoV1ByToolListingIdMutation,
  useGetDynamoV1RunByRunIdQuery,
  useLazyGetDynamoV1RunByRunIdQuery,
  useGetDynamoV1ByIdIsDynamoRunningQuery,
  useLazyGetDynamoV1ByIdIsDynamoRunningQuery,
  usePostDynamoV1ByIdReportDataTelemetryMutation,
  usePostDynamoV1ApplyByTimelineEventIdMutation,
  useGetCalendarV1RenewalsByCalendarKeyQuery,
  useLazyGetCalendarV1RenewalsByCalendarKeyQuery,
  usePostCreditCardV1ListQuery,
  useLazyPostCreditCardV1ListQuery,
  useGetCreditCardV1PickerOptionsQuery,
  useLazyGetCreditCardV1PickerOptionsQuery,
  usePostCreditCardV1LockMutation,
  usePostCreditCardV1UnlockByIdMutation,
  useGetOnboardingV1LatestQuery,
  useLazyGetOnboardingV1LatestQuery,
  usePostOnboardingV1StepByStepTypeRecordMutation,
  useGetNegotiationV1ByIdQuery,
  useLazyGetNegotiationV1ByIdQuery,
  usePostNegotiationV1ComposeMutation,
  usePostNegotiationV1EmailSendMutation,
  usePostNegotiationV1UpdateContextMutation,
  usePostNegotiationV1RegenerateMutation,
  usePostNegotiationV1ResetMutation,
  usePostNegotiationV1GmailAddonHomepageMutation,
  usePostNegotiationV1GmailAddonCreateNegotiationMutation,
  usePostNegotiationV1GmailAddonEmailOpenedMutation,
  usePostNegotiationV1EmailSimRunMutation,
  usePostNegotiationV1EmailSimInitMutation,
  useGetSubscribeV1OrganizationQuery,
  useLazyGetSubscribeV1OrganizationQuery,
  useGetSubscribeV1LinksByCodeWorkflowRunQuery,
  useLazyGetSubscribeV1LinksByCodeWorkflowRunQuery,
  usePostRetoolV1DynamoRunLinearQaAgentMutation,
  usePostRetoolV1DynamoRunLinearQaAgentByIssueIdMutation,
  usePostRetoolV1DynamoRunComplianceAgentMutation,
  usePostRetoolV1DynamoSyncToolDataMutation,
  useGetRetoolV1WorkflowsLintQuery,
  useLazyGetRetoolV1WorkflowsLintQuery,
  usePostToolListingV1Mutation,
  usePutToolListingV1ByIdMutation,
  useDeleteToolListingV1ByIdMutation,
  usePostToolListingV1ByIdReportDataMutation,
  usePostToolListingV1KeysMutation,
  usePutToolListingV1ReplaceListingMutation,
  usePutToolListingV1ByIdLogoMutation,
  usePostToolListingV1TransferLoginMutation,
  usePostToolListingV1TransferDescriptorMutation,
  usePostToolListingV1UpdateListingLogosMutation,
  usePostVendorListingV1Mutation,
  usePostVendorListingV1MarkSimilarityCheckedMutation,
  usePostVendorListingV1QueryVectorsMutation,
  usePutVendorListingV1ByIdMutation,
  useDeleteVendorListingV1ByIdMutation,
  usePostVendorListingV1ByIdReportDataMutation,
  usePostVendorListingV1KeysMutation,
  usePutVendorListingV1ReplaceListingMutation,
  usePutVendorListingV1ByIdLogoMutation,
  useGetVendorListingV1FindWebsiteByListingTypeQuery,
  useLazyGetVendorListingV1FindWebsiteByListingTypeQuery,
  usePostVendorListingV1TransferToolMutation,
  usePostVendorListingV1TransferDescriptorMutation,
  usePostVendorListingV1UpdateListingLogosMutation,
  useGetVendorListingV1DraftListingFromUrlByListingTypeQuery,
  useLazyGetVendorListingV1DraftListingFromUrlByListingTypeQuery,
  usePutTransactionVendorToolListingV1ByMerchantIdentificationMutation,
} = injectedRtkApi
