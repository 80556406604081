import { ONBOARDING_FLOW_CONFIG } from "@brm/type-helpers/onboarding.js"
import { Flex, useDisclosure } from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import posthog from "posthog-js"
import { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useGetUserV1WhoamiQuery } from "../../app/services/generated-api.js"
import { selectCurrentOnboardingStep } from "../../features/onboarding/onboarding-slice.js"
import { useSubscribe } from "../../features/pubsub/subscribe.js"
import { SAVED_PATH } from "../../util/constant.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import InitialGmailExtractionBanner from "../Banner/InitialGmailExtractionBanner.js"
import NavSideBar from "../Nav/NavSideBar.js"
import { NAVBAR_WIDTH } from "../Nav/constants.js"
import { TrialExpiredModal } from "../TrialExpiredModal.js"

declare global {
  interface Window {
    pylon: unknown
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Pylon?: ((action: "show" | "hide") => void) | undefined
  }
}

export default function LoggedInContainer() {
  // rtk query pubsub cache invalidation
  useSubscribe(`${import.meta.env.VITE_API_BASE_URL}/subscribe/v1/organization`)

  const navigate = useNavigate()
  const trialExpiredModal = useDisclosure({ defaultIsOpen: true })
  const currentStep = useSelector(selectCurrentOnboardingStep)
  const location = useLocation()

  const { data: whoami } = useGetUserV1WhoamiQuery()
  const trialExpired = useMemo(
    () =>
      !!whoami?.organization.trial_expires_at &&
      Temporal.Instant.compare(whoami.organization.trial_expires_at, Temporal.Now.instant()) < 0,
    [whoami]
  )
  const trialExpiredModalIsOpen = trialExpired && trialExpiredModal.isOpen

  // Redirect users to the seller container if they are a seller
  useEffect(() => {
    if (whoami && whoami.organization.is_seller && !whoami.organization.is_buyer) {
      navigate("/seller")
    }
  }, [navigate, whoami])

  // Enable Pylon Chat
  useEffect(() => {
    if (whoami) {
      window.pylon = {
        chat_settings: {
          app_id: import.meta.env.VITE_PYLON_APP_ID,
          email: whoami.email,
          name: whoami.person.display_name,
          avatar_url: getPublicImageGcsUrl(whoami.profile_image?.gcs_file_name),
        },
      }
    }
  }, [whoami])

  useEffect(() => {
    const savedPath = window.sessionStorage.getItem(SAVED_PATH)
    if (savedPath === null) return
    window.sessionStorage.removeItem(SAVED_PATH)
    const url = new URL(savedPath)
    navigate({
      pathname: url.pathname,
      search: url.search,
      hash: url.hash,
    })
  }, [navigate])

  useEffect(() => {
    if (currentStep && currentStep !== "complete") {
      const onboardingStepPath = ONBOARDING_FLOW_CONFIG[currentStep].path
      if (onboardingStepPath && window.location.pathname !== onboardingStepPath) {
        navigate(onboardingStepPath)
        posthog.capture("onboarding_force_navigate", { step: currentStep })
      }
    }
  }, [currentStep, navigate])

  const isOnboardingPage = location.pathname.includes("/welcome")

  return (
    <>
      <Flex height="100%" flexDir="column">
        {!isOnboardingPage && <InitialGmailExtractionBanner />}
        <Flex flexGrow={1} overflow="auto">
          {!isOnboardingPage && <NavSideBar />}
          <Flex flexDir="column" flexGrow={1} flexShrink={1} minW={0} left={`${NAVBAR_WIDTH}px`}>
            <Outlet />
          </Flex>
        </Flex>
      </Flex>
      {trialExpiredModalIsOpen && <TrialExpiredModal {...trialExpiredModal} isOpen={trialExpiredModalIsOpen} />}
    </>
  )
}
