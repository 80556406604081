import { ONBOARDING_FLOW_CONFIG, type EnabledOnboardingStep } from "@brm/type-helpers/onboarding.js"
import { Box, Button, Flex, HStack, Icon, Stack, Text } from "@chakra-ui/react"
import posthog from "posthog-js"
import { useCallback } from "react"
import { FormattedMessage } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import {
  goBackOnboardingStep,
  goToNextOnboardingStep,
  selectHasPreviousOnboardingStep,
} from "../../features/onboarding/onboarding-slice.js"
import { OnboardingCalloutConfigurations } from "./OnboardingCalloutConfigurations.js"

export default function OnboardingCallout({
  step,
  onNextStep,
}: {
  step: EnabledOnboardingStep
  onNextStep?: () => void
}) {
  const dispatch = useDispatch()
  const goToNextStep = useCallback(() => {
    posthog.capture("onboarding_clicked_callout_next", { step })
    if (onNextStep) {
      onNextStep()
    } else {
      dispatch(goToNextOnboardingStep())
    }
  }, [dispatch, step, onNextStep])

  const goBackStep = useCallback(() => {
    posthog.capture("onboarding_clicked_callout_back", { step })
    dispatch(goBackOnboardingStep())
  }, [dispatch, step])

  const hasPreviousStep = useSelector(selectHasPreviousOnboardingStep)

  const stepConfig = OnboardingCalloutConfigurations[step]
  if (!stepConfig) {
    return null
  }

  const { icon, heading, subtitle } = stepConfig
  const nextStep = ONBOARDING_FLOW_CONFIG[step].nextStep

  return (
    <Box p={4} maxWidth="25vw">
      <Flex gap="1rem" userSelect="none">
        <Icon as={icon} borderRadius="md" p=".5rem" boxSize="2.5rem" bg="brand.lime" mt=".25rem" />
        <Stack>
          <Text fontSize="lg" fontWeight="semibold">
            {heading}
          </Text>
          <Text fontSize="md" fontWeight="normal">
            {subtitle}
          </Text>
          <HStack>
            {hasPreviousStep && (
              <Button variant="outline" size="md" onClick={goBackStep}>
                <FormattedMessage
                  id="onboarding.callout.back"
                  description="Message shown when user needs to proceed without completing onboarding action"
                  defaultMessage="Back"
                />
              </Button>
            )}
            <Button colorScheme="brand" size="md" onClick={goToNextStep}>
              {nextStep === "complete" ? (
                <FormattedMessage
                  id="onboarding.callout.done"
                  description="Message shown on onboarding callout when user is on the last step"
                  defaultMessage="Done"
                />
              ) : (
                <FormattedMessage
                  id="onboarding.callout.proceed"
                  description="Message shown when user needs to proceed without completing onboarding action"
                  defaultMessage="Continue"
                />
              )}
            </Button>
          </HStack>
        </Stack>
      </Flex>
    </Box>
  )
}
