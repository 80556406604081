import { hasPermission } from "@brm/schema-helpers/role.js"
import { Box, Button, Center, HStack, Icon, Stack, Text } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import {
  useGetUserV1WhoamiQuery,
  useGetWorkflowV1DefinitionsKindListQuery,
  type WorkflowKind,
} from "../../../../app/services/generated-api.js"
import { NextIcon } from "../../../../components/icons/icons.js"
import { Link } from "../../../../components/Link.js"
import Spinner from "../../../../components/spinner.js"
import WorkflowKindIcon from "../WorkflowRunKindIcon.js"

export default function WorkflowKindSection({
  startWorkflow,
  closeModal,
}: {
  startWorkflow: (workflowKind: WorkflowKind) => void
  closeModal: () => void
}) {
  const intl = useIntl()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const { data: configuredWorkflowKinds, isLoading: isWorkflowDefsLoading } = useGetWorkflowV1DefinitionsKindListQuery()
  const missingSoftwarePurchaseConfig = !configuredWorkflowKinds?.includes("software_purchase")
  const missingSoftwareRenewalConfig = !configuredWorkflowKinds?.includes("software_renewal")
  const missingVendorPurchaseConfig = !configuredWorkflowKinds?.includes("vendor_purchase")
  const missingVendorRenewalConfig = !configuredWorkflowKinds?.includes("vendor_renewal")

  const translateWorkflowKindToRequestOption = (kind: WorkflowKind) => {
    switch (kind) {
      case "software_purchase":
        return intl.formatMessage({
          defaultMessage: "Purchase new software",
          description: "Request start button option to request to purchase a tool",
          id: "request.start.modal.kind.purchase",
        })
      case "software_renewal":
        return intl.formatMessage({
          defaultMessage: "Renew software",
          description: "Request start button option to request to renew a tool",
          id: "request.start.modal.kind.renewal",
        })
      case "vendor_purchase":
        return intl.formatMessage({
          defaultMessage: "Purchase vendor goods or services",
          description: "Request start button option to request to purchase a vendor",
          id: "request.start.modal.kind.vendor_purchase",
        })
      case "vendor_renewal":
        return intl.formatMessage({
          defaultMessage: "Renew vendor services",
          description: "Request start button option to request to renew a vendor",
          id: "request.start.modal.kind.vendor_renewal",
        })
      case "gather_data":
        return intl.formatMessage({
          defaultMessage: "Gather compliance data",
          description: "Request start button option to request to gather compliance data",
          id: "request.start.modal.kind.gather_data",
        })
    }
  }

  if (isWorkflowDefsLoading) {
    return (
      <Center height="100%">
        <Spinner size="md" />
      </Center>
    )
  }

  return (
    <Stack gap={6}>
      <Stack>
        <Text fontWeight="semibold">
          <FormattedMessage
            id="requests.start.modal.purchase.heading"
            description="Subheading for purchase options in the start request modal"
            defaultMessage="Buy something new"
          />
        </Text>
        <Button
          onClick={() => {
            startWorkflow("software_purchase")
          }}
          variant="hoverOutline"
          isDisabled={missingSoftwarePurchaseConfig}
          width="full"
          justifyContent="start"
          leftIcon={<WorkflowKindIcon kind="software_purchase" />}
        >
          {translateWorkflowKindToRequestOption("software_purchase")}
        </Button>
        <Button
          onClick={() => {
            startWorkflow("vendor_purchase")
          }}
          variant="hoverOutline"
          isDisabled={missingVendorPurchaseConfig}
          width="full"
          justifyContent="start"
          leftIcon={<WorkflowKindIcon kind="vendor_purchase" />}
        >
          {translateWorkflowKindToRequestOption("vendor_purchase")}
        </Button>
      </Stack>
      <Stack>
        <Text fontWeight="semibold">
          <FormattedMessage
            id="requests.start.modal.renewal.heading"
            description="Subheading for renewal options in the start request modal"
            defaultMessage="Manage existing spend"
          />
        </Text>
        <Button
          onClick={() => {
            startWorkflow("software_renewal")
          }}
          variant="hoverOutline"
          isDisabled={missingSoftwareRenewalConfig}
          width="full"
          justifyContent="start"
          leftIcon={<WorkflowKindIcon kind="software_renewal" />}
        >
          {translateWorkflowKindToRequestOption("software_renewal")}
        </Button>
        <Button
          onClick={() => {
            startWorkflow("vendor_renewal")
          }}
          variant="hoverOutline"
          isDisabled={missingVendorRenewalConfig}
          width="full"
          justifyContent="start"
          leftIcon={<WorkflowKindIcon kind="vendor_renewal" />}
        >
          {translateWorkflowKindToRequestOption("vendor_renewal")}
        </Button>
      </Stack>
      <Stack>
        <Text fontWeight="semibold">
          <FormattedMessage
            id="requests.start.modal.gather_data.heading"
            description="Subheading for gather data options in the start request modal"
            defaultMessage="Gather data"
          />
        </Text>
        <HStack alignItems="center">
          <Button
            onClick={() => {
              startWorkflow("gather_data")
            }}
            variant="hoverOutline"
            width="full"
            justifyContent="start"
            leftIcon={<WorkflowKindIcon kind="gather_data" />}
          >
            <FormattedMessage
              id="request.start.modal.kind.gather_data"
              defaultMessage="Gather compliance data"
              description="Request start button option to request to gather compliance data"
            />
          </Button>
        </HStack>
      </Stack>
      {!isWorkflowDefsLoading &&
      (missingSoftwareRenewalConfig ||
        missingSoftwarePurchaseConfig ||
        missingVendorRenewalConfig ||
        missingVendorPurchaseConfig) ? (
        <Box fontSize="sm">
          {hasPermission(whoami?.roles, "workflow_def:create") ? (
            <Link
              to="/settings/requests"
              onClick={closeModal}
              variant="highlighted"
              display="flex"
              gap={0.5}
              alignItems="center"
            >
              <FormattedMessage
                id="request.start.config.cta"
                description="Link text shown to user when they have no request types configured. Clicking this will navigate them to the request configuration page"
                defaultMessage="Missing a request option? Click here to configure."
              />
              <Icon as={NextIcon} />
            </Link>
          ) : (
            <Text>
              <FormattedMessage
                id="request.start.config.no_permission"
                description="Message shown to user when no request types have been configured"
                defaultMessage="Missing a request option? Please contact an administrator to get set up."
              />
            </Text>
          )}
        </Box>
      ) : null}
    </Stack>
  )
}
