import type { EmailDraftInput } from "../../app/services/generated-api.js"

export const EMPTY_EMAIL_DRAFT: EmailDraftInput = {
  html_body: "",
  to_addresses: [],
  cc_addresses: [],
  bcc_addresses: [],
  html_reply_body: "",
  include_reply_body: false,
  response_type: null,
}
