import type { Tool } from "@brm/schema-types/types.js"
import { Tooltip, type SystemStyleObject } from "@chakra-ui/react"
import { memo } from "react"
import { useGetUserV1WhoamiQuery } from "../../../app/services/generated-api.js"
import ToolDisplay from "../../../features/tool/ToolDisplay.js"
import { getPublicImageGcsUrl } from "../../../util/url.js"
import { VendorIcon } from "../../icons/icons.js"
import { Link } from "../../Link.js"
import LogoInfoCard from "../../LogoInfoCard.js"

interface Props {
  tool: Pick<Tool, "id" | "display_name" | "image_asset" | "description">
  textContentStyle?: SystemStyleObject
  tab?: string
}

export default memo(
  function ToolCell({ tool, textContentStyle, tab }: Props) {
    const { data: whoami } = useGetUserV1WhoamiQuery()

    if (whoami?.organization.is_lite) {
      return (
        <Tooltip
          padding={0}
          label={
            <LogoInfoCard
              displayName={tool.display_name}
              logoUrl={getPublicImageGcsUrl(tool.image_asset?.gcs_file_name)}
              fallbackAvatarLogo={VendorIcon}
              description={tool.description}
            />
          }
          shouldWrapChildren
        >
          <ToolDisplay
            toolLogoUrl={getPublicImageGcsUrl(tool.image_asset?.gcs_file_name)}
            toolName={tool.display_name}
            size="md"
            textContentStyle={textContentStyle}
          />
        </Tooltip>
      )
    }
    return (
      <Link to={`/tools/${tool.id}${tab ? `/${tab}` : ""}`} flexShrink={1} height="100%" maxWidth="100%">
        <ToolDisplay
          toolLogoUrl={getPublicImageGcsUrl(tool.image_asset?.gcs_file_name)}
          toolName={tool.display_name}
          size="md"
          textContentStyle={textContentStyle}
        />
      </Link>
    )
  },
  (prevProps, nextProps) =>
    prevProps.tool.id === nextProps.tool.id &&
    prevProps.tool.display_name === nextProps.tool.display_name &&
    prevProps.tool.image_asset?.gcs_file_name === nextProps.tool.image_asset?.gcs_file_name
)
