import { LoginError } from "@brm/type-helpers/user.js"
import { isObject } from "@brm/util/type-guard.js"
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  Text,
  chakra,
} from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate, useSearchParams } from "react-router-dom"
import {
  useGetOauthV1SignupValidateQuery,
  usePostOrganizationV1OpenSignupMutation,
} from "../../app/services/generated-api.js"
import { Link } from "../../components/Link.js"
import { GoogleIcon } from "../../components/icons/provider-icons.js"
import { LoginBaseContainer } from "./LoginBaseContainer.js"

// Types
interface OpenSignupFormState {
  organization_name: string
}

// Subcomponents
function WelcomeSubtitle() {
  const intl = useIntl()
  return (
    <Text fontSize="lg" color="gray.600" fontWeight="normal">
      {intl.formatMessage(
        {
          id: "openSignup.subtitle",
          description: "Subtitle on Open Signup page",
          defaultMessage: "Create your organization and start managing contracts {highlight}",
        },
        {
          highlight: (
            <Text as="span" color="brand.600" display="inline" fontStyle="italic" fontWeight="medium">
              {intl.formatMessage({
                id: "openSignup.subtitle.highlight",
                description: "Subtitle highlight on Open Signup page",
                defaultMessage: "Automagically",
              })}
            </Text>
          ),
        }
      )}
    </Text>
  )
}

function GoogleOAuthButton() {
  const [searchParams] = useSearchParams()
  const code = searchParams.get("code")

  return (
    <Button
      id="google-signup"
      variant="outline"
      as={Link}
      to={`${import.meta.env.VITE_API_BASE_URL}/oauth/v1/signin/google?open_signup=true&code=${code}`}
      target="_self"
      width="full"
      height="8"
    >
      <HStack>
        <GoogleIcon />
        <Text>
          <FormattedMessage
            id="openSignup.oauth.option"
            description="OAuth open signup option CTA"
            defaultMessage="Continue with Google"
          />
        </Text>
      </HStack>
    </Button>
  )
}

// Main Component
export default function OpenSignup() {
  const intl = useIntl()
  const navigate = useNavigate()
  const [error, setError] = useState<string | null>(null)
  const [searchParams] = useSearchParams()
  const code = searchParams.get("code")
  const { data: validationData, isError: isValidationError } = useGetOauthV1SignupValidateQuery(
    { code: code ?? "" },
    { skip: !code }
  )
  const [openSignup] = usePostOrganizationV1OpenSignupMutation()

  // Handle validation error
  useEffect(() => {
    if (isValidationError) {
      setError(
        intl.formatMessage({
          id: "openSignup.error.invalidSession",
          description: "Error message when signup session is invalid",
          defaultMessage: "Invalid signup session. Please try signing up again.",
        })
      )
    }
  }, [isValidationError, intl])

  // Handle error from URL parameters
  useEffect(() => {
    const searchParamsError = searchParams.get("error")
    if (searchParamsError) {
      switch (searchParamsError) {
        case LoginError.USER_ALREADY_EXISTS:
        case LoginError.FREE_EMAIL_NOT_ALLOWED:
        case LoginError.ORGANIZATION_ALREADY_EXISTS:
          setError(searchParamsError)
          break
        default:
          setError(
            intl.formatMessage({
              id: "openSignup.error.generic",
              description: "Generic error message for open signup",
              defaultMessage: "Something went wrong. Please try again.",
            })
          )
      }
    }
  }, [searchParams, intl])

  const form = useForm<OpenSignupFormState>({
    defaultValues: {
      organization_name: "",
    },
    reValidateMode: "onSubmit",
  })

  const handleSubmit = async (values: OpenSignupFormState) => {
    const code = searchParams.get("code")
    if (!code) {
      setError(
        intl.formatMessage({
          id: "openSignup.error.noCode",
          description: "Error message when signup code is missing",
          defaultMessage: "Missing signup code. Please try signing up again.",
        })
      )
      return
    }

    try {
      await openSignup({
        body: {
          organization_name: values.organization_name,
          code,
        },
      }).unwrap()

      // Redirect to home page after successful signup
      navigate("/")
    } catch (err) {
      if (
        isObject(err) &&
        "data" in err &&
        isObject(err.data) &&
        "message" in err.data &&
        typeof err.data.message === "string"
      ) {
        setError(err.data.message)
      } else {
        setError(
          intl.formatMessage({
            id: "openSignup.error.generic",
            description: "Generic error message for open signup",
            defaultMessage: "Something went wrong. Please try again.",
          })
        )
      }
    }
  }

  return (
    <LoginBaseContainer
      heading={intl.formatMessage({
        id: "openSignup.heading",
        description: "Heading on Open Signup page",
        defaultMessage: "Welcome to BRM",
      })}
      subtitleElement={<WelcomeSubtitle />}
    >
      <Stack
        spacing="5"
        as={chakra.form}
        onSubmit={(event) => {
          event.preventDefault()
          void form.handleSubmit(handleSubmit)(event)
        }}
      >
        {validationData?.isValid ? (
          <Controller
            control={form.control}
            name="organization_name"
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={fieldState.invalid} isRequired>
                <FormLabel htmlFor="organization_name">
                  <FormattedMessage
                    id="openSignup.form.organization_name"
                    description="Label for open signup form organization name field"
                    defaultMessage="Organization Name"
                  />
                </FormLabel>
                <Input {...field} id="organization_name" type="text" />
              </FormControl>
            )}
          />
        ) : null}

        {error && (
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>
              {error === LoginError.USER_ALREADY_EXISTS ? (
                <FormattedMessage
                  id="openSignup.error.userExists"
                  description="Error message when user already exists"
                  defaultMessage="A user with your email already exists. {loginLink}"
                  values={{
                    loginLink: (
                      <Link to="/login" fontWeight="semibold" color="red.700">
                        <FormattedMessage
                          description="Link to login page in error message"
                          id="openSignup.error.userExists.loginLink"
                          defaultMessage="Log in instead"
                        />
                      </Link>
                    ),
                  }}
                />
              ) : error === LoginError.FREE_EMAIL_NOT_ALLOWED ? (
                <FormattedMessage
                  id="openSignup.error.freeEmailNotAllowed"
                  description="Error message when user tries to sign up with a free email provider"
                  defaultMessage="Please use your work email address. Free email providers are not allowed."
                />
              ) : error === LoginError.ORGANIZATION_ALREADY_EXISTS ? (
                <FormattedMessage
                  id="openSignup.error.organizationExists"
                  description="Error message when an organization with the email domain already exists"
                  defaultMessage="An organization with your email domain already exists. {loginLink}"
                  values={{
                    loginLink: (
                      <Link to="/login" fontWeight="semibold" color="red.700">
                        <FormattedMessage
                          description="Link to login page in error message"
                          id="openSignup.error.organizationExists.loginLink"
                          defaultMessage="Log in instead"
                        />
                      </Link>
                    ),
                  }}
                />
              ) : (
                error
              )}
            </AlertDescription>
          </Alert>
        )}

        {!validationData?.isValid ? (
          <GoogleOAuthButton />
        ) : (
          <Button type="submit" colorScheme="brand" isLoading={form.formState.isSubmitting}>
            <FormattedMessage
              id="openSignup.form.submit"
              description="Submit button text for open signup form"
              defaultMessage="Create Organization"
            />
          </Button>
        )}

        <Text size="sm" textAlign="center">
          <FormattedMessage
            id="openSignup.loginLink.description"
            description="Description of link to login page"
            defaultMessage="Already have an account? {loginLink}"
            values={{
              loginLink: (
                <Link to="/login" fontWeight="semibold" color="brand.700">
                  <FormattedMessage
                    description="link to the login page"
                    id="openSignup.loginLink.link"
                    defaultMessage="Log in"
                  />
                </Link>
              ),
            }}
          />
        </Text>
      </Stack>
    </LoginBaseContainer>
  )
}
