import type { NullableOnboardingStep } from "@brm/type-helpers/onboarding.js"
import { ONBOARDING_FLOW_CONFIG } from "@brm/type-helpers/onboarding.js"
import { createListenerMiddleware, createSlice } from "@reduxjs/toolkit"
import posthog from "posthog-js"
import { generatedApi } from "../../app/services/generated-api.js"
import type { RootState } from "../../app/store-types.js"

interface OnboardingState {
  currentStep: NullableOnboardingStep
  initialized: boolean
  userHasOnboardingFlow: boolean
}

const initialState: OnboardingState = {
  currentStep: null,
  initialized: false,
  userHasOnboardingFlow: false,
}

function getNextStep(currentStep: NullableOnboardingStep): NullableOnboardingStep {
  return currentStep ? ONBOARDING_FLOW_CONFIG[currentStep].nextStep : null
}

function getPreviousStep(currentStep: NullableOnboardingStep): NullableOnboardingStep {
  return currentStep ? ONBOARDING_FLOW_CONFIG[currentStep].previousStep : null
}

// Selectors
export const selectCurrentOnboardingStep = (state: RootState): NullableOnboardingStep =>
  state.onboarding.userHasOnboardingFlow ? state.onboarding.currentStep : null
export const selectIsOnboardingInitialized = (state: RootState): boolean => state.onboarding.initialized
export const selectHasPreviousOnboardingStep = (state: RootState): boolean =>
  state.onboarding.currentStep !== null && getPreviousStep(state.onboarding.currentStep) !== null

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    initializeOnboardingState: (
      state,
      {
        payload: { step, userHasOnboardingFlow },
      }: { payload: { step: NullableOnboardingStep; userHasOnboardingFlow: boolean } }
    ) => {
      if (state.initialized) return
      if (!userHasOnboardingFlow) return
      if (!step) return

      state.currentStep = step
      state.initialized = true
      state.userHasOnboardingFlow = userHasOnboardingFlow

      if (step !== "complete") {
        posthog.capture("onboarding_reenter_flow", { step })
      }
    },
    enterOnboarding: (state) => {
      const firstStep = "welcome.never_miss_a_renewal"
      state.currentStep = firstStep
      state.initialized = true
      state.userHasOnboardingFlow = true
      posthog.capture("onboarding_start_flow", { step: firstStep })
    },
    goToNextOnboardingStep: (state) => {
      if (!state.userHasOnboardingFlow) return
      if (!state.currentStep) return

      const prevStep = state.currentStep
      const nextStep = getNextStep(state.currentStep)
      state.currentStep = nextStep
      state.initialized = true

      if (prevStep !== "complete") {
        posthog.capture(nextStep === "complete" ? "onboarding_complete_flow" : "onboarding_go_next_step", {
          step: prevStep,
          next_step: nextStep,
        })
      }
    },
    goBackOnboardingStep: (state) => {
      if (!state.userHasOnboardingFlow) return
      if (!state.currentStep) return

      const prevStep = getPreviousStep(state.currentStep)
      state.currentStep = prevStep
      state.initialized = true
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(generatedApi.endpoints.getOnboardingV1Latest.matchFulfilled, (state, { payload }) => {
        state.currentStep = payload?.step ?? null
      })
      .addMatcher(generatedApi.endpoints.postOnboardingV1StepByStepTypeRecord.matchFulfilled, (state, { payload }) => {
        state.currentStep = payload.step
      })
  },
})

// Create listener middleware to sync state changes with backend
export const onboardingListenerMiddleware = createListenerMiddleware()

onboardingListenerMiddleware.startListening({
  actionCreator: onboardingSlice.actions.enterOnboarding,
  effect: async (_, { dispatch, getState }) => {
    const state = getState() as RootState
    const currentStep = selectCurrentOnboardingStep(state)
    if (currentStep) {
      await dispatch(
        generatedApi.endpoints.postOnboardingV1StepByStepTypeRecord.initiate({
          stepType: currentStep,
        })
      ).unwrap()
    }
  },
})

onboardingListenerMiddleware.startListening({
  actionCreator: onboardingSlice.actions.goToNextOnboardingStep,
  effect: async (_, { dispatch, getState }) => {
    const state = getState() as RootState
    const currentStep = selectCurrentOnboardingStep(state)
    if (currentStep) {
      await dispatch(
        generatedApi.endpoints.postOnboardingV1StepByStepTypeRecord.initiate({
          stepType: currentStep,
        })
      ).unwrap()
    }
  },
})

onboardingListenerMiddleware.startListening({
  actionCreator: onboardingSlice.actions.goBackOnboardingStep,
  effect: async (_, { dispatch, getState }) => {
    const state = getState() as RootState
    const currentStep = selectCurrentOnboardingStep(state)
    if (currentStep) {
      await dispatch(
        generatedApi.endpoints.postOnboardingV1StepByStepTypeRecord.initiate({
          stepType: currentStep,
        })
      ).unwrap()
    }
  },
})

export const { enterOnboarding, goToNextOnboardingStep, goBackOnboardingStep, initializeOnboardingState } =
  onboardingSlice.actions
export const onboardingReducer = onboardingSlice.reducer
