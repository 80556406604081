import { serializeRichTextToMarkdown } from "@brm/schema-helpers/rich-text/serialize.js"
import type {
  Conversation,
  EmailDraftInput,
  Message,
  Negotiation,
  NegotiationConversation,
  PickableEntityFilter,
  ToolCall,
} from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import { isEmpty } from "@brm/util/type-guard.js"
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Spacer,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
  useToast,
  VStack,
  type CreateToastFnReturn,
  type UseToastOptions,
} from "@chakra-ui/react"
import { fetchEventSource } from "@microsoft/fetch-event-source"
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { type Descendant } from "slate"
import braimLogo from "../../../assets/braim.svg"
import {
  useGetUserV1WhoamiQuery,
  useGetUserV1WritingPersonaQuery,
  usePostNegotiationV1UpdateContextMutation,
  usePostUserV1WritingPersonaMutation,
  type Email,
} from "../../app/services/generated-api.js"
import BetsyDisplayName from "../../components/ContextMenu/BetsyDisplayName.js"
import { IconButtonWithTooltip } from "../../components/IconButtonWithTooltip.js"
import {
  CopyIcon,
  PuzzlePieceIcon,
  RenewalIcon,
  SendIcon,
  ShareIcon,
  WritingPersonaIcon,
  ZapIcon,
} from "../../components/icons/icons.js"
import { EMPTY_EMAIL_DRAFT } from "../../components/negotiation/util.js"
import RichTextEditor from "../../components/RichTextEditor/RichTextEditor.js"
import { DEFAULT_PICKABLE_ENTITIES, trimRichTextWhiteSpace } from "../../components/RichTextEditor/util/common.js"
import SuggestedPromptBadge from "../../components/SuggestedPromptBadge/SuggestedPromptBadge.js"
import { log } from "../../util/logger.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import type { AgentAction } from "./AgentResponse.js"
import { ChatMessage, type ChatMessageAuthor } from "./ChatMessage.js"
import EmailComposer from "./EmailComposer.js"
import { NegotiationDataModal } from "./NegotiationDataModal.js"
import { defaultSuggestedPrompts } from "./util.js"
import { WritingPersonaModal } from "./WritingPersonaModal.js"

const DEFAULT_ERROR_TOAST: UseToastOptions = {
  description: "Something went wrong",
  status: "error",
}

const ChatRichTextEditor = forwardRef<
  HTMLDivElement,
  {
    initialValue?: Descendant[]
    isLoadingAnswer: boolean
    isReadOnly: boolean
    showAgentTelemetry: boolean
    startNewConversation?: () => void
    submit: (draft: Descendant[] | undefined) => Promise<void>
    pickableEntityFilters: Omit<PickableEntityFilter, "name">
    enableWritingPersonaModal: boolean
  }
>(function ChatRichTextEditor(
  {
    isLoadingAnswer,
    isReadOnly,
    showAgentTelemetry,
    startNewConversation,
    submit,
    pickableEntityFilters,
    initialValue,
    enableWritingPersonaModal,
  },
  ref
) {
  const intl = useIntl()
  const [draft, setDraft] = useState<Descendant[] | undefined>(initialValue)

  const { data: writingPersona, refetch: refetchWritingPersona } = useGetUserV1WritingPersonaQuery()

  const [updateWritingPersona, { isLoading: isUpdatingWritingPersona }] = usePostUserV1WritingPersonaMutation()

  // useDisclosure to open and close the writing persona modal
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <WritingPersonaModal
        isOpen={isOpen}
        onClose={onClose}
        isUpdating={isUpdatingWritingPersona}
        userPersona={writingPersona?.writing_persona ?? null}
        onPersonaUpdate={async (persona: string) => {
          await updateWritingPersona({ body: { writing_persona: persona } }).unwrap()
          await refetchWritingPersona()
        }}
      />
      <RichTextEditor
        placeholder={intl.formatMessage({
          id: "chat.placeholder",
          description: "Placeholder for the chat input",
          defaultMessage: "Type here to chat...",
        })}
        forceFocus={true}
        isReadOnly={isReadOnly}
        initialValue={draft}
        containerProps={{
          onKeyDown: async (e) => {
            if (
              e.key === "Enter" &&
              (e.metaKey || e.ctrlKey) &&
              !isLoadingAnswer &&
              !isReadOnly &&
              !showAgentTelemetry
            ) {
              const savedDraft = structuredClone(draft)
              setDraft(undefined)
              try {
                await submit(savedDraft)
              } catch (_) {
                setDraft(savedDraft)
              }
            } else if (e.key === "k" && e.metaKey && e.shiftKey) {
              startNewConversation?.()
            }
          },
        }}
        onChange={setDraft}
        sendButton={
          <IconButton
            padding={1}
            isDisabled={isReadOnly || isLoadingAnswer || !draft || showAgentTelemetry}
            isLoading={isLoadingAnswer}
            size="sm"
            variant="link"
            colorScheme="brand"
            aria-keyshortcuts="Enter"
            onClick={async () => {
              const savedDraft = structuredClone(draft)
              setDraft(undefined)
              try {
                await submit(savedDraft)
              } catch (_) {
                setDraft(savedDraft)
              }
            }}
            icon={<Icon as={SendIcon} />}
            aria-label={intl.formatMessage({
              id: "chat.send",
              description: "Aria label for the send button in the chat",
              defaultMessage: "Send",
            })}
          />
        }
        pickableEntityFilters={pickableEntityFilters}
        ref={ref}
        additionalToolbarButtons={
          enableWritingPersonaModal && (
            <IconButtonWithTooltip
              variant="ghost"
              size="sm"
              onClick={onOpen}
              icon={<Icon as={WritingPersonaIcon} />}
              label={intl.formatMessage({
                id: "chat.persona",
                description: "Persona context",
                defaultMessage: "Writing Persona",
              })}
            />
          )
        }
      />
    </>
  )
})

function Chat({
  conversation,
  startNewConversation,
  onError,
  onSuccess,
  defaultMessages,
  initialValue,
  onSubmitStreamingUrl,
  onRegenerate,
  onReset,
  addOns = {},
  additionalRequestParams = {},
  showAgentTelemetry,
}: {
  conversation: Partial<Omit<Conversation, "id">> & { id: string }
  startNewConversation?: () => void
  onError?: () => void
  // Calls onSuccess with the AI response
  onSuccess?: (message?: string) => void
  initialValue?: Descendant[]
  defaultMessages?: Message[]
  onSubmitStreamingUrl: string
  onRegenerate?: () => void
  onReset?: (requestParams: object) => void
  addOns?: {
    suggestedPrompts?: {
      enabled: boolean
      defaultPrompts?: string[]
    }
    negotiation?: {
      enabled: boolean
      negotiation: Negotiation
      negotiationConversation: NegotiationConversation | undefined
      onSelectReplyToEmail: (email: Email) => Promise<NegotiationConversation | undefined>
      onSendSuccess?: () => void
    }
  }
  additionalRequestParams?: Record<string, string | undefined>
  showAgentTelemetry?: boolean
  onSubmitSuccess?: () => void
}) {
  const intl = useIntl()
  const navigate = useNavigate()
  const toast = useToast()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const { id: conversationId } = conversation
  const { negotiationConversation, onSelectReplyToEmail, negotiation, onSendSuccess } = addOns.negotiation ?? {}
  const [messages, setMessages] = useState<Message[] | undefined>(conversation?.messages ?? defaultMessages)
  const [status, setStatus] = useState("waiting")
  const [isLoadingAnswer, setIsLoadingAnswer] = useState(false)
  const bottomRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLDivElement>(null)
  const [emailDraft, setEmailDraft] = useState<EmailDraftInput>(
    negotiationConversation?.email_drafts?.at(-1) ?? EMPTY_EMAIL_DRAFT
  )

  const [suggestedPrompts, setSuggestedPrompts] = useState<string[] | undefined>(defaultSuggestedPrompts)

  const [updateNegotiationContext, { isLoading: isUpdatingNegotiationContext }] =
    usePostNegotiationV1UpdateContextMutation()

  // Reset state when conversation Id changes
  useEffect(() => {
    setMessages(conversation.messages ?? defaultMessages)
    setSuggestedPrompts(addOns.suggestedPrompts?.defaultPrompts)
    setIsLoadingAnswer(false)
    setEmailDraft(negotiationConversation?.email_drafts?.at(-1) ?? EMPTY_EMAIL_DRAFT)
    inputRef.current?.focus()
  }, [
    conversationId,
    conversation.messages,
    defaultMessages,
    initialValue,
    addOns.suggestedPrompts?.defaultPrompts,
    negotiationConversation?.email_drafts,
    negotiationConversation?.id,
  ])

  const pickableEntityFilters: Omit<PickableEntityFilter, "name"> = useMemo(
    () => ({
      entities: DEFAULT_PICKABLE_ENTITIES,
    }),
    []
  )

  useEffect(() => {
    if (!isLoadingAnswer && suggestedPrompts && suggestedPrompts.length > 0) {
      // Scroll to bottom when suggested prompts are updated
      bottomRef.current?.scrollIntoView({ behavior: "instant" })
    }

    if (bottomRef.current && window.innerHeight - bottomRef.current.getBoundingClientRect().y > 0) {
      // Pin to bottom if the user is already scrolled to the bottom
      bottomRef.current.scrollIntoView({ behavior: "instant" })
    }

    // Scroll to bottom on new agent replies
    if (messages?.[messages.length - 1]?.content === "") {
      bottomRef.current?.scrollIntoView({ behavior: "instant" })
    }
  }, [messages, suggestedPrompts, isLoadingAnswer])

  const isReadOnly: boolean = conversation?.user_id ? whoami?.id !== conversation.user_id : false

  const userAuthor: ChatMessageAuthor = useMemo(
    () =>
      conversation.user_id && conversation?.user_id !== whoami?.id
        ? {
            name:
              conversation?.user?.first_name ||
              (conversation?.user && displayPersonName(conversation.user, intl)) ||
              "User",
            image: getPublicImageGcsUrl(conversation?.user?.profile_image?.gcs_file_name),
            role: "user" as const,
          }
        : {
            name: whoami?.first_name || (whoami && displayPersonName(whoami, intl)) || "User",
            image: getPublicImageGcsUrl(whoami?.profile_image?.gcs_file_name),
            role: "user" as const,
          },
    [conversation, whoami, intl]
  )

  const fetchAnswer = useCallback(
    async (draft: Descendant[], messages: Message[] | undefined) => {
      let dataReceived = false
      await fetchEventSource(onSubmitStreamingUrl, {
        method: "POST",
        credentials: "include",
        openWhenHidden: true,
        body: JSON.stringify({
          query: draft,
          messages,
          conversation_id: conversation.id,
          log_message: true,
          ...(addOns.negotiation?.enabled && emailDraft ? { email_draft: emailDraft } : {}),
          ...additionalRequestParams,
        }),
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Accept: "text/event-stream",
          ["Content-Type"]: "application/json",
        },
        async onopen(res) {
          if (res.ok && res.status === 200) {
            // do nothing
            return
          }
          if (res.status === 401) {
            navigate("/login")
            return
          }

          throw new Error("braim api failure")
        },
        onmessage(event) {
          if (event.event === "data") {
            dataReceived = true
            const data = JSON.parse(event.data)
            const text = data?.text
            const suggestedPrompts = data?.suggestedPrompts
            const emailDraft = data?.emailDraft
            if (addOns.suggestedPrompts?.enabled && !isEmpty(suggestedPrompts)) {
              setSuggestedPrompts(suggestedPrompts)
            }
            if (addOns.negotiation?.enabled && !isEmpty(emailDraft)) {
              setEmailDraft(emailDraft)
            }
            if (!isEmpty(text)) {
              setStatus("waiting")
              setMessages((c) => {
                const currentMessage: Message = (c ?? []).at(-1)!
                const ret = c?.slice(0, -1) ?? []
                ret.push({ ...currentMessage, content: `${currentMessage?.content ?? ""}${text}` })
                return ret
              })
            }
          } else if (event.event === "status") {
            const data = JSON.parse(event.data)
            setStatus(data.status)
            log.info("braim api status", data.status)
          }
        },
        onclose() {
          if (!dataReceived) {
            throw new Error("braim api failure")
          }
        },
        onerror(e) {
          log.error("braim api failure", e, { draft, messages })

          // reset the last two messages
          setMessages((messages) => {
            // remove the last two messages
            return messages?.slice(0, -2) ?? []
          })

          toast(DEFAULT_ERROR_TOAST)
          onError?.()
          setIsLoadingAnswer(false)
          throw e
        },
      })
    },
    [
      onSubmitStreamingUrl,
      conversation.id,
      addOns.negotiation?.enabled,
      addOns.suggestedPrompts?.enabled,
      emailDraft,
      additionalRequestParams,
      navigate,
      toast,
      onError,
    ]
  )
  const submit = useCallback(
    async (draft: Descendant[] | undefined) => {
      const trimmedBody = draft && trimRichTextWhiteSpace(draft)
      if (trimmedBody) {
        const serializedQuery = serializeRichTextToMarkdown(trimmedBody)
        setMessages((currentMessages) => {
          return [
            ...(currentMessages ?? []),
            { content: serializedQuery, role: "user", rich_text: trimmedBody, tool_calls: null, citations: null },
            { content: "", role: "assistant", tool_calls: null, citations: null },
          ]
        })
        setIsLoadingAnswer(true)

        await fetchAnswer(trimmedBody, messages)

        // handle success
        setIsLoadingAnswer(false)
        setStatus("waiting")
        inputRef.current?.focus()
        onSuccess?.(messages?.[messages.length - 1]?.content ?? "")
      }
    },
    [messages, setMessages, fetchAnswer, onSuccess]
  )

  const { isOpen, onOpen, onClose } = useDisclosure()

  if (addOns.negotiation?.enabled && !negotiationConversation && negotiation && onSelectReplyToEmail) {
    return (
      <Flex width="100%" height="100%" flex={1} minH={0} gap={0}>
        <EmailComposer
          isLoading={isLoadingAnswer}
          emailDraft={emailDraft}
          onChange={(value) => setEmailDraft({ ...emailDraft, ...value })}
          negotiation={negotiation}
          negotiationConversation={negotiationConversation}
          onSelectReplyToEmail={onSelectReplyToEmail}
          onSendSuccess={onSendSuccess}
        />
      </Flex>
    )
  }

  return (
    <HStack width="100%" height="100%" flex={1} minH={0} gap={0}>
      {addOns.negotiation?.enabled && negotiation && onSelectReplyToEmail && (
        <>
          <EmailComposer
            isLoading={isLoadingAnswer}
            emailDraft={emailDraft}
            onChange={(value) => setEmailDraft({ ...emailDraft, ...value })}
            negotiation={addOns.negotiation?.negotiation}
            negotiationConversation={negotiationConversation}
            onSelectReplyToEmail={onSelectReplyToEmail}
            onSendSuccess={onSendSuccess}
          />
          <Divider orientation="vertical" />
        </>
      )}
      <Flex p={4} overflow="hidden" height="100%" minWidth="40%" flex={1}>
        {addOns.negotiation?.enabled && negotiation && negotiation.context !== null && (
          <>
            <NegotiationDataModal
              isOpen={isOpen}
              onClose={onClose}
              isUpdating={isUpdatingNegotiationContext}
              onUpdate={async (key, value) => {
                // Handle data updates
                await updateNegotiationContext({
                  body: {
                    negotiation_id: negotiation.id,
                    key,
                    value,
                  },
                })
              }}
              currentData={negotiation.context}
            />
            <Button onClick={onOpen} variant="ghost">
              <Icon as={PuzzlePieceIcon} />
            </Button>
          </>
        )}
        <Stack justifyContent="space-between" width="100%">
          <ChatMessages
            showAgentTelemetry={showAgentTelemetry ?? false}
            messages={messages ?? []}
            status={status}
            isLoadingAnswer={isLoadingAnswer}
            defaultMessages={defaultMessages ?? []}
            conversationId={conversationId}
            userAuthor={userAuthor}
            bottomRef={bottomRef}
            setEmailDraft={setEmailDraft}
          />
          <Box
            pos="relative"
            bottom="0"
            insetX="0"
            bgGradient="linear(to-t, white 80%, rgba(0,0,0,0))"
            paddingY="4"
            marginX="4"
          >
            <Stack maxW="prose" mx="auto">
              {addOns.suggestedPrompts?.enabled && (
                <HStack flexWrap="wrap" gap={2}>
                  {!isLoadingAnswer &&
                    suggestedPrompts?.map((prompt, i) => (
                      <SuggestedPromptBadge
                        key={i}
                        prompt={prompt}
                        onClick={async () => {
                          try {
                            await submit([
                              {
                                type: "paragraph",
                                children: [
                                  {
                                    text: prompt,
                                  },
                                ],
                              },
                            ])
                          } catch (_) {
                            toast(DEFAULT_ERROR_TOAST)
                          }
                        }}
                      />
                    ))}
                </HStack>
              )}
              <HStack as="form" pos="relative" my={3}>
                <ChatRichTextEditor
                  enableWritingPersonaModal={addOns.negotiation?.enabled ?? false}
                  initialValue={initialValue}
                  isLoadingAnswer={(isLoadingAnswer || negotiationConversation?.show_agent_telemetry) ?? false}
                  isReadOnly={(isReadOnly || negotiationConversation?.show_agent_telemetry) ?? false}
                  showAgentTelemetry={showAgentTelemetry ?? false}
                  startNewConversation={startNewConversation}
                  submit={submit}
                  pickableEntityFilters={pickableEntityFilters}
                  ref={inputRef}
                />
                {addOns.negotiation?.enabled && (
                  <VStack alignItems="baseline">
                    <Spacer />
                    <IconButtonWithTooltip
                      variant="ghost"
                      onClick={() => {
                        try {
                          onRegenerate?.()
                          // removing the messages on the client reduces flicker during the regeneration process
                          setMessages((messages) => {
                            if (!messages) {
                              return undefined
                            }

                            // remove the last N assistant messages until we have a user message
                            let N = 0
                            for (let i = messages.length - 1; i >= 0; i--) {
                              if (messages[i]?.role === "assistant") {
                                N++
                              } else {
                                break
                              }
                            }
                            return messages?.slice(0, -N) ?? []
                          })
                        } catch (_) {
                          toast(DEFAULT_ERROR_TOAST)
                        }
                      }}
                      icon={<Icon as={ZapIcon} />}
                      label={intl.formatMessage({
                        id: "chat.regenerate",
                        description: "Regenerate the conversation",
                        defaultMessage: "Regenerate",
                      })}
                      size="sm"
                    />
                    <IconButtonWithTooltip
                      variant="ghost"
                      onClick={() => {
                        try {
                          onReset?.({ replyToEmailId: negotiationConversation?.reply_to_email?.id })
                          setEmailDraft(EMPTY_EMAIL_DRAFT)
                          setMessages(undefined)
                        } catch (_) {
                          toast(DEFAULT_ERROR_TOAST)
                        }
                      }}
                      icon={<Icon as={RenewalIcon} />}
                      label={intl.formatMessage({
                        id: "chat.reset",
                        description: "Reset the conversation",
                        defaultMessage: "Reset",
                      })}
                      size="sm"
                    />
                  </VStack>
                )}
              </HStack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </HStack>
  )
}

// TODO: centralize the names for the tools, maybe move api/src/agent-tools
// into its own package or into an existing shared package.
const toolCallToAgentAction: Record<ToolCall["function"]["name"], AgentAction> = {
  write_email: { displayName: "Drafted email", name: "write_email" },
  fetch_url: { displayName: "Read a website", name: "fetch_url" },
  web_search: { displayName: "Searched the web", name: "web_search" },
  read_legal_agreement: { displayName: "Read the agreement", name: "read_legal_agreement" },
  update_negotiation_context: { displayName: "Took some notes", name: "update_negotiation_context" },
}

function getActions(
  message: Message,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setEmailDraft: React.Dispatch<React.SetStateAction<EmailDraftInput>>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toast: CreateToastFnReturn
): AgentAction[] | undefined {
  if (message.tool_calls === undefined || message.tool_calls === null) {
    return undefined
  }

  const actions: AgentAction[] = []
  for (const toolCall of message.tool_calls) {
    const functionName = toolCall.function.name
    // const args = toolCall.function.arguments

    if (functionName === "write_email") {
      const defaultAction = toolCallToAgentAction[functionName]
      if (defaultAction) {
        actions.push(defaultAction)
      }
      // NOTE: The email draft action is broken currently due to an update to the agent tool
      // we need to build this feature in a way that does not rely on the tool call
      // which cannot be expected to remain stable.
      // actions.push({
      //   name: "write_email",
      //   displayName: "Drafted email",
      //   onClick: () => {
      //     setEmailDraft((emailDraft) => {
      //       try {
      //         const prevEmailDraft = JSON.parse(args)
      //         return {
      //           ...emailDraft,
      //           html_body: prevEmailDraft.html_body,
      //         }
      //       } catch (_) {
      //         toast({
      //           description: "Failed to load email draft",
      //           status: "error",
      //         })
      //       }
      //       return emailDraft
      //     })
      //   },
      // })
    } else {
      const defaultAction = toolCallToAgentAction[functionName]
      if (defaultAction) {
        actions.push(defaultAction)
      }
    }
  }

  return actions
}

export function ChatMessages({
  showAgentTelemetry,
  messages,
  status,
  isLoadingAnswer,
  defaultMessages,
  conversationId,
  userAuthor,
  bottomRef,
  setEmailDraft,
}: {
  showAgentTelemetry: boolean
  messages: Message[]
  status: string
  isLoadingAnswer: boolean
  defaultMessages: Message[]
  conversationId: string
  userAuthor: ChatMessageAuthor
  bottomRef: React.RefObject<HTMLDivElement>
  setEmailDraft: React.Dispatch<React.SetStateAction<EmailDraftInput>>
}) {
  const toast = useToast()
  const intl = useIntl()

  return (
    <Box overflowY="auto" paddingY={2}>
      <Stack
        maxW="prose"
        mx="auto"
        paddingX={{ base: "1", md: "0" }}
        divider={
          <Box marginLeft="14!">
            <StackDivider />
          </Box>
        }
        spacing="5"
      >
        {messages?.map((m, i) => (
          <ChatMessage
            citations={m.citations ?? undefined}
            actions={getActions(m, setEmailDraft, toast)}
            richText={(m.rich_text as Descendant[]) ?? undefined}
            status={i === messages.length - 1 && status !== "waiting" ? status : undefined}
            after={
              m !== defaultMessages?.[0] &&
              i === messages?.length - 1 && (
                <HStack py={2}>
                  <IconButtonWithTooltip
                    placement="bottom"
                    label={intl.formatMessage({
                      id: "message.copy.tooltip",
                      description: "Tooltip for the copy button",
                      defaultMessage: "Copy",
                    })}
                    variant="ghost"
                    onClick={async () => {
                      await navigator.clipboard.writeText(m.content)
                      toast({
                        description: intl.formatMessage({
                          id: "conversation.copy.success",
                          description: "Message displayed when the user successfully copies the message",
                          defaultMessage: "Copied to clipboard",
                        }),
                        status: "success",
                      })
                    }}
                    icon={<Icon as={CopyIcon} />}
                    size="sm"
                  />
                  <IconButtonWithTooltip
                    variant="ghost"
                    onClick={async () => {
                      const url = new URL(`braim/${conversationId}`, window.location.origin)
                      await navigator.clipboard.writeText(url.toString())
                      toast({
                        description: intl.formatMessage({
                          id: "conversation.share.success",
                          description: "Message displayed when the user successfully copies the conversation link",
                          defaultMessage: "Link copied to clipboard",
                        }),
                        status: "success",
                      })
                    }}
                    placement="bottom"
                    label={intl.formatMessage({
                      id: "conversation.share.tooltip",
                      description: "Tooltip for the share button",
                      defaultMessage:
                        "Share conversation. Anyone in your organization with the link will be able to view this conversation",
                    })}
                    icon={<Icon as={ShareIcon} />}
                    size="sm"
                  />
                </HStack>
              )
            }
            isLoading={i === messages.length - 1 && isLoadingAnswer}
            key={i}
            author={
              m.role === "user"
                ? userAuthor
                : {
                    name: "Braim",
                    renderedName: (
                      <Text display="inline" fontWeight="medium">
                        <BetsyDisplayName />
                      </Text>
                    ),
                    image: braimLogo,
                    role: m.role,
                  }
            }
            content={m.content}
          />
        ))}

        {showAgentTelemetry && (
          <ChatMessage
            author={{
              name: "Braim",
              role: "assistant",
              renderedName: (
                <Text display="inline" fontWeight="medium">
                  <BetsyDisplayName />
                </Text>
              ),
            }}
            content=""
            isAgentTelemetry={true}
            isLoading={false}
          />
        )}
      </Stack>
      <Box height="1px" ref={bottomRef} />
    </Box>
  )
}

export default Chat
