import posthog from "posthog-js"
import { useCallback } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import ContractCollectionIcon from "../../components/icons/contract-collection-graphic.js"
import { GmailIcon, SlackIcon } from "../../components/icons/provider-icons.js"
import RenewalCalendarGraphic from "../../components/icons/renewal-calendar-graphic.js"
import TermsExtractionGraphic from "../../components/icons/terms-extraction-graphic.js"
import {
  OnboardingWelcomeCard,
  type OnboardingWelcomeCardProps,
} from "../../components/Onboarding/OnboardingWelcomeCard.js"
import { goToNextOnboardingStep } from "./onboarding-slice.js"
import { OnboardingLogoLayout } from "./OnboardingLogoLayout.js"

type OnboardingWelcomeSlideType = "contract-collection" | "terms-extraction" | "renewal-calendar"
interface OnboardingWelcomeSlideProps extends OnboardingWelcomeCardProps {
  rightGraphic: React.ReactElement
}

export const OnboardingWelcomeSlide = ({ slide }: { slide: OnboardingWelcomeSlideType }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const goToNextStep = useCallback(() => {
    dispatch(goToNextOnboardingStep())
  }, [dispatch])

  const SlideConfig: Record<OnboardingWelcomeSlideType, OnboardingWelcomeSlideProps> = {
    "contract-collection": {
      heading: intl.formatMessage({
        id: "onboarding.contractCollection.header",
        defaultMessage: "Contract Collection.",
        description: "Header text for the contract collection onboarding slide",
      }),
      contentParagraphs: [
        intl.formatMessage({
          id: "onboarding.contractCollection.contentParagraph",
          defaultMessage:
            "Past, present, and future—SuperAgents have you covered. They’ll search your email inbox, find agreements up to 3 years back, and keep new ones flowing in—so you never have to lift a finger.",
          description: "Main explanatory text describing how the contract collection feature works",
        }),
      ],
      cta: intl.formatMessage({
        id: "onboarding.contractCollection.buttonCTA",
        defaultMessage: "Connect to Gmail",
        description: "Call to action button text for connecting Gmail to collect contracts",
      }),
      ctaIcon: <GmailIcon boxSize={6} />,
      onCtaClick: () => {
        posthog.capture("onboarding_connect_gmail_button_clicked", {
          step: "welcome.contract_collection",
        })
        window.location.href = `${import.meta.env.VITE_API_BASE_URL}/oauth/v1/connect/gmail_oauth`
      },
      rightGraphic: <ContractCollectionIcon boxSize={96} />,
      secondaryActionIntro: intl.formatMessage({
        id: "onboarding.contractCollection.secondaryActionIntro",
        defaultMessage: "Not sure?",
        description: "Intro text for the secondary action on the contract collection slide",
      }),
      secondaryActionCta: intl.formatMessage({
        id: "onboarding.contractCollection.secondaryActionCta",
        defaultMessage: "Tell me more about how this works.",
        description: "CTA text for the secondary action on the contract collection slide",
      }),
      secondaryActionOnClick: () => {
        posthog.capture("onboarding_tell_me_more_button_clicked", {
          step: "welcome.contract_collection",
        })
        window.open("https://support.brm.ai/articles/9229708029-contract-collector#faqs-7", "_blank")
      },
    },
    "terms-extraction": {
      preHeader: intl.formatMessage({
        id: "onboarding.termsExtraction.preheader",
        defaultMessage: "Inbox connected. Collecting your agreements now.",
        description: "Status message shown after Gmail is connected and contracts are being collected",
      }),
      heading: intl.formatMessage({
        id: "onboarding.termsExtraction.header",
        defaultMessage: "Terms... We Gotcha.",
        description: "Header text for the terms extraction onboarding slide",
      }),
      contentParagraphs: [
        intl.formatMessage({
          id: "onboarding.termsExtraction.contentParagraph1",
          defaultMessage:
            "Our SuperAgents have trained on tens of thousands of vendor agreements. They extract, organize, and link key terms.",
          description: "paragraph 1 describing how the terms extraction feature works",
        }),
        intl.formatMessage({
          id: "onboarding.termsExtraction.contentParagraph2",
          defaultMessage:
            "They rarely make a mistake, but you’re in control. Your team only sees agreements only after you verify them.",
          description: "paragraph 2 describing how the terms extraction feature works",
        }),
      ],
      cta: intl.formatMessage({
        id: "onboarding.termsExtraction.buttonCTA",
        defaultMessage: "Got it!",
        description: "Acknowledgment button text for the terms extraction slide",
      }),
      onCtaClick: () => {
        goToNextStep()
      },
      rightGraphic: <TermsExtractionGraphic boxSize={96} />,
    },
    "renewal-calendar": {
      heading: intl.formatMessage({
        id: "onboarding.renewalCalendar.header",
        defaultMessage: "Renewal Calendar.",
        description: "Header text for the renewal calendar onboarding slide",
      }),
      contentParagraphs: [
        intl.formatMessage({
          id: "onboarding.renewalCalendar.contentParagraph",
          defaultMessage:
            "Stay ahead of every deadline. No last-minute scrambles, no missed renewals—you’re in control.",
          description: "Main explanatory text describing the benefits of the renewal calendar feature",
        }),
      ],
      contentBullets: [
        intl.formatMessage({
          id: "onboarding.renewalCalendar.contentBullet1",
          defaultMessage: "Email reminders are set to notify you before it’s time to make a renewal decision.",
          description: "First bullet point explaining the email reminder functionality",
        }),
        intl.formatMessage({
          id: "onboarding.renewalCalendar.contentBullet2",
          defaultMessage: "Want instant alerts? Connect Slack for real-time notifications.",
          description: "Second bullet point promoting Slack integration for notifications",
        }),
      ],
      cta: intl.formatMessage({
        id: "onboarding.renewalCalendar.buttonCTA",
        defaultMessage: "Connect Slack",
        description: "Call to action button text for connecting Slack for notifications",
      }),
      ctaIcon: <SlackIcon boxSize={6} />,
      onCtaClick: () => {
        posthog.capture("onboarding_connect_slack_button_clicked", {
          step: "welcome.renewal_calendar",
        })
        window.open(`${import.meta.env.VITE_API_BASE_URL}/oauth/v1/auth/slack_oauth`, "_self")
      },
      secondaryActionCta: intl.formatMessage({
        id: "onboarding.renewalCalendar.secondaryActionCta",
        defaultMessage: "Skip for now",
        description: "CTA text for the secondary action on the renewal calendar slide",
      }),
      secondaryActionOnClick: () => {
        posthog.capture("onboarding_clicked_skip_connect_slack", {
          step: "welcome.renewal_calendar",
        })
        goToNextStep()
      },
      rightGraphic: <RenewalCalendarGraphic boxSize={96} />,
    },
  }

  return (
    <OnboardingLogoLayout
      content={
        <OnboardingWelcomeCard
          preHeader={SlideConfig[slide].preHeader}
          heading={SlideConfig[slide].heading}
          contentParagraphs={SlideConfig[slide].contentParagraphs}
          contentBullets={SlideConfig[slide].contentBullets}
          contentHighlight={SlideConfig[slide].contentHighlight}
          cta={SlideConfig[slide].cta}
          ctaIcon={SlideConfig[slide].ctaIcon}
          onCtaClick={SlideConfig[slide].onCtaClick}
          secondaryActionIntro={SlideConfig[slide].secondaryActionIntro}
          secondaryActionCta={SlideConfig[slide].secondaryActionCta}
          secondaryActionOnClick={SlideConfig[slide].secondaryActionOnClick}
        />
      }
    />
  )
}
