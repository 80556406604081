import type { LegalAgreementListItem } from "@brm/schema-types/types.js"
import { formatCurrency } from "@brm/util/currency/format.js"
import { Box, Flex, HStack, Icon, Stack, Tag, TagLabel, Text, Tooltip } from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { CalendarDay } from "../../components/Calendar/CalendarDay.js"
import { AlertTriangleIcon, RenewalIcon, VendorIcon } from "../../components/icons/icons.js"
import { VendorLogo } from "../../components/icons/Logo.js"
import { Link } from "../../components/Link.js"
import LogoInfoCard from "../../components/LogoInfoCard.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import type { AgreementGroup } from "./types.js"

interface AgreementGroupCardProps {
  group: AgreementGroup
  isPast?: boolean
}

export const AgreementGroupCard: React.FC<AgreementGroupCardProps> = ({ group, isPast }) => {
  const intl = useIntl()
  if (group.agreements.length === 0) {
    return null
  }

  return (
    <Box minW={400}>
      <Flex align="end" mb={2} gap={2}>
        <Text fontSize="lg" fontWeight="bold" color={isPast ? "error.400" : "gray.600"}>
          {group.label}
        </Text>
        <Text fontSize="md" color="gray.500">
          {formatCurrency({ amount: group.stats.tcvTotalUsd.toString(), currency_code: "USD" }, intl)}
        </Text>
      </Flex>
      <Box
        borderWidth={isPast ? 2 : 1}
        borderRadius="2xl"
        borderColor={isPast ? "error.400" : "gray.200"}
        // Overflow hidden to prevent the border radius from showing through the hover state
        overflow="hidden"
      >
        <Flex direction="column" gap={0}>
          {group.agreements.map((agreement, index) => {
            const decisionDate = agreement.decision_date ? Temporal.PlainDate.from(agreement.decision_date) : null
            if (!decisionDate) {
              return null
            }

            const vendor = agreement.vendor
            return (
              <Link
                key={agreement.id}
                variant="noUnderline"
                to={`/agreements/${agreement.id}`}
                display="flex"
                px={4}
                py={2}
                justifyContent="space-between"
                alignItems="center"
                borderBottomWidth={index === group.agreements.length - 1 ? 0 : "1px"}
                borderColor="gray.200"
                _hover={{ bg: "gray.50" }}
                className="group"
              >
                <HStack spacing={4} flexShrink={1} minW="0">
                  <CalendarDay date={decisionDate} />
                  <Stack flexShrink={1} minW="0">
                    <Text fontSize="md" fontWeight="semibold" isTruncated _groupHover={{ color: "brand.500" }}>
                      {agreement.display_name}
                    </Text>
                    <RenewalAgendaItemTags agreement={agreement} />
                  </Stack>
                </HStack>
                {vendor && (
                  <Tooltip
                    p={0}
                    label={
                      <LogoInfoCard
                        displayName={vendor.display_name}
                        description={vendor.description}
                        logoUrl={getPublicImageGcsUrl(vendor?.image_asset?.gcs_file_name)}
                        fallbackAvatarLogo={VendorIcon}
                      />
                    }
                    shouldWrapChildren
                  >
                    <VendorLogo logo={getPublicImageGcsUrl(vendor?.image_asset?.gcs_file_name)} boxSize="3em" />
                  </Tooltip>
                )}
              </Link>
            )
          })}
        </Flex>
      </Box>
    </Box>
  )
}

const RenewalAgendaItemTags: React.FC<{
  agreement: LegalAgreementListItem
}> = ({ agreement }) => {
  const { auto_renews, total_contract_value, verification_status, vendor_spend } = agreement
  const { ltm } = vendor_spend || {}
  const intl = useIntl()

  return (
    <Flex gap={2} alignItems="center" overflow="hidden">
      {verification_status !== "verified" && (
        <AgendaPill
          keyString={`${agreement.id}-unverified-pill`}
          colorScheme="error"
          icon={<Icon as={AlertTriangleIcon} color="error.500" boxSize={3} />}
          label={intl.formatMessage({
            id: "renewalCalendar.unverified",
            defaultMessage: "Unverified",
            description: "Label for unverified pill",
          })}
        />
      )}
      {auto_renews === true && (
        <AgendaPill
          colorScheme="warning"
          keyString={`${agreement.id}-auto-renews-pill`}
          icon={<Icon as={RenewalIcon} color="warning.500" boxSize={3} />}
          label={intl.formatMessage({
            id: "renewalCalendar.autoRenews",
            defaultMessage: "Auto-renews",
            description: "Label for auto-renews pill",
          })}
        />
      )}
      {ltm?.currency_amount && (
        <AgendaPill
          keyString={`${agreement.id}-tcv-pill`}
          icon={
            <Text fontWeight="semibold" color="purple.500">
              <FormattedMessage id="renewalCalendar.tcv.icon" defaultMessage="LTM" description="LTM pill icon" />
            </Text>
          }
          label={formatCurrency(
            { amount: ltm.currency_amount.amount, currency_code: ltm.currency_amount.currency_code },
            intl
          )}
        />
      )}
      {total_contract_value && (
        <AgendaPill
          keyString={`${agreement.id}-tcv-pill`}
          icon={
            <Text fontWeight="semibold" color="brand.500">
              <FormattedMessage id="renewalCalendar.tcv.icon" defaultMessage="TCV" description="TCV pill icon" />
            </Text>
          }
          label={formatCurrency(
            { amount: total_contract_value.amount, currency_code: total_contract_value.currency_code },
            intl
          )}
        />
      )}
    </Flex>
  )
}

const AgendaPill: React.FC<{
  icon: React.ReactNode
  label: string
  keyString: string
  colorScheme?: string
}> = ({ icon, label, keyString, colorScheme = "gray" }) => {
  return (
    <Tag key={keyString} size="sm" variant="outline" colorScheme={colorScheme} borderRadius="full">
      {icon}
      <TagLabel ml={2}>{label}</TagLabel>
    </Tag>
  )
}
