import type { ToolListItem } from "@brm/schema-types/types.js"
import { type VendorListItem } from "@brm/schema-types/types.js"
import type { BRMPaths } from "@brm/type-helpers/paths.js"
import { mapBy } from "@brm/util/collections.js"
import { Center, Flex, useToast } from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import type { ColumnPinningState } from "@tanstack/react-table"
import { useMemo } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import {
  useGetSavedViewV1ByTableIdentifierQuery,
  usePostVendorV1AiFilterMutation,
  usePostVendorV1CsvMutation,
  usePostVendorV1ListQuery,
} from "../../../app/services/generated-api.js"
import DataTable from "../../../components/DataTable/DataTable.js"
import FullDataTableHeader from "../../../components/DataTable/FullDataTableHeader.js"
import TablePageHeader from "../../../components/DataTable/SchemaFilter/TablePageHeader.js"
import SchemaTableColumnCustomization from "../../../components/DataTable/SchemaTableColumnCustomization.js"
import { displayNameForTableId } from "../../../components/DataTable/const.js"
import { pathForColumn, ROOT_COLUMN_ID, useSchemaColumns } from "../../../components/DataTable/use-schema-columns.js"
import {
  useLocalStorageTableParamsSync,
  useUrlTableParams,
} from "../../../components/DataTable/use-schema-table-params.js"
import Spinner from "../../../components/spinner.js"
import { packageSortFilterOptionsForAPI, shownColumnsForTableParamState } from "../../../util/schema-table.js"
import { useObjectSchema } from "../../../util/use-schema.js"
import ZeroIntegrationsView from "../../error/ZeroIntegrationsView.js"
import { VENDOR_TABLE_DEFAULT_PARAMS, VENDOR_TABLE_ID } from "./constants.js"
import { pathForVendor } from "../../../util/json-schema.js"

const defaultColumns: BRMPaths<VendorListItem>[] = [
  "owner",
  "status",
  "spend.mtd",
  "spend.ytd",
  "spend.ltm",
  "spend.total",
  "tool_count",
  "vendor_categories",
  "primary_legal_agreement.display_name",
  "primary_legal_agreement.end_date",
]

const primaryColumn = ROOT_COLUMN_ID
const primarySearchColumn: BRMPaths<ToolListItem> = "display_name"

const columnPinning: ColumnPinningState = {
  left: [primaryColumn],
}

export default function VendorList() {
  const intl = useIntl()
  const toast = useToast()
  const navigate = useNavigate()

  const [queryVendorAiFilter] = usePostVendorV1AiFilterMutation()
  const [exportCsv, exportCsvResult] = usePostVendorV1CsvMutation()

  const vendorSchema = useObjectSchema("Vendor")
  const { data: savedViewData } = useGetSavedViewV1ByTableIdentifierQuery({ tableIdentifier: VENDOR_TABLE_ID })

  useLocalStorageTableParamsSync(VENDOR_TABLE_ID)
  const { tableParams, updateTableParams } = useUrlTableParams({
    objectSchema: vendorSchema,
    defaultParams: VENDOR_TABLE_DEFAULT_PARAMS,
    savedViews: savedViewData,
    primarySearchColumn,
  })

  const shownColumns = useMemo(
    () => tableParams && shownColumnsForTableParamState(tableParams, defaultColumns),
    [tableParams]
  )
  const columns = useSchemaColumns<VendorListItem>({ objectSchema: vendorSchema, shownColumns, primaryColumn })

  const apiParams = useMemo(
    () => tableParams && vendorSchema && packageSortFilterOptionsForAPI(tableParams, vendorSchema, intl),
    [intl, tableParams, vendorSchema]
  )

  const { data, isFetching, isLoading } = usePostVendorV1ListQuery(
    apiParams ? { listQueryStringParams: apiParams } : skipToken
  )

  if (isLoading) {
    return (
      <Center height="100%">
        <Spinner size="md" />
      </Center>
    )
  }

  if (!isFetching && data?.vendors.length === 0 && apiParams?.filter.length === 0) {
    return <ZeroIntegrationsView />
  }

  if (!columns || !vendorSchema || !tableParams || !shownColumns || !data) {
    return null
  }

  return (
    <Flex justifyContent="center" flexDir="column" gap={0} flexGrow={1} minHeight={0}>
      <FullDataTableHeader
        displayTitle={displayNameForTableId(VENDOR_TABLE_ID, intl)}
        savedViews={savedViewData}
        savedViewState={tableParams.savedViewState}
        onSavedViewStateChange={(newSavedViewState) => updateTableParams({ savedViewState: newSavedViewState })}
      >
        <TablePageHeader
          tableId={VENDOR_TABLE_ID}
          primarySearch={{
            column: primarySearchColumn,
            currentPrimaryFilter: tableParams.primaryFilter?.fields,
            placeholder: intl.formatMessage({
              id: "vendorList.searchPlaceholder",
              description: "placeholder text for the vendor search input",
              defaultMessage: "Search vendors by name",
            }),
            onPrimaryFilterChange: (fields) =>
              updateTableParams({ primaryFilter: fields && { column: primarySearchColumn, fields } }),
          }}
          queryAiFilter={async (query) => {
            try {
              const result = await queryVendorAiFilter({ body: { query } }).unwrap()
              updateTableParams({ filterMap: mapBy(result, (result) => result.column) })
            } catch (_err) {
              toast({
                description: intl.formatMessage({
                  id: "vendor.list.filter.ai.error",
                  description: "error message when the AI filter fails",
                  defaultMessage: "Failed to apply AI filter",
                }),
                status: "error",
              })
            }
          }}
          objectSchema={vendorSchema}
          filterMap={tableParams.filterMap}
          onChangeFilters={(filterMap) => updateTableParams({ filterMap })}
          savedViews={savedViewData}
          savedViewState={tableParams.savedViewState}
          onSavedViewStateChange={(savedViewState) => updateTableParams({ savedViewState })}
          selectedColumns={shownColumns}
          afterSavedView={
            <SchemaTableColumnCustomization
              activeColumns={shownColumns}
              primaryColumn={primaryColumn}
              onActiveColumnsChange={(selectedColumns) => updateTableParams({ selectedColumns })}
              objectSchema={vendorSchema}
            />
          }
          isExporting={exportCsvResult.isLoading}
          exportCsv={async () => {
            const result = await exportCsv({
              body: {
                ...apiParams,
                columns: [pathForColumn(primaryColumn), ...shownColumns],
              },
            }).unwrap()
            return result.url
          }}
        />
      </FullDataTableHeader>
      <DataTable<VendorListItem>
        data={data.vendors}
        columns={columns}
        columnPinning={columnPinning}
        columnOrder={[primaryColumn, ...shownColumns]}
        sorting={tableParams.sorting}
        onSortingChange={(sorting) =>
          sorting instanceof Function
            ? updateTableParams({ sorting: sorting(tableParams.sorting) })
            : updateTableParams({ sorting })
        }
        onRowClick={(row, event) => {
          if (event.metaKey || event.ctrlKey) {
            return window.open(pathForVendor(row.id))
          }
          return navigate(row.id)
        }}
        paginationProps={{
          page: tableParams.page,
          pageSize: tableParams.pageSize,
          onPageChange: (page) => updateTableParams({ page }),
          onPageSizeChange: (pageSize) => updateTableParams({ pageSize }),
          totalListElements: data.total,
        }}
        isSticky
      />
    </Flex>
  )
}
