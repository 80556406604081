import type { FieldMetadataWithSuggestions, ToolOptionWithVendor } from "@brm/schema-types/types.js"
import { Box, chakra, HStack, Icon, Text, Tooltip } from "@chakra-ui/react"
import type {
  ControlProps,
  CreatableProps,
  GroupBase,
  OptionProps,
  Props,
  SelectInstance,
  SingleValueProps,
} from "chakra-react-select"
import { chakraComponents } from "chakra-react-select"
import { useMemo, useRef, useState } from "react"
import type { RefCallBack } from "react-hook-form"
import { useIntl } from "react-intl"
import { useGetToolV1PickerOptionsQuery } from "../../app/services/generated-api.js"
import { isNewOption } from "../../util/form.js"
import { pathForTool } from "../../util/json-schema.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import OptionWithFieldSource from "../DynamicForm/OptionWithFieldSource.js"
import type { DynamicFormFieldApproval, ValueWithSource } from "../DynamicForm/types.js"
import { IconButtonWithTooltip } from "../IconButtonWithTooltip.js"
import LogoInfoCard from "../LogoInfoCard.js"
import CreatableSelect from "../Select/CreatableSelect.js"
import Select from "../Select/Select.js"
import { ToolLogo } from "../icons/Logo.js"
import { InfoIcon, PlusIcon, ShareIcon, ToolIcon } from "../icons/icons.js"
import { chakraStylesForProfilePicturePickerOption } from "./constants.js"

type ToolOptionPickerProps = Props<ToolOptionWithVendor, false>

export function ToolOptionPicker(props: ToolOptionPickerProps) {
  const { onChange, value } = props

  const [search, setSearch] = useState("")
  const ref = useRef<SelectInstance<ToolOptionWithVendor, false>>(null)

  const { data, isLoading } = useGetToolV1PickerOptionsQuery({ search })

  return (
    <Box flexGrow={1}>
      <Select<ToolOptionWithVendor>
        options={data || []}
        isLoading={isLoading}
        chakraStyles={chakraStylesForProfilePicturePickerOption((toolOption) =>
          getPublicImageGcsUrl(toolOption.image_asset?.gcs_file_name)
        )}
        getOptionLabel={(toolOption) => toolOption.display_name}
        getOptionValue={(toolOption) => toolOption.tool_listing_id}
        onChange={onChange}
        value={value}
        inputValue={search}
        onInputChange={setSearch}
        ref={ref}
        menuPortalTarget={document.body}
        {...props}
      />
    </Box>
  )
}

export type CreatableToolOptionPickerProps = {
  allowCreate: boolean
  suggestions?: ValueWithSource<ToolOptionWithVendor>[]
  fieldMetadata?: FieldMetadataWithSuggestions
  fieldApproval?: DynamicFormFieldApproval
  inputRef?: RefCallBack
  isLite?: boolean
} & CreatableProps<
  ValueWithSource<ToolOptionWithVendor | null>,
  false,
  GroupBase<ValueWithSource<ToolOptionWithVendor | null>>
>

const Control = ({ children, ...props }: ControlProps<ValueWithSource<ToolOptionWithVendor | null>, false>) => {
  const { inputValue } = props.selectProps
  const value = props.getValue()[0]?.value
  const hasInput = inputValue.length > 0
  const data = props.getValue()
  return (
    <chakraComponents.Control {...props}>
      <chakra.span ml={3}>
        {value && hasInput ? <ToolLogo boxSize={6} /> : null}
        {value && data[0] && !hasInput ? (
          <ToolLogo boxSize={6} logo={getPublicImageGcsUrl(data[0].value?.image_asset?.gcs_file_name)} />
        ) : null}
      </chakra.span>
      {children}
    </chakraComponents.Control>
  )
}

const SingleValue = ({ children, ...props }: SingleValueProps<ValueWithSource<ToolOptionWithVendor | null>, false>) => {
  const intl = useIntl()
  const value = props.getValue()[0]?.value
  const toolId = value?.id
  return (
    <chakraComponents.SingleValue {...props}>
      {children}
      {toolId && (
        <chakra.span float="right" position="absolute" right={0} verticalAlign="middle" marginRight={1}>
          <IconButtonWithTooltip
            color="gray.600"
            variant="ghost"
            size="sm"
            onClick={(e) => {
              window.open(pathForTool(toolId), "_blank")
              e.stopPropagation()
            }}
            icon={<Icon boxSize={4} as={ShareIcon} />}
            label={intl.formatMessage({
              defaultMessage: "View Tool",
              description: "Tooltip for viewing a tool",
              id: "Form.ToolOptionPicker.ViewTool",
            })}
          />
        </chakra.span>
      )}
    </chakraComponents.SingleValue>
  )
}

const SingleValueLite = ({
  children,
  ...props
}: SingleValueProps<ValueWithSource<ToolOptionWithVendor | null>, false>) => {
  const value = props.getValue()[0]?.value
  return (
    <chakraComponents.SingleValue {...props}>
      {children}
      {value?.description && (
        <chakra.span float="right" position="absolute" right={0} verticalAlign="middle" marginRight={1}>
          <Tooltip
            padding={0}
            label={
              <LogoInfoCard
                displayName={value.display_name}
                logoUrl={getPublicImageGcsUrl(value.image_asset?.gcs_file_name)}
                fallbackAvatarLogo={ToolIcon}
                description={value.description}
              />
            }
            shouldWrapChildren
          >
            <Icon as={InfoIcon} />
          </Tooltip>
        </chakra.span>
      )}
    </chakraComponents.SingleValue>
  )
}

const Option = ({ children, ...props }: OptionProps<ValueWithSource<ToolOptionWithVendor | null>, false>) => {
  const intl = useIntl()
  const fieldSources = props.data.field_sources

  if (!props.data.value) {
    return <chakraComponents.Option {...props}>{children}</chakraComponents.Option>
  }

  return props.data.value?.tool_listing_id !== "new" ? (
    <chakraComponents.Option {...props}>
      <ToolLogo boxSize={6} logo={getPublicImageGcsUrl(props.data.value?.image_asset?.gcs_file_name)} />
      <OptionWithFieldSource fieldSources={fieldSources}>
        <HStack flexGrow={1} minWidth={0} justifyContent="space-between">
          <Text flexShrink={1} minWidth={0} textOverflow="ellipsis" overflow="hidden">
            {children}
          </Text>
          {!props.data.value?.id && (
            <chakra.span color="gray.600" fontSize="xs" marginTop="auto" flexShrink={0}>
              {intl.formatMessage({
                defaultMessage: "Add to your organization",
                description: "Support text to indicate a net-new tool",
                id: "Option.span",
              })}
            </chakra.span>
          )}
        </HStack>
      </OptionWithFieldSource>
    </chakraComponents.Option>
  ) : (
    <chakraComponents.Option {...props}>
      <Icon boxSize={5} as={PlusIcon} /> {children}
    </chakraComponents.Option>
  )
}

export function ToolOptionPickerCreatable(props: CreatableToolOptionPickerProps) {
  const { value, suggestions, fieldMetadata, fieldApproval, inputRef, isLite, ...rest } = props

  const [search, setSearch] = useState("")
  const { data, isLoading } = useGetToolV1PickerOptionsQuery({ search })

  const toolList = useMemo(() => {
    return (
      data
        // Filter out fetched tool if it is already in suggestions
        ?.filter(
          (tool) => !suggestions?.some((suggestedTool) => suggestedTool.value.tool_listing_id === tool.tool_listing_id)
        )
        .map((tool) => ({
          value: tool,
          fieldSources: [],
        })) || []
    )
  }, [data, suggestions])

  const options = useMemo(
    () => [{ value: null, field_sources: [] }, ...(suggestions ?? []), ...toolList],
    [suggestions, toolList]
  )
  return (
    <Box flexGrow={1}>
      <CreatableSelect<ValueWithSource<ToolOptionWithVendor | null>>
        options={options}
        isValidNewOption={(newToolName: string) => {
          // do not allow creating a new tool with an empty string
          if (newToolName === "") {
            return false
          }

          // It is common that tools share the same display name, thus we need to allow users
          // to create a net new tool, even if there is an existing tool with the same display_name.
          // If all of the current options contain the same key, display the new tool create option.
          return toolList.every((toolListing) =>
            toolListing.value.display_name.toLowerCase().includes(newToolName.toLowerCase())
          )
        }}
        ref={(select) => {
          inputRef?.(select?.inputRef ?? null)
        }}
        openMenuOnFocus={true}
        isLoading={isLoading}
        components={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Control,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Option,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          SingleValue: isLite ? SingleValueLite : SingleValue,
        }}
        chakraStyles={{
          singleValue: (styles) => ({ ...styles, display: "flex", alignItems: "center" }),
          valueContainer: (styles) => ({ ...styles, paddingLeft: "4px" }),
          option: (styles, { data }) => {
            const { isNew, colorScheme } = isNewOption(data.field_sources, fieldMetadata, fieldApproval)
            return {
              ...styles,
              display: "flex",
              gap: "0.5rem",
              ...(isNew && {
                backgroundColor: `${colorScheme}.50`,
              }),
            }
          },
        }}
        menuPortalTarget={document.body}
        getOptionLabel={({ value }) => value?.display_name ?? ""}
        getOptionValue={({ value }) => value?.tool_listing_id || ""}
        getNewOptionData={(inputValue) => {
          return {
            value: {
              display_name: inputValue,
              tool_listing_id: "new",
              description: null,
              website: null,
              image_asset: null,
              id: null,
              object_type: "Tool",
            },
          }
        }}
        value={value}
        inputValue={search}
        onInputChange={setSearch}
        {...rest}
      />
    </Box>
  )
}
