import { skipToken } from "@reduxjs/toolkit/query"
import { useMemo } from "react"
import { useIntl } from "react-intl"
import { Navigate } from "react-router-dom"
import { usePostLegalV1AgreementsListQuery } from "../../../app/services/generated-api.js"
import { packageSortFilterOptionsForAPI } from "../../../util/schema-table.js"
import { useObjectSchema } from "../../../util/use-schema.js"
import LegalAgreementEditorLite from "./LegalAgreementEditorLite.js"
import { baseUnverifiedAgreementsApiParams } from "./util.js"

export default function LegalAgreementOnboarding() {
  const intl = useIntl()

  const legalAgreementSchema = useObjectSchema("LegalAgreement")
  const apiParams = useMemo(
    () =>
      legalAgreementSchema &&
      packageSortFilterOptionsForAPI<string>(baseUnverifiedAgreementsApiParams, legalAgreementSchema, intl),
    [legalAgreementSchema, intl]
  )
  const { data: pendingAgreements } = usePostLegalV1AgreementsListQuery(
    apiParams ? { listQueryStringParams: apiParams } : skipToken
  )
  if (!pendingAgreements) {
    return null
  }

  const sampleAgreement = pendingAgreements.items.find((agreement) => agreement.visibility_status === "demo")

  if (!sampleAgreement) {
    return <Navigate to="/agreements" />
  }
  return <LegalAgreementEditorLite legalAgreement={sampleAgreement} refetchAgreement={() => {}} isOnboarding />
}
