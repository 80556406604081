import { acceptedDocumentExtensionsByMimeType, maxDocumentSize } from "@brm/type-helpers/document.js"
import {
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  // eslint-disable-next-line @typescript-eslint/no-restricted-imports
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  useToast,
  type FlexProps,
} from "@chakra-ui/react"
import { useRef, useState, type ReactElement } from "react"
import { useDropzone } from "react-dropzone"
import { useIntl } from "react-intl"
import { useSlate } from "slate-react"
import { IconButtonWithTooltip } from "../IconButtonWithTooltip.js"
import {
  AttachmentIcon,
  BoldIcon,
  BulletedListIcon,
  HeadingIcon,
  ItalicIcon,
  LinkIcon,
  NumberedListIcon,
  QuoteIcon,
  UnderlineIcon,
} from "../icons/icons.js"
import { onDropRejected } from "../on-drop-rejected.js"
import { insertLink, isLinkActive } from "./util/inline.js"
import { isBlockActive, isMarkActive, toggleBlock, toggleMark } from "./util/rich-text.js"

interface Props extends FlexProps {
  additionalButtons?: React.ReactNode
  sendButton?: React.ReactNode
  onDocumentUpload?: (file: File) => Promise<void>
}

const buttonSize = "xs" as const
const maxFiles = 20

const BOLD_FORMAT = "bold"
const ITALIC_FORMAT = "italic"
const UNDERLINE_FORMAT = "underline"
const HEADING_ONE_FORMAT = "heading-one"
const BLOCK_QUOTE_FORMAT = "block-quote"
const BULLETED_LIST_FORMAT = "bulleted-list"
const NUMBERED_LIST_FORMAT = "numbered-list"

export function RichTextEditorToolbar(props: Props) {
  const { additionalButtons, sendButton, onDocumentUpload, ...flexProps } = props
  const intl = useIntl()
  const editor = useSlate()

  return (
    // Note: The gap is required to keep a 24px area per button despite the xs size as per https://www.w3.org/WAI/WCAG22/Understanding/target-size-minimum
    <Flex alignItems="center" gap={4} height={6} onMouseDown={(event) => event.preventDefault()} {...flexProps}>
      <Flex alignItems="center" gap={2} height={6} overflowY="auto" sx={{ scrollbarWidth: "none" }}>
        <MarkButton
          isActive={isMarkActive(editor, BOLD_FORMAT)}
          onClick={() => toggleMark(editor, BOLD_FORMAT)}
          icon={<Icon as={BoldIcon} />}
          label={intl.formatMessage({
            id: "editor.toolbar.bold",
            description: "Label for the bold button in the editor toolbar",
            defaultMessage: "Bold",
          })}
        />
        <MarkButton
          isActive={isMarkActive(editor, ITALIC_FORMAT)}
          onClick={() => toggleMark(editor, ITALIC_FORMAT)}
          icon={<Icon as={ItalicIcon} />}
          label={intl.formatMessage({
            id: "editor.toolbar.italic",
            description: "Label for the italic button in the editor toolbar",
            defaultMessage: "Italic",
          })}
        />
        <MarkButton
          isActive={isMarkActive(editor, UNDERLINE_FORMAT)}
          onClick={() => toggleMark(editor, UNDERLINE_FORMAT)}
          icon={<Icon as={UnderlineIcon} />}
          label={intl.formatMessage({
            id: "editor.toolbar.underline",
            description: "Label for the underline button in the editor toolbar",
            defaultMessage: "Underline",
          })}
        />
        <AddLinkButton onAddLink={(url) => insertLink(editor, url)} isLinkActive={isLinkActive(editor)} />
        <BlockButton
          isActive={isBlockActive(editor, HEADING_ONE_FORMAT)}
          onClick={() => toggleBlock(editor, HEADING_ONE_FORMAT)}
          icon={<Icon as={HeadingIcon} />}
          label={intl.formatMessage({
            id: "editor.toolbar.heading",
            description: "Label for the heading button in the editor toolbar",
            defaultMessage: "Heading",
          })}
        />
        <BlockButton
          isActive={isBlockActive(editor, BLOCK_QUOTE_FORMAT)}
          onClick={() => toggleBlock(editor, BLOCK_QUOTE_FORMAT)}
          icon={<Icon as={QuoteIcon} />}
          label={intl.formatMessage({
            id: "editor.toolbar.quote",
            description: "Label for the quote button in the editor toolbar",
            defaultMessage: "Quote",
          })}
        />
        <BlockButton
          isActive={isBlockActive(editor, BULLETED_LIST_FORMAT)}
          onClick={() => toggleBlock(editor, BULLETED_LIST_FORMAT)}
          icon={<Icon as={BulletedListIcon} />}
          label={intl.formatMessage({
            id: "editor.toolbar.bulleted-list",
            description: "Label for the bulleted list button in the editor toolbar",
            defaultMessage: "Bulleted List",
          })}
        />
        <BlockButton
          isActive={isBlockActive(editor, NUMBERED_LIST_FORMAT)}
          onClick={() => toggleBlock(editor, NUMBERED_LIST_FORMAT)}
          icon={<Icon as={NumberedListIcon} />}
          label={intl.formatMessage({
            id: "editor.toolbar.numbered-list",
            description: "Label for the numbered list button in the editor toolbar",
            defaultMessage: "Numbered List",
          })}
        />
      </Flex>
      {additionalButtons}
      <HStack alignItems="center" marginLeft="auto" gap={2} height={6} overflowY="auto" sx={{ scrollbarWidth: "none" }}>
        {onDocumentUpload && <AttachmentButton onDocumentUpload={onDocumentUpload} />}
        {sendButton}
      </HStack>
    </Flex>
  )
}

export const AttachmentButton = ({ onDocumentUpload }: { onDocumentUpload: (file: File) => Promise<void> }) => {
  const intl = useIntl()
  const toast = useToast()
  const dropzone = useDropzone({
    accept: acceptedDocumentExtensionsByMimeType,
    multiple: true,
    maxFiles,
    maxSize: maxDocumentSize,
    onDropAccepted: async (files) => {
      for (const file of files) {
        await onDocumentUpload(file)
      }
    },
    onDropRejected: onDropRejected(toast, { maxFiles }),
  })
  return (
    <>
      <input {...dropzone.getInputProps()} />
      <IconButtonWithTooltip
        padding={1}
        height="auto"
        color="gray.600"
        size={buttonSize}
        variant="ghost"
        onClick={dropzone.open}
        icon={<Icon as={AttachmentIcon} />}
        label={intl.formatMessage({
          id: "editor.toolbar.attach-file",
          description: "Label for the attach file button in the editor toolbar",
          defaultMessage: "Attach file",
        })}
      />
    </>
  )
}

export const MarkButton = ({
  icon,
  label,
  onClick,
  isActive,
}: {
  icon: ReactElement
  label: string
  onClick: () => void
  isActive: boolean
}) => {
  return (
    <IconButton
      height="auto"
      padding={1}
      isActive={isActive}
      onClick={(event) => {
        event.preventDefault()
        onClick()
      }}
      variant="ghost"
      size={buttonSize}
      icon={icon}
      aria-label={label}
    />
  )
}

export const BlockButton = ({
  icon,
  label,
  onClick,
  isActive,
}: {
  icon: ReactElement
  label: string
  onClick: () => void
  isActive: boolean
}) => {
  return (
    <IconButton
      padding={1}
      isActive={isActive}
      size={buttonSize}
      variant="ghost"
      height="auto"
      onClick={(event) => {
        event.preventDefault()
        onClick()
      }}
      icon={icon}
      aria-label={label}
    />
  )
}

export const AddLinkButton = ({
  onAddLink,
  isLinkActive,
}: {
  onAddLink: (url: string) => void
  isLinkActive: boolean
}) => {
  const intl = useIntl()
  const inputRef = useRef<HTMLInputElement>(null)
  const popoverDisclosure = useDisclosure()
  const [url, setUrl] = useState("")
  return (
    <Popover initialFocusRef={inputRef} {...popoverDisclosure}>
      <PopoverTrigger>
        <IconButton
          padding={1}
          size={buttonSize}
          variant="ghost"
          height="auto"
          isActive={isLinkActive || popoverDisclosure.isOpen}
          icon={<Icon as={LinkIcon} />}
          aria-label={intl.formatMessage({
            id: "editor.toolbar.link",
            description: "Label for the link button in the editor toolbar",
            defaultMessage: "Link",
          })}
        />
      </PopoverTrigger>
      <PopoverContent width="20ch">
        <Input
          size="sm"
          ref={inputRef}
          placeholder={intl.formatMessage({
            id: "editor.link.placeholder",
            description: "Placeholder in input url when user is adding a link to an editor input",
            defaultMessage: "Enter link URL",
          })}
          type="url"
          value={url}
          onChange={(event) => setUrl(event.currentTarget.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault()
              const url = event.currentTarget.value
              if (url) {
                onAddLink(url)
                setUrl("")
              }
              popoverDisclosure.onClose()
            }
          }}
        />
      </PopoverContent>
    </Popover>
  )
}
