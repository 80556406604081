import type {
  DocumentMinimal,
  DocumentWithExtraction,
  FieldCategory,
  FieldMetadataWithSuggestions,
  FieldSourceOutputProperties,
  FormFieldConfig,
} from "@brm/schema-types/types.js"
import { isObject } from "@brm/util/type-guard.js"
import { Center, chakra, Flex, Icon, Stack } from "@chakra-ui/react"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import { skipToken } from "@reduxjs/toolkit/query"
import { useCallback, useRef, useState, type ReactNode } from "react"
import type { DefaultValues, FieldValues, Path, SetValueConfig } from "react-hook-form"
import { useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import type { Except, PartialDeep, ReadonlyDeep } from "type-fest"
import { useDebounceCallback, useSessionStorage } from "usehooks-ts"
import {
  useGetDocumentV1ByObjectTypeAndObjectIdDocumentsDocumentIdUrlQuery,
  usePostDocumentV1ByIdBackgroundExtractMutation,
} from "../../app/services/generated-api.js"
import { cleanValues } from "../../features/workflows/run/utils.js"
import { PAGE_PADDING_X, PAGE_PADDING_Y } from "../../util/constant.js"
import { initializeReactHookFormState } from "../../util/form.js"
import { log } from "../../util/logger.js"
import type { DocumentChangeHandler } from "../Document/DocumentUpload.js"
import { DocumentViewerWithHighlighting } from "../Document/DocumentViewerWithHighlighting.js"
import { IconButtonWithTooltip } from "../IconButtonWithTooltip.js"
import { XIcon } from "../icons/icons.js"
import Spinner from "../spinner.js"
import { DynamicFormField, type DynamicFormFieldProps } from "./DynamicFormField.js"
import { FieldSourceFooter } from "./FieldSourceFooter.js"
import { FORM_MAX_WIDTH, updateFormFieldsWithDirtyFields } from "./utils.js"

export const INITIALIZE_EXTRACT_DEBOUNCE_DELAY = 150

export interface DynamicFormProps<TFieldValues extends FieldValues> {
  initialFormValues: DefaultValues<TFieldValues> | undefined
  rootSchema: ReadonlyDeep<JSONSchema>
  formFields: (FormFieldConfig & Pick<DynamicFormFieldProps, "path">)[]
  onSubmit: (outputs: TFieldValues) => Promise<void>
  /** Set this to undefined if the given schema does not need to render any documents */
  documentDownloadURL: undefined | ((path: (string | number)[], document: DocumentMinimal) => string)
  isEditing: boolean
  formHeader: ReactNode
  // The optional props below are if the form needs to render a document viewer when the provenance for a field is clicked.
  connectedDocuments?: DocumentWithExtraction[]
  requestingEntity?: { object_type: "Tool" | "Vendor"; object_id: string; category?: FieldCategory }
  timelineHideStorageKey?: string
  document?: DocumentWithExtraction | undefined
  setDocument?: (document: DocumentWithExtraction | undefined) => void
}

/**
 * Given a root schema and an output schema, DynamicForm creates a form that submits to the given submit function
 * onBlur whenever there is changes made so that the form can be submitted without the user having to click a button.
 *
 * DynamicForm
 *  └─ DynamicFormField
 *      └─ InputContainer
 *           └─ Input Component (NumberInput, StringInput, etc.)
 */
export default function DynamicSorForm<TFieldValues extends FieldValues>({
  initialFormValues,
  rootSchema,
  formFields,
  onSubmit,
  documentDownloadURL,
  isEditing,
  formHeader,
  connectedDocuments,
  requestingEntity,
  timelineHideStorageKey,
  document,
  setDocument,
}: DynamicFormProps<TFieldValues>) {
  const intl = useIntl()

  const timelineData = useSessionStorage(timelineHideStorageKey ?? "", false)
  const setIsTimelineHidden = timelineHideStorageKey ? timelineData[1] : undefined

  const form = useForm<TFieldValues>({ defaultValues: initialFormValues, shouldFocusError: false })
  initializeReactHookFormState(form)

  // Need to use `any` here because from may have circular references which causes a type error here otherwise.
  // Since this function is typed to take any string and returns unknown, type safety inside the function doesn't matter anyway.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formGetValue = useCallback((path: string): unknown => form.watch<any>(path || undefined), [form])

  const [initializeExtractionById] = usePostDocumentV1ByIdBackgroundExtractMutation()

  const formRef = useRef<HTMLFormElement>(null)

  const [selectedProvenancePath, setSelectedProvenancePath] = useState<(string | number)[]>([])

  const {
    data: documentDownloadUrl,
    isLoading: documentIsLoading,
    isFetching: documentIsFetching,
  } = useGetDocumentV1ByObjectTypeAndObjectIdDocumentsDocumentIdUrlQuery(
    document?.id && requestingEntity
      ? {
          objectType: requestingEntity.object_type,
          objectId: requestingEntity.object_id,
          documentId: document.id,
        }
      : skipToken
  )

  const onProvenanceClick = useCallback(
    (path: (number | string)[], fieldSource?: FieldSourceOutputProperties) => {
      if (!fieldSource || !("id" in fieldSource)) {
        return
      }
      const documentId =
        (fieldSource.type === "document" && fieldSource.id) ||
        (fieldSource.assigned_by_metadata?.object_field_source.type === "document" &&
          fieldSource.assigned_by_metadata?.object_field_source.id)

      if (!documentId) {
        return
      }
      // We need all the documents from the legal object since some step may not have the document configured
      const fieldSourceDocument = connectedDocuments?.find((document) => document.id === documentId)

      if (!fieldSourceDocument) {
        return
      }
      setSelectedProvenancePath(path)
      setDocument?.(fieldSourceDocument)
      setIsTimelineHidden?.(true)
    },
    [setDocument, setIsTimelineHidden, connectedDocuments, setSelectedProvenancePath]
  )

  const renderFieldSource = useCallback(
    (path: (string | number)[], fieldSource?: FieldSourceOutputProperties): ReactNode => {
      if (!fieldSource) {
        return null
      }

      return <FieldSourceFooter fieldSource={fieldSource} onClick={() => onProvenanceClick?.(path, fieldSource)} />
    },
    [onProvenanceClick]
  )

  const documentDownloadUrlCallback = useCallback(
    (path: (string | number)[], document: DocumentMinimal): string => {
      if (documentDownloadURL) {
        return documentDownloadURL(path, document)
      }
      log.error("No document download URL found", new Error("Unexpected document found on form"))
      // Returning empty string here will not allow the user to click the document
      return ""
    },
    [documentDownloadURL]
  )

  const onDocumentChange = useDebounceCallback<DocumentChangeHandler>(async ({ type, document, field_name }) => {
    formRef.current?.requestSubmit()
    if (
      field_name &&
      type === "add" &&
      isObject(initialFormValues) &&
      initialFormValues.id &&
      "object_type" in initialFormValues &&
      (initialFormValues.object_type === "Tool" || initialFormValues.object_type === "Vendor")
    ) {
      await initializeExtractionById({
        id: document.id,
        documentBackgroundExtractionRequest: {
          extraction_request_type: "field_upload",
          field_name,
          starting_relations: {
            object_type: initialFormValues.object_type,
            object_ids: [initialFormValues.id],
          },
        },
      }).unwrap()
    }
  }, INITIALIZE_EXTRACT_DEBOUNCE_DELAY)

  const getValue = useCallback(
    (path: string) => {
      // Need to handle an empty string path as undefined to make sure that react-hook-form returns the root object
      return form.watch((path || undefined) as Path<TFieldValues>)
    },
    [form]
  )

  const formFieldProps: Except<DynamicFormFieldProps, "path" | "formField"> = {
    control: form.control,
    getValue,
    setValue: form.setValue as (path: string, value: unknown, options?: SetValueConfig) => void,
    resetField: form.resetField as (path: string, options?: SetValueConfig) => void,
    rootBaseSchema: rootSchema,
    isReadOnly: !isEditing,
    onDocumentChange,
    getDocumentDownloadUrl: documentDownloadUrlCallback,
    renderFieldSource,
    onDocumentClick: (document) => {
      setIsTimelineHidden?.(true)
      setDocument?.(document)
    },
    selectedDocument: document,
  }

  return (
    <Flex alignItems="stretch" minHeight={0} minWidth={0} gap={2} height="100%">
      {document && selectedProvenancePath && (
        <Center boxShadow="lg" minH={0} borderRightWidth="1px" flex={3} height="100%" overflow="auto">
          {documentIsFetching || documentIsLoading ? (
            <Spinner />
          ) : (
            <DocumentViewerWithHighlighting
              document={document}
              path={selectedProvenancePath}
              parentPath={[]}
              rootSchema={rootSchema}
              downloadUrl={documentDownloadUrl?.download_url}
              requestingEntity={requestingEntity}
              fieldMetadata={
                selectedProvenancePath &&
                (formGetValue(["fields_metadata", ...selectedProvenancePath].join(".")) as FieldMetadataWithSuggestions)
              }
              closeButton={
                <IconButtonWithTooltip
                  icon={<Icon as={XIcon} />}
                  onClick={() => {
                    setDocument?.(undefined)
                  }}
                  variant="ghost"
                  label={intl.formatMessage({
                    defaultMessage: "Close",
                    id: "documentViewer.backButton.tooltip",
                    description: "label for previous page button in document viewer toolbar",
                  })}
                />
              }
            />
          )}
        </Center>
      )}
      <Flex
        flex={2}
        overflowY="auto"
        justifyContent="center"
        height="100%"
        paddingX={PAGE_PADDING_X}
        paddingY={PAGE_PADDING_Y}
      >
        <Stack maxWidth={FORM_MAX_WIDTH} pb={6} height="fit-content" width="100%">
          {formHeader}
          <chakra.form
            // Submit on blur if changed
            onBlur={(event) => {
              if (isEditing && form.formState.isDirty) {
                event.currentTarget.requestSubmit()
              }
            }}
            onSubmit={form.handleSubmit(async (values) => {
              const cleanedValues = cleanValues(
                updateFormFieldsWithDirtyFields<TFieldValues>(
                  form.formState.dirtyFields,
                  values as unknown as PartialDeep<TFieldValues>,
                  rootSchema
                ),
                rootSchema
              )
              await onSubmit(cleanedValues as TFieldValues)
              form.reset(form.getValues(), { keepValues: true, keepDefaultValues: false, keepErrors: true })
            })}
            noValidate={true} // ensure react-hook-form validation is used
            ref={formRef}
          >
            <Stack gap={6}>
              {formFields.map((formField) => {
                return (
                  <DynamicFormField
                    {...formFieldProps}
                    key={formField.path.join(".")}
                    formField={formField}
                    path={formField.path}
                  />
                )
              })}
            </Stack>
          </chakra.form>
        </Stack>
      </Flex>
    </Flex>
  )
}
