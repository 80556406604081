import type { EmailDraftInput, Negotiation, NegotiationConversation } from "@brm/schema-types/types.js"
import { formatCurrency } from "@brm/util/currency/format.js"
import { diffLines } from "@brm/util/diff.js"
import { formatDate, formatDateTime } from "@brm/util/format-date-time.js"
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  Collapse,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { chakra, type StyleProps, type SystemStyleObject } from "@chakra-ui/system"
import type { Change } from "diff"
import { memo, useCallback, useEffect, useRef, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import {
  useGetUserV1WhoamiQuery,
  usePostNegotiationV1EmailSendMutation,
  type Email,
} from "../../app/services/generated-api.js"
import { IconButtonWithTooltip } from "../../components/IconButtonWithTooltip.js"
import {
  AgreementIcon,
  BoldIcon,
  BulletedListIcon,
  ChevronDownIcon,
  CollapseIcon,
  CopyIcon,
  FlipBackwardIcon,
  FlipForwardIcon,
  ForwardIcon,
  HeadingIcon,
  ItalicIcon,
  MoreMenuIcon,
  NumberedListIcon,
  QuoteIcon,
  ReflectIcon,
  ReplyAllIcon,
  ReplyIcon,
  SendIcon,
  UnderlineIcon,
} from "../../components/icons/icons.js"
import Iframe from "../../components/Iframe.js"
import { Link } from "../../components/Link.js"
import { EMPTY_EMAIL_DRAFT } from "../../components/negotiation/util.js"
import OverflownText from "../../components/OverflownText.js"
import { BlockButton, MarkButton } from "../../components/RichTextEditor/EditorToolbar.js"
import { pathForLegalAgreement } from "../../util/json-schema.js"
import { getPublicImageGcsUrl } from "../../util/url.js"

const emailStyles: SystemStyleObject = {
  "& ul": {
    paddingLeft: "var(--chakra-space-4)",
  },
  "& ol": {
    paddingLeft: "var(--chakra-space-4)",
  },
  "& blockquote": {
    marginLeft: "var(--chakra-space-4)",
    borderLeft: "1px solid",
    borderColor: "var(--chakra-colors-gray-300)",
    paddingLeft: "var(--chakra-space-2)",
    // color: "var(--chakra-colors-brand-700)",
  },
  "& h1": {
    fontSize: "lg",
  },
}

type ExecCommandType =
  | "bold"
  | "italic"
  | "insertUnorderedList"
  | "insertOrderedList"
  | "underline"
  | "formatBlock"
  | "insertHTML"

export const EmailComposer = memo(EmailComposerComponent)
type ResponseType = "reply" | "replyAll" | "forward"

function EmailComposerComponent({
  emailDraft,
  onChange,
  negotiation,
  negotiationConversation,
  isLoading,
  onSelectReplyToEmail,
  onSendSuccess,
}: {
  emailDraft: EmailDraftInput
  onChange: (value: Partial<EmailDraftInput>) => void
  negotiation: Negotiation
  negotiationConversation: NegotiationConversation | undefined
  isLoading: boolean
  onSelectReplyToEmail: (email: Email) => Promise<NegotiationConversation | undefined>
  onSendSuccess?: () => void
}) {
  const emailThread = negotiation?.email_thread
  const headerHeight = "4rem"
  const [responseType, setResponseType] = useState<ResponseType>("reply")
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const [previousEmailDraft, setPreviousEmailDraft] = useState<EmailDraftInput>(
    negotiationConversation?.email_drafts?.at(-2) ?? EMPTY_EMAIL_DRAFT
  )
  const emailDraftCursor = useRef<number | undefined>(undefined)
  useEffect(() => {
    setPreviousEmailDraft(negotiationConversation?.email_drafts?.at(-2) ?? EMPTY_EMAIL_DRAFT)
    if (negotiationConversation?.email_drafts) {
      emailDraftCursor.current = negotiationConversation?.email_drafts?.length - 1
    }
  }, [negotiationConversation])

  const onReply = useCallback(
    async (typeOfResponse: ResponseType, respondingEmail: Email) => {
      setResponseType(typeOfResponse)
      if (!emailThread || emailThread.length === 0) return
      const firstEmail = emailThread.at(0)
      const allRecipientsInThread = [
        ...(firstEmail?.cc_email_addresses ?? []),
        ...(firstEmail?.to_email_addresses ?? []),
        ...(firstEmail?.from_email_address ? [firstEmail.from_email_address] : []),
      ]

      const isSelectingCurrentConversation = respondingEmail.id === negotiationConversation?.reply_to_email?.id
      let newConversation: NegotiationConversation | undefined
      if (!negotiationConversation || !isSelectingCurrentConversation) {
        newConversation = await onSelectReplyToEmail(respondingEmail)
      } else if (isSelectingCurrentConversation) {
        newConversation = negotiationConversation
      }

      const latestDraft = newConversation?.email_drafts?.at(-1) ?? EMPTY_EMAIL_DRAFT
      switch (typeOfResponse) {
        case "reply":
          onChange({
            ...latestDraft,
            include_reply_body: true,
            to_addresses: [respondingEmail.from_email_address],
            response_type: "reply",
            cc_addresses: [],
            html_reply_body: "",
          })
          break
        case "replyAll":
          onChange({
            ...latestDraft,
            include_reply_body: true,
            cc_addresses: allRecipientsInThread.filter(
              (emailAddress) => emailAddress !== respondingEmail.from_email_address && emailAddress !== whoami?.email
            ),
            to_addresses: [respondingEmail.from_email_address],
            response_type: "reply",
          })
          break
        case "forward":
          onChange({
            ...latestDraft,
            include_reply_body: true,
            to_addresses: [],
            response_type: "forward",
            cc_addresses: [],
          })
          break
      }
    },
    [emailThread, negotiationConversation, onSelectReplyToEmail, onChange, whoami?.email]
  )

  const isFlipForwardDisabled: boolean =
    emailDraftCursor.current === undefined ||
    (!!negotiationConversation?.email_drafts &&
      emailDraftCursor.current >= negotiationConversation.email_drafts.length - 1)

  const isFlipBackwardDisabled: boolean =
    emailDraftCursor.current === undefined || (!!negotiationConversation?.email_drafts && emailDraftCursor.current <= 0)

  const onFlipForward = isFlipForwardDisabled
    ? null
    : () => {
        if (emailDraftCursor.current !== undefined && negotiationConversation?.email_drafts) {
          emailDraftCursor.current++
          const newEmailDraft = negotiationConversation.email_drafts?.[emailDraftCursor.current]
          const newPreviousEmailDraft = negotiationConversation.email_drafts?.[emailDraftCursor.current - 1]
          if (newEmailDraft && newPreviousEmailDraft) {
            onChange({ ...EMPTY_EMAIL_DRAFT, ...newEmailDraft })
            setPreviousEmailDraft(newPreviousEmailDraft)
          }
        }
      }

  const onFlipBackward = isFlipBackwardDisabled
    ? null
    : () => {
        // the email draft cursor position is initially set to the index of the most recent email_draft
        if (emailDraftCursor.current !== undefined && negotiationConversation?.email_drafts) {
          const savedIndex = emailDraftCursor.current
          emailDraftCursor.current--
          const newEmailDraft = negotiationConversation.email_drafts?.[emailDraftCursor.current]

          // specifically, when the incoming cursor index is 1,
          // then we need to set the previous email to empty
          const newPreviousEmailDraft =
            savedIndex === 1 ? EMPTY_EMAIL_DRAFT : negotiationConversation.email_drafts?.[emailDraftCursor.current - 1]

          if (newEmailDraft && newPreviousEmailDraft) {
            onChange({ ...EMPTY_EMAIL_DRAFT, ...newEmailDraft })
            setPreviousEmailDraft(newPreviousEmailDraft)
          }
        }
      }

  const emailDraftCount = negotiationConversation?.email_drafts?.length ?? 0
  const emailDraftProps = {
    emailDraft,
    previousEmailDraft,
    onChange,
    negotiationConversation,
    isLoading,
    responseType,
    onReply,
    onSendSuccess,
    onFlipForward,
    onFlipBackward,
    emailDraftCursor: emailDraftCursor.current ?? 0,
    emailDraftCount,
  }

  return (
    <Box width="100%" height="100%" textAlign="left" flexDirection="column">
      {negotiation && <EmailHeader negotiation={negotiation} height={headerHeight} />}

      <Flex direction="column" overflow="auto" textAlign="left" height={`calc(100% - ${headerHeight})`}>
        {emailThread && emailThread.length > 0 ? (
          <EmailThread emailThread={emailThread} onReply={onReply} emailDraftProps={emailDraftProps} />
        ) : (
          <EmailDraft {...emailDraftProps} />
        )}
      </Flex>
    </Box>
  )
}

const EmailHeader = ({ negotiation, height }: { negotiation: Negotiation; height: string }) => {
  const { email_thread: emailThread } = negotiation
  const intl = useIntl()
  const subject = emailThread?.at(0)?.subject
  const deadline = negotiation.input_legal_agreement?.decision_date
  const tcv = negotiation.input_legal_agreement?.total_contract_value
  if (!subject && !negotiation.input_legal_agreement) return null
  return (
    <Stack
      borderBottom="1px solid"
      borderColor="gray.200"
      paddingX={4}
      paddingY={2}
      pos="sticky"
      top={0}
      backgroundColor="white"
      zIndex="sticky"
      // 26px is the height of one line of text in the header.
      height={subject ? height : `calc(${height} - 26px)`}
    >
      {negotiation.input_legal_agreement && (
        <HStack gap={2}>
          {deadline && (
            <Badge borderRadius="full" variant="subtleOutlined" paddingX={2} gap={1} backgroundColor="transparent">
              <chakra.span color="error.500" fontWeight="medium">
                <FormattedMessage
                  id="negotiation.deadline"
                  description="Deadline of the negotiation"
                  defaultMessage="Deadline"
                />
              </chakra.span>
              {formatDate(intl, deadline)}
            </Badge>
          )}
          {tcv && (
            <Badge borderRadius="full" variant="subtleOutlined" paddingX={2} gap={1} backgroundColor="transparent">
              <chakra.span color="brand.500" fontWeight="medium">
                <FormattedMessage
                  id="negotiation.tcv"
                  description="Total contract value of the negotiation"
                  defaultMessage="TCV"
                />
              </chakra.span>
              {formatCurrency(tcv, intl)}
            </Badge>
          )}
          <Badge
            borderRadius="full"
            variant="clickable"
            paddingX={2}
            gap={1}
            colorScheme="brand"
            cursor="pointer"
            fontWeight="medium"
            borderColor="brand.200"
            as={Link}
            to={pathForLegalAgreement(negotiation.input_legal_agreement?.id)}
            target="_blank"
          >
            <Icon as={AgreementIcon} color="brand.500" boxSize={3} />
            <FormattedMessage
              id="negotiation.viewActiveAgreement"
              description="View active agreement"
              defaultMessage="View active agreement"
            />
          </Badge>
        </HStack>
      )}
      {subject && (
        <OverflownText fontWeight="medium" color="gray.900" fontSize="lg" whiteSpace="nowrap">
          {subject}
        </OverflownText>
      )}
    </Stack>
  )
}

interface EmailDraftProps {
  emailDraft: EmailDraftInput
  previousEmailDraft: EmailDraftInput
  onChange: (value: Partial<EmailDraftInput>) => void
  negotiationConversation: NegotiationConversation | undefined
  isLoading: boolean
  onFlipForward: (() => void) | null
  onFlipBackward: (() => void) | null
  emailDraftCursor: number
  emailDraftCount: number
  onReply: (typeOfResponse: ResponseType, respondingEmail: Email) => void
  responseType: ResponseType
  onSendSuccess?: () => void
}

const EmailDraft = ({
  emailDraft,
  onChange,
  negotiationConversation,
  previousEmailDraft,
  isLoading,
  onReply,
  responseType,
  onSendSuccess,
  onFlipForward,
  onFlipBackward,
  emailDraftCursor,
  emailDraftCount,
}: EmailDraftProps) => {
  const intl = useIntl()
  const toast = useToast()
  const containerRef = useRef<HTMLFormElement>(null)
  const [sendEmail, { isLoading: isSendingEmail }] = usePostNegotiationV1EmailSendMutation()
  const [hideCc, setHideCc] = useState(emailDraft.cc_addresses.length === 0)
  const [hideBcc, setHideBcc] = useState(emailDraft.bcc_addresses.length === 0)
  const [expandReplyBody, setExpandReplyBody] = useState(false)
  const editorRef = useRef<HTMLDivElement>(null)
  const replyToEmail = negotiationConversation?.reply_to_email
  useEffect(() => {
    if (emailDraft.cc_addresses.length > 0) {
      setHideCc(false)
    }
    if (emailDraft.bcc_addresses.length > 0) {
      setHideBcc(false)
    }
  }, [emailDraft.cc_addresses, emailDraft.bcc_addresses])

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }, [containerRef, negotiationConversation?.id])

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (emailDraft && negotiationConversation?.id) {
      try {
        const response = await sendEmail({
          body: {
            email_draft: {
              ...emailDraft,
              html_body: editorRef.current?.innerHTML || "",
            },
            negotiation_conversation_id: negotiationConversation.id,
          },
        })
        if (response.error && "data" in response.error) {
          const errorData = response.error.data as { message?: string }
          throw new Error(errorData.message ?? "Error")
        }
        if (editorRef.current) {
          editorRef.current.innerHTML = ""
        }
      } catch {
        toast({
          description: intl.formatMessage({
            id: "email.send.error",
            description: "Error message when sending email fails",
            defaultMessage: "Failed to send email",
          }),
          status: "error",
        })
        throw new Error("Failed to send email")
      }
      onSendSuccess?.()
    }
  }

  return (
    <chakra.form
      display="flex"
      flexDirection="column"
      gap={3}
      onSubmit={onSubmit}
      paddingX={10}
      paddingY={6}
      ref={containerRef}
    >
      {!replyToEmail && (
        <FormControl isRequired>
          <FormLabel>
            <FormattedMessage id="email.subject" description="Label for email subject field" defaultMessage="Subject" />
          </FormLabel>
          <Input
            value={emailDraft.subject}
            onChange={(e) => onChange({ ...emailDraft, subject: e.target.value })}
            placeholder={intl.formatMessage({
              id: "email.subject.placeholder",
              description: "Placeholder for email subject field",
              defaultMessage: "Enter subject",
            })}
          />
        </FormControl>
      )}
      <FormControl isRequired>
        <HStack justify="space-between">
          <FormLabel display="flex" justifyContent="space-between">
            <FormattedMessage id="email.to" description="Label for email to field" defaultMessage="To" />
          </FormLabel>
          <HStack gap={0}>
            {hideCc && (
              <Button onClick={() => setHideCc(false)} variant="link">
                <FormattedMessage
                  id="email.cc.button"
                  description="Button to show CC field in email form"
                  defaultMessage="Cc"
                />
              </Button>
            )}
            {hideBcc && (
              <Button onClick={() => setHideBcc(false)} variant="link">
                <FormattedMessage
                  id="email.bcc.button"
                  description="Button to show BCC field in email form"
                  defaultMessage="Bcc"
                />
              </Button>
            )}
          </HStack>
        </HStack>
        <InputGroup>
          {replyToEmail && (
            <InputLeftAddon>
              <Menu>
                <Tooltip
                  label={intl.formatMessage({
                    id: "email.responseType.tooltip",
                    description: "Tooltip for email response type picker",
                    defaultMessage: "Type of response",
                  })}
                >
                  <MenuButton gap={1} type="button">
                    <Icon
                      as={
                        responseType === "reply" ? ReplyIcon : responseType === "replyAll" ? ReplyAllIcon : ForwardIcon
                      }
                    />
                    <Icon as={ChevronDownIcon} />
                  </MenuButton>
                </Tooltip>
                <EmailReplyOptions onReply={(responseType) => onReply(responseType, replyToEmail)} />
              </Menu>
            </InputLeftAddon>
          )}
          <Input
            autoFocus={true}
            value={emailDraft.to_addresses.join(", ")}
            onBlur={(e) =>
              onChange({
                ...emailDraft,
                to_addresses: e.target.value
                  .split(",")
                  .map((s) => s.trim())
                  .filter(Boolean),
              })
            }
            onChange={(e) =>
              onChange({
                ...emailDraft,
                to_addresses: [e.target.value],
              })
            }
            placeholder={intl.formatMessage({
              id: "email.to.placeholder",
              description: "Placeholder for email to field",
              defaultMessage: "Enter recipients (comma separated)",
            })}
          />
        </InputGroup>
      </FormControl>

      {!hideCc && (
        <FormControl>
          <FormLabel>
            <FormattedMessage id="email.cc" description="Label for email cc field" defaultMessage="Cc" />
          </FormLabel>
          <Input
            autoFocus={true}
            value={emailDraft.cc_addresses.join(", ")}
            onBlur={(e) =>
              onChange({
                cc_addresses: e.target.value
                  .split(",")
                  .map((s) => s.trim())
                  .filter(Boolean),
              })
            }
            onChange={(e) =>
              onChange({
                cc_addresses: [e.target.value],
              })
            }
            placeholder={intl.formatMessage({
              id: "email.cc.placeholder",
              description: "Placeholder for email cc field",
              defaultMessage: "Add Cc recipients (comma separated)",
            })}
          />
        </FormControl>
      )}

      {!hideBcc && (
        <FormControl>
          <FormLabel>
            <FormattedMessage id="email.bcc" description="Label for email bcc field" defaultMessage="Bcc" />
          </FormLabel>
          <Input
            autoFocus={true}
            value={emailDraft.bcc_addresses.join(", ")}
            onBlur={(e) =>
              onChange({
                bcc_addresses: e.target.value
                  .split(",")
                  .map((s) => s.trim())
                  .filter(Boolean),
              })
            }
            onChange={(e) =>
              onChange({
                bcc_addresses: [e.target.value],
              })
            }
            placeholder={intl.formatMessage({
              id: "email.bcc.placeholder",
              description: "Placeholder for email bcc field",
              defaultMessage: "Add Bcc recipients (comma separated)",
            })}
          />
        </FormControl>
      )}

      <FormControl>
        <EmailBodyEditor
          emailDraftCursor={emailDraftCursor}
          emailDraftCount={emailDraftCount}
          isLoading={isLoading}
          editorRef={editorRef}
          value={emailDraft.html_body ?? ""}
          previous_value={previousEmailDraft.html_body ?? ""}
          onFlipForward={onFlipForward}
          onFlipBackward={onFlipBackward}
          sendButton={
            <IconButtonWithTooltip
              type="submit"
              size="sm"
              variant="link"
              colorScheme="brand"
              padding={1}
              isLoading={isSendingEmail}
              icon={<Icon as={SendIcon} />}
              label={intl.formatMessage({
                id: "email.send",
                description: "Aria label for the send button in the email composer",
                defaultMessage: "Send email",
              })}
            />
          }
        />
      </FormControl>

      {emailDraft.html_reply_body && (
        <FormControl>
          <Checkbox
            isChecked={!!emailDraft.include_reply_body}
            onChange={(e) => {
              onChange({ ...emailDraft, include_reply_body: e.target.checked })
              setExpandReplyBody(false)
            }}
          >
            <FormattedMessage
              id="email.includeReplyBody"
              description="Checkbox to include reply body"
              defaultMessage="Include reply body"
            />
          </Checkbox>
        </FormControl>
      )}
      {!!emailDraft.include_reply_body && emailDraft.html_reply_body && (
        <Box
          position="relative"
          width="100%"
          outline="1px solid"
          backgroundColor="gray.50"
          outlineColor="brand.100"
          borderRadius="md"
        >
          <Box
            maxHeight={expandReplyBody ? "none" : "200px"}
            overflow="hidden"
            padding={2}
            sx={{
              maskImage: expandReplyBody ? "none" : "linear-gradient(to bottom, black 160px, transparent 200px)",
            }}
          >
            <Iframe
              key="email-reply-body"
              initialContent={emailDraft.html_reply_body ?? undefined}
              documentStyle={emailStyles}
            />
          </Box>
          {!expandReplyBody && (
            <Button
              variant="link"
              size="sm"
              position="absolute"
              bottom={-5}
              left="50%"
              onClick={() => setExpandReplyBody(true)}
            >
              <FormattedMessage
                id="email.seeMore"
                description="Button text to expand email reply body"
                defaultMessage="...view more"
              />
            </Button>
          )}
        </Box>
      )}
    </chakra.form>
  )
}

const CollapsableEmails = ({
  emails,
  onReply,
  emailDraftProps,
  ...props
}: {
  emails: Email[]
  onReply: (typeOfResponse: ResponseType, respondingEmail: Email) => void
  emailDraftProps: EmailDraftProps
} & StyleProps) => {
  const { isOpen, onToggle } = useDisclosure()
  return (
    <>
      {!isOpen && (
        <Button
          {...props}
          onClick={onToggle}
          variant="ghost"
          flex={1}
          justifyContent="flex-start"
          color="gray.500"
          fontWeight="normal"
          gap={2}
          paddingX={3}
          paddingY={2}
          borderRadius={0}
        >
          <Icon color="gray.400" as={CollapseIcon} boxSize={6} marginX={1} />
          <FormattedMessage
            id="email.thread.toggle"
            description="Button to toggle email thread"
            defaultMessage="{count} {count, plural, one {email} other {emails}} collapsed"
            values={{ count: emails.length }}
          />
        </Button>
      )}
      <Collapse in={isOpen}>
        {emails.map((email, i) => (
          <EmailItem
            key={i + 1}
            email={email}
            borderBottom={0}
            borderTop={i === 0 ? 0 : undefined}
            onReply={onReply}
            emailDraftProps={emailDraftProps}
          />
        ))}
      </Collapse>
    </>
  )
}

const EmailThread = ({
  emailThread,
  onReply,
  emailDraftProps,
}: {
  emailThread: Email[]
  onReply: (typeOfResponse: ResponseType, respondingEmail: Email) => void
  emailDraftProps: EmailDraftProps
}) => {
  const [indexes, setIndexes] = useState<number[]>([emailThread ? emailThread.length - 1 : 0])

  // the expected experience is that the first and last emails are always visible
  // and the middle emails are collapsed
  // examples
  // [1] => first: 1, middle = [], last = 1 (we filter this case via the thread length)
  // [1,2] => first: 1, middle = [], last = 2
  // [1,2,3] => first: 1, middle = [2], last = 3
  // [1,2,3,4] => first: 1, middle = [2,3], last = 4
  // ...

  const firstEmail = emailThread.at(0)
  const middleEmails = emailThread.slice(1, -1)
  const lastEmail = emailThread.at(-1)

  // 3 flows
  // if there are 0 middle emails, return null
  // if there are 1-4 middle emails, return the emails without any fancy collapsing
  // if there are 5+ middle emails, return a button to toggle the emails
  // if the draft is replying to a middle email, split up the collapse into 2 sections
  const getMiddleItems = () => {
    if (middleEmails.length === 0) return null

    if (middleEmails.length <= 4) {
      return middleEmails.map((email, i) => (
        <EmailItem
          key={i + 1}
          email={email}
          borderBottom={0}
          borderTop={i === 0 ? 0 : undefined}
          onReply={onReply}
          emailDraftProps={emailDraftProps}
        />
      ))
    }

    const isReplyingToEmailIndex = middleEmails.findIndex(
      (email) => email.id === emailDraftProps.negotiationConversation?.reply_to_email?.id
    )

    if (isReplyingToEmailIndex !== -1) {
      const firstHalf = middleEmails.slice(0, isReplyingToEmailIndex)
      const secondHalf = middleEmails.slice(isReplyingToEmailIndex + 1)
      const replyingToEmail = middleEmails[isReplyingToEmailIndex]
      return (
        <>
          {firstHalf.length > 0 && (
            <CollapsableEmails emails={firstHalf} onReply={onReply} emailDraftProps={emailDraftProps} />
          )}
          {replyingToEmail && (
            <EmailItem
              email={replyingToEmail}
              onReply={onReply}
              emailDraftProps={emailDraftProps}
              borderTopWidth={firstHalf.length === 0 ? 0 : undefined}
            />
          )}
          {secondHalf.length > 0 && (
            <CollapsableEmails
              emails={secondHalf}
              onReply={onReply}
              emailDraftProps={emailDraftProps}
              borderTopWidth={1}
            />
          )}
        </>
      )
    }

    return <CollapsableEmails emails={middleEmails} onReply={onReply} emailDraftProps={emailDraftProps} />
  }

  const emailToShowReplyButtons =
    lastEmail && emailDraftProps.negotiationConversation?.reply_to_email?.id !== lastEmail.id
      ? lastEmail
      : !lastEmail && emailDraftProps.negotiationConversation?.reply_to_email?.id === firstEmail?.id
        ? firstEmail
        : undefined

  return (
    <Accordion
      reduceMotion
      allowMultiple={true}
      index={indexes}
      onChange={(newIndexes) => setIndexes(newIndexes as number[])}
    >
      {firstEmail && (
        <EmailItem
          email={firstEmail}
          key={0}
          borderTop={0}
          borderBottomWidth={middleEmails.length > 0 ? 1 : 0}
          onReply={onReply}
          emailDraftProps={emailDraftProps}
        />
      )}
      {getMiddleItems()}
      {lastEmail && emailThread.length > 1 && (
        <EmailItem email={lastEmail} key={emailThread.length - 1} onReply={onReply} emailDraftProps={emailDraftProps} />
      )}
      {emailToShowReplyButtons && (
        <HStack mx={12} py={4} marginBottom={20} gap={4} borderTopWidth={1} borderColor="gray.200">
          <Button
            colorScheme="brand"
            leftIcon={<Icon as={ReplyIcon} />}
            onClick={() => onReply("reply", emailToShowReplyButtons)}
          >
            <FormattedMessage id="email.reply" description="Button to reply last email" defaultMessage="Reply" />
          </Button>
          <Button
            colorScheme="brand"
            leftIcon={<Icon as={ReplyAllIcon} />}
            onClick={() => onReply("replyAll", emailToShowReplyButtons)}
          >
            <FormattedMessage id="email.replyAll" description="Button to reply all emails" defaultMessage="Reply All" />
          </Button>
          <Button leftIcon={<Icon as={ForwardIcon} />} onClick={() => onReply("forward", emailToShowReplyButtons)}>
            <FormattedMessage id="email.forward" description="Button to forward email" defaultMessage="Forward" />
          </Button>
        </HStack>
      )}
    </Accordion>
  )
}

const EmailItem = ({
  email,
  onReply,
  emailDraftProps,
  ...props
}: {
  email: Email
  onReply: (typeOfResponse: ResponseType, respondingEmail: Email) => void
  emailDraftProps: EmailDraftProps
} & StyleProps) => {
  const isDrafting = emailDraftProps.negotiationConversation?.reply_to_email?.id === email.id
  const intl = useIntl()
  return (
    <>
      <AccordionItem {...props}>
        {({ isExpanded }) => (
          <>
            <AccordionButton
              px={3}
              py={2}
              _hover={{ backgroundColor: "white", cursor: isDrafting ? "default" : "pointer" }}
            >
              <HStack gap={3} width="100%">
                <Avatar
                  size="md"
                  src={getPublicImageGcsUrl(email.from_person?.profile_image?.gcs_file_name)}
                  name={email.from_person?.display_name ?? email.from_email_address}
                />
                <Stack gap={0} width="100%">
                  <HStack justify="space-between">
                    <Text fontWeight="semibold" color="gray.600">
                      {email.from_person?.display_name ?? email.from_email_address}
                    </Text>
                    <HStack>
                      {email.received_at && (
                        <Text color="gray.500" fontSize="xs" flexShrink={0}>
                          {formatDateTime(intl, email.received_at)}
                        </Text>
                      )}
                      {(isDrafting || isExpanded) && (
                        <HStack alignItems="flex-start" gap={1}>
                          <IconButtonWithTooltip
                            color="gray.600"
                            size="sm"
                            variant="ghost"
                            icon={<Icon as={ReplyIcon} />}
                            label={intl.formatMessage({
                              id: "email.reply",
                              description: "Label for email reply button",
                              defaultMessage: "Reply",
                            })}
                            onClick={(e) => {
                              e.stopPropagation()
                              onReply("reply", email)
                            }}
                          />
                          <Menu>
                            <IconButtonWithTooltip
                              as={MenuButton}
                              color="gray.600"
                              size="sm"
                              variant="ghost"
                              icon={<Icon as={MoreMenuIcon} />}
                              label={intl.formatMessage({
                                id: "email.replyOptions",
                                description: "Label for email reply options",
                                defaultMessage: "More",
                              })}
                              onClick={(e) => {
                                e.stopPropagation()
                              }}
                            />
                            <EmailReplyOptions onReply={(responseType) => onReply(responseType, email)} />
                          </Menu>
                        </HStack>
                      )}
                    </HStack>
                  </HStack>
                  <chakra.span
                    color="gray.600"
                    fontSize="sm"
                    noOfLines={1}
                    overflow="hidden"
                    textOverflow="ellipsis"
                    textAlign="left"
                  >
                    {isDrafting || isExpanded ? (
                      <FormattedMessage
                        id="email.to"
                        description="Label for email to field"
                        defaultMessage="To: {to}"
                        values={{ to: email.to_email_addresses.join(", ") }}
                      />
                    ) : (
                      email.snippet
                    )}
                  </chakra.span>
                </Stack>
              </HStack>
            </AccordionButton>
            {(isDrafting || isExpanded) && (
              <Box pb={4} marginLeft={12} paddingLeft={2}>
                <Iframe key={email.id} initialContent={email.html_text ?? undefined} documentStyle={emailStyles} />
              </Box>
            )}
          </>
        )}
      </AccordionItem>
      {isDrafting && (
        <Box borderTopWidth={1} borderColor="gray.200" width="100%">
          <EmailDraft {...emailDraftProps} />
        </Box>
      )}
    </>
  )
}

const EmailReplyOptions = ({ onReply }: { onReply: (typeOfResponse: ResponseType) => void }) => {
  return (
    <Portal>
      <MenuList>
        <MenuItem icon={<Icon as={ReplyIcon} color="gray.600" />} onClick={() => onReply("reply")}>
          <FormattedMessage id="email.reply" description="Label for email reply button" defaultMessage="Reply" />
        </MenuItem>
        <MenuItem icon={<Icon as={ReplyAllIcon} color="gray.600" />} onClick={() => onReply("replyAll")}>
          <FormattedMessage
            id="email.replyAll"
            description="Label for email reply all button"
            defaultMessage="Reply All"
          />
        </MenuItem>
        <MenuItem icon={<Icon as={ForwardIcon} color="gray.600" />} onClick={() => onReply("forward")}>
          <FormattedMessage id="email.forward" description="Label for email forward button" defaultMessage="Forward" />
        </MenuItem>
      </MenuList>
    </Portal>
  )
}
function visualizeDiff(changes: Change[]) {
  return changes
    .map((change) => {
      if (change.removed) {
        return `<div style="background-color: var(--chakra-colors-red-50); color: var(--chakra-colors-red-700); padding: 2px 4px; margin: 2px 0; border-left: 2px solid var(--chakra-colors-red-500);">${change.value}</div>`
      }
      if (change.added) {
        return `<div style="background-color: var(--chakra-colors-green-50); color: var(--chakra-colors-green-700); padding: 2px 4px; margin: 2px 0; border-left: 2px solid var(--chakra-colors-green-500);">${change.value}</div>`
      }
      return change.value
    })
    .join("\n")
}

export function EmailBodyEditor(props: {
  value: string // html text
  previous_value: string // html text
  isLoading: boolean
  editorRef: React.RefObject<HTMLDivElement>
  onFlipForward: (() => void) | null
  onFlipBackward: (() => void) | null
  emailDraftCursor: number
  emailDraftCount: number
  sendButton: React.ReactNode
}) {
  const {
    value,
    previous_value,
    editorRef,
    isLoading,
    onFlipForward,
    onFlipBackward,
    emailDraftCursor,
    emailDraftCount,
    sendButton,
  } = props
  const intl = useIntl()
  const toast = useToast()
  const [isFocused, setIsFocused] = useState(false)
  const [activeFormats, setActiveFormats] = useState({
    bold: false,
    italic: false,
    underline: false,
    heading: false,
    quote: false,
    bulletedList: false,
    numberedList: false,
  })
  const [viewMode, setViewMode] = useState<"current" | "diff" | "previous">("current")

  // lock the editor in the current view mode if the email is loading
  useEffect(() => {
    if (isLoading === true) {
      setViewMode("current")
    }
  }, [isLoading, editorRef])

  useEffect(() => {
    if (editorRef.current) {
      switch (viewMode) {
        case "previous":
          editorRef.current.innerHTML = `<div style="background-color: var(--chakra-colors-orange-50); padding: 8px; border-radius: 4px;">${previous_value}</div>`
          break
        case "diff": {
          const diffObject = diffLines(previous_value, value)
          editorRef.current.innerHTML = visualizeDiff(diffObject)
          break
        }
        case "current":
        default:
          editorRef.current.innerHTML = value
          break
      }
    }
  }, [editorRef, value, viewMode, previous_value])

  const execCommand = useCallback(
    (command: ExecCommandType, value?: string) => {
      document.execCommand(command, false, value)
      editorRef.current?.focus()
      checkActiveFormats()
    },
    [editorRef]
  )

  const checkActiveFormats = () => {
    setActiveFormats({
      bold: document.queryCommandState("bold"),
      italic: document.queryCommandState("italic"),
      underline: document.queryCommandState("underline"),
      // TODO: This is not working as expected need to use selections
      heading: document.queryCommandState("formatBlock"),
      quote: document.queryCommandState("formatBlock"),
      bulletedList: document.queryCommandState("insertUnorderedList"),
      numberedList: document.queryCommandState("insertOrderedList"),
    })
  }

  useEffect(() => {
    const handleSelectionChange = () => {
      // Only check formats if the selection is within our editor
      if (document.activeElement === editorRef.current) {
        checkActiveFormats()
      }
    }

    document.addEventListener("selectionchange", handleSelectionChange)
    return () => document.removeEventListener("selectionchange", handleSelectionChange)
  }, [editorRef])

  return (
    <>
      <HStack paddingBottom={1} justifyContent="flex-end">
        <Text fontSize="sm" color="gray.500">
          <FormattedMessage
            id="email.draft.counter"
            description="Counter showing current draft number out of total drafts"
            defaultMessage="Draft {current} of {total}"
            values={{
              current: emailDraftCursor + 1,
              total: emailDraftCount,
            }}
          />
        </Text>
        <IconButtonWithTooltip
          isDisabled={isLoading}
          variant="ghost"
          icon={<Icon as={ReflectIcon} />}
          label={intl.formatMessage({
            id: "editor.toolbar.reflect",
            description: "Label for the reflect button in the editor toolbar",
            defaultMessage: "View Changes",
          })}
          onClick={() => {
            if (viewMode === "diff") {
              setViewMode("current")
            } else {
              setViewMode("diff")
            }
          }}
        />
        <IconButtonWithTooltip
          isDisabled={isLoading || onFlipBackward === null}
          variant="ghost"
          icon={<Icon as={FlipBackwardIcon} />}
          label={intl.formatMessage({
            id: "editor.toolbar.flip-backward",
            description: "Label for the flip backward button in the editor toolbar",
            defaultMessage: "Previous Email",
          })}
          onClick={() => onFlipBackward?.()}
        />
        <IconButtonWithTooltip
          isDisabled={isLoading || onFlipForward === null}
          variant="ghost"
          icon={<Icon as={FlipForwardIcon} />}
          label={intl.formatMessage({
            id: "editor.toolbar.flip-forward",
            description: "Label for the flip forward button in the editor toolbar",
            defaultMessage: "Next Email",
          })}
          onClick={() => onFlipForward?.()}
        />
      </HStack>

      <Stack
        borderRadius="md"
        border="1px solid"
        borderColor={isFocused ? "brand.500" : "gray.200"}
        boxShadow={isFocused ? "outline" : "none"}
        padding={1}
        transitionProperty="common"
        transitionDuration="normal"
      >
        <Box
          padding={1}
          ref={editorRef}
          onMouseUp={checkActiveFormats}
          contentEditable={true}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          minHeight={50}
          onPaste={(e) => {
            e.preventDefault()
            const text = e.clipboardData.getData("text/html")
            execCommand("insertHTML", text)
          }}
          boxShadow={0}
          outline={0}
          _focus={{
            outline: "none",
            boxShadow: "none",
          }}
          sx={emailStyles}
          listStylePos="inside"
        />
        <Flex
          alignItems="center"
          gap={4}
          height={6}
          justifyContent="space-between"
          onMouseDown={(event) => event.preventDefault()}
        >
          <Flex alignItems="center" overflowY="auto" gap={2} height={6} sx={{ scrollbarWidth: "none" }}>
            <MarkButton
              isActive={activeFormats.bold}
              onClick={() => execCommand("bold")}
              icon={<Icon as={BoldIcon} />}
              label={intl.formatMessage({
                id: "editor.toolbar.bold",
                description: "Label for the bold button in the editor toolbar",
                defaultMessage: "Bold",
              })}
            />
            <MarkButton
              isActive={activeFormats.italic}
              onClick={() => execCommand("italic")}
              icon={<Icon as={ItalicIcon} />}
              label={intl.formatMessage({
                id: "editor.toolbar.italic",
                description: "Label for the italic button in the editor toolbar",
                defaultMessage: "Italic",
              })}
            />
            <MarkButton
              isActive={activeFormats.underline}
              onClick={() => execCommand("underline")}
              icon={<Icon as={UnderlineIcon} />}
              label={intl.formatMessage({
                id: "editor.toolbar.underline",
                description: "Label for the underline button in the editor toolbar",
                defaultMessage: "Underline",
              })}
            />
            {/* <AddLinkButton onAddLink={(url) => insertLink(editor, url)} isLinkActive={isLinkActive(editor)} /> */}
            <BlockButton
              isActive={activeFormats.heading}
              onClick={() => execCommand("formatBlock", "h1")}
              icon={<Icon as={HeadingIcon} />}
              label={intl.formatMessage({
                id: "editor.toolbar.heading",
                description: "Label for the heading button in the editor toolbar",
                defaultMessage: "Heading",
              })}
            />
            <BlockButton
              isActive={activeFormats.quote}
              onClick={() => execCommand("formatBlock", "blockquote")}
              icon={<Icon as={QuoteIcon} />}
              label={intl.formatMessage({
                id: "editor.toolbar.quote",
                description: "Label for the quote button in the editor toolbar",
                defaultMessage: "Quote",
              })}
            />
            <BlockButton
              isActive={activeFormats.bulletedList}
              onClick={() => execCommand("insertUnorderedList")}
              icon={<Icon as={BulletedListIcon} />}
              label={intl.formatMessage({
                id: "editor.toolbar.bulleted-list",
                description: "Label for the bulleted list button in the editor toolbar",
                defaultMessage: "Bulleted List",
              })}
            />
            <BlockButton
              isActive={activeFormats.numberedList}
              onClick={() => execCommand("insertOrderedList")}
              icon={<Icon as={NumberedListIcon} />}
              label={intl.formatMessage({
                id: "editor.toolbar.numbered-list",
                description: "Label for the numbered list button in the editor toolbar",
                defaultMessage: "Numbered List",
              })}
            />
          </Flex>
          <HStack gap={1}>
            <IconButtonWithTooltip
              variant="ghost"
              size="sm"
              icon={<Icon as={CopyIcon} />}
              onClick={() => {
                if (editorRef.current) {
                  void navigator.clipboard.write([
                    new ClipboardItem({
                      "text/html": new Blob([editorRef.current.innerHTML], { type: "text/html" }),
                      "text/plain": new Blob([editorRef.current.innerHTML], { type: "text/plain" }),
                    }),
                  ])
                  toast({
                    description: intl.formatMessage({
                      id: "email.copy.success",
                      description: "Success message when copying email content",
                      defaultMessage: "Email copied to clipboard",
                    }),
                    status: "success",
                    duration: 2000,
                  })
                }
              }}
              label={intl.formatMessage({
                id: "email.copy.success",
                description: "Success message when copying email content",
                defaultMessage: "Copy to clipboard",
              })}
            />
            {sendButton}
          </HStack>
        </Flex>
      </Stack>
    </>
  )
}

export default EmailComposer
