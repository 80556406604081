import type { BlockType, LeafType } from "@brm/schema-helpers/rich-text/ast-types.js"
import { isBlockNode, isLeafNode, iterateRichText } from "@brm/schema-helpers/rich-text/rich-text.js"
import type {
  ObjectType,
  PickableEntityFilter,
  PickableEntityType,
  UserWithOrganization,
} from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import {
  Avatar,
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  GridItem,
  Icon,
  IconButton,
  InputGroup,
  chakra,
  useToast,
} from "@chakra-ui/react"
import { usePostHog } from "posthog-js/react"
import { useCallback, useEffect, useMemo, useRef, useState, type FC } from "react"
import { createPortal } from "react-dom"
import { Controller, useForm, useWatch, type Control } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useSearchParams } from "react-router-dom"
import { type Descendant } from "slate"
import {
  useGetUserV1WhoamiQuery,
  usePostTimelineV1EventsObjectsByObjectTypeAndObjectIdMutation,
  type MentionedUserToCreateTaskFor,
} from "../../app/services/generated-api.js"
import { dynamoTimelineEventStream } from "../../features/betsy/streaming-api.js"
import type { GetLogoForOrganizationProps } from "../../features/workflows/run/utils.js"
import { userDocumentDownloadUrl } from "../../util/document.js"
import type { StreamingController } from "../../util/hooks/streaming.js"
import { log } from "../../util/logger.js"
import { isApplePlatform } from "../../util/platform.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import { validateRichTextRequired } from "../../util/validation.js"
import { LockIcon, SendIcon, UsersIcon } from "../icons/icons.js"
import RichTextEditor from "../RichTextEditor/RichTextEditor.js"
import {
  DEFAULT_PICKABLE_ENTITIES,
  EMPTY_RICH_TEXT_BODY,
  isWhitespaceParagraphNode,
  trimRichTextWhiteSpace,
} from "../RichTextEditor/util/common.js"
import type { MentionFromRichText } from "./AtMentionTaskArea.js"
import AtMentionTaskArea from "./AtMentionTaskArea.js"

export interface TimelineCommentInputProps {
  parentId?: string
  workflowRunId?: string
  workflowRunStepId?: string
  path?: (string | number)[]
  objectType: ObjectType
  objectId: string
  fieldName?: string
  label: string
  isCustomField?: boolean
  onSaveComment?: () => void
  showPrivacyControls?: boolean
  /**
   * If true, the comment input will be focused when the component mounts.
   */
  autoFocus?: boolean
  onOpen?: () => void
  streamingController?: StreamingController
  disableReplyTasks?: boolean
}

interface FormState {
  body: Descendant[]
  is_private?: boolean
  mentionTaskUsers: Array<MentionedUserToCreateTaskFor>
}
export function TimelineCommentInput({
  label,
  path,
  fieldName,
  objectType,
  objectId,
  parentId,
  workflowRunId,
  workflowRunStepId,
  showPrivacyControls,
  onSaveComment,
  getLogoToShowByOrganizationId,
  autoFocus,
  onOpen,
  streamingController,
  isCustomField,
  disableReplyTasks,
}: TimelineCommentInputProps & GetLogoForOrganizationProps) {
  const intl = useIntl()
  const toast = useToast()
  const [, setSearchParams] = useSearchParams()
  const [atMentions, setAtMentions] = useState<Map<string, MentionFromRichText>>(new Map())
  const [fieldIsDirty, setFieldIsDirty] = useState(false)
  const [editorKey, setEditorKey] = useState(0)
  const formRef = useRef<HTMLFormElement>(null)
  const editorRef = useRef<{ focus: () => void }>(null)
  const posthog = usePostHog()

  useEffect(() => {
    // Focus the editor when the component mounts
    if (autoFocus) {
      editorRef.current?.focus()
    }
  }, [autoFocus])

  const pickableEntityFilters: Omit<PickableEntityFilter, "name"> = useMemo(() => {
    const entities = workflowRunId
      ? ([...DEFAULT_PICKABLE_ENTITIES, "workflow_seller", "workflow_buyer"] satisfies PickableEntityType[])
      : DEFAULT_PICKABLE_ENTITIES
    return {
      entities,
      requesting_entity: workflowRunId
        ? {
            object_type: "WorkflowRun",
            object_id: workflowRunId,
          }
        : {
            object_type: objectType,
            object_id: objectId,
          },
    }
  }, [objectId, objectType, workflowRunId])

  const whoami = useGetUserV1WhoamiQuery()
  const [postComment, postCommentResult] = usePostTimelineV1EventsObjectsByObjectTypeAndObjectIdMutation()

  const storageKey = workflowRunStepId
    ? `comment:${workflowRunStepId}:${objectId}:${fieldName}`
    : parentId
      ? `comment:${objectType}:${objectId}:${parentId}`
      : `comment:${objectType}:${objectId}`

  const restoredState = useMemo(() => {
    const storedState = localStorage.getItem(storageKey)
    if (!storedState) {
      return undefined
    }
    const restoredFormState = JSON.parse(storedState) as { body: Descendant[] | string }
    if (typeof restoredFormState.body === "string") {
      localStorage.removeItem(storageKey)
      return undefined
    }
    return restoredFormState as FormState
  }, [storageKey])

  const formStartState = {
    body: structuredClone(EMPTY_RICH_TEXT_BODY),
    is_private: workflowRunId ? true : undefined,
    mentionTaskUsers: [],
  }

  const form = useForm<FormState>({
    defaultValues: restoredState ?? formStartState,
    reValidateMode: "onSubmit",
  })

  const watchIsPrivate = form.watch("is_private")

  const formId = `comment-form:${path ? path.join(".") : parentId ? `${objectType}:${parentId}` : objectType}`
  useEffect(() => {
    // Load the initial mention values
    const formValues = form.getValues()
    if (formValues.body) {
      const node = formValues.body as BlockType | LeafType | (BlockType | LeafType)[]
      if (whoami.data?.organization.is_buyer) {
        const { mentions, fieldIsDirty } = getMentionsForTasksAndDirtyStateFromNode(whoami.data, node)
        setAtMentions(new Map(mentions.map((mention) => [mention.user_id, mention])))
        setFieldIsDirty(fieldIsDirty)
      }
    }
    form.watch((values, { type }) => {
      if (type !== "change") {
        return
      }

      const node = values.body as BlockType | LeafType | (BlockType | LeafType)[]
      if (whoami.data) {
        const { mentions, fieldIsDirty } = getMentionsForTasksAndDirtyStateFromNode(whoami.data, node)
        setAtMentions(new Map(mentions.map((mention) => [mention.user_id, mention])))
        setFieldIsDirty(fieldIsDirty)
      }

      localStorage.setItem(storageKey, JSON.stringify(values))
    })
  }, [form, storageKey, whoami.data, workflowRunId])

  const handleMentionsToCreateTasksFor = useCallback(
    (mentionsChecked: Map<string, string>) => {
      const newArray: Array<MentionedUserToCreateTaskFor> = Array.from(
        mentionsChecked.entries(),
        ([user_id, date]) => ({
          user_id,
          date,
        })
      )
      form.setValue("mentionTaskUsers", newArray)
    },
    [form]
  )

  return (
    <>
      <GridItem>
        <Avatar
          name={whoami.data && displayPersonName(whoami.data, intl)}
          src={getPublicImageGcsUrl(whoami.data?.profile_image?.gcs_file_name)}
        />
      </GridItem>
      <GridItem minW={0}>
        <InputGroup minW={0}>
          <Controller
            name="body"
            control={form.control}
            rules={{
              validate: (value) =>
                validateRichTextRequired(
                  value,
                  intl.formatMessage({
                    id: "comment.input.body.validate.empty",
                    description: "Validation message shown when user attempts to create a comment that is empty",
                    defaultMessage: "Comment cannot be empty",
                  })
                ),
            }}
            render={({ field, fieldState }) => (
              <FormControl isRequired isInvalid={fieldState.invalid}>
                <RichTextEditor
                  key={editorKey}
                  ref={editorRef}
                  getDocumentUrl={userDocumentDownloadUrl}
                  enableDocumentAttachments
                  initialValue={field.value}
                  onChange={(value) => field.onChange(value)}
                  placeholder={intl.formatMessage({
                    id: "timeline.comment.editor.placeholder",
                    description: "Placeholder for the comment editor",
                    defaultMessage: "Add a comment...",
                  })}
                  aria-invalid={fieldState.invalid}
                  aria-label={intl.formatMessage(
                    {
                      defaultMessage: "Comment on {fieldLabel}",
                      description: "Aria label for the comment box on the field timeline",
                      id: "timeline.comment.ariaLabel",
                    },
                    { fieldLabel: label }
                  )}
                  containerProps={{
                    onKeyDown: (event) => {
                      if (event.key === "Enter" && (event.metaKey || event.ctrlKey) && !postCommentResult.isLoading) {
                        formRef.current?.requestSubmit()
                      }
                    },
                  }}
                  additionalToolbarButtons={showPrivacyControls && <ShareButtons control={form.control} />}
                  sendButton={
                    <FieldTimelineCommentSendButton
                      control={form.control}
                      formId={formId}
                      isLoading={postCommentResult.isLoading}
                    />
                  }
                  isPrivate={watchIsPrivate}
                  pickableEntityFilters={pickableEntityFilters}
                  getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
                  hasError={fieldState.invalid}
                  forceFocus={fieldIsDirty}
                />
                {fieldState.error && <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>}
                {!disableReplyTasks && (
                  <AtMentionTaskArea
                    mentions={Array.from(atMentions.values())}
                    setMentionsToCreateTasksFor={handleMentionsToCreateTasksFor}
                  />
                )}
              </FormControl>
            )}
          />
        </InputGroup>
      </GridItem>
      {/* Render form in a portal because forms cannot be nested in HTML (but you can use the `form` attribute to achieve the same effect) */}
      {createPortal(
        <form
          method="dialog"
          ref={formRef}
          id={formId}
          noValidate
          onSubmit={async (event) => {
            event.preventDefault()
            event.stopPropagation()
            setSearchParams(
              (current) => {
                current.delete("event")
                return current
              },
              { replace: true }
            )
            setAtMentions(new Map())
            await form.handleSubmit(async (values) => {
              const originalBody = values.body
              const trimmedBody = trimRichTextWhiteSpace(values.body) as Descendant[]
              // If the timeline is collapsible, make sure it is open after saving a comment
              onOpen?.()
              try {
                let isAgentCall = false
                for (const node of iterateRichText(trimmedBody as (BlockType | LeafType)[])) {
                  if (isBlockNode(node) && node.type === "agent-tag") {
                    isAgentCall = true
                    break
                  }
                }

                // if the comment is an agent call, then we need to use the streaming endpoint
                if (isAgentCall) {
                  posthog.capture("ai_mention_comment")
                  // immediately reset the form to clear the editor on submit
                  form.reset(formStartState)
                  // Reset the editor key to force a re-render of the editor on submit
                  setEditorKey((key) => key + 1)

                  const abortController = new AbortController()

                  // streaming is optional because it is not supported everywhere (yet)
                  streamingController?.start()
                  await dynamoTimelineEventStream(
                    {
                      workflowRunId,
                      workflowRunStepId,
                      objectType,
                      objectId,
                      fieldName,
                      timelineInput: parentId
                        ? {
                            parent_id: parentId,
                            type: "timeline_event_reply",
                            rich_text_body: trimmedBody,
                            mentioned_users_to_create_tasks_for: values.mentionTaskUsers,
                          }
                        : {
                            type: "comment",
                            rich_text_body: trimmedBody,
                            is_private: values.is_private,
                            is_custom_field: isCustomField,
                            mentioned_users_to_create_tasks_for: values.mentionTaskUsers,
                          },
                    },
                    abortController.signal,
                    streamingController?.onText,
                    streamingController?.onStatus
                  )
                  streamingController?.done()

                  localStorage.removeItem(storageKey)
                  onSaveComment?.()

                  abortController.abort()
                  return
                }

                await postComment({
                  workflowRunId,
                  workflowRunStepId,
                  objectType,
                  objectId,
                  fieldName,
                  timelineInput: parentId
                    ? {
                        parent_id: parentId,
                        type: "timeline_event_reply",
                        rich_text_body: trimmedBody,
                        mentioned_users_to_create_tasks_for: values.mentionTaskUsers,
                      }
                    : {
                        type: "comment",
                        rich_text_body: trimmedBody,
                        is_private: values.is_private,
                        is_custom_field: isCustomField,
                        mentioned_users_to_create_tasks_for: values.mentionTaskUsers,
                      },
                }).unwrap()
                form.reset(formStartState)
                // Reset the editor key to force a re-render of the editor on submit
                setEditorKey((key) => key + 1)
                localStorage.removeItem(storageKey)
                onSaveComment?.()
              } catch (err) {
                // put the original body back in the form
                form.setValue("body", originalBody)
                toast({
                  description: intl.formatMessage({
                    id: "request.comment.submit.error",
                    description: "Toast error message when submitting a comment fails",
                    defaultMessage: "There was an error posting your comment",
                  }),
                  status: "error",
                })
                log.error("Error submitting comment", err)
              }
            })(event)
          }}
        />,
        document.body,
        formId
      )}
    </>
  )
}

function FieldTimelineCommentSendButton({
  control,
  formId,
  isLoading,
}: {
  control: Control<FormState>
  formId: string
  isLoading: boolean
}) {
  const intl = useIntl()
  const commentBody = useWatch({ control, name: "body" })
  const isCommentEmpty = useMemo(() => {
    if (commentBody.length > 1) {
      return false
    }
    const firstDescendant = commentBody[0]
    if (!firstDescendant || isWhitespaceParagraphNode(firstDescendant)) {
      return true
    }
    return false
  }, [commentBody])

  return (
    <IconButton
      type="submit"
      size="sm"
      isDisabled={isCommentEmpty}
      variant="link"
      form={formId}
      icon={<Icon as={SendIcon} />}
      colorScheme="brand"
      aria-label={intl.formatMessage({
        defaultMessage: "Submit comment",
        description: "Aria label for the submit comment button on the field timeline",
        id: "timeline.comment.submit",
      })}
      aria-keyshortcuts={isApplePlatform() ? "Meta+Enter" : "Ctrl+Enter"}
      isLoading={isLoading}
    />
  )
}

interface ShareButtonsProps {
  control: Control<FormState>
}

const ShareButtons: FC<ShareButtonsProps> = ({ control }) => {
  return (
    <Controller
      name="is_private"
      control={control}
      render={({ field, fieldState }) => (
        <FormControl isRequired isInvalid={fieldState.invalid} display="contents">
          <ButtonGroup size="sm" isAttached variant="outline" minW={16} flexShrink={8}>
            <Button
              onClick={() => field.onChange(true)}
              isActive={field.value}
              variant="outline"
              leftIcon={<Icon as={LockIcon} />}
            >
              <chakra.span overflow="hidden">
                <FormattedMessage
                  defaultMessage="Internal"
                  description="The button to select that this comment will be private to the org on the request"
                  id="timeline.comment.shareButton.internal"
                />
              </chakra.span>
            </Button>
            <Button
              onClick={() => field.onChange(false)}
              isActive={!field.value}
              variant="outline"
              leftIcon={<Icon as={UsersIcon} />}
            >
              <chakra.span overflow="hidden">
                <FormattedMessage
                  defaultMessage="Shared"
                  description="The button to select that this comment will be shared with other orgs on the request"
                  id="timeline.comment.shareButton.shared"
                />
              </chakra.span>
            </Button>
          </ButtonGroup>
        </FormControl>
      )}
    />
  )
}

const getMentionsForTasksAndDirtyStateFromNode = (
  user: UserWithOrganization,
  descendant: BlockType | LeafType | (BlockType | LeafType)[]
): { mentions: Array<MentionFromRichText>; fieldIsDirty: boolean } => {
  const mentions: Array<MentionFromRichText> = []

  let atLeastOneNonEmptyLeafNode = false

  for (const node of iterateRichText(descendant)) {
    if (isLeafNode(node) && node.text.length > 0) {
      atLeastOneNonEmptyLeafNode = true
    }

    if (!isLeafNode(node) && node.type === "mention" && node.display_name && node.organization_id && node.user_id) {
      mentions.push({
        display_name: node.display_name,
        user_id: node.user_id,
        organization_id: node.organization_id,
      })
    }
  }

  // Only buyers can make tasks for other users
  if (!user.organization.is_buyer) {
    return {
      mentions: [],
      fieldIsDirty: atLeastOneNonEmptyLeafNode,
    }
  }

  return {
    mentions: mentions.filter((mention) => {
      // Don't let users make tasks for themselves
      if (mention.user_id === user.id) {
        return false
      }
      // Don't let users make tasks for other users in other orgs
      if (mention.organization_id !== user.organization.id) {
        return false
      }
      return true
    }),
    fieldIsDirty: atLeastOneNonEmptyLeafNode,
  }
}
