import { Box, Button, Heading, Stack, Text } from "@chakra-ui/react"

export interface OnboardingWelcomeCardProps {
  preHeader?: string
  heading: string
  contentParagraphs?: string[]
  contentBullets?: string[]
  contentHighlight?: string
  cta: string
  ctaIcon?: React.ReactElement
  onCtaClick: () => void
  secondaryActionIntro?: string
  secondaryActionCta?: string
  secondaryActionOnClick?: () => void
}

export const OnboardingWelcomeCard = ({
  preHeader,
  heading,
  contentParagraphs,
  contentBullets,
  contentHighlight,
  cta,
  ctaIcon,
  onCtaClick,
  secondaryActionIntro,
  secondaryActionCta,
  secondaryActionOnClick,
}: OnboardingWelcomeCardProps) => {
  return (
    <Box position="relative" fontFamily="geograph" maxW={88}>
      {preHeader && (
        <Box position="absolute" bottom="100%" mb={8}>
          <OnboardingWelcomeCardPreHeader preHeader={preHeader} />
        </Box>
      )}
      <Stack spacing={8}>
        <Stack spacing={2}>
          <OnboardingWelcomeCardHeader header={heading} />
          {contentParagraphs && <OnboardingWelcomeCardContentParagraph paragraphs={contentParagraphs} />}
          {contentBullets && <OnboardingWelcomeCardContentBullets bullets={contentBullets} />}
          {contentHighlight && <OnboardingWelcomeCardContentHighlight highlight={contentHighlight} />}
        </Stack>
        <OnboardingWelcomeCardButton cta={cta} onClick={onCtaClick} icon={ctaIcon || undefined} />
        {secondaryActionCta && secondaryActionOnClick && (
          <OnboardingWelcomeCardSecondaryAction
            intro={secondaryActionIntro}
            cta={secondaryActionCta}
            onClick={secondaryActionOnClick}
          />
        )}
      </Stack>
    </Box>
  )
}

const OnboardingWelcomeCardPreHeader = ({ preHeader }: { preHeader: string }) => {
  return (
    <Text fontWeight="medium" fontSize="2xl" lineHeight="1.6rem" letterSpacing="-.018em" color="brand.500">
      {preHeader}
    </Text>
  )
}

const OnboardingWelcomeCardHeader = ({ header }: { header: string }) => {
  return (
    <Heading fontSize="2rem" fontWeight="bold" letterSpacing="-.016em" lineHeight={9} color="black">
      {header}
    </Heading>
  )
}

const OnboardingWelcomeCardContentParagraph = ({ paragraphs }: { paragraphs: string[] }) => {
  return (
    <Stack spacing={1}>
      {paragraphs.map((paragraph, index) => (
        <Text key={index} fontSize="xl" lineHeight="1.6rem" letterSpacing="-.024em" color="black">
          {paragraph}
        </Text>
      ))}
    </Stack>
  )
}

const OnboardingWelcomeCardContentBullets = ({ bullets }: { bullets: string[] }) => {
  return (
    <Box marginLeft="1.5rem">
      <Stack as="ul" spacing={0}>
        {bullets.map((bullet) => (
          <Text as="li" key={bullet} fontSize="xl" lineHeight="1.6rem" letterSpacing="-.024em" color="black">
            {bullet}
          </Text>
        ))}
      </Stack>
    </Box>
  )
}

const OnboardingWelcomeCardContentHighlight = ({ highlight }: { highlight: string }) => {
  return (
    <Text fontWeight="medium" fontSize="2xl" lineHeight="1.6rem" letterSpacing="-.018em" color="brand.500">
      {highlight}
    </Text>
  )
}

interface OnboardingWelcomeCardButtonProps {
  cta: string
  icon?: React.ReactElement | null
  onClick: () => void
}

const OnboardingWelcomeCardButton = ({ cta, icon, onClick }: OnboardingWelcomeCardButtonProps) => {
  return (
    <Button
      size="lg"
      bgColor="black"
      color="white"
      onClick={onClick}
      borderRadius="full"
      leftIcon={icon || undefined}
      _hover={{
        border: "2px solid var(--chakra-colors-brand-600)",
        boxShadow: "0 3px 0 0 var(--chakra-colors-brand-600),0 0 2em 0 var(--chakra-colors-brand-lime)",
        transform: "translateY(-3px)",
        bgGradient: "linear(to-r, #F9FF84, brand.lime, brand.500, #F9FF84)",
        backgroundSize: "200% 100%",
        animation: "gradient 4s linear infinite",
        color: "black",
        "@keyframes gradient": {
          "100%": {
            backgroundPosition: "0% 50%",
          },
          "0%": {
            backgroundPosition: "-200% 50%",
          },
        },
      }}
      _active={{
        boxShadow: "0 0 2em 0 var(--chakra-colors-brand-lime)",
        transform: "translateY(0)",
      }}
    >
      {cta}
    </Button>
  )
}

interface OnboardingWelcomeCardSecondaryActionProps {
  intro?: string
  cta: string
  onClick: () => void
}

const OnboardingWelcomeCardSecondaryAction = ({ intro, cta, onClick }: OnboardingWelcomeCardSecondaryActionProps) => {
  return (
    <Text fontSize="sm" color="black" textAlign="center">
      {intro && <Text as="span">{intro} </Text>}
      <Text as="span" textDecoration="underline" cursor="pointer" onClick={onClick}>
        {cta}
      </Text>
    </Text>
  )
}
