import type {
  ContractCollectorPrivacySetting,
  IntegrationProvider,
  IntegrationSummary,
  User,
  UserWithOrganization,
} from "@brm/schema-types/types.js"
import { ContractCollectorPrivacySettingSchema } from "@brm/schemas"
import { Flags } from "@brm/util/flags.js"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Checkbox,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  // eslint-disable-next-line @typescript-eslint/no-restricted-imports
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import React, { useMemo, useRef, useState, type FunctionComponent } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import {
  useGetIntegrationV1PrivateQuery,
  useGetUserV1EmailDelegatesQuery,
  useGetUserV1SettingsContractCollectorPrivacyQuery,
  useGetUserV1WhoamiQuery,
  usePostIntegrationV1ByIdDisableMutation,
  usePostIntegrationV1ByIdEnableMutation,
  usePostUserV1EmailDelegatesMutation,
  usePostUserV1SettingsContractCollectorPrivacyMutation,
} from "../../app/services/generated-api.js"
import ObjectSelectorFilter from "../../components/DataTable/SchemaFilter/FilterTypes/ObjectSelectorFilter.js"
import { AlertTriangleIcon, MailIcon, MoreMenuIcon, SearchIcon, UsersIcon } from "../../components/icons/icons.js"
import { IntegrationProviderIcon } from "../../components/icons/IntegrationProviderIcon.js"
import { Link } from "../../components/Link.js"
import CreateIntegrationModal from "../integrations/CreateIntegrationModal.js"
import { DeleteIntegrationConfirmationModal } from "../integrations/DeleteIntegrationConfirmationModal.js"
import PersonDisplay from "../person/PersonDisplay.js"
import { SettingsContainer } from "./SettingsContainer.js"
import { SettingsHeader } from "./SettingsHeader.js"

import { useFeatureFlagEnabled } from "posthog-js/react"
export const ContractCollectorSettings: FunctionComponent = () => {
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const { data: contractCollectorPrivacySettings } = useGetUserV1SettingsContractCollectorPrivacyQuery()
  const { data: integrations, isLoading: isIntegrationsLoading } = useGetIntegrationV1PrivateQuery()
  const { data: currentDelegates, isLoading: isCurrentDelegatesLoading } = useGetUserV1EmailDelegatesQuery()

  const integrationByProvider = useMemo((): Map<IntegrationProvider, IntegrationSummary[]> | null => {
    return integrations ? Map.groupBy(integrations, (integration) => integration.provider) : null
  }, [integrations])

  const connectedIntegrations = integrationByProvider?.get("gmail_oauth") ?? []

  if (
    !whoami ||
    !contractCollectorPrivacySettings ||
    !currentDelegates ||
    isIntegrationsLoading ||
    isCurrentDelegatesLoading
  ) {
    return null
  }

  return (
    <ContractCollectorSettingsPage
      whoami={whoami}
      contractCollectorPrivacySettings={contractCollectorPrivacySettings.settings}
      integrations={connectedIntegrations}
      currentDelegates={currentDelegates}
    />
  )
}

const privacySettingIsLite: Record<ContractCollectorPrivacySetting, boolean> = {
  new_legal_agreement: true,
  existing_legal_agreement: true,
  workflow_run: false,
  system_of_record: false,
}

export const ContractCollectorSettingsPage: FunctionComponent<{
  whoami: UserWithOrganization
  contractCollectorPrivacySettings: ContractCollectorPrivacySetting[] | null
  integrations: IntegrationSummary[]
  currentDelegates: User[]
}> = ({ whoami, contractCollectorPrivacySettings, integrations, currentDelegates }) => {
  const intl = useIntl()
  const toast = useToast()
  const privacySettingsEnabled = useFeatureFlagEnabled(Flags.CONTRACT_COLLECTOR_PRIVACY_SETTINGS_FLAG)

  const searchInputRef = useRef<HTMLInputElement>(null)
  const addEmailButtonRef = useRef<HTMLButtonElement>(null)
  const [selectedIntegration, setSelectedIntegration] = useState<IntegrationSummary | null>(null)
  const [disableIntegration] = usePostIntegrationV1ByIdDisableMutation()
  const [enableIntegration] = usePostIntegrationV1ByIdEnableMutation()
  const [updateDelegates] = usePostUserV1EmailDelegatesMutation()
  const [updatePrivacySettings] = usePostUserV1SettingsContractCollectorPrivacyMutation()

  const [searchInput, setSearchInput] = useState("")

  const connectModal = useDisclosure()
  const reconnectModal = useDisclosure()
  const disconnectModal = useDisclosure()

  const gmailIntegrationCardRef = useRef<HTMLDivElement>(null)

  const currentDelegateIds = new Set(currentDelegates?.map((d) => d.id) ?? [])

  const privacySettings: Record<ContractCollectorPrivacySetting, { primaryText: string; description: string }> = {
    new_legal_agreement: {
      primaryText: intl.formatMessage({
        id: "contractCollector.privacySettings.newLegalAgreement.title",
        description: "Title of the new legal agreement privacy setting",
        defaultMessage: "Create new agreements privately",
      }),
      description: intl.formatMessage({
        id: "contractCollector.privacySettings.newLegalAgreement.description",
        description: "Description of the new legal agreement privacy setting",
        defaultMessage: "BRM won’t show new agreements to your team until you verify them.",
      }),
    },
    existing_legal_agreement: {
      primaryText: intl.formatMessage({
        id: "contractCollector.privacySettings.existingLegalAgreement.title",
        description: "Title of the existing legal agreement privacy setting",
        defaultMessage: "Update agreements with new documents privately",
      }),
      description: intl.formatMessage({
        id: "contractCollector.privacySettings.existingLegalAgreement.description",
        description: "Description of the existing legal agreement privacy setting",
        defaultMessage: "BRM won’t show new documents for existing contracts to your team until you verify them.",
      }),
    },
    workflow_run: {
      primaryText: intl.formatMessage({
        id: "contractCollector.privacySettings.workflowRun.title",
        description: "Title of the workflow run privacy setting",
        defaultMessage: "Update active requests with new documents privately",
      }),
      description: intl.formatMessage({
        id: "contractCollector.privacySettings.workflowRun.description",
        description: "Description of the workflow run privacy setting",
        defaultMessage: "BRM won’t show new documents related to active requests to your team until you verify them.",
      }),
    },
    system_of_record: {
      primaryText: intl.formatMessage({
        id: "contractCollector.privacySettings.statementOfRecord.title",
        description: "Title of the statement of record privacy setting",
        defaultMessage: "Update Statement of Record (SoR) with new documents privately",
      }),
      description: intl.formatMessage({
        id: "contractCollector.privacySettings.statementOfRecord.description",
        description: "Description of the statement of record privacy setting",
        defaultMessage:
          "BRM won’t show new documents related to Tool or Vendor fields to your team until you verify them.",
      }),
    },
  }

  return (
    <SettingsContainer>
      <SettingsHeader
        title={intl.formatMessage({
          defaultMessage: "Contract Collector",
          description: "The heading for the user contract collector settings page",
          id: "settings.profile.heading",
        })}
        subtitle={intl.formatMessage({
          defaultMessage:
            "Connect and manage your email accounts to automagically collect and organize vendor documents.",
          description: "The subtitle for the user contract collector settings page",
          id: "settings.profile.subtitle",
        })}
      />
      <Stack spacing={8} maxWidth="720px">
        <Card variant="outline" padding={0} ref={gmailIntegrationCardRef}>
          <CardBody display="flex" flexDirection="column" gap={4} color="gray.700" fontSize="sm">
            <HStack>
              <Card variant="outline" padding={2}>
                <IntegrationProviderIcon integration={{ provider: "gmail_oauth" }} boxSize={8} />
              </Card>
              <Text fontWeight="bold" fontSize="lg">
                <FormattedMessage
                  id="integrations.page.connect.gmail.title"
                  description="Title of the Gmail integration card"
                  defaultMessage="Gmail"
                />
              </Text>
              <Spacer />
              <Button ref={addEmailButtonRef} onClick={connectModal.onOpen} colorScheme="brand" flexShrink={0}>
                <FormattedMessage
                  id="integrations.page.connect.button"
                  description="Button text of integration page connect integration option"
                  defaultMessage="Add Email"
                />
              </Button>
            </HStack>
            {integrations.length > 0 ? (
              <>
                <FormattedMessage
                  id="integrations.page.connect.gmail.description"
                  description="Description of the Gmail integration card"
                  defaultMessage="BRM is automagically finding vendor documents from the following emails:"
                />
                <Card variant="outline" marginTop={2} overflow="hidden">
                  {integrations.map((integration) => (
                    <HStack
                      key={integration.id}
                      padding={2}
                      backgroundColor={
                        integration.status === "disconnected" || integration.status === "deleting"
                          ? "warning.50"
                          : integration.status === "disabled"
                            ? "gray.100"
                            : "white"
                      }
                    >
                      <Icon as={MailIcon} />
                      <Text as="span" color="gray.800" fontSize="xs" fontWeight="medium" isTruncated>
                        {integration.email}
                      </Text>
                      {integration.status === "disconnected" || integration.status === "deleting" ? (
                        <Tooltip
                          label={
                            integration.status === "disconnected"
                              ? intl.formatMessage({
                                  id: "integrations.page.disconnected.tooltip",
                                  description: "Tooltip text of integration page disconnected integration option",
                                  defaultMessage:
                                    "The integration has failed and won’t retrieve new data until it is reconnected.",
                                })
                              : intl.formatMessage({
                                  id: "integrations.page.deleting.tooltip",
                                  description: "Tooltip text of integration page deleting integration option",
                                  defaultMessage: "The integration is being disconnected",
                                })
                          }
                          shouldWrapChildren
                        >
                          <Icon as={AlertTriangleIcon} color="warning.600" />
                        </Tooltip>
                      ) : integration.status === "disabled" ? (
                        <Tooltip
                          label={intl.formatMessage({
                            id: "integrations.page.disabled.tooltip",
                            description: "Tooltip text of integration page disabled integration option",
                            defaultMessage: "The integration is paused and won’t retrieve new data.",
                          })}
                          shouldWrapChildren
                        >
                          <Icon as={AlertTriangleIcon} />
                        </Tooltip>
                      ) : null}
                      <Spacer />
                      <Text as="span" color="gray.600" fontSize="xs" isTruncated>
                        {integration.detail_text}
                      </Text>
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          size="xs"
                          icon={<Icon as={MoreMenuIcon} />}
                          aria-label={intl.formatMessage({
                            id: "contractCollector.connectedIntegration.more",
                            description: "Aria label of integration page connected integration more actions option",
                            defaultMessage: "More",
                          })}
                          variant="ghost"
                        />
                        <Portal>
                          <MenuList>
                            {integration.status === "disconnected" && (
                              <MenuItem
                                onClick={() => {
                                  setSelectedIntegration(integration)
                                  reconnectModal.onOpen()
                                }}
                              >
                                <FormattedMessage
                                  id="integrations.page.connect.reconnect"
                                  description="Button text of integration page connect integration option"
                                  defaultMessage="Reconnect"
                                />
                              </MenuItem>
                            )}
                            {integration.status !== "disconnected" && (
                              <MenuItem
                                onClick={async () => {
                                  if (integration.status === "disabled") {
                                    await enableIntegration({ id: integration.id })
                                  } else {
                                    await disableIntegration({ id: integration.id })
                                  }
                                }}
                              >
                                {integration.status === "disabled" ? (
                                  <FormattedMessage
                                    id="integrations.page.connect.gmail.enable"
                                    description="Button text of integration page resume integration option"
                                    defaultMessage="Resume"
                                  />
                                ) : (
                                  <FormattedMessage
                                    id="integrations.page.connect.gmail.disable"
                                    description="Button text of integration page disable integration option"
                                    defaultMessage="Disable"
                                  />
                                )}
                              </MenuItem>
                            )}

                            <MenuItem
                              onClick={() => {
                                setSelectedIntegration(integration)
                                disconnectModal.onOpen()
                              }}
                            >
                              <FormattedMessage
                                id="integrations.page.connect.disconnect"
                                description="Button text of integration page connect integration option"
                                defaultMessage="Disconnect"
                              />
                            </MenuItem>
                          </MenuList>
                        </Portal>
                      </Menu>
                    </HStack>
                  ))}
                </Card>
                {privacySettingsEnabled && (
                  <React.Fragment>
                    <Text fontWeight="medium" color="gray.800" fontSize="md">
                      <FormattedMessage
                        id="contractCollector.privacySettings.title"
                        description="Title of the privacy settings card"
                        defaultMessage="Privacy Controls"
                      />
                    </Text>
                    {ContractCollectorPrivacySettingSchema.enum
                      .filter((setting) => !whoami.organization.is_lite || privacySettingIsLite[setting])
                      .map((setting) => (
                        <React.Fragment key={setting}>
                          <HStack alignItems="start">
                            <Checkbox
                              onChange={async ({ target: { checked } }) => {
                                const checkedSettings = new Set(contractCollectorPrivacySettings ?? [])
                                if (checked) {
                                  checkedSettings.add(setting)
                                } else {
                                  checkedSettings.delete(setting)
                                }
                                await updatePrivacySettings({ body: { settings: Array.from(checkedSettings) } })
                              }}
                              isChecked={contractCollectorPrivacySettings?.includes(setting)}
                            />
                            <Stack gap={1}>
                              <Text as="span" fontWeight="medium" color="gray.800">
                                {privacySettings[setting].primaryText}
                              </Text>
                              <Text as="span" fontSize="sm" color="gray.600">
                                {privacySettings[setting].description}
                              </Text>
                            </Stack>
                          </HStack>
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                )}
              </>
            ) : (
              <FormattedMessage
                id="integrations.page.connect.gmail.description.noIntegrations"
                description="Description of the Gmail integration card with no connected integrations"
                defaultMessage="Connect your Gmail account to allow BRM to gather your contracts automagically."
              />
            )}
          </CardBody>
          {integrations.length > 0 && (
            <CardFooter borderTopWidth="1px" paddingY={4} justifyContent="center">
              <Button as={Link} fontSize="sm" variant="link" colorScheme="brand" to="/agreements?source=email">
                <FormattedMessage
                  id="integrations.page.goToAgreements.button"
                  description="Button text of integration page go to agreements option"
                  defaultMessage="Go to agreements"
                />
              </Button>
            </CardFooter>
          )}
        </Card>
        <Card variant="outline">
          <CardBody display="flex" flexDirection="column" gap={4}>
            <HStack>
              <Card variant="outline" padding={3}>
                <Icon as={UsersIcon} boxSize={6} />
              </Card>
              <Text fontWeight="bold" fontSize="lg">
                <FormattedMessage
                  id="contractCollector.delegateAccess.title"
                  description="Title of the delegate access card"
                  defaultMessage="Delegate Access"
                />
              </Text>
              <Spacer />
              <Popover isLazy initialFocusRef={searchInputRef} placement="bottom-end" closeOnBlur>
                <PopoverTrigger>
                  <Button variant="solid" fontSize="sm" colorScheme="brand">
                    <FormattedMessage
                      id="integrations.page.connect.addDelegates"
                      defaultMessage="Add delegates"
                      description="Label for button to add delegates to integration"
                    />
                  </Button>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent>
                    <Flex background="white" zIndex={1} borderBottomWidth="1px" p={2} gap={1} alignItems="center">
                      <InputGroup>
                        <InputLeftElement>
                          <Icon as={SearchIcon} />
                        </InputLeftElement>
                        <Input
                          ref={searchInputRef}
                          type="search"
                          onChange={(e) => setSearchInput(e.target.value)}
                          autoFocus
                        />
                      </InputGroup>
                    </Flex>
                    <ObjectSelectorFilter
                      searchInput={searchInput}
                      excludedValues={new Set([...currentDelegateIds, ...(whoami ? [whoami.id] : [])])}
                      onChange={async (value) => {
                        try {
                          currentDelegateIds.add(value)
                          await updateDelegates({
                            body: { delegate_ids: Array.from(currentDelegateIds) },
                          })
                          toast({
                            status: "success",
                            description: intl.formatMessage({
                              id: "contractCollector.delegateAccess.add.success",
                              defaultMessage: "Delegate added",
                              description: "Success message for adding delegate",
                            }),
                          })
                        } catch {
                          toast({
                            status: "error",
                            description: intl.formatMessage({
                              id: "contractCollector.delegateAccess.add.error",
                              defaultMessage: "Failed to add delegate",
                              description: "Error message for failed to add delegate",
                            }),
                          })
                        }
                      }}
                      selectedValues={currentDelegateIds}
                      selectable={{
                        objectType: "User",
                        permission: {
                          value: "legal:update",
                          includeAll: false,
                        },
                      }}
                    />
                  </PopoverContent>
                </Portal>
              </Popover>
            </HStack>
            <Text as="span" fontSize="sm" color="gray.600">
              <FormattedMessage
                id="contractCollector.delegateAccess.description"
                description="Description of the delegate access card"
                defaultMessage="Delegates will have access to review and approve agreements found in your inbox before others in your organization can see them. Delegates can verify and approve these agreements on your behalf, but cannot modify your email settings or add other delegates. Users must have the Legal role enabled in order to be selected as a delegate."
              />
            </Text>
            {currentDelegates.length > 0 ? (
              <Card variant="outline" marginTop={2} overflow="hidden">
                {currentDelegates.map((currentDelegate) => (
                  <HStack key={currentDelegate.id} padding={2}>
                    <PersonDisplay person={currentDelegate.person} subText={currentDelegate.email} />
                    <Spacer />
                    <Button
                      variant="ghost"
                      colorScheme="error"
                      onClick={async () => {
                        try {
                          currentDelegateIds.delete(currentDelegate.id)
                          await updateDelegates({ body: { delegate_ids: Array.from(currentDelegateIds) } })
                          toast({
                            status: "success",
                            description: intl.formatMessage({
                              id: "contractCollector.delegateAccess.remove.success",
                              defaultMessage: "Delegate removed",
                              description: "Success message for removing delegate",
                            }),
                          })
                        } catch {
                          toast({
                            status: "error",
                            description: intl.formatMessage({
                              id: "contractCollector.delegateAccess.remove.error",
                              defaultMessage: "Failed to remove delegate",
                              description: "Error message for failed to remove delegate",
                            }),
                          })
                        }
                      }}
                    >
                      <FormattedMessage
                        id="contractCollector.delegateAccess.remove"
                        defaultMessage="Remove"
                        description="Label for button to remove delegate from integration"
                      />
                    </Button>
                  </HStack>
                ))}
              </Card>
            ) : null}
          </CardBody>
        </Card>
        <CreateIntegrationModal integrationProvider="gmail_oauth" {...connectModal} />
        {selectedIntegration && disconnectModal.isOpen && (
          <DeleteIntegrationConfirmationModal {...disconnectModal} integrationBeingDeleted={selectedIntegration} />
        )}
        {selectedIntegration && reconnectModal.isOpen && (
          <CreateIntegrationModal
            integrationId={selectedIntegration.id}
            integrationProvider="gmail_oauth"
            {...reconnectModal}
          />
        )}
      </Stack>
    </SettingsContainer>
  )
}
