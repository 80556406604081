import type { NegotiationConversation } from "@brm/schema-types/types.js"
import { Center, Flex, Heading, Spinner, Stack, chakra } from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import { useCallback, useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import {
  useGetNegotiationV1ByIdQuery,
  usePostNegotiationV1RegenerateMutation,
  usePostNegotiationV1ResetMutation,
  type Email,
} from "../../app/services/generated-api.js"
import Chat from "../../features/betsy/Chat.js"
import FullTextSearch from "../../features/search/FullTextSearch.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import { ToolLogo, VendorLogo } from "../icons/Logo.js"
import { Link } from "../Link.js"

const Negotiation = () => {
  const navigate = useNavigate()
  const { vendorId, negotiationId } = useParams()
  const { data: negotiation, isLoading } = useGetNegotiationV1ByIdQuery(
    negotiationId ? { id: negotiationId } : skipToken
  )

  const [regenerateNegotiation] = usePostNegotiationV1RegenerateMutation()
  const [resetNegotiation] = usePostNegotiationV1ResetMutation()

  const lastConversation = negotiation?.conversations.at(-1)
  const [negotiationConversationId, setNegotiationConversationId] = useState(
    lastConversation?.sent_email ? undefined : lastConversation?.id
  )
  const negotiationConversation = negotiationConversationId
    ? negotiation?.conversations.find((c) => c.id === negotiationConversationId)
    : undefined

  useEffect(() => {
    if (!negotiationId) {
      navigate(`/negotiations/${vendorId}/${crypto.randomUUID()}`)
    }
  }, [negotiationId, vendorId, navigate])

  const onReset = useCallback(
    async (requestParams: { replyToEmailId?: string }) => {
      if (!negotiation) return
      const response = await resetNegotiation({
        body: {
          negotiation_id: negotiation.id,
          reply_to_email_id: requestParams.replyToEmailId,
        },
      }).unwrap()
      setNegotiationConversationId(response?.negotiation_conversation?.id)
      return response?.negotiation_conversation
    },
    [resetNegotiation, negotiation]
  )

  const onSelectReplyToEmail = useCallback(
    async (email: Email): Promise<NegotiationConversation | undefined> => {
      const existingConversation = negotiation?.conversations.find((c) => c.reply_to_email?.id === email.id)
      if (existingConversation) {
        setNegotiationConversationId(existingConversation.id)
        return existingConversation
      }
      return await onReset({ replyToEmailId: email.id })
    },
    [onReset, negotiation]
  )

  if (isLoading) {
    return (
      <Center h="100%" flex={1}>
        <Spinner size="md" />
      </Center>
    )
  }

  if (!vendorId || !negotiation) {
    return null
  }

  return (
    <Stack height="100%" width="100%" gap={0}>
      <Flex
        px={6}
        py={2}
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="gray.100"
      >
        <Flex alignItems="center" gap={2}>
          <Heading
            size="md"
            display="flex"
            alignItems="baseline"
            justifyContent="center"
            flexDirection="row"
            gap={2}
            fontWeight="bold"
            color="gray.900"
          >
            <FormattedMessage
              id="negotiation.title"
              defaultMessage="Negotiate {with} {toolOrVendor}"
              description="Negotiation title"
              values={{
                toolOrVendor: (
                  <Link
                    to={negotiation?.tool ? `/tools/${negotiation?.tool?.id}` : `/vendors/${negotiation?.vendor.id}`}
                    target="_blank"
                    variant="outline"
                    size="lg"
                    fontSize="4xl"
                    gap={2}
                    fontWeight="bold"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="row"
                  >
                    {negotiation?.tool ? negotiation?.tool.display_name : negotiation?.vendor.display_name}
                    {negotiation?.tool ? (
                      <ToolLogo
                        logo={getPublicImageGcsUrl(negotiation?.tool.image_asset?.gcs_file_name)}
                        borderColor="gray.200"
                        borderWidth={1}
                        borderStyle="solid"
                        boxSize={10}
                      />
                    ) : (
                      <VendorLogo
                        logo={getPublicImageGcsUrl(negotiation?.vendor.image_asset?.gcs_file_name)}
                        borderRadius="full"
                        borderColor="gray.200"
                        borderWidth={1}
                        borderStyle="solid"
                        boxSize={10}
                      />
                    )}
                  </Link>
                ),
                with: (
                  <chakra.span as="span" fontSize="3xl" color="gray.600" fontWeight="normal">
                    <FormattedMessage id="negotiation.with" defaultMessage="with" description="with" />
                  </chakra.span>
                ),
              }}
            />
          </Heading>
        </Flex>
        <FullTextSearch />
      </Flex>
      <Chat
        showAgentTelemetry={negotiationConversation?.show_agent_telemetry ?? false}
        conversation={negotiationConversation?.betsy_conversation ?? { id: crypto.randomUUID() }}
        onSubmitStreamingUrl={`${import.meta.env.VITE_API_BASE_URL}/negotiation/v1/compose`}
        onRegenerate={async () => {
          if (!negotiationConversationId) return
          await regenerateNegotiation({
            body: {
              negotiation_conversation_id: negotiationConversationId,
            },
          }).unwrap()
        }}
        onReset={onReset}
        addOns={{
          negotiation: {
            enabled: true,
            negotiationConversation,
            negotiation,
            onSelectReplyToEmail,
            onSendSuccess: () => setNegotiationConversationId(undefined),
          },
        }}
        additionalRequestParams={{
          negotiation_conversation_id: negotiationConversationId,
          vendor_id: vendorId,
        }}
      />
    </Stack>
  )
}

export default Negotiation
