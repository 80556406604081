import { isDisplayableIntegrationType } from "@brm/schema-helpers/integrations.js"
import { isBooleanWithRichTextDetails, isRichTextType } from "@brm/schema-helpers/rich-text/rich-text.js"
import {
  isDocumentOrURLStringType,
  isDocumentType,
  isFrequencyOrOneTimeType,
  isWorkflowRunProgressType,
} from "@brm/schema-helpers/schema.js"
import type { FieldMetadataWithSuggestions, LegalAgreementListItem, PersonStatus } from "@brm/schema-types/types.js"
import { DocumentMinimalSchema, HttpUrlString } from "@brm/schemas"
import { isObjectWithIdAndObjectType } from "@brm/type-helpers/object.js"
import {
  isApplicableWithDocumentType,
  isCompliantWithDocumentType,
  isCurrencyAmountType,
  isIntegrationType,
  isPersonType,
  isReceiptType,
  isSpendSummaryType,
  isToolType,
  isUserType,
  isUserWithProviderType,
  isVendorType,
} from "@brm/type-helpers/schema.js"
import { displayPersonName } from "@brm/util/names.js"
import {
  getEnumMemberTitle,
  getEnumOptions,
  getTitle,
  isEnumArrayType,
  isEnumType,
  isNumericStringType,
  isURLType,
} from "@brm/util/schema.js"
import { hasDisplayName, isObject } from "@brm/util/type-guard.js"
import type { DropSuffix } from "@brm/util/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import {
  Avatar,
  HStack,
  Icon,
  Tooltip,
  chakra,
  type AvatarProps,
  type LayoutProps,
  type SpaceProps,
} from "@chakra-ui/react"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import { capitalCase } from "change-case"
import React, { Fragment, useEffect, type ReactNode } from "react"
import { FormattedList, FormattedMessage, FormattedNumber, useIntl } from "react-intl"
import { isDefined } from "ts-is-present"
import type { ReadonlyDeep } from "type-fest"
import PersonStatusBadge from "../../features/person/PersonStatusBadge.js"
import ScopeTag from "../../features/security/ScopeTag.js"
import { AUTO_SELECT_STEP_HASH } from "../../features/workflows/constants.js"
import { NestedBadge } from "../../features/workflows/run/NestedBadge.js"
import { workflowRunStatusColors } from "../../features/workflows/run/status-colors.js"
import { getCurrencyAmountsFromParent } from "../../util/currency.js"
import { isToolListingType, isWorkflowRunType, pathForWorkflowRun } from "../../util/json-schema.js"
import { log } from "../../util/logger.js"
import { getDisplayUrl, getPublicImageGcsUrl } from "../../util/url.js"
import MoneyCell from "../DataTable/CellRenderer/MoneyCell.js"
import OwnerCell from "../DataTable/CellRenderer/OwnerCell.js"
import ToolCell from "../DataTable/CellRenderer/ToolCell.js"
import UserWithProviderBadge from "../DataTable/CellRenderer/UserWithProviderBadge.js"
import VendorCell from "../DataTable/CellRenderer/VendorCell.js"
import { FileTypeIcon } from "../Document/FileTypeIcon.js"
import { EnumBadge } from "../EnumBadge.js"
import { FormattedDate, FormattedDuration, FormattedFrequency } from "../FormattedDate.js"
import { FormattedMultiCurrency } from "../FormattedMultiCurrency.js"
import { Link, LinkOrSpan } from "../Link.js"
import RichTextDisplay from "../RichTextEditor/RichTextDisplay.js"
import { Timestamp } from "../Timestamp.js"
import UpdateStatusBadge, { type StatusField } from "../UpdateStatusBadge.js"
import { DisplayableIntegrationTypeIcon, IntegrationProviderIcon } from "../icons/IntegrationProviderIcon.js"
import { ToolLogo } from "../icons/Logo.js"
import { ImageUserIcon, ReceiptIcon, RequestIcon, UserIcon } from "../icons/icons.js"
import { GmailIcon } from "../icons/provider-icons.js"
import FieldSource from "./FieldSource.js"

// const EXTRACTED_FIELD_SOURCES: string[] = ["derived", "document", "transaction"] satisfies FieldSourceType[]

const DEFAULT_TOOL_TIP_DELAY = 500
const DEFAULT_PLACEMENT = "top"

export const FieldValue = ({
  value,
  parentValue,
  fieldSchema,
  fieldPath,
  fieldLabel,
  logoSize = 4,
  avatarSize = "xs",
  getDocumentDownloadUrl,
  fieldMetadata,
}: {
  value: unknown
  parentValue?: unknown
  fieldSchema: ReadonlyDeep<JSONSchema> | undefined
  fieldPath: readonly (string | number)[]
  fieldLabel?: string
  avatarSize?: AvatarProps["size"]
  logoSize?: number
  getDocumentDownloadUrl?: (fieldPath: readonly (string | number)[]) => string
  fieldMetadata?: FieldMetadataWithSuggestions
}): JSX.Element | JSX.Element[] | (string | JSX.Element)[] | string | undefined => {
  const intl = useIntl()
  const schemaWithValue = { schema: fieldSchema, value }
  const fieldName = fieldPath.at(-1)?.toString()
  const [richTextRenderKey, setRichTextRenderKey] = React.useState(0)

  useEffect(() => {
    // RichTextEditor is an uncontrolled component that needs to be re-rendered when the value changes.
    // Without this, Rich Text Editors in the table will not update when filtering or sorting.
    setRichTextRenderKey((key) => key + 1)
  }, [value])

  // Put custom ObjectType and fieldName overrides here
  // Can move each ObjectType to separate components if it starts to get too big.
  if (isObjectWithIdAndObjectType(parentValue)) {
    switch (parentValue.object_type) {
      case "Tool":
      case "Vendor": {
        // Tool/Vendor Inline-editable fields
        // TODO in the future, inline editing could be more generic instead of special-casing field-by-field
        if (fieldName === "owner" && isPersonType(fieldSchema, value)) {
          return (
            <OwnerCell
              objectId={parentValue.id}
              objectType={parentValue.object_type}
              owner={value ?? null}
              showTooltip
            />
          )
        }

        if (
          (fieldName === "status" ||
            fieldName === "it_status" ||
            fieldName === "compliance_status" ||
            fieldName === "finance_status") &&
          isEnumType(schemaWithValue)
        ) {
          const enumOptions = getEnumOptions(schemaWithValue.schema)
          const categoryStatus =
            fieldName !== "status"
              ? (fieldName.replace(/_status$/u, "") as DropSuffix<typeof fieldName, "_status">)
              : undefined
          const updatePermissions = [
            `${parentValue.object_type.toLowerCase() as Lowercase<typeof parentValue.object_type>}:update` as const,
            categoryStatus && (`${categoryStatus}:update` as const),
          ]
          return (
            <UpdateStatusBadge<string, StatusField>
              status={schemaWithValue.value}
              objectType={parentValue.object_type}
              objectId={parentValue.id}
              fieldName={fieldName}
              displayStatus={(status) => getEnumMemberTitle(status, schemaWithValue.schema)}
              options={enumOptions.map((option) => (typeof option === "string" ? option : option.const))}
              statusColors={Object.fromEntries(
                enumOptions.map((option) => [
                  typeof option === "string" ? option : option.const,
                  (isObject(option) && "colorScheme" in option && (option.colorScheme as string)) || "gray",
                ])
              )}
              updatePermissions={updatePermissions.filter(isDefined)}
            />
          )
        }
        break
      }
      case "Person": {
        // Can remove this when we handle icon rendering for enum statuses
        if (fieldName === "status" && isEnumType(schemaWithValue)) {
          return <PersonStatusBadge status={schemaWithValue.value as PersonStatus | null} />
        }
        break
      }
      case "LegalAgreement":
        if (fieldName === "source" && isEnumArrayType(schemaWithValue)) {
          const parent = parentValue as LegalAgreementListItem
          return (
            <HStack gap={1}>
              {parent.source.map((source) => {
                switch (source) {
                  case "email":
                    return (
                      <Tooltip
                        key={source}
                        label={intl.formatMessage(
                          {
                            id: "field.value.legalAgreement.source.email",
                            description: "Tooltip for email source",
                            defaultMessage: "Agreement collected from Gmail: {emails}",
                          },
                          {
                            emails: intl.formatList(
                              parent.email_source.map((userWithProvider) => displayPersonName(userWithProvider, intl))
                            ),
                          }
                        )}
                        shouldWrapChildren
                      >
                        <Icon as={GmailIcon} />
                      </Tooltip>
                    )
                  case "transaction":
                    return (
                      <Tooltip
                        key={source}
                        label={intl.formatMessage({
                          id: "field.value.legalAgreement.source.transaction",
                          description: "Tooltip for transaction source",
                          defaultMessage: "Agreement created from transactions",
                        })}
                        shouldWrapChildren
                      >
                        <Icon as={ReceiptIcon} />
                      </Tooltip>
                    )
                  case "workflow_run":
                    return (
                      <Tooltip
                        key={source}
                        label={intl.formatMessage(
                          {
                            id: "field.value.legalAgreement.source.request",
                            description: "Tooltip for request source",
                            defaultMessage: "Agreement created from {request}",
                          },
                          { request: parent.workflow_run_source?.display_name }
                        )}
                        shouldWrapChildren
                      >
                        <Icon as={RequestIcon} />
                      </Tooltip>
                    )
                  case "manual":
                    return (
                      <Tooltip
                        key={source}
                        label={
                          parent.created_by
                            ? intl.formatMessage(
                                {
                                  id: "field.value.legalAgreement.source.manual",
                                  description: "Tooltip for manual source",
                                  defaultMessage: "Agreement created by {user}",
                                },
                                { user: displayPersonName(parent.created_by, intl) }
                              )
                            : intl.formatMessage({
                                id: "field.value.legalAgreement.source.manual",
                                description: "Tooltip for manual source",
                                defaultMessage: "Agreement created manually",
                              })
                        }
                        shouldWrapChildren
                      >
                        <Icon as={ImageUserIcon} />
                      </Tooltip>
                    )
                  default:
                    unreachable(source)
                }
              })}
            </HStack>
          )
        }
        break
    }
  }

  if (value === null || value === undefined) {
    return intl.formatMessage({
      id: "field.value.empty",
      description: "Empty field value",
      defaultMessage: "Empty Value",
    })
  }
  if (isObject(fieldSchema)) {
    // Login scopes
    if (fieldPath.at(-1) === "scopes" && Array.isArray(value)) {
      return value.map(({ scope }) => (
        <Fragment key={scope}>
          <ScopeTag scope={scope} />{" "}
        </Fragment>
      ))
    }

    if (fieldSchema.type === "boolean") {
      return value ? (
        <ValueWithExtractedIcon
          fieldName={fieldName}
          value={intl.formatMessage({
            id: "field.boolean.yes",
            description: "Boolean field value `yes`",
            defaultMessage: "Yes",
          })}
          fieldMetadata={fieldMetadata}
        />
      ) : (
        <ValueWithExtractedIcon
          fieldName={fieldName}
          value={intl.formatMessage({
            id: "field.boolean.no",
            description: "Boolean field value `no`",
            defaultMessage: "No",
          })}
          fieldMetadata={fieldMetadata}
        />
      )
    }

    if (fieldSchema.type === "integer" && typeof value === "number") {
      return <ValueWithExtractedIcon fieldName={fieldName} value={value} fieldMetadata={fieldMetadata} />
    }
    if (
      (fieldSchema.type === "number" || isNumericStringType(fieldSchema, value)) &&
      (typeof value === "number" || typeof value === "string")
    ) {
      return (
        <ValueWithExtractedIcon
          fieldName={fieldName}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          value={<FormattedNumber value={value as any} style={fieldSchema.numberStyle} />}
          fieldMetadata={fieldMetadata}
        />
      )
    }

    if (isFrequencyOrOneTimeType(fieldSchema, value)) {
      return (
        <ValueWithExtractedIcon
          fieldName={fieldName}
          value={<FormattedFrequency value={value} />}
          fieldMetadata={fieldMetadata}
        />
      )
    }

    if (fieldSchema.type === "string" && typeof value === "string") {
      if (fieldName?.endsWith("created_at") || fieldName === "updated_at") {
        return <Timestamp dateTime={value} />
      }
      if (fieldSchema.format === "date" || fieldSchema.format === "date-time") {
        return (
          <ValueWithExtractedIcon
            fieldName={fieldName}
            value={<FormattedDate value={value} />}
            fieldMetadata={fieldMetadata}
          />
        )
      }
      if (fieldSchema.format === "duration") {
        return (
          <ValueWithExtractedIcon
            fieldName={fieldName}
            value={<FormattedDuration value={value} />}
            fieldMetadata={fieldMetadata}
          />
        )
      }
      if (isURLType(fieldSchema, value)) {
        try {
          const url = new URL(value, window.location.href)
          return (
            <ValueWithExtractedIcon
              fieldName={fieldName}
              value={
                <Tooltip label={url.href} placement={DEFAULT_PLACEMENT} openDelay={DEFAULT_TOOL_TIP_DELAY}>
                  <Link to={url.href} rel="noreferrer noopener">
                    {getDisplayUrl(url)}
                  </Link>
                </Tooltip>
              }
              fieldMetadata={fieldMetadata}
            />
          )
        } catch {
          return (
            <Tooltip
              placement={DEFAULT_PLACEMENT}
              openDelay={DEFAULT_TOOL_TIP_DELAY}
              label={intl.formatMessage({
                id: "link.invalid.tooltip",
                description: "Tooltip text explaining that a link is not clickable because it is invalid",
                defaultMessage: "Invalid URL",
              })}
            >
              <LinkOrSpan>{value}</LinkOrSpan>
            </Tooltip>
          )
        }
      }

      if (fieldSchema.singleLine) {
        return (
          <Tooltip label={value} shouldWrapChildren openDelay={DEFAULT_TOOL_TIP_DELAY} placement={DEFAULT_PLACEMENT}>
            <ValueWithExtractedIcon fieldName={fieldName} value={value} fieldMetadata={fieldMetadata} />
          </Tooltip>
        )
      }

      // There eventually be custom field categories so field_category will eventually not be able to have an enum typecheck for
      if (
        fieldName === "field_category" &&
        isObject(parentValue) &&
        "field_name" in parentValue &&
        typeof parentValue.field_name === "string"
      ) {
        return (
          <Tooltip
            label={intl.formatMessage(
              {
                id: "field.value.fieldCategory",
                description: "Tooltip for field category",
                defaultMessage: "Uploaded to {field_name}",
              },
              { field_name: capitalCase(parentValue.field_name) }
            )}
            shouldWrapChildren
            openDelay={DEFAULT_TOOL_TIP_DELAY}
            placement={DEFAULT_PLACEMENT}
          >
            {capitalCase(value)}
          </Tooltip>
        )
      }

      return (
        <Tooltip label={value} shouldWrapChildren openDelay={DEFAULT_TOOL_TIP_DELAY} placement={DEFAULT_PLACEMENT}>
          {value}
        </Tooltip>
      )
    }

    if (isRichTextType(fieldSchema, value)) {
      return <RichTextDisplay key={richTextRenderKey} content={value} />
    }
    if (isBooleanWithRichTextDetails(fieldSchema, value)) {
      if (typeof value.value !== "boolean") {
        return intl.formatMessage({
          id: "field.value.empty",
          description: "Empty field value",
          defaultMessage: "Empty Value",
        })
      }
      if (value.value) {
        return value.details
          ? intl.formatMessage({
              id: "field.boolean.yes.withDetails",
              description: "Boolean field value `yes` with accompanying details",
              defaultMessage: "Yes with details",
            })
          : intl.formatMessage({
              id: "field.boolean.yes",
              description: "Boolean field value `yes`",
              defaultMessage: "Yes",
            })
      }
      return intl.formatMessage({
        id: "field.boolean.no",
        description: "Boolean field value `no`",
        defaultMessage: "No",
      })
    }
    if (isCurrencyAmountType(fieldSchema, value)) {
      const amountsPerCurrency = getCurrencyAmountsFromParent(parentValue, fieldName)
      return (
        <ValueWithExtractedIcon
          fieldName={fieldName}
          value={<FormattedMultiCurrency currencyAmount={value} amountsPerCurrency={amountsPerCurrency} />}
          fieldMetadata={fieldMetadata}
        />
      )
    }
    if (isSpendSummaryType(fieldSchema, value)) {
      const currencyAmounts = getCurrencyAmountsFromParent(parentValue, fieldName)
      return (
        <MoneyCell
          currencyAmount={value.currency_amount}
          amountsPerCurrency={currencyAmounts}
          percentGrowth={value.growth}
        />
      )
    }

    if (isWorkflowRunProgressType(schemaWithValue)) {
      const { schema, value } = schemaWithValue
      const enumOption = getEnumOptions(schema.properties.status).find((o) => o.const === value.status)
      return (
        <NestedBadge
          colorScheme={enumOption?.colorScheme}
          leftBadgeContent={getTitle(value.status, enumOption)}
          rightContent={
            <FormattedMessage
              id="workflowRunStatusCell.approval.status"
              defaultMessage="{approvalsGiven}/{approvalsRequired} Approvals"
              description="Approvals given / required for workflow run status cell"
              values={{
                approvalsGiven: value.approvals_given,
                approvalsRequired: value.approvals_required,
              }}
            />
          }
        />
      )
    }

    if (isEnumArrayType(schemaWithValue)) {
      const parentValue = schemaWithValue.value
      return (
        <ValueWithExtractedIcon
          fieldName={fieldName}
          value={
            <>
              {schemaWithValue.value.map((value, index) => (
                <React.Fragment key={value}>
                  <FieldValue
                    fieldLabel={fieldLabel}
                    value={value}
                    fieldPath={[...fieldPath, index]}
                    fieldSchema={fieldSchema.items}
                    logoSize={logoSize}
                    parentValue={parentValue}
                  />{" "}
                </React.Fragment>
              ))}
            </>
          }
          fieldMetadata={fieldMetadata}
        />
      )
    }
    if (isEnumType(schemaWithValue)) {
      const { schema, value } = schemaWithValue
      if (isDisplayableIntegrationType(value)) {
        return <DisplayableIntegrationTypeIcon type={value} boxSize={logoSize} />
      }
      return (
        <ValueWithExtractedIcon
          fieldName={fieldName}
          value={<EnumBadge value={value} schema={schema} />}
          fieldMetadata={fieldMetadata}
        />
      )
    }
    const logoProps: LayoutProps & SpaceProps = {
      boxSize: logoSize,
      mr: Math.floor(logoSize / 4),
      display: "inline-flex",
      verticalAlign: "middle",
      marginTop: "-0.2em",
    }
    if (isUserType(fieldSchema, value)) {
      if (isUserWithProviderType(fieldSchema, value) && value.integration_provider === "gmail_oauth") {
        return <UserWithProviderBadge user={value} />
      }

      const name = displayPersonName(value, intl)
      return (
        <Link to={`/people/${value.person.id}`}>
          <Avatar
            {...logoProps}
            size={avatarSize}
            src={getPublicImageGcsUrl(value.profile_image?.gcs_file_name)}
            icon={<Icon as={UserIcon} />}
            name={name}
          />
          <chakra.span fontWeight="medium">{name}</chakra.span>
        </Link>
      )
    }
    if (isPersonType(fieldSchema, value)) {
      const name = displayPersonName(value, intl)
      return (
        <Link to={`/people/${value.id}`}>
          <Avatar
            {...logoProps}
            size={avatarSize}
            src={getPublicImageGcsUrl(value.profile_image?.gcs_file_name)}
            icon={<Icon as={UserIcon} />}
            name={name}
          />
          <chakra.span fontWeight="medium">{name}</chakra.span>
        </Link>
      )
    }
    if (isVendorType(fieldSchema, value)) {
      return <VendorCell vendor={value} />
    }
    if (isToolType(fieldSchema, value)) {
      return <ToolCell tool={value} />
    }
    if (isToolListingType(fieldSchema, value)) {
      return (
        <ValueWithExtractedIcon
          fieldName={fieldName}
          value={
            <>
              <ToolLogo {...logoProps} logo={getPublicImageGcsUrl(value.image_asset?.gcs_file_name)} />
              <chakra.span fontWeight="medium">{value.display_name}</chakra.span>
            </>
          }
          fieldMetadata={fieldMetadata}
        />
      )
    }
    if (isWorkflowRunType(fieldSchema, value)) {
      return (
        <NestedBadge
          as={Link}
          variant="clickable"
          to={{ pathname: pathForWorkflowRun(value), hash: AUTO_SELECT_STEP_HASH }}
          colorScheme={workflowRunStatusColors[value.status]}
          rightContent={value.display_name}
        />
      )
    }
    if (isReceiptType(fieldSchema, value)) {
      return (
        <FieldValue
          fieldLabel={fieldLabel}
          value={value.document}
          fieldPath={[...fieldPath, "document"]}
          fieldSchema={DocumentMinimalSchema}
          logoSize={logoSize}
        />
      )
    }
    if (isIntegrationType(fieldSchema, value)) {
      return <IntegrationProviderIcon integration={value} boxSize={logoSize} enableLink />
    }
    if (isDocumentType(fieldSchema, value)) {
      return (
        <Tooltip label={value.file_name} placement={DEFAULT_PLACEMENT} openDelay={DEFAULT_TOOL_TIP_DELAY}>
          <LinkOrSpan to={getDocumentDownloadUrl?.(fieldPath)} variant="highlighted">
            <FileTypeIcon {...logoProps} mimeType={value.mime_type} boxSize={logoSize} display="inline" />{" "}
            {value.file_name}
          </LinkOrSpan>
        </Tooltip>
      )
    }
    if (isDocumentOrURLStringType(fieldSchema, value)) {
      if (isObject(value)) {
        const documentSchema = fieldSchema?.anyOf?.find((s) => isDocumentType(s))
        if (!documentSchema) {
          log.warn("DocumentOrURLString field has no document schema", { fieldSchema, value })
        } else {
          return (
            <FieldValue
              fieldLabel={fieldLabel}
              value={value}
              fieldPath={fieldPath}
              fieldSchema={documentSchema}
              getDocumentDownloadUrl={getDocumentDownloadUrl}
              logoSize={logoSize}
              fieldMetadata={fieldMetadata}
            />
          )
        }
      }
      if (typeof value === "string") {
        const linkSchema = fieldSchema?.anyOf?.find((s) => isURLType(s))
        if (!linkSchema) {
          log.warn("DocumentOrURLString field has no URL schema", { fieldSchema, value })
        } else {
          return (
            <FieldValue
              fieldLabel={fieldLabel}
              value={value}
              fieldPath={fieldPath}
              fieldSchema={linkSchema}
              fieldMetadata={fieldMetadata}
            />
          )
        }
      }
    }
    if (isCompliantWithDocumentType(fieldSchema, value)) {
      if (typeof value.compliant !== "boolean") {
        return intl.formatMessage({
          id: "field.value.empty",
          description: "Empty field value",
          defaultMessage: "Empty Value",
        })
      }
      if (value.compliant) {
        if (isObject(value.document)) {
          return intl.formatMessage({
            id: "field.value.yes.document",
            description: "yes value with accompanying document",
            defaultMessage: "Yes with document evidence",
          })
        }
        if (typeof value.document === "string") {
          return intl.formatMessage(
            {
              id: "field.value.yes.url",
              description: "yes value with accompanying URL",
              defaultMessage: "Yes with linked evidence ({url})",
            },
            {
              url: (
                <FieldValue
                  fieldLabel={fieldLabel}
                  value={value.document}
                  fieldPath={fieldPath}
                  fieldSchema={HttpUrlString}
                  getDocumentDownloadUrl={getDocumentDownloadUrl}
                />
              ),
            }
          )
        }
        return intl.formatMessage({
          id: "field.boolean.yes",
          description: "Boolean field value `yes`",
          defaultMessage: "Yes",
        })
      }
      return intl.formatMessage({
        id: "field.boolean.no",
        description: "Boolean field value `no`",
        defaultMessage: "No",
      })
    }

    if (isApplicableWithDocumentType(fieldSchema, value)) {
      if (typeof value.applicable !== "boolean") {
        return intl.formatMessage({
          id: "field.value.empty",
          description: "Empty field value",
          defaultMessage: "Empty Value",
        })
      }
      if (value.applicable) {
        if (isObject(value.document)) {
          return intl.formatMessage({
            id: "field.value.yes.document",
            description: "yes value with accompanying document",
            defaultMessage: "Yes with document evidence",
          })
        }
        if (typeof value.document === "string") {
          return intl.formatMessage(
            {
              id: "field.value.yes.url",
              description: "yes value with accompanying URL",
              defaultMessage: "Yes with linked evidence ({url})",
            },
            {
              url: (
                <FieldValue
                  fieldLabel={fieldLabel}
                  value={value.document}
                  fieldPath={[...fieldPath, "document"]}
                  fieldSchema={HttpUrlString}
                  fieldMetadata={fieldMetadata}
                />
              ),
            }
          )
        }
        return intl.formatMessage({
          id: "field.boolean.yes",
          description: "Boolean field value `yes`",
          defaultMessage: "Yes",
        })
      }
      return intl.formatMessage({
        id: "field.boolean.no",
        description: "Boolean field value `no`",
        defaultMessage: "No",
      })
    }

    if (fieldSchema.type === "array" && Array.isArray(value)) {
      // If the array items will be rendered as icons, we don't want to render them as a comma-separated list.
      if (isReceiptType(fieldSchema.items) || isIntegrationType(fieldSchema.items)) {
        return value.map((item, index) => (
          <Fragment key={index}>
            <FieldValue
              fieldLabel={fieldLabel}
              key={index}
              value={item}
              fieldPath={[...fieldPath, index]}
              fieldSchema={fieldSchema.items}
              logoSize={logoSize}
            />{" "}
          </Fragment>
        ))
      }
      return (
        <FormattedList
          style="narrow"
          value={value.map((item, index) => (
            <FieldValue
              fieldLabel={fieldLabel}
              key={index}
              value={item}
              fieldPath={[...fieldPath, index]}
              fieldSchema={fieldSchema.items}
              logoSize={logoSize}
            />
          ))}
        />
      )
    }

    if (fieldSchema.type === "object" && hasDisplayName(value)) {
      return <ValueWithExtractedIcon fieldName={fieldName} value={value.display_name} fieldMetadata={fieldMetadata} />
    }
  }

  log.error("Don’t know how to render value, unknown schema", { value, fieldSchema })
  return <FormattedMessage defaultMessage="Unknown Value" description="Unknown field value" id="field.value.unknown" />
}

const ValueWithExtractedIcon = ({
  fieldName,
  value,
  fieldMetadata,
}: {
  fieldName?: string
  value: ReactNode
  fieldMetadata?: FieldMetadataWithSuggestions
}) => {
  if (!fieldMetadata || !fieldMetadata.type) {
    return value
  }
  return (
    <chakra.span
      display="inline-flex"
      alignItems="center"
      justifyContent="space-between"
      overflow="hidden"
      gap={1}
      maxW="100%"
    >
      <chakra.span flexShrink={1} textOverflow="ellipsis" overflow="hidden">
        {value}
      </chakra.span>
      <chakra.span flexShrink={0} display="inline-flex">
        <FieldSource fieldName={fieldName} fieldMetadata={fieldMetadata} boxSize={3} />
      </chakra.span>
    </chakra.span>
  )
}
