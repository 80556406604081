import {
  Box,
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  Textarea,
  Tooltip,
  useToast,
  type UseModalProps,
} from "@chakra-ui/react"
import type { ReactElement } from "react"
import { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { HelpIcon, RefreshIcon } from "../../components/icons/icons.js"

type PersonaKey = "professional" | "friendly" | "technical" | "concise"

interface Persona {
  label: string
  description: string
  prompt: string
}

const PERSONAS: Record<PersonaKey, Persona> = {
  professional: {
    label: "Professional",
    description: "Clear, formal, and business-appropriate communication style",
    prompt: `
I will write in a clear, formal, and business-appropriate style. I'll use professional language and maintain a polite, respectful tone throughout.

Examples:
I recommend we proceed with the implementation instead of "lets do it"

I'll avoid casual expressions and maintain appropriate business etiquette while ensuring the message remains accessible and easy to understand.`.trim(),
  },
  friendly: {
    label: "Friendly",
    description: "Warm, approachable, and conversational tone",
    prompt:
      `I will write in a warm, approachable, and conversational tone. I'll use friendly language and express genuine enthusiasm where appropriate.

Examples:
Looking forward to catching up with you at 3 PM!
Instead of: The meeting is scheduled for 3 PM

I'll maintain a positive and engaging tone while still being professional and respectful.`.trim(),
  },
  technical: {
    label: "Technical",
    description: "Precise, detailed, and technically-oriented language",
    prompt:
      `I will use precise, detailed, and technically-oriented language. I'll include specific terminology, metrics, and technical details when relevant.

Examples:
The page load time exceeds 3 seconds, with the main bottleneck being unoptimized image assets averaging 2.5MB in size
Instead of: The website is slow

I'll prioritize accuracy and thoroughness in my explanations while maintaining clarity.`.trim(),
  },
  concise: {
    label: "Concise",
    description: "Brief, direct, and to-the-point communication",
    prompt:
      `I will communicate briefly and directly, focusing on essential information only. I'll eliminate unnecessary words and get straight to the point.

Examples:
Project timeline updated
Instead of: I wanted to reach out to you to let you know that the project timeline has been updated

I'll prioritize brevity while ensuring all crucial information is conveyed.`.trim(),
  },
}

interface WritingPersonaModalProps extends UseModalProps {
  onPersonaUpdate: (persona: string) => void
  userPersona: string | null
  isUpdating: boolean
}

type ModalScreens = "personaSelection" | "customization"

const maxChars = 999

export function WritingPersonaModal({
  isOpen,
  onClose,
  onPersonaUpdate,
  userPersona,
  isUpdating,
}: WritingPersonaModalProps) {
  const intl = useIntl()
  const toast = useToast()

  const [state, setState] = useState<{
    modalState: ModalScreens
    writingPersona: string
  }>({
    modalState: userPersona === null ? "personaSelection" : "customization",
    writingPersona: userPersona ?? "",
  })

  useEffect(() => {
    setState((prev_state) => ({
      ...prev_state,
      modalState: userPersona === null ? "personaSelection" : "customization",
      writingPersona: userPersona ?? "",
    }))
  }, [userPersona])

  const handlePersonaSelect = (prompt: string) => {
    setState({
      ...state,
      writingPersona: prompt,
      modalState: "customization",
    })
  }

  const handleSubmit = () => {
    try {
      onPersonaUpdate(state.writingPersona)
      onClose()
    } catch (_) {
      toast({
        title: "Error",
        description: "Failed to update writing persona",
        status: "error",
      })
    }
  }

  const screens: Record<ModalScreens, ReactElement> = {
    personaSelection: (
      <ModalBody display="flex" flexDirection="column" gap={3}>
        <Text fontSize="md">
          <FormattedMessage
            id="writingPersona.modal.description"
            description="Description text for the writing style modal"
            defaultMessage="Pick a writing style to set the tone, then fine-tune as needed"
          />
        </Text>

        <Stack spacing={3}>
          {(Object.entries(PERSONAS) as [PersonaKey, Persona][]).map(([key, persona]) => (
            <Button
              key={key}
              onClick={() => handlePersonaSelect(persona.prompt)}
              height="auto"
              py={3}
              px={4}
              _hover={{ bg: "brand.50" }}
            >
              <Stack spacing={0} align="flex-start" flex={1}>
                <Text fontWeight="medium">{persona.label}</Text>
                <Text fontSize="sm" color="gray.600">
                  {persona.description}
                </Text>
              </Stack>
            </Button>
          ))}
        </Stack>
      </ModalBody>
    ),
    customization: (
      <>
        <ModalBody>
          <Stack spacing={4}>
            <Text fontSize="md">
              <FormattedMessage
                id="writingPersona.modal.customization"
                description="Description text for the writing style customization screen"
                defaultMessage="Fine-tune your style"
              />
            </Text>

            <Textarea
              value={state.writingPersona}
              onChange={(e) => setState({ ...state, writingPersona: e.target.value })}
              maxLength={maxChars}
              minHeight={250}
              placeholder={intl.formatMessage({
                id: "writingPersona.modal.promptPlaceholder",
                description: "Placeholder text for writing style textarea",
                defaultMessage: "Describe your writing style...",
              })}
            />
            <Text fontSize="xs" color="gray.500" alignSelf="flex-end">
              {`${state.writingPersona.length}/${maxChars}`}
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter display="flex" flexBasis="start" gap={3}>
          <Button variant="ghost" onClick={() => setState({ ...state, modalState: "personaSelection" })}>
            <FormattedMessage
              id="writingPersona.modal.back"
              description="Back button text"
              defaultMessage="View Presets"
            />
          </Button>
          {/* button to reset back to original persona */}
          <Button
            variant="ghost"
            onClick={() => setState({ ...state, writingPersona: userPersona ?? "", modalState: "customization" })}
            leftIcon={<Icon as={RefreshIcon} />}
            isDisabled={userPersona === null || userPersona === state.writingPersona || isUpdating}
          >
            <FormattedMessage id="writingPersona.modal.reset" description="Reset button text" defaultMessage="Reset" />
          </Button>
          <Spacer />
          <Button
            variant="ghost"
            colorScheme="brand"
            onClick={handleSubmit}
            isDisabled={userPersona !== null && userPersona === state.writingPersona}
            isLoading={isUpdating}
          >
            <FormattedMessage id="writingPersona.modal.apply" description="Apply button text" defaultMessage="Save" />
          </Button>
        </ModalFooter>
      </>
    ),
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent height={480}>
        <ModalHeader display="flex" gap={2}>
          <FormattedMessage
            id="writingPersona.modal.title"
            description="Title of the writing style selection modal"
            defaultMessage="Your Writing Persona"
          />
          <Tooltip
            label={
              <Text padding={1}>
                {intl.formatMessage({
                  id: "writingPersona.modal.help",
                  description: "Help text for the writing style selection modal",
                  defaultMessage:
                    "Your writing persona is used to generate personalized AI responses. Edit this content to align our agents with your voice.",
                })}
              </Text>
            }
            placement="top"
          >
            <Box>
              <Icon as={HelpIcon} color="gray.400" />
            </Box>
          </Tooltip>
          <ModalCloseButton />
        </ModalHeader>
        {screens[state.modalState]}
      </ModalContent>
    </Modal>
  )
}
